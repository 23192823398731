import { isFunction } from "lodash";
import { t } from "@/i18n-js/instance";
import { useGamificationSettings } from "@circle-react/components/SettingsApp/Gamification/LevelsForm/gamificationApi";
import {
  Filter,
  SingleSelectFilter,
  SingleSelectFilterSelectedText,
} from "@circle-react-shared/Filter";
import { Loader } from "@circle-react-uikit/Loader";
import type { BaseFilter } from "../types";

const localeNamespace = "members_directory.header.search.filters.levels";

interface LevelFilterProps {
  isInitialOpen?: boolean;
  onApply: (value: BaseFilter) => void;
  param: BaseFilter;
}

export const LevelFilter = ({
  isInitialOpen = false,
  onApply,
  param,
}: LevelFilterProps) => {
  const { data, isLoading } = useGamificationSettings();
  const levelItems = data?.levels?.map(level => ({
    label: level.name,
    value: level.level.toString(),
  }));
  return (
    <Filter
      chip={t([localeNamespace, "singular_title"])}
      title={t([localeNamespace, "title"])}
      selectedText={param.value}
      renderSelectedText={() => (
        <SingleSelectFilterSelectedText items={levelItems} param={param} />
      )}
      isInitialOpen={isInitialOpen}
      onApply={value => {
        if (isFunction(onApply)) {
          onApply(value);
        }
      }}
      shouldShowRemoveButton
    >
      {isLoading ? (
        <Loader />
      ) : (
        <SingleSelectFilter
          options={levelItems || []}
          legend={t([localeNamespace, "singular_legend"])}
          param={param}
        />
      )}
    </Filter>
  );
};
