import { MemberTagsVisibleInMemberList as getMemberTagsVisibleInMemberList } from "@circle-react/helpers/memberTagsHelper";
import { useProfileLinkProps } from "@circle-react/hooks/profile/useProfileLinkProps";
import type { MemberTag } from "@circle-react/types";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { MemberTags } from "@circle-react-uikit/MemberTags";
import { Bio } from "./Bio";
import { CardHeader } from "./CardHeader";

export interface CommunityMemberProps {
  communityMember: any;
  shouldShowHiddenMemberTooltip?: boolean;
}

export const CommunityMember = ({
  communityMember,
  shouldShowHiddenMemberTooltip = false,
}: CommunityMemberProps) => {
  const memberTags = getMemberTagsVisibleInMemberList({
    memberTags: communityMember.member_tags,
  });
  const profileLinkProps = useProfileLinkProps({
    public_uid: communityMember.public_uid,
  });

  const visibleMemberTags = memberTags.filter(
    (memberTag: MemberTag) =>
      memberTag?.is_public && memberTag?.display_locations?.member_directory,
  );

  return (
    <li
      className="border-primary bg-primary hover:shadow-c-card group rounded-lg border px-4 pb-5 pt-4 first:mt-4 first:md:mt-0"
      data-testid="community_member"
    >
      <a className="flex w-full gap-x-4" {...profileLinkProps}>
        <div>
          <UserImage
            name={communityMember.name}
            src={communityMember.avatar_url}
            size="16"
          />
        </div>
        <section className="flex min-w-0 flex-1 flex-col gap-y-3">
          <div className="flex flex-col gap-y-2">
            <CardHeader
              communityMember={communityMember}
              shouldShowHiddenMemberTooltip={shouldShowHiddenMemberTooltip}
            />
            <Bio text={communityMember.bio} />
          </div>
          <MemberTags
            memberTags={visibleMemberTags}
            limit={5}
            shouldShowActivityScore
            memberId={communityMember.id}
          />
        </section>
      </a>
    </li>
  );
};
