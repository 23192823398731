import { useEffect } from "react";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { useIsMarketingHubStatusEnabled } from "@circle-react/hooks/useIsMarketingHubStatus";
import { Modal } from "@circle-react-uikit/ModalV2";
import { LandingPage } from "./";

const MarketingHubModalComponent = ModalManager.create(() => {
  const modal = useModal();
  const onClose = () => modal.hide();

  const isMarketingHubEnabled = useIsMarketingHubStatusEnabled();

  useEffect(() => {
    if (isMarketingHubEnabled) {
      void modal.hide();
    }
  }, [isMarketingHubEnabled]);

  return (
    <Modal isOpen={modal.visible} onClose={onClose}>
      <Modal.Overlay />
      <Modal.Content size="2xl">
        <Modal.Header className="flex flex-row-reverse md:hidden">
          <Modal.CloseButton onClick={onClose} />
        </Modal.Header>
        <Modal.Body>
          <LandingPage modal />
        </Modal.Body>
      </Modal.Content>
      <Modal.Footer>
        <div className="py-4" />
      </Modal.Footer>
    </Modal>
  );
});

export const useMarketingHubModal = () => useModal(MarketingHubModalComponent);
