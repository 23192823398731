import { useEffect, useMemo } from "react";
import { isFunction } from "lodash";
import type { SelectDataLabelProps } from "@circle-react/components/SettingsApp/InvitationLinks/interfaces";
import {
  PAYWALL_STATUS,
  paywallsEligibleForTrial,
} from "@circle-react/helpers/paywallHelpers";
import { usePaywallList } from "@circle-react/hooks/paywalls/usePaywallList";
import { useCreatePaywallSubscriptionFormContext } from "../CreatePaywallSubscriptionFormContextProvider";

type ValueOf<T> = T[keyof T];
export type PaywallStatusArray = Array<ValueOf<typeof PAYWALL_STATUS>>;
interface UsePaywallFormProps {
  communityMemberId?: number | null;
  paywallStatuses?: PaywallStatusArray;
}

export const paywallStatusesDefault: PaywallStatusArray = [
  PAYWALL_STATUS.ACTIVE,
  PAYWALL_STATUS.INACTIVE,
];

export const usePaywallForm = ({
  communityMemberId,
  paywallStatuses = paywallStatusesDefault,
}: UsePaywallFormProps) => {
  const { paywallsListQuery } = usePaywallList({
    communityMemberId,
    status: paywallStatuses,
  });

  const [paywallOptions, paywallMappedById, firstValidPaywallId]: any[] =
    useMemo(() => {
      const paywallOptions: SelectDataLabelProps[] = [];
      const paywallMappedById: any = {};
      const paywallListData = !paywallsListQuery.isLoading
        ? paywallsListQuery.data
        : [];
      let firstValidPaywallId = null;

      paywallsEligibleForTrial(paywallListData).forEach(paywall => {
        firstValidPaywallId ||= paywall?.id;

        paywallOptions.push({
          label: paywall?.name || paywall?.display_name,
          value: paywall?.id,
        });

        paywallMappedById[paywall.id] = { ...paywall };
      });

      return [paywallOptions, paywallMappedById, firstValidPaywallId];
    }, [paywallsListQuery.isLoading, paywallsListQuery.data]);

  const createPaywallSubscriptionFormContext =
    useCreatePaywallSubscriptionFormContext();

  useEffect(() => {
    if (
      createPaywallSubscriptionFormContext &&
      "setPaywallsIndex" in createPaywallSubscriptionFormContext &&
      isFunction(createPaywallSubscriptionFormContext.setPaywallsIndex)
    ) {
      const { setPaywallsIndex } = createPaywallSubscriptionFormContext;
      setPaywallsIndex(paywallMappedById ?? {});
    }
  }, [paywallMappedById, createPaywallSubscriptionFormContext]);

  return {
    paywallsListQuery,
    paywallOptions,
    firstValidPaywallId,
  };
};
