import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { useButtonVariant } from "@circle-react/helpers/useButtonVariant";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { useFilterContext } from "../Filter";

export const InputFilter = ({ name, ...rest }) => {
  const { selectedText, onApply } = useFilterContext();
  const handleApply = (submitData, { reset }) => {
    onApply(submitData[name]);
    reset();
  };
  const primaryButtonVariant = useButtonVariant();

  return (
    <Form defaultValues={{ [name]: selectedText }} onSubmit={handleApply}>
      <div className="flex min-w-[18rem] flex-col gap-y-4 p-4">
        <Form.Input name={name} autoFocus {...rest} />
        <Button variant={primaryButtonVariant} type="submit" full>
          {t("filters.apply")}
        </Button>
      </div>
    </Form>
  );
};

InputFilter.propTypes = {
  /** name for input, also used for default value in form */
  name: PropTypes.string.isRequired,
};
