import PropTypes from "prop-types";
import classnames from "classnames";

export const Container = ({ maxWidth = "lg", className, children }) => {
  const maxWidths = {
    md: "lg:max-w-2xl",
    lg: "lg:max-w-5xl",
    full: "max-w-full",
  };

  const maxWidthClass = maxWidths[maxWidth] || maxWidths.lg;

  return (
    <div
      className={classnames(
        "lg:mx-auto lg:flex lg:gap-6",
        maxWidthClass,
        className,
      )}
    >
      {children}
    </div>
  );
};

Container.propTypes = {
  maxWidth: PropTypes.oneOf(["md", "lg", "full"]),
  className: PropTypes.string,
  children: PropTypes.node,
};
