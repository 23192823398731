import PropTypes from "prop-types";
import classNames from "classnames";
import noop from "lodash/noop";
import { Button } from "./Button";
import { Connector } from "./Connector";

export const BlockConnector = ({
  hasTopConnection = true,
  hasButton = true,
  hasBottomConnection = true,
  onClick = noop,
  className,
}) => {
  if (!hasTopConnection && !hasButton && !hasBottomConnection) return null;

  if (!hasButton && (hasTopConnection || hasBottomConnection)) {
    return (
      <div className={className}>
        <Connector />
      </div>
    );
  }

  return (
    <div className={classNames("flex w-6 flex-col items-center", className)}>
      {hasTopConnection && <Connector />}
      {hasButton && <Button onClick={onClick} />}
      {hasBottomConnection && <Connector />}
    </div>
  );
};

BlockConnector.propTypes = {
  hasTopConnection: PropTypes.bool,
  hasButton: PropTypes.bool,
  hasBottomConnection: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
