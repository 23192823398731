import { Form } from "@circle-react-uikit/Form";
import { formId } from "../constants";
import { useRefundTypeForm } from "./hooks/useRefundTypeForm";

export interface RefundTypeFormProps {
  refundOptionsSubscriptionData: any;
}

export const RefundTypeForm = ({
  refundOptionsSubscriptionData,
}: RefundTypeFormProps) => {
  const { selectionOptions, isDisabled } = useRefundTypeForm({
    refundOptionsSubscriptionData,
  });

  if (isDisabled) {
    return null;
  }

  return (
    <div className="pb-2 pt-5">
      <Form.Item
        className="border-0"
        labelClassName="text-xs font-semibold"
        name="refund_type"
        translationRoot="settings.paywalls_admin_dashboard.subscriptions.cancel_and_refund_modal.refund_type_options"
        hideDescription
        hiddenField
        id={formId}
      >
        <Form.SelectNative
          name="refund_type"
          options={selectionOptions}
          disabled={isDisabled}
        />
      </Form.Item>
    </div>
  );
};
