import { useMemo } from "react";
import { snakeCase } from "lodash";
import { t } from "@/i18n-js/instance";
import { SanitizeHTMLString } from "@circle-react/components/shared/uikit/SanitizeContent";
import { ConfirmationModal } from "@circle-react-shared/ModalsV2/ConfirmationModal";

interface GetContentReturn {
  title: string;
  confirmVariant: "danger" | "primary" | "circle";
  onConfirm: () => void;
  disabled: boolean;
}

const getContent = (
  onConfirm: any,
  destroyMutation: any,
): GetContentReturn => ({
  title: t("settings.paywalls_admin_dashboard.remove_attribution_modal.title"),
  confirmVariant: "danger",
  onConfirm,
  disabled: Boolean(destroyMutation?.isLoading),
});

export interface RemoveAffiliateAttributionConfirmationModalProps {
  attributable: any;
  onConfirm: () => void;
  isOpen: boolean;
  onClose: () => void;
  destroyMutation: any;
}

export const RemoveAffiliateAttributionConfirmationModal = ({
  attributable,
  onConfirm,
  isOpen,
  onClose,
  destroyMutation,
}: RemoveAffiliateAttributionConfirmationModalProps) => {
  const content = useMemo(
    () => getContent(onConfirm, destroyMutation),
    [onConfirm, destroyMutation],
  );
  const {
    paywalls_affiliates_attribution_info: { attributable_type },
  } = attributable;

  return (
    <ConfirmationModal isOpen={isOpen} onClose={onClose} {...content}>
      <SanitizeHTMLString
        content={t(
          `settings.paywalls_admin_dashboard.remove_attribution_modal.${snakeCase(
            attributable_type,
          )}.description`,
        )}
      />
    </ConfirmationModal>
  );
};
