import { Typography } from "@circle-react-uikit/Typography";

interface AccessGroupTitleProps {
  description?: string;
  title?: string;
  areFormItemsEmbedded: boolean;
}
export const AccessGroupHeader = ({
  description,
  title,
  areFormItemsEmbedded,
}: AccessGroupTitleProps) => {
  const Title = areFormItemsEmbedded ? Typography.LabelMd : Typography.TitleSm;
  const Description = areFormItemsEmbedded
    ? Typography.BodySm
    : Typography.BodyMd;
  return (
    <>
      {title && <Title weight="semibold">{title}</Title>}
      {description && (
        <div className="pt-2">
          <Description
            weight={areFormItemsEmbedded ? "normal" : "semibold"}
            color={areFormItemsEmbedded ? "text-default " : "text-dark"}
          >
            {description}
          </Description>
        </div>
      )}
    </>
  );
};
