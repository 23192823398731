import { WithIcon } from "./WithIcon";
import { WithLogo } from "./WithLogo";
import { WithName } from "./WithName";

export interface LogoProps {
  bgColor?: string;
  iconUrl?: string | null;
  includeName?: boolean;
  logoURL?: string | null;
  name: string;
  textColor?: string;
}

export const Logo = ({
  logoURL,
  name,
  iconUrl,
  textColor,
  bgColor,
  includeName = false,
}: LogoProps) => {
  if (logoURL) {
    return <WithLogo logoURL={logoURL} name={name} />;
  }

  if (iconUrl) {
    return <WithIcon iconUrl={iconUrl} name={name} />;
  }

  return (
    <WithName
      name={name}
      textColor={textColor}
      bgColor={bgColor}
      includeName={includeName}
    />
  );
};
