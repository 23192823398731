import type { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";

interface ToolbarButtonProps
  extends ComponentPropsWithoutRef<typeof IconButton> {
  isActive: boolean;
}

export function ToolbarButton({
  isActive,
  iconSize = 16,
  ...rest
}: ToolbarButtonProps) {
  return (
    <IconButton
      iconSize={iconSize}
      buttonSize={32}
      iconClassName={classNames(isActive ? "!text-dark" : "text-default")}
      className={classNames(
        "rounded border",
        isActive ? "bg-tertiary border-light" : "border-transparent",
      )}
      {...rest}
    />
  );
}
