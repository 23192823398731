import { useState } from "react";
import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { useButtonVariant } from "@circle-react/helpers/useButtonVariant";
import { Button } from "@circle-react-uikit/Button";
import { Fieldset } from "@circle-react-uikit/Fieldset";
import { Form } from "@circle-react-uikit/Form";
import { useFilterContext } from "../../Filter";
import { SearchBar } from "./SearchBar";

export const CheckboxFilter = ({
  options,
  name,
  legend,
  hasSearch,
  searchProps,
  activeText,
}) => {
  const { onApply } = useFilterContext();
  const [searchTerm, setSearchTerm] = useState("");
  const primaryButtonVariant = useButtonVariant();

  const filteredOptions = options.filter(option =>
    !searchTerm
      ? option
      : option.label.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const getSelectedItems = () => {
    if (Array.isArray(activeText)) {
      return activeText.map(item => +item);
    }
    return activeText?.split(",") ?? [];
  };

  const getDefaultValues = () => {
    const selectedItems = getSelectedItems();
    const defaults = options.reduce((acc, option) => {
      acc[option.value] = selectedItems.includes(option.value);
      return acc;
    }, {});
    return {
      [name]: defaults,
    };
  };

  const handleApply = (submitData, { reset }) => {
    // Find items with value true
    const checkedValues = Object.entries(submitData[name]).filter(
      ([, value]) => value,
    );
    const checkedKeys = checkedValues.map(([key]) => key).join(",");
    onApply(checkedKeys);
    reset();
  };

  return (
    <div className="flex flex-col gap-y-5 p-4">
      {hasSearch && (
        <SearchBar
          name={name}
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          searchProps={searchProps}
        />
      )}
      <Form defaultValues={getDefaultValues()} onSubmit={handleApply}>
        <div className="flex flex-col gap-y-3">
          <div className="max-h-[20rem] gap-y-4 overflow-y-auto p-1">
            <Fieldset legend={legend} verticalSpacing="tight">
              {filteredOptions.map(option => (
                <Form.Item
                  key={option.value}
                  name={`${name}.${option.value}`}
                  id={`${name}-${option.value}`}
                  hideLabel
                  hideBorder
                  hideDescription
                  isFirstChild
                  className="!pb-0"
                >
                  <Form.Checkbox
                    option={option}
                    labelClassName="text-base font-normal"
                    className="!mt-0"
                  />
                </Form.Item>
              ))}
            </Fieldset>
          </div>
          <Button variant={primaryButtonVariant} type="submit" full>
            {t("filters.apply")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

SearchBar.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  searchProps: PropTypes.object,
};

CheckboxFilter.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      richLabel: PropTypes.element,
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
  ),
  legend: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  /** Display a search bar */
  hasSearch: PropTypes.bool,
  /** Passed to search input */
  searchProps: PropTypes.shape({
    searchPlaceholder: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
  }),
  activeText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
};
