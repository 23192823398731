import { useRouteMatch } from "react-router-dom";
import { isSubscriptionExpired } from "@/react/helpers/communityHelpers";
import {
  communityBillingPath,
  planSettingsPath,
} from "@/react/helpers/urlHelpers";
import { usePunditUserContext } from "@circle-react/contexts";

export const useProtectedRoute = () => {
  const isCurrentRouteWhitelisted = Boolean(
    useRouteMatch([planSettingsPath(), communityBillingPath()]),
  );

  const { currentCommunity } = usePunditUserContext();
  const hasExpiredSubscription = isSubscriptionExpired(currentCommunity);

  const canSeeProtectedRoute =
    isCurrentRouteWhitelisted || !hasExpiredSubscription;

  return {
    canSeeProtectedRoute,
  };
};
