import { useEffect } from "react";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { AccessGroupsSelect } from "./AccessGroupsSelect";
import { useAccessGroupSourcesData } from "./DataStoreContext/AccessGroupSourcesDataContext";
import { SelectedAccessGroups } from "./SelectedAccessGroups";
import { useAccessGroupsActions } from "./hooks/useAccessGroupsActions";

const i18nRoot = "settings.access_groups.admin.entry_points.association";

interface AccessGroupSourceFormContentProps {
  isUpdatingAccessGroupSources: boolean;
}
export const AccessGroupSourceFormContent = ({
  isUpdatingAccessGroupSources,
}: AccessGroupSourceFormContentProps) => {
  const { accessGroups, areFormItemsEmbedded } = useAccessGroupSourcesData();

  const {
    updateAccessGroupSourcesDefaults,
    finalOriginalAccessGroupSources,
    isFormDirty,
    areAccessGroupsSetFromFormDefaults,
  } = useAccessGroupsActions();

  useEffect(() => {
    // Only update the access groups if they are not set from the form defaults
    // TODO: standardize all entry points to inject access groups to the form instead os passing the recordId
    // to fetch the access groups from backend separately
    !areAccessGroupsSetFromFormDefaults &&
      updateAccessGroupSourcesDefaults(finalOriginalAccessGroupSources);
  }, [
    finalOriginalAccessGroupSources,
    updateAccessGroupSourcesDefaults,
    areAccessGroupsSetFromFormDefaults,
  ]);

  return (
    <>
      <AccessGroupsSelect />
      {accessGroups.length > 0 && (
        <>
          <SelectedAccessGroups />
          <div
            className={classNames("mt-6 flex", {
              "justify-between": isFormDirty,
              "justify-end": !isFormDirty,
            })}
          >
            {!areFormItemsEmbedded && (
              <>
                {isFormDirty && (
                  <Typography.LabelMd weight="medium">
                    {t([i18nRoot, "unsaved_changes"])}
                  </Typography.LabelMd>
                )}
                <Button
                  disabled={isUpdatingAccessGroupSources}
                  type="submit"
                  variant="circle"
                  large
                >
                  {t([i18nRoot, "actions.save"])}
                </Button>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};
