import {
  SmallRectangle,
  SmallSquareWithBorder,
} from "@circle-react-shared/Placeholders";

export const BookmarkItem = () => (
  <div className="flex flex-row items-center">
    <SmallSquareWithBorder />
    <SmallRectangle className="ml-2 w-24" />
  </div>
);
