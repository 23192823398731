import PropTypes from "prop-types";
import classNames from "classnames";
import { Icon } from "@circle-react-shared/Icon";
import { IconButton } from "./IconButton";

export const HandleIcon = ({ icon, dragEventListeners }) => {
  const isDraggable = Boolean(dragEventListeners);

  return (
    <span className="flex">
      <Icon
        className={classNames("flex", {
          "group-hover:hidden": isDraggable,
        })}
        type={icon}
        size={16}
        useWithFillCurrentColor
      />
      {isDraggable && (
        <IconButton
          className="hidden cursor-move group-hover:flex"
          {...dragEventListeners}
        >
          <Icon type="16-drag-handle" size={16} />
        </IconButton>
      )}
    </span>
  );
};

HandleIcon.propTypes = {
  icon: PropTypes.string,
  dragEventListeners: PropTypes.object,
};
