import PropTypes from "prop-types";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "@circle-react-uikit/Loader";
import { MemberCard } from "./MemberCard";

export const CardView = ({ memberDataParams }) => {
  const { members, fetchNextPage, hasNextPage } = memberDataParams;
  return (
    <InfiniteScroll
      style={{ overflow: "initial" }}
      scrollThreshold={0.8}
      next={fetchNextPage}
      hasMore={!!hasNextPage}
      loader={<Loader />}
      dataLength={members.length}
      scrollableTarget="panel"
    >
      <ol
        className={classNames(
          "grid-cols-member-directory-grid-mobile md:grid-cols-member-directory-grid m-4 grid list-none auto-rows-fr justify-center gap-6 md:m-0 lg:m-auto",
          /* Safari has an issue of overflowing content, this is added as a fix */
          "max-w-[-webkit-fill-available]",
        )}
        data-testid="directory_members_grid"
      >
        {members.map(member => (
          <MemberCard key={member.id} member={member} />
        ))}
      </ol>
    </InfiniteScroll>
  );
};

CardView.propTypes = {
  memberDataParams: PropTypes.shape({
    members: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      }),
    ),
    fetchNextPage: PropTypes.func,
    hasNextPage: PropTypes.bool,
  }).isRequired,
};
