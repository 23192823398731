import { useMutation, useQueryClient } from "react-query";
import { t } from "@/i18n-js/instance";
import { accessGroupsSourcesApi } from "@circle-react/api/admin/accessGroups/accessGroupsSourcesApi";
import { listAccessGroupSourceQueryKey } from "@circle-react/hooks/accessGroups/useListAccessGroupSourcesQuery";
import { useToast } from "@circle-react-shared/uikit/ToastV2";

interface UpdateAccessGroupsSourcesProps {
  access_groups: [];
  record_type: string;
  record_id: string;
}

export const useUpdateAccessGroupsSources = () => {
  const queryClient = useQueryClient();
  const toastr = useToast();
  const { manageAccessGroupSources } = accessGroupsSourcesApi();

  const { mutate: handleSubmit, isLoading } = useMutation({
    mutationFn: (params: UpdateAccessGroupsSourcesProps) =>
      manageAccessGroupSources({ params: params }),
    onSuccess: () => {
      toastr.success(
        t(
          "settings.access_groups.admin.entry_points.association.messages.changes_saved",
        ),
      );
      void queryClient.invalidateQueries(listAccessGroupSourceQueryKey);
      void queryClient.invalidateQueries(["pundit-user"]);
    },
    onError: (error: any) => toastr.error(error.message),
  });

  return {
    isUpdatingAccessGroupSources: isLoading,
    udpateAccessGroupSourcesHandler: handleSubmit,
  };
};
