import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-uikit/Typography";

export const NewChip = () => (
  <div className="border-v2-danger bg-v2-danger flex w-fit items-center space-x-2 rounded-2xl px-1 text-white">
    <Typography.LabelXxs
      weight="semibold"
      color="text-inherit"
      truncate
      data-testid="chip-title"
    >
      {t("settings.access_groups.admin.entry_points.association.new")}
    </Typography.LabelXxs>
  </div>
);
