import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import type { IconType } from "@circle-react-shared/Icon/IconNames";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface HeaderProp {
  localeRoot: string;
  backgroundColor: string;
  iconName: IconType;
}

export const Header = ({
  localeRoot,
  backgroundColor,
  iconName,
}: HeaderProp) => (
  <>
    <div>
      <div
        className="flex h-[60px] w-[60px] flex-col items-center justify-center rounded-full"
        style={{ backgroundColor }}
      >
        <Icon
          type={iconName}
          className="text-default !h-7 !w-7"
          viewBox="0 0 24 24"
          style={{ color: "#191B1F" }}
        />
      </div>
    </div>
    <div className="flex flex-col gap-y-3">
      <Typography.TitleLg color="text-[#2B2E33]">
        {t([localeRoot, "title"])}
      </Typography.TitleLg>
      <Typography.LabelLg color="text-[#2B2E33]" weight="bold">
        {t([localeRoot, "subtitle"])}
      </Typography.LabelLg>
    </div>
  </>
);
