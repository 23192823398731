import { useGamificationSettings } from "@circle-react/components/SettingsApp/Gamification/LevelsForm/gamificationApi";

export const useShouldShowLevelFilter = filters => {
  const { data: gamificationSettings } = useGamificationSettings();

  if (gamificationSettings?.enabled) {
    return filters;
  }

  return filters.filter(filter => filter.name !== "level");
};
