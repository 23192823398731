import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import type { RenderFilter } from "./types";

export const useShouldShowInvitationLinksFilter = (filters: RenderFilter[]) => {
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);

  if (isAdmin) {
    return filters;
  }

  return filters.filter(filter => filter.name !== "invitation_link_ids");
};
