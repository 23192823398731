import type { ReactNode } from "react";

export interface ContainerProps {
  children: ReactNode;
}
export const Container = ({ children }: ContainerProps) => (
  <section className="mx-auto flex max-w-7xl flex-col gap-y-6 px-4">
    {children}
  </section>
);
