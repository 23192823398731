import classNames from "classnames";
import { t } from "@/i18n-js/instance";

export interface WithIconProps {
  iconUrl: string;
  name: string;
  className?: string;
  imageClassName?: string;
}

export const WithIcon = ({
  iconUrl,
  name,
  className = "",
  imageClassName = "",
}: WithIconProps) => (
  <div
    className={classNames(
      "brand-icon brand-icon__image !h-8 !w-8 !rounded-lg !outline-none",
      className,
    )}
  >
    <img
      loading="eager"
      className={classNames("!max-h-8 !max-w-8 rounded-lg", imageClassName)}
      alt={t("community_logo_alt", { community_name: name })}
      src={iconUrl}
      style={{ width: 32, height: 32 }}
    />
  </div>
);
