import { Slider } from "@circle-react-shared/uikit/Slider";
import { SliderLabel } from "./SliderLabel";

interface SliderWithLabelsProps {
  value?: number[];
  onChange: (value: number[]) => void;
  onBlur: () => void;
  defaultValue?: number[];
  max?: number;
  step?: number;
}

export function SliderWithLabels({
  value,
  onChange,
  onBlur,
  defaultValue = [0, 10],
  max = 10,
  step = 1,
}: SliderWithLabelsProps) {
  const internalValue = value ?? defaultValue;

  return (
    <div className="flex flex-col gap-y-5">
      <div className="flex justify-between">
        <SliderLabel type="min" value={internalValue?.[0]} />
        <SliderLabel type="max" value={internalValue?.[1]} />
      </div>
      <Slider.Root
        value={value}
        defaultValue={defaultValue}
        onValueChange={onChange}
        onBlur={onBlur}
        max={max}
        step={step}
        minStepsBetweenThumbs={1}
      >
        <Slider.Track>
          <Slider.Range />
        </Slider.Track>
        <Slider.Thumb />
        <Slider.Thumb />
      </Slider.Root>
    </div>
  );
}
