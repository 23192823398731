import type { ReactNode } from "react";
import { useNavContext } from ".";
import { Typography } from "@circle-react-uikit/Typography";

interface NavGroupContentProps {
  title: string;
  children: ReactNode;
}

export const NavGroupContent = ({ title, children }: NavGroupContentProps) => {
  const { isActive } = useNavContext();
  if (!isActive) {
    return null;
  }

  return (
    <>
      <Typography.TitleSm weight="semibold">{title}</Typography.TitleSm>
      <div
        className="mt-3 flex flex-col space-y-px"
        data-testid="nav-group-content"
      >
        {children}
      </div>
    </>
  );
};
