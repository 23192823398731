import { usePaywallFilterList } from "@circle-react/hooks/paywalls/usePaywallFilterList";
import { paywallTypes } from "@circle-react/types";
import { DropdownFilter } from "../DropdownFilter";

export const PaywallsFilter = () => {
  const { paywalls, isPaywallsLoading } = usePaywallFilterList("paywalls", {
    type: [paywallTypes.space],
  });

  if (isPaywallsLoading && !paywalls.length) {
    return null;
  }

  return (
    <DropdownFilter
      queryParam="paywalls"
      type="multiselect"
      options={paywalls}
    />
  );
};
