import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import type { BlockedActionInfoModalProps } from "./BlockedActionInfoModal";
import { BlockedActionInfoModal } from "./BlockedActionInfoModal";

const BlockedActionInfoModalComponent = ModalManager.create(
  (props: BlockedActionInfoModalProps) => <BlockedActionInfoModal {...props} />,
);

export const useBlockedActionInfoModal = () =>
  useModal(BlockedActionInfoModalComponent);
