import { isFunction } from "lodash";
import { t } from "@/i18n-js/instance";
import { useCommunityAiUpgradeModal } from "@/react/components/SettingsApp/CommunityAi/UpgradeModal";
import { useShouldShowCommunityAiModal } from "@circle-react/hooks/useIsActivityScoreEnabled";
import { Filter } from "@circle-react-shared/Filter";
import { FilterChip } from "@circle-react-shared/Filter/FilterChip";
import { SliderFilter } from "@circle-react-shared/Filter/FilterTypes/SliderFilter";
import { isSliderFilterParam } from "@circle-react-shared/Filter/FilterTypes/SliderFilter/SliderFilter";
import { UPGRADE_FLOW_SOURCES } from "@circle-react-shared/UpgradePlan/constants";
import type { BaseFilter } from "../types";

const localeNamespace = "members_directory.header.search.filters";

export const renderSelectedOptionLabel = (param: BaseFilter) => {
  if (isSliderFilterParam(param)) {
    return `${param.gte} - ${param.lte}`;
  }

  return "";
};

export interface ActivityScoreFilterProps {
  isInitialOpen?: boolean;
  onApply?: (value?: BaseFilter) => void;
  param: BaseFilter;
}

export const ActivityScoreFilter = ({
  isInitialOpen,
  onApply,
  param,
}: ActivityScoreFilterProps) => {
  const communityAiModal = useCommunityAiUpgradeModal();
  const shouldShowCommunityAiModal = useShouldShowCommunityAiModal();

  const handleClick = async () => {
    await communityAiModal.show({
      source: UPGRADE_FLOW_SOURCES.ACTIVITY_SCORES_FILTER_CHIP,
    });
  };

  if (shouldShowCommunityAiModal) {
    return (
      <button type="button" className="cursor-pointer" onClick={handleClick}>
        <FilterChip>{t([localeNamespace, "activity_score"])}</FilterChip>
      </button>
    );
  }

  return (
    <Filter
      chip={t([localeNamespace, "activity_score"])}
      title={t([localeNamespace, "activity_score"])}
      selectedText={
        isSliderFilterParam(param)
          ? renderSelectedOptionLabel(param)
          : undefined
      }
      isInitialOpen={isInitialOpen}
      onApply={value => {
        if (isFunction(onApply)) {
          onApply(value);
        }
      }}
      shouldShowRemoveButton
    >
      <SliderFilter param={param} />
    </Filter>
  );
};
