import { t } from "@/i18n-js/instance";
import { DropdownFilter } from "../DropdownFilter";

export const RoleFilter = () => {
  const roleOptions = [
    { label: t("role_admin") },
    { label: t("member") },
    { label: t("moderator") },
  ];

  return (
    <DropdownFilter queryParam="role" type="select" options={roleOptions} />
  );
};
