import { t } from "@/i18n-js/instance";
import { PaymentMethodColumn } from "@circle-react-shared/Paywalls/PaymentMethodColumn";
import { PropertyLine } from "../PropertyLine";

const localeStringRoot =
  "settings.paywalls_admin_dashboard.charges.charge_details_modal";

export const PaymentMethodProperty = ({ charge }: any) =>
  charge.payment_method_type && (
    <PropertyLine
      title={t(`${localeStringRoot}.details_labels.payment_method.title`)}
    >
      <PaymentMethodColumn charge={charge} />
    </PropertyLine>
  );
