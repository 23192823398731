import { createContext, useContext, useState } from "react";
import { usePunditUserContext } from "@circle-react/contexts";

const CreatePaywallSubscriptionFormContext =
  createContext<CreatePaywallSubscriptionFormContextProp>({});
CreatePaywallSubscriptionFormContext.displayName =
  "CreatePaywallSubscriptionFormContext";

interface CreatePaywallSubscriptionFormContextProp {
  communityId?: number;
  communityMemberId?: string;
  paywallsIndex?: Record<string, any>;
  setPaywallsIndex?: (object: Record<string, any>) => void;
  paywallPricesIndex?: Record<string, any>;
  setPaywallPricesIndex?: (object: Record<string, any>) => void;
  couponsIndex?: Record<string, any>;
  setCouponsIndex?: (object: Record<string, any>) => void;
  currentPaymentMethod?: any;
  setCurrentPaymentMethod?: any;
}

export const CreatePaywallSubscriptionFormContextProvider = ({
  communityMemberId,
  children,
}: any) => {
  const [paywallsIndex, setPaywallsIndex] = useState({});
  const [paywallPricesIndex, setPaywallPricesIndex] = useState({});
  const [couponsIndex, setCouponsIndex] = useState({});
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState(null);
  const { currentCommunity } = usePunditUserContext();

  const valueToStore = {
    communityId: currentCommunity?.id,
    communityMemberId,
    paywallsIndex,
    setPaywallsIndex,
    paywallPricesIndex,
    setPaywallPricesIndex,
    couponsIndex,
    setCouponsIndex,
    currentPaymentMethod,
    setCurrentPaymentMethod,
  };

  return (
    <CreatePaywallSubscriptionFormContext.Provider value={valueToStore}>
      {children}
    </CreatePaywallSubscriptionFormContext.Provider>
  );
};

export const useCreatePaywallSubscriptionFormContext = () =>
  useContext<CreatePaywallSubscriptionFormContextProp>(
    CreatePaywallSubscriptionFormContext,
  );
