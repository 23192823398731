import type { CSSProperties, ReactNode } from "react";
import { memo } from "react";
import FrameComponent from "react-frame-component";
import type { FrameComponentProps } from "react-frame-component";

const responsiveFrame: CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
};

export interface ResponsiveFrameProps extends Omit<FrameComponentProps, "ref"> {
  children: ReactNode;
}

export const ResponsiveFrame = memo(
  ({ children, style, ...rest }: ResponsiveFrameProps) => (
    <FrameComponent style={{ ...responsiveFrame, ...style }} {...rest}>
      {children}
    </FrameComponent>
  ),
);

ResponsiveFrame.displayName = "ResponsiveFrame";
