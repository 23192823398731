import PropTypes from "prop-types";
import Select from "react-select";

/** @deprecated Please use the new SearchableSelect at "@circle-react-uikit/SearchableSelect" */
export const SearchableSelect_DEPRECATED = ({
  options = [],
  customKey,
  selected,
  searchable = true,
  onOptionSelect,
  defaultValue,
  placeholder,
}) => {
  const onKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <div className="form-input">
      <Select
        key={customKey}
        options={options}
        onChange={onOptionSelect}
        isSearchable={searchable}
        value={options.filter(option => option.value == selected)}
        onKeyDown={onKeyDown}
        className="react-searchable-select"
        classNamePrefix="react-searchable-select"
        defaultValue={defaultValue}
        placeholder={placeholder}
      />
    </div>
  );
};

SearchableSelect_DEPRECATED.propTypes = {
  options: PropTypes.array,
  customKey: PropTypes.string,
  selected: PropTypes.string,
  searchable: PropTypes.bool,
  onOptionSelect: PropTypes.func,
  defaultValue: PropTypes.object,
  placeholder: PropTypes.string,
};
