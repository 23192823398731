import PropTypes from "prop-types";
import { useTabNavigation } from "../TabNavigation";
import { TabViewDisplay } from "./TabViewDisplay";

export const TabView = ({ ...rest }) => {
  const { currentTab } = useTabNavigation();
  return <TabViewDisplay currentTab={currentTab} {...rest} />;
};

TabView.propTypes = {
  children: PropTypes.node,
};
