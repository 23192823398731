import { createContext, useContext } from "react";
import invariant from "tiny-invariant";
import type {
  BaseFilter,
  ProfileFieldBaseFilter,
} from "../MemberFilters/types";

interface FilterContextValue {
  open: boolean;
  referenceElement: HTMLLIElement | null;
  setReferenceElement: (element: HTMLLIElement | null) => void;
  onApply: (value: BaseFilter | ProfileFieldBaseFilter | null) => void;
}

export const FilterContext = createContext<FilterContextValue | null>(null);

export const useFilterContext = () => {
  const context = useContext(FilterContext);
  invariant(context, "useFilterContext must be used within a FilterContext");
  return context;
};

FilterContext.displayName = "FilterContext";
