import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@circle-react-uikit/Button";

export const TextFilter = ({ onChange }) => {
  const [value, setValue] = useState("");
  const EnterKey = "Enter";
  const ref = useRef();

  const handleSubmit = () => {
    onChange(value);
  };

  const handleKeypress = e => {
    if (e.key === EnterKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  useEffect(() => {
    ref.current?.focus();
  }, [ref]);

  return (
    <>
      <input
        ref={ref}
        type="text"
        value={value}
        onChange={e => setValue(e.target.value)}
        onKeyPress={handleKeypress}
        className="form-control form-control--sm placeholder:text-light placeholder:opacity-100"
      />
      <div className="mt-3">
        <Button variant="primary" type="large" onClick={handleSubmit}>
          {I18n.t("members_directory.header.search.apply_filter")}
        </Button>
      </div>
    </>
  );
};

TextFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
};
