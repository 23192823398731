import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import invariant from "tiny-invariant";
import { useGetBroadcastReport } from "@circle-react/components/SettingsApp/Emails/hooks/useGetBroadcastReport";

const BroadcastReportContext = createContext<ReturnType<
  typeof useGetBroadcastReport
> | null>(null);

BroadcastReportContext.displayName = "BroadcastReportContext";

interface BroadcastReportContextProviderProps {
  id: number;
  children: ReactNode;
}

export const useBroadcastReport = () => {
  const context = useContext(BroadcastReportContext);
  invariant(
    context,
    "useBroadcastReport must be used within a BroadcastReportContextProvider",
  );
  return context;
};

export function BroadcastReportContextProvider({
  id,
  children,
}: BroadcastReportContextProviderProps) {
  const broadcastProps = useGetBroadcastReport({ id });

  return (
    <BroadcastReportContext.Provider value={broadcastProps}>
      {children}
    </BroadcastReportContext.Provider>
  );
}
