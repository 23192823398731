import { t } from "@/i18n-js/instance";
import {
  UpgradeFlow,
  useShouldShowProfileFieldsUpgradeModal,
} from "@circle-react-shared/ProfileFields";
import { UPGRADE_FLOW_SOURCES } from "@circle-react-shared/UpgradePlan/constants";
import { FilterContainer } from "../AvailableFiltersList/FilterContainer";

export function ProfileFieldUpgradeMessage() {
  const shouldShowProfileFieldsUpgradeModal =
    useShouldShowProfileFieldsUpgradeModal();

  if (!shouldShowProfileFieldsUpgradeModal) {
    return null;
  }

  return (
    <FilterContainer title={t("profile_fields.upgrade_flow.title")}>
      <div className="mt-2 pl-4">
        <UpgradeFlow source={UPGRADE_FLOW_SOURCES.PROFILE_FIELDS_FILTER_CHIP} />
      </div>
    </FilterContainer>
  );
}
