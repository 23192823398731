import { isFunction } from "lodash";
import { t } from "@/i18n-js/instance";
import { DaysFilter, Filter } from "@circle-react-shared/Filter";
import type { DaysAgoFormOption } from "../../Filter/FilterTypes/DaysFilter";
import { renderSelectedOption } from "../../Filter/FilterTypes/DaysFilter";
import type { BaseFilter } from "../types";

const localeNamespace = "members_directory.header.search.filters";

const options: DaysAgoFormOption[] = [
  {
    label: t([localeNamespace, "days_ago.lt"]),
    value: "lt",
  },
  {
    label: t([localeNamespace, "days_ago.eq"]),
    value: "eq",
  },
  {
    label: t([localeNamespace, "days_ago.gt"]),
    value: "gt",
  },
];

interface InvitedAtDaysAgoFilterProps {
  isInitialOpen?: boolean;
  onApply?: (value: DaysAgoFormOption) => void;
  param: BaseFilter;
}

export const formatInvitedAtDaysAgoLabel = renderSelectedOption(options);

export const InvitedAtDaysAgoFilter = ({
  isInitialOpen,
  onApply,
  param,
}: InvitedAtDaysAgoFilterProps) => (
  <Filter
    chip={t([localeNamespace, "invited_at_days_ago"])}
    title={t([localeNamespace, "invited_at_days_ago"])}
    selectedText={param.value}
    isInitialOpen={isInitialOpen}
    renderSelectedText={() => formatInvitedAtDaysAgoLabel(param)}
    onApply={value => {
      if (isFunction(onApply)) {
        onApply(value);
      }
    }}
    shouldShowRemoveButton
  >
    <DaysFilter options={options} param={param} />
  </Filter>
);
