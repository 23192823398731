import invariant from "tiny-invariant";
import { t } from "@/i18n-js/instance";
import { Filter } from "@circle-react-shared/FilterV3";
import { Loader } from "@circle-react-uikit/Loader";
import { ArrayFilterComparator } from "../../../Filter/FilterTypes/ArrayFilterComparator/ArrayFilterComparator";
import {
  type ArrayComparatorParam,
  handleArrayComparatorApply,
} from "../../../Filter/FilterTypes/ArrayFilterComparator/arrayComparators";
import { useArrayComparatorSelectedItem } from "../../../Filter/FilterTypes/ArrayFilterComparator/useArrayComparatorSelectedItem";
import { useSpaceGroupsList } from "./useSpaceGroupsList";

interface SpaceGroupsFilterProps {
  name: string;
  localeNamespace?: string;
  isInitialOpen?: boolean;
  onApply?: (param: ArrayComparatorParam | null) => void;
  param: ArrayComparatorParam;
}

/**
 * This is the v2 version filters that is to be used on Members which will support comparators and multiple instances.
 * The v1 version of this filter is shared by other pages, hence created a copy.
 */
export const SpaceGroupsFilter = ({
  name,
  localeNamespace = "members_directory.header.search.filters.space_groups",
  isInitialOpen = false,
  onApply,
  param,
}: SpaceGroupsFilterProps) => {
  const spaceGroupsList = useSpaceGroupsList();
  /** @todo
   * The hook returns an array at a point. This is possibly a bug.
   * To check for usage in other places before refactoring.
   */
  invariant(!Array.isArray(spaceGroupsList), "spaceGroupsList is not an array");
  const { visibleSpaceGroups, isLoading } = spaceGroupsList;
  const renderSelectedValue =
    useArrayComparatorSelectedItem(visibleSpaceGroups);

  return (
    <Filter onApply={param => handleArrayComparatorApply(param, onApply)}>
      <Filter.Chip renderSelectedValue={() => renderSelectedValue(param)}>
        {t([localeNamespace, "singular_title"])}
      </Filter.Chip>

      <Filter.Popover
        title={t([localeNamespace, "title"])}
        isInitialOpen={isInitialOpen}
      >
        {isLoading ? (
          <Loader center />
        ) : (
          <ArrayFilterComparator
            name={name}
            options={visibleSpaceGroups}
            param={param}
          />
        )}
      </Filter.Popover>
    </Filter>
  );
};
