import { usePunditUserContext } from "@circle-react/contexts";

export const usePaywallFilter = (filters = []) => {
  const { currentCommunity, currentCommunityMember } = usePunditUserContext();

  const canPaywallsIndex = Boolean(
    currentCommunityMember?.policies?.can_paywalls_index,
  );
  const hasNonDraftPaywalls = Boolean(currentCommunity?.has_non_draft_paywalls);

  if (!canPaywallsIndex)
    return filters.filter(
      ({ name }) => !["paywalls", "has_past_due_subscription"].includes(name),
    );

  return hasNonDraftPaywalls
    ? filters
    : filters.filter(({ name }) => name !== "paywalls");
};
