import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { ResumePaywallSubscriptionModal } from "./ResumePaywallSubscriptionModal";

const ResumePaywallSubscriptionModalComponent = ModalManager.create(
  (params: any) => <ResumePaywallSubscriptionModal {...params} />,
);

export const useResumePaywallSubscriptionModal = () =>
  useModal(ResumePaywallSubscriptionModalComponent);
