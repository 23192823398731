import { find } from "lodash";
import { t } from "@/i18n-js/instance";
import { TextSelect } from "@circle-react-shared/TextSelect";
import { EmptyState } from "@circle-react-shared/uikit/EmptyState";
import { useAccessGroupSourcesData } from "./DataStoreContext/AccessGroupSourcesDataContext";
import { Wrapper } from "./Wrapper";
import { useAccessGroupsActions } from "./hooks/useAccessGroupsActions";

const i18nRoot = "settings.access_groups.admin.entry_points.association";

export const AccessGroupsSelect = () => {
  const {
    selectedAccessGroups,
    appendAccessGroup,
    openNewAccessGroupModal,
    isSyncing,
  } = useAccessGroupsActions();

  const { accessGroups } = useAccessGroupSourcesData();

  const onSelect = (value: number) => {
    const selectedGroup = find(accessGroups, { id: value });
    if (selectedGroup) {
      appendAccessGroup(selectedGroup);
    }
  };

  if (accessGroups.length <= 0) {
    return (
      <Wrapper className="mt-6">
        <EmptyState
          header={t([i18nRoot, "no_groups_empty_state.title"])}
          description={t([i18nRoot, "no_groups_empty_state.description"])}
          primaryLabel={t([i18nRoot, "no_groups_empty_state.primary_cta"])}
          onPrimaryClick={openNewAccessGroupModal}
        />
      </Wrapper>
    );
  }

  const accessGroupsSelectedIds =
    (selectedAccessGroups &&
      selectedAccessGroups?.map(accessGroup => accessGroup?.id)) ||
    [];

  const options = accessGroups
    .filter(accessGroup => !accessGroupsSelectedIds.includes(accessGroup.id))
    .map(accessGroup => ({
      value: accessGroup.id,
      label: accessGroup.name,
    }));

  return (
    <div className="mb-6 mt-2">
      <TextSelect
        placeholder={t([i18nRoot, "select_placeholders.select"])}
        emptyMessage={t([i18nRoot, "select_placeholders.no_groups"])}
        options={options}
        onChange={onSelect}
        value=""
        disabled={isSyncing}
      />
    </div>
  );
};
