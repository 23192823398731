import { getOnChangingSortFunc } from "../../utils";
import { orderingColumn } from "../Columns/columns";

export const usePaywallChargesList = ({ chargesData }) => {
  const onChangeSorting = getOnChangingSortFunc({
    dataQuery: chargesData,
    orderingColumn,
  });

  return {
    onChangeSorting,
  };
};
