import PropTypes from "prop-types";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { FilterContainer } from "./FilterContainer";

export const FiltersList = ({ filters, title, onAddFilter }) => {
  if (!filters.length) {
    return null;
  }

  return (
    <FilterContainer title={title}>
      {filters.map(({ name, label, availableLabel }) => (
        <Dropdown.ItemWithLink key={name} onClick={() => onAddFilter(name)}>
          {availableLabel ?? label}
        </Dropdown.ItemWithLink>
      ))}
    </FilterContainer>
  );
};

FiltersList.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
  onAddFilter: PropTypes.func.isRequired,
};
