import { useCallback } from "react";
import * as yup from "yup";
import { t } from "@/i18n-js/instance";
import { useCurrentPlanApi } from "@/react/components/SettingsApp/Plan/hooks/useCurrentPlanApi";
import { useSupportWidgetContext } from "@circle-react/components/SupportWidget/SupportWidgetContext";
import { useMarketingHubSubscriptionApi } from "@circle-react/hooks/useMarketingHubSubscriptionApi";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { useToast } from "@circle-react-uikit/ToastV2";
import { FormFields } from "./FormFields";
import { otherOption } from "./reasonOptions";

const localeRoot = "marketing_hub.cancellation_modal.reason_form";

interface ReasonFormProps {
  onClose: () => void;
}

export const ReasonForm = ({ onClose }: ReasonFormProps) => {
  const { toggleWidget } = useSupportWidgetContext();
  const { success, error } = useToast();
  const { refetchCurrentPlan, isRefetchingCurrentPlan } =
    useCurrentPlanApi(true);
  const {
    cancelMarketingHubSubscription,
    isCancellingMarketingHubSubscription,
  } = useMarketingHubSubscriptionApi(handleSuccess, handleError);

  const validationSchema = createValidationSchema();
  const isLoading =
    isCancellingMarketingHubSubscription || isRefetchingCurrentPlan;

  function handleSuccess() {
    onClose();
    success(t("pricing_plans.subscription_updated"));
    executeWithDelay(() => {
      void refetchCurrentPlan();
    });
  }

  const handleSupportWidget = useCallback(() => {
    onClose();
    toggleWidget();
  }, [onClose, toggleWidget]);

  function handleError() {
    onClose();
    error(t("pricing_plans.errors.update_subscription_error"));
    executeWithDelay(() => {
      void refetchCurrentPlan();
    });
  }

  function executeWithDelay(callback: () => void) {
    setTimeout(callback, 2000);
  }

  function createValidationSchema() {
    return yup
      .object({
        reason: yup.string().required(t([localeRoot, "reason_required"])),
        reason_description: yup.string().when("reason", {
          is: (reason: string) => reason === otherOption,
          then: yup
            .string()
            .required(t([localeRoot, "reason_description_required"])),
          otherwise: yup.string().notRequired(),
        }),
      })
      .required();
  }

  function handleSubmit(data: { reason: string; reason_description: string }) {
    void cancelMarketingHubSubscription({
      reason: data.reason,
      reasonDescription: data.reason_description || "",
    });
  }

  return (
    <div>
      <Typography.LabelMd weight="semibold">
        {t([localeRoot, "title"])}
      </Typography.LabelMd>
      <div className="mt-1">
        <Typography.BodySm>{t([localeRoot, "body"])}</Typography.BodySm>
      </div>

      <div className="mt-1">
        <Button variant="circle-text" onClick={handleSupportWidget}>
          {t([localeRoot, "get_help"])}
        </Button>
      </div>

      <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
        <FormFields />

        <div className="mb-2 mt-4 flex items-stretch justify-end space-x-2">
          <Button onClick={onClose} variant="secondary">
            {t("cancel")}
          </Button>

          <Button type="submit" variant="danger" disabled={isLoading}>
            {t("confirm_disabling")}
          </Button>
        </div>
      </Form>
    </div>
  );
};
