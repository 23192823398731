import isFunction from "lodash/isFunction";
import { BlockConnector } from "@circle-react/components/shared/BlockConnector";
import { useItemData } from "./ItemDataProvider";

export const Connector = () => {
  const { onAddNewConnection, hasBottomConnection } = useItemData();

  if (isFunction(onAddNewConnection)) {
    return (
      <BlockConnector
        hasBottomConnection={hasBottomConnection}
        onClick={onAddNewConnection}
      />
    );
  }

  if (hasBottomConnection) {
    return <BlockConnector hasBottomConnection={hasBottomConnection} />;
  }

  return null;
};

Connector.propTypes = {};
