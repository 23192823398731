import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  attributeSaleModalType,
  isSaleAttributed,
} from "@circle-react/helpers/paywalls/affiliates/helpers";
import type {
  Attributable,
  AttributableType,
} from "@circle-react/types/PaywallsAffiliates";
import { useAttributeSaleModal } from "../Modals/AttributeSaleModal";

interface AttributeSaleMenuItemProps {
  attributable: Attributable;
  attributableType: AttributableType;
  onSuccess: () => void;
}

export const useAttributeSaleMenuItem = ({
  attributable,
  attributableType,
  onSuccess,
}: AttributeSaleMenuItemProps) => {
  const attributeSaleModal = useAttributeSaleModal();

  const { currentCommunity } = usePunditUserContext();
  const isAffiliatesEnabled = currentCommunity?.affiliates_feature_flag_enabled;

  if (
    !isAffiliatesEnabled ||
    (!isSaleAttributed(attributable) &&
      !attributable.can_be_attributed_for_affiliation)
  )
    return null;

  return {
    key: "attribute-sale",
    label: t(
      `settings.paywalls_admin_dashboard.attributable.list.dropdown_menu.${attributeSaleModalType(
        attributable,
      )}`,
    ),
    onClick: (e: any) => {
      void attributeSaleModal.show({
        attributable,
        attributableType,
        onSuccess,
      });
      e.stopPropagation();
    },
  };
};
