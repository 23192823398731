import { t } from "@/i18n-js/instance";
import type { SelectedAccessGroupType } from "@circle-react/types/AccessGroup";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { useAccessGroupsActions } from "../hooks/useAccessGroupsActions";

export const ReEnableAction = ({
  accessGroup,
}: {
  accessGroup: SelectedAccessGroupType;
}) => {
  const { reEnableAccessGroup } = useAccessGroupsActions();

  if (!accessGroup) {
    return null;
  }

  return (
    <Dropdown.ItemWithLink
      onClick={event => {
        reEnableAccessGroup(accessGroup.id);
        event.preventDefault();
      }}
    >
      {t(
        "settings.access_groups.admin.entry_points.association.actions.re_enable",
      )}
    </Dropdown.ItemWithLink>
  );
};
