import { useEffect, useState } from "react";
import classNames from "classnames";
import { flatten } from "lodash";
import { useInfiniteQuery } from "react-query";
import {
  DOWN_KEY_CODE,
  ENTER_KEY_CODE,
  ESCAPE_KEY_CODE,
  UP_KEY_CODE,
} from "@circle-react/components/constants";
import { useFocusOnLoad } from "@circle-react/hooks/useFocusOnLoad";
import { useDebouncedValue } from "@circle-react/hooks/utils/useDebouncedValue";
import { CustomerColumn } from "@circle-react-shared/Paywalls/CustomerColumn";
import { Input } from "@circle-react-uikit/Input";
import { OptionsDropdown } from "./OptionsDropdown";
import { useCustomerSearch } from "./hooks/useCustomerSearch";

const shouldRenderOptionFlag = true;
const noOfChars = 2;

interface CustomerSearchProp {
  record?: object | null;
  recordId?: number | null;
  recordType: string;
  searchInputPlaceholder: string;
  otherSearchParams?: object;
  onApply: (value: number | null) => void;
  onRemove?: () => void;
  className?: string;
  optionsClassName?: string;
  customerInfoClassName?: string;
}

export const CustomerSearch = ({
  record = null,
  recordId = null,
  recordType,
  searchInputPlaceholder,
  otherSearchParams = {},
  onApply,
  onRemove,
  className = "",
  optionsClassName = "",
  customerInfoClassName = "",
}: CustomerSearchProp) => {
  const [searchQuery, setSearchQuery] = useState("");
  const searchQueryDebounced = useDebouncedValue(searchQuery, 500);
  const [activeOptionIndex, setActiveOptionIndex] = useState(0);

  const inputRef = useFocusOnLoad<HTMLInputElement | HTMLTextAreaElement>();

  useEffect(() => {
    if (searchQueryDebounced.length) {
      setActiveOptionIndex(0);
    }
  }, [searchQueryDebounced]);

  const { fetchPage, communityMemberRecord } = useCustomerSearch({
    recordType,
    searchQueryDebounced,
    otherSearchParams,
  });

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    [`${recordType}-infinite-search`, searchQueryDebounced],
    fetchPage,
    {
      enabled: searchQueryDebounced.length >= noOfChars,
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? Number(lastPage.page) + 1 : undefined,
    },
  );

  const dataFoundLength = data?.pages[0].count || 0;
  const records = dataFoundLength
    ? flatten(data?.pages.map(page => page.records))
    : [];
  if (recordId) {
    record = records.find(record => record.id === recordId);
  }

  const handleOnChange = (event: any) => {
    const value = event.target.value;
    if (shouldRenderOptionFlag && value.length >= noOfChars) {
      setSearchQuery(value);
    } else {
      setSearchQuery("");
    }
  };

  const calculateScroll = (currentOption: any) =>
    Math.floor(currentOption / 3) * 240;

  const onKeyDown = (event: any) => {
    const scrollDiv = document.getElementById("scrollableDiv");
    if (event.keyCode === ESCAPE_KEY_CODE) {
      setSearchQuery("");
      return;
    }

    if (event.keyCode === ENTER_KEY_CODE) {
      onApply(records[activeOptionIndex].id);
      event.preventDefault();
      return;
    }

    if (event.keyCode === UP_KEY_CODE) {
      if (scrollDiv)
        scrollDiv.scrollTop = calculateScroll(
          Math.max(activeOptionIndex - 1, 0),
        );

      if (activeOptionIndex === 0) {
        return;
      }

      setActiveOptionIndex(activeOptionIndex - 1);
      return;
    }

    if (event.keyCode === DOWN_KEY_CODE) {
      if (scrollDiv)
        scrollDiv.scrollTop = calculateScroll(
          Math.min(activeOptionIndex + 1, records.length),
        );
      if (activeOptionIndex + 1 === records.length) {
        return;
      }

      setActiveOptionIndex(activeOptionIndex + 1);
    }
  };

  return (
    <div className={classNames("p-4", className)}>
      {record && (
        <CustomerColumn
          customer={communityMemberRecord(record)}
          onRemove={onRemove}
          infoClassName={customerInfoClassName}
          setSearchQuery={setSearchQuery}
        />
      )}
      {!record && (
        <>
          <Input
            id="user-text-box"
            placeholder={searchInputPlaceholder}
            onChange={handleOnChange}
            onKeyDown={(event: any) => onKeyDown(event)}
            ref={inputRef}
            autoFocus
            autoComplete="off"
          />
          <OptionsDropdown
            records={records}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            activeOptionIndex={activeOptionIndex}
            onMouseOver={setActiveOptionIndex}
            setRecord={onApply}
            communityMemberRecord={communityMemberRecord}
            className={optionsClassName}
          />
        </>
      )}
    </div>
  );
};
