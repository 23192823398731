import { t } from "@/i18n-js/instance";
import featurePreview1x from "../../../../../../../assets/images/features/marketing-hub/landing-page/feature-3_1x.png";
import featurePreview2x from "../../../../../../../assets/images/features/marketing-hub/landing-page/feature-3_2x.png";
import featurePreview4x from "../../../../../../../assets/images/features/marketing-hub/landing-page/feature-3_4x.png";
import { Blocks } from "../Blocks";
import { localeRoot as marketingHubLocale } from "../constants";

const localeRoot = `${marketingHubLocale}.blocks.audience_crm`;

const backgroundColors = {
  primary: "#FFC9D3",
  secondary: "#FFF1F3",
};

export const AudienceCRMFeature = () => (
  <Blocks>
    <Blocks.FeatureSummary backgroundColor={backgroundColors.secondary}>
      <Blocks.Header
        localeRoot={localeRoot}
        iconName="courses-community"
        backgroundColor={backgroundColors.primary}
      />
      <Blocks.FeatureList>
        <Blocks.FeatureItem backgroundColor={backgroundColors.primary}>
          {t([localeRoot, "features", "0"])}
        </Blocks.FeatureItem>
        <Blocks.FeatureItem backgroundColor={backgroundColors.primary}>
          {t([localeRoot, "features", "1"])}
        </Blocks.FeatureItem>
        <Blocks.FeatureItem backgroundColor={backgroundColors.primary}>
          {t([localeRoot, "features", "2"])}
        </Blocks.FeatureItem>
      </Blocks.FeatureList>
    </Blocks.FeatureSummary>
    <Blocks.FeaturePreview
      backgroundColor={backgroundColors.primary}
      className="flex flex-col items-center justify-center p-8"
    >
      <img
        alt=""
        loading="eager"
        src={featurePreview1x}
        srcSet={`${featurePreview1x} 663w, ${featurePreview2x} 812w, ${featurePreview4x} 2000w`}
      />
    </Blocks.FeaturePreview>
  </Blocks>
);
