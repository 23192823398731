import { useFormContext } from "react-hook-form";
import { RadioElement } from "./RadioElement";
import type { DaysAgoFormOption } from "./utils";

interface DaysFormControlProps {
  options: DaysAgoFormOption[];
}

export const DaysFormControl = ({ options }: DaysFormControlProps) => {
  const {
    formState: { errors },
  } = useFormContext<{ value: string }>();
  const error = errors.value;

  return (
    <div className="flex flex-col gap-y-2">
      <fieldset className="flex flex-col gap-y-4">
        {options.map(option => (
          <RadioElement key={option.value} option={option} />
        ))}
      </fieldset>
      {error && <p className="form-group__error text-sm">{error.message}</p>}
    </div>
  );
};
