import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-shared/uikit/Typography";

export interface HighlightsItemProps {
  label: string;
  value: string;
  shouldShowSeparator?: boolean;
}

export const HighlightsItem = ({
  label,
  value,
  shouldShowSeparator = true,
}: HighlightsItemProps) => (
  <>
    {shouldShowSeparator && (
      <Typography.LabelXs key={`${label}-separator`} weight="medium">
        {t("separator_dot")}
      </Typography.LabelXs>
    )}
    <Typography.LabelXs key={`${label}-value`} weight="medium">
      {value}
    </Typography.LabelXs>
  </>
);
