import { t } from "@/i18n-js/instance";
import { useBroadcastReport } from "@circle-react-shared/Emails/BroadcastReport/BroadcastReportContext";
import { InfiniteQueryDataTable } from "@circle-react-shared/InfiniteQueryDataTable";
import { DataTable } from "@circle-react-uikit/DataTableV2";
import { useMatchedRecipientModal } from "../LinkClicksStats/ViewRecipients/MatchedRecipientModal";

const i18nRoot =
  "settings.emails.view_broadcast.reports_tab.links_and_file_table_columns";

const columns = [
  {
    accessorKey: "name",
    header: t([i18nRoot, "name"]),
    cell: (info: any) =>
      info.row.original.link_text || info.row.original.link_label,
  },
  {
    accessorKey: "unique_clicks",
    thClassName: "!px-3",
    header: t([i18nRoot, "unique_clicks"]),
    cell: (info: any) => info.getValue(),
  },
  {
    accessorKey: "clicks",
    thClassName: "!px-3",
    header: t([i18nRoot, "total_clicks"]),
    cell: (info: any) => info.getValue(),
  },
];

export const LinkClicksStatsTableV2 = () => {
  const recipientModal = useMatchedRecipientModal();
  const { data } = useBroadcastReport();

  const handleRowClick = (row: any) => {
    void recipientModal.show({
      broadcastId: Number(data?.id),
      link: row.original.link,
      recipient_type: "link_clicks_recipients",
    });
  };

  if (!data) {
    return null;
  }

  return (
    <div className="bg-primary pb-4">
      <InfiniteQueryDataTable
        columns={columns}
        infiniteQuery={{ data: { pages: [{ page: 1, ...data }] } }}
        pageSize={30}
        initialPage={1}
        pageDataPath="link_clicks"
        onClickRow={handleRowClick}
      >
        <div className="isolate overflow-auto">
          <DataTable.Table>
            <DataTable.Header className="z-10" />
            <DataTable.Loader />
            <DataTable.NoRecords text={t([i18nRoot, "no_link_found"])} />
            <DataTable.Body />
          </DataTable.Table>
        </div>
      </InfiniteQueryDataTable>
    </div>
  );
};
