import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  communitySignInUrl,
  communitySignupUrl,
  shouldShowSignupLink,
} from "@circle-react/helpers/communityHelpers";
import { useRouterQueryParams } from "@circle-react/hooks/useRouterQueryParams";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { Button } from "@circle-react-uikit/Button";

export interface ConditionallyRenderLoginSignupButtonsProps {
  hideSignup?: boolean;
}

export const ConditionallyRenderLoginSignupButtons = ({
  hideSignup,
}: ConditionallyRenderLoginSignupButtonsProps) => {
  const { currentCommunity, currentUser, isLoading } = usePunditUserContext();

  const hasPrivateSignUpUrl = !!currentCommunity?.private_sign_up_url;

  const hasSignUpLink =
    shouldShowSignupLink(currentCommunity) || hasPrivateSignUpUrl;

  const signupUrl = hasPrivateSignUpUrl
    ? currentCommunity?.private_sign_up_url
    : communitySignupUrl(currentCommunity);

  const { post_login_redirect: postLoginRedirectUrlParam } =
    useRouterQueryParams();

  if (isLoading) {
    return <Loader className="flex !py-0" />;
  }

  if (currentUser) {
    return null;
  }

  return (
    <div
      className={classNames("hidden items-center justify-end gap-2.5 md:flex")}
    >
      <Button
        type="button"
        variant="secondary"
        large
        onClick={() => {
          window.location.href = communitySignInUrl(currentCommunity, {
            post_login_redirect:
              postLoginRedirectUrlParam || window.location.href,
          });
        }}
      >
        {t("log_in")}
      </Button>
      {!hideSignup && hasSignUpLink && (
        <Button
          type="button"
          variant="primary"
          large
          onClick={() => {
            if (!signupUrl) return;
            window.location.href = signupUrl;
          }}
        >
          {hasPrivateSignUpUrl
            ? currentCommunity?.private_sign_up_label
            : t("join")}
        </Button>
      )}
    </div>
  );
};
