import PropTypes from "prop-types";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { Typography } from "@circle-react-uikit/Typography";

export const HelloBar = ({ to = "", children, className }) => (
  <NavLink
    to={to}
    className={classNames("bg-tertiary flex justify-center p-3.5", className)}
  >
    <div className="border-dark hover:border-secondary border-b border-dashed leading-none transition-colors">
      <Typography.LabelSm weight="semibold">{children}</Typography.LabelSm>
    </div>
  </NavLink>
);

HelloBar.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};
