import { useEffect, useState } from "react";
import { isCommunityAdmin } from "@/react/helpers/communityMemberHelpers";
import {
  usePunditUserContext,
  useSpaceGroupsContext,
  useSpacesContext,
} from "@circle-react/contexts";
import { isSpaceVisibleOnSidebar } from "@circle-react/helpers/spaceHelpers";
import { DropdownFilter } from "../DropdownFilter";

export const SpaceGroupsFilter = () => {
  const [visibleSpaceGroups, setVisibleSpaceGroups] = useState([]);
  const { currentCommunityMember, isCommunityLoading } = usePunditUserContext();

  const {
    helpers: { isDataLoading: isSpaceGroupsDataLoading },
    records: spaceGroups,
  } = useSpaceGroupsContext();
  const {
    helpers: { isDataLoading: isSpacesDataLoading, spaceVisibleOnSidebar },
  } = useSpacesContext();
  const spaces = spaceVisibleOnSidebar();
  const isLoading =
    isSpacesDataLoading() || isSpaceGroupsDataLoading() || isCommunityLoading;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setVisibleSpaceGroups(
      spaceGroups.filter(spaceGroup => {
        if (isCommunityAdmin(currentCommunityMember)) {
          return true;
        }

        spaces.some(
          space =>
            space.space_group_id === spaceGroup.id &&
            isSpaceVisibleOnSidebar({ space, spaceGroup }),
        );
      }),
    );
  }, [isLoading, spaces, spaceGroups, currentCommunityMember]);

  if (isLoading && !visibleSpaceGroups.length) {
    return null;
  }

  const options = visibleSpaceGroups.map(record => ({
    label: record.name,
    value: record.slug,
  }));

  return (
    <DropdownFilter
      queryParam="space_groups"
      type="multiselect"
      options={options}
    />
  );
};
