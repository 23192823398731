import {
  dateFormats,
  formattedDateTime,
} from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface HeaderProp {
  spaceName: string;
}

export const headerHeightInRem = 5.75;

export const Header = ({ spaceName }: HeaderProp) => (
  <div className="bg-primary sticky inset-x-0 top-0 z-10 pt-3">
    <div className="mx-2 flex flex-row items-center justify-between">
      <div className="flex-1 text-center">
        <Typography.LabelMd weight="semibold">
          {formattedDateTime({
            dateTime: new Date(),
            format: dateFormats.short_time_24hrs,
          })}
        </Typography.LabelMd>
      </div>
      <div className="h-full">
        <div
          className="h-full flex-1 rounded-full bg-black"
          style={{ width: "8rem", height: "2.25rem" }}
        />
      </div>
      <div className="flex flex-1 flex-row justify-center gap-x-1.5">
        <Icon type="antenna-connection" className="!w-5" viewBox="0 0 20 20" />
        <Icon type="wifi" className="!w-5" viewBox="0 0 20 20" />
        <Icon type="battery" className="!w-7" viewBox="0 0 28 14" />
      </div>
    </div>
    <div className="flex h-[3rem] flex-row py-3">
      <Icon type="20-back" className="!w-8" viewBox="0 0 20 20" />
      <div className="flex grow flex-row justify-center gap-x-2 self-center">
        <Icon type="16-lock" className="!w-5" />
        <div>{spaceName}</div>
      </div>
      <div className="w-8" />
    </div>
  </div>
);
