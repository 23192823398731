import { useState } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { delay } from "lodash";
import { AvailableFiltersList } from "./AvailableFiltersList";
import { FiltersItems } from "./FiltersItems";

export const DynamicFilterItems = ({
  defaultFilters = [],
  customFilters = [],
  onAddFilter = noop,
  onRemoveFilter = noop,
  getSplitAndAvailableFilters = noop,
  label,
}) => {
  const [isInitialOpen, setIsInitialOpen] = useState(false);

  const { displayedFilters, availableFilters } = getSplitAndAvailableFilters([
    ...defaultFilters,
    ...customFilters,
  ]);
  const hasAvailableFilters = availableFilters.length !== 0;

  const handleAddFilter = name => {
    /* open the newly added items by default but reset for the next one.
      ensure that default added ones are not opened
    */
    setIsInitialOpen(true);
    delay(() => setIsInitialOpen(false), 500);
    onAddFilter(name);
  };

  return (
    <FiltersItems data-testid="members_filters">
      {displayedFilters.map(
        ({
          name,
          component: Component,
          isInitialOpen: isDefaultOpen,
          ...props
        }) => (
          <Component
            key={name}
            name={name}
            onApply={value => onRemoveFilter(value, name)}
            isInitialOpen={isDefaultOpen || isInitialOpen}
            shouldShowRemoveButton
            {...props}
          />
        ),
      )}
      {hasAvailableFilters && (
        <AvailableFiltersList
          availableFilters={availableFilters}
          onAddFilter={handleAddFilter}
          defaultFilters={defaultFilters}
          customFilters={customFilters}
          label={label}
        />
      )}
    </FiltersItems>
  );
};

DynamicFilterItems.propTypes = {
  defaultFilters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  customFilters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  onAddFilter: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
  getSplitAndAvailableFilters: PropTypes.func.isRequired,
  label: PropTypes.string,
};
