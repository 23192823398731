import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { useLayoutContext } from "@circle-react/components/SettingsApp/Layout/LayoutContext";
import { usePunditUserContext } from "@circle-react/contexts";
import { settingsNavPathV3 } from "@circle-react/helpers/urlHelpers/settingsNavPathV3";
import { AnalyticsLinks } from "./AnalyticsLinks";
import { BrandedAppsDashboardLinks } from "./BrandedAppsDashboardLinks";
import { CommunityBotLinks } from "./CommunityBotLinks";
import { CommunityInboxLinks } from "./CommunityInboxLinks";
import { ContentLinks } from "./ContentLinks";
import { DevelopersLinks } from "./DevelopersLinks";
import { EmailLinks } from "./EmailLinks";
import { MemberLinks } from "./MemberLinks";
import { PaywallLinks } from "./PaywallLinks";
import { PaywallsAffiliatesLinks } from "./PaywallsAffiliatesLinks";
import { PlansLinks } from "./PlansLinks";
import { SettingsLinks } from "./SettingsLinks";
import { SiteLinks } from "./SiteLinks";
import { WorkflowLinks } from "./WorkflowLinks";

export const NestedNavBar = () => {
  const { pathname } = useLocation();
  const { currentCommunitySettings } = usePunditUserContext();
  const { config: layoutConfig } = useLayoutContext();

  const isCommunityBotEnabled = currentCommunitySettings?.community_bot_enabled;
  const { shouldHideSidebarNestedNavBar } = layoutConfig;

  const isCommunityInboxPage = pathname.includes(
    settingsNavPathV3.community_inbox.index,
  );

  if (shouldHideSidebarNestedNavBar) {
    return null;
  }

  return (
    <div
      className={classNames(
        "bg-primary h-full w-60",
        isCommunityBotEnabled && isCommunityInboxPage
          ? "lg:w-[20rem]"
          : "p-5 lg:w-[18.5rem]",
      )}
    >
      <MemberLinks />
      <EmailLinks />
      <SiteLinks />
      <ContentLinks />
      <WorkflowLinks />
      <PaywallLinks />
      <SettingsLinks />
      <PlansLinks />
      <AnalyticsLinks />
      <PaywallsAffiliatesLinks />
      <DevelopersLinks />
      <BrandedAppsDashboardLinks />
      {isCommunityBotEnabled && isCommunityInboxPage && <CommunityInboxLinks />}
      {isCommunityBotEnabled && <CommunityBotLinks />}
    </div>
  );
};
