import { useCallback } from "react";
import { find, isEmpty, isEqual, map, pick } from "lodash";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useQueryClient } from "react-query";
import { t } from "@/i18n-js/instance";
import { useNewAccessGroupModal } from "@circle-react/components/SettingsApp/AccessGroups/NewAccessGroupModal";
import { accessGroupPaths } from "@circle-react/helpers/urlHelpers/accessGroupPaths";
import { queryKey as useAccessGroupsQueryKey } from "@circle-react/hooks/accessRegistry/useAccessGroups";
import type {
  AccessGroupSourceType,
  AccessGroupType,
  SelectedAccessGroupType,
} from "@circle-react/types/AccessGroup";
import {
  activeCount,
  normalizeAccessGroupSources,
  originalAccessGroupSourcesNormalized,
} from "@circle-react-shared/AccessGroups/helpers";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useAccessGroupSourcesData } from "../DataStoreContext/AccessGroupSourcesDataContext";
import { useEditAccessGroupModal } from "../EditAccessGroupModal";
import { ACTION_STATUSES } from "../constants";

const ACCESS_GROUP_FORM_KEY = "access_groups";
const ACCESS_GROUP_SOURCES_FORM_KEY = "access_group_sources";
export const useAccessGroupsActions = () => {
  const toast = useToast();
  const {
    originalAccessGroupSources,
    canRemoveAllAccessGroups,
    recordId,
    isSyncing,
    syncingTooltip,
  } = useAccessGroupSourcesData();

  const areAccessGroupsSetFromFormDefaults = !recordId;

  const { watch } = useFormContext();
  const { replace, remove, append, update } = useFieldArray({
    name: ACCESS_GROUP_FORM_KEY,
  });

  const selectedAccessGroups: Array<SelectedAccessGroupType> = watch(
    ACCESS_GROUP_FORM_KEY,
  );

  const originalAccessGroupSourcesFromForm: Array<AccessGroupSourceType> =
    watch(ACCESS_GROUP_SOURCES_FORM_KEY);

  const finalOriginalAccessGroupSources = isEmpty(originalAccessGroupSources)
    ? originalAccessGroupSourcesFromForm
    : originalAccessGroupSources;

  const isFormDirty = useCallback(() => {
    const originalItems = originalAccessGroupSourcesNormalized(
      finalOriginalAccessGroupSources,
    );

    const selectedItems = map(selectedAccessGroups, function (obj) {
      return pick(obj, ["id", "status"]);
    });
    return !isEqual(originalItems, selectedItems);
  }, [finalOriginalAccessGroupSources, selectedAccessGroups]);

  const findAccessGroupIndex = useCallback(
    (accessGroupId: number) =>
      selectedAccessGroups.findIndex(
        accessGroup => accessGroup.id === accessGroupId,
      ),
    [selectedAccessGroups],
  );

  const canRemoveAccessGroups = useCallback(() => {
    if (canRemoveAllAccessGroups) {
      return true;
    }
    return activeCount(selectedAccessGroups).length > 1;
  }, [canRemoveAllAccessGroups, selectedAccessGroups]);

  const removeAccessGroup = useCallback(
    (accessGroup: SelectedAccessGroupType) => {
      const index = findAccessGroupIndex(accessGroup.id);
      if (!canRemoveAccessGroups()) {
        return toast.error(
          t(
            "settings.access_groups.admin.entry_points.association.messages.cannot_remove_access_group",
          ),
        );
      }
      if (accessGroup.hasMembers) {
        update(index, {
          ...selectedAccessGroups[index],
          status: ACTION_STATUSES.REMOVED,
        });
      } else {
        remove(index);
      }
    },
    [
      findAccessGroupIndex,
      update,
      selectedAccessGroups,
      remove,
      canRemoveAccessGroups,
      toast,
    ],
  );

  const queryClient = useQueryClient();
  const newAccessGroupModal = useNewAccessGroupModal();
  const editAccessGroupModal = useEditAccessGroupModal();
  const onSuccessNewAccessGroup = (accessGroup: AccessGroupType) => {
    void queryClient.invalidateQueries(useAccessGroupsQueryKey);
    appendAccessGroup(accessGroup);
    void editAccessGroupModal.show({ accessGroup: accessGroup });
  };
  const openNewAccessGroupModal = () => {
    void newAccessGroupModal.show({ onSuccess: onSuccessNewAccessGroup });
  };

  const isAccessGroupSourceOriginallyAdded = (accessGroupId: number) =>
    Boolean(
      find(
        finalOriginalAccessGroupSources,
        source => source.access_group.id === accessGroupId,
      ),
    );

  const getOriginalAccessGroup = (accessGroupId: number) =>
    find(
      finalOriginalAccessGroupSources,
      source => source.access_group.id === accessGroupId,
    );

  const appendAccessGroup = (accessGroup: AccessGroupType) => {
    append({
      ...accessGroup,
      hasMembers: false,
      status: isAccessGroupSourceOriginallyAdded(accessGroup.id)
        ? ACTION_STATUSES.ACTIVE
        : ACTION_STATUSES.NEW,
    });
  };

  const isAcessGroupSourceRemoved = (status: string) =>
    status === ACTION_STATUSES.REMOVED;

  const isAcessGroupSourceActive = (status: string) =>
    status === ACTION_STATUSES.ACTIVE;

  const reEnableAccessGroup = (accessGroupId: number) => {
    const originalAccessGroup = getOriginalAccessGroup(accessGroupId);
    const index = findAccessGroupIndex(accessGroupId);
    update(index, {
      ...selectedAccessGroups[index],
      status: originalAccessGroup?.deleted_at
        ? ACTION_STATUSES.RE_ENABLE
        : ACTION_STATUSES.ACTIVE,
    });
  };

  const updateAccessGroupSourcesDefaults = useCallback(
    (newAccessGroupSources: AccessGroupSourceType[]) => {
      replace(normalizeAccessGroupSources(newAccessGroupSources));
    },
    [replace],
  );

  const redirectToAccessGroups = (accessGroupId: number) => {
    window.open(
      accessGroupPaths.edit({
        accessGroupId: accessGroupId,
      }),
      "_blank",
    );
  };

  const shouldShowRemoveConfirmationModal = (
    accessGroupSource: SelectedAccessGroupType,
  ) =>
    Boolean(
      isAcessGroupSourceActive(accessGroupSource.status) &&
        accessGroupSource.hasMembers,
    );

  return {
    appendAccessGroup,
    removeAccessGroup,
    selectedAccessGroups,
    openNewAccessGroupModal,
    isAcessGroupSourceRemoved,
    reEnableAccessGroup,
    updateAccessGroupSourcesDefaults,
    redirectToAccessGroups,
    isAcessGroupSourceActive,
    shouldShowRemoveConfirmationModal,
    finalOriginalAccessGroupSources,
    isFormDirty: isFormDirty(),
    areAccessGroupsSetFromFormDefaults,
    isSyncing,
    syncingTooltip,
  };
};
