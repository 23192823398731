import { t } from "@/i18n-js/instance";
import {
  isPaymentMethodTypeAffirm,
  isPaymentMethodTypeAfterpayClearpay,
  isPaymentMethodTypeApplePay,
  isPaymentMethodTypeCard,
  isPaymentMethodTypeGooglePay,
  isPaymentMethodTypeKlarna,
  isPaymentMethodTypeLink,
  isPaymentMethodTypeNull,
  isPaymentMethodTypePix,
} from "@circle-react/helpers/communityMemberChargeHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";
import { CardProperty } from "./CardProperty";
import { PixProperty } from "./PixProperty";

export const PaymentProperty = ({ charge }: any) => {
  const isCard =
    isPaymentMethodTypeCard(charge) ||
    isPaymentMethodTypeGooglePay(charge) ||
    isPaymentMethodTypeApplePay(charge) ||
    isPaymentMethodTypeLink(charge);

  const localeStringRoot =
    "settings.paywalls_admin_dashboard.charges.charge_details_modal";

  return (
    <>
      {isPaymentMethodTypePix(charge) && (
        <div className="flex flex-row gap-x-1">
          <PixProperty />
        </div>
      )}
      {isCard && (
        <div className="flex flex-row">
          <CardProperty charge={charge} creditCardIconClassName="!w-5" />
        </div>
      )}
      {isPaymentMethodTypeKlarna(charge) && (
        <div className="flex flex-row items-center gap-x-1">
          <Icon type="klarna" viewBox="0 0 36 36" size={36} />
          <Typography.LabelSm>
            {t(`${localeStringRoot}.details_labels.payment_method.klarna`)}
          </Typography.LabelSm>
        </div>
      )}
      {isPaymentMethodTypeAffirm(charge) && (
        <div className="flex flex-row items-center gap-x-1">
          <Icon type="affirm" height={18} width={24} viewBox="0 0 24 18" />
          <Typography.LabelSm>
            {t(`${localeStringRoot}.details_labels.payment_method.affirm`)}
          </Typography.LabelSm>
        </div>
      )}
      {isPaymentMethodTypeAfterpayClearpay(charge) && (
        <div className="flex flex-row items-center gap-x-1">
          <Icon type="afterpay-clearpay" viewBox="0 0 36 36" size={36} />
          <Typography.LabelSm>
            {t(
              `${localeStringRoot}.details_labels.payment_method.afterpay_clearpay`,
            )}
          </Typography.LabelSm>
        </div>
      )}
      {isPaymentMethodTypeNull(charge) && <div>-</div>}
    </>
  );
};
