import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

const localeStringRoot =
  "settings.paywalls_admin_dashboard.charges.charge_details_modal";

export const PixProperty = () => (
  <>
    <Icon type="20-pix" viewBox="0 0 24 24" size={24} />
    <Typography.LabelSm>
      {t(`${localeStringRoot}.details_labels.payment_method.pix`)}
    </Typography.LabelSm>
  </>
);
