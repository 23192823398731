import { uniqueId } from "lodash";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { useCurrentPlanApi } from "@/react/components/SettingsApp/Plan/hooks/useCurrentPlanApi";
import {
  dateFormats,
  formattedDateTime,
} from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Button } from "@circle-react-uikit/Button";
import { SanitizeHTMLString } from "@circle-react-uikit/SanitizeContent";
import { paths } from "./paths";

const localeRoot = "marketing_hub.cancellation_modal";

interface CancelTermsProps {
  onClose: () => void;
}

export const CancelTerms = ({ onClose }: CancelTermsProps) => {
  const history = useHistory();

  const { currentPlan } = useCurrentPlanApi();

  const { marketing_hub_billing: marketingHubBilling } = currentPlan;

  const isInFreePeriod = marketingHubBilling?.billing_status === "free_period";
  const currentPeriodEnd = marketingHubBilling?.current_period_end;
  const sharedSubscription = marketingHubBilling?.shared_subscription;

  const cancelImmediately = sharedSubscription || isInFreePeriod;

  const featuresDisabled = [
    t([localeRoot, "after_disabling", "actions", "0"]),
    t([localeRoot, "after_disabling", "actions", "1"]),
    t([localeRoot, "after_disabling", "actions", "2"]),
  ];

  const tooltipContent = cancelImmediately
    ? t([localeRoot, "tooltip", "with_free_period"])
    : t([localeRoot, "tooltip", "with_subscription"]);

  const cancelTermsText = cancelImmediately
    ? t([localeRoot, "cancel_terms", "with_free_period"])
    : t([localeRoot, "cancel_terms", "with_subscription"], {
        date: formattedDateTime({
          dateTime: new Date(currentPeriodEnd),
          format: dateFormats.short_month_and_date_with_year,
        }),
      });

  return (
    <div className="mt-4 flex flex-col gap-y-7 pb-2">
      <div className="flex flex-col gap-y-2">
        <div className="flex flex-row gap-x-1">
          <Typography.LabelMd weight="semibold">
            {t([localeRoot, "cancel_terms", "title"])}
          </Typography.LabelMd>
          <TippyV2
            content={tooltipContent}
            className="flex cursor-pointer"
            placement="top"
          >
            <span className="flex">
              <Icon
                type="12-question-mark"
                size={12}
                className="!text-default !h-3 !w-3"
              />
            </span>
          </TippyV2>
        </div>
        <Typography.BodySm>
          <SanitizeHTMLString content={cancelTermsText} />
        </Typography.BodySm>
      </div>
      <div className="flex flex-col gap-y-2">
        <Typography.LabelMd weight="semibold">
          {t([localeRoot, "after_disabling", "title"])}
        </Typography.LabelMd>
        <ul>
          {featuresDisabled.map((featureDisabled: string) => (
            <Typography.BodySm as="li" key={uniqueId()}>
              {featureDisabled}
            </Typography.BodySm>
          ))}
        </ul>
      </div>

      <div className="flex items-stretch justify-end space-x-2">
        <Button onClick={onClose} variant="secondary">
          {t("cancel")}
        </Button>

        <Button onClick={() => history.push(paths.REASON)} variant="circle">
          {t("continue")}
        </Button>
      </div>
    </div>
  );
};
