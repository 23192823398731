import { useEffect, useRef, useState } from "react";
import debounce from "lodash.debounce";
import { Icon } from "../Icon";

const styles = {
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 15,
  },
};

export const Pagination = ({
  initialPage = 2,
  fetchNewPage,
  debounceInterval = 50,
  footerDistanceFromBottom = -1000,
  componentId,
  onEvent = "scrollDown",
}) => {
  const elementRef = useRef(null);
  const [page, setPage] = useState(initialPage);
  const [loading, setLoading] = useState(false);
  const incrementPage = () => {
    setPage(page + 1);
  };

  const component = document.getElementById(componentId) || window;

  useEffect(() => {
    const debouncedScrollHandler = debounce(async () => {
      if (loading) {
        return;
      }

      let sendRequest = false;
      if (onEvent === "scrollUp") {
        sendRequest = component.scrollTop < 20;
      } else {
        const position = elementRef.current.getBoundingClientRect();
        const windowHeight = component.innerHeight || component.clientHeight;
        const loc = windowHeight - position.top;
        sendRequest = loc > footerDistanceFromBottom;
      }

      if (sendRequest) {
        await setLoading(true);
        await fetchNewPage(page);
        incrementPage();
        setLoading(false);
      }
    }, debounceInterval);

    component.addEventListener("scroll", debouncedScrollHandler, {
      passive: true,
    });
    return () => {
      component.removeEventListener("scroll", debouncedScrollHandler);
    };
  }, [page, loading]);

  return (
    <div
      ref={elementRef}
      className="pagination-container"
      style={styles.paginationContainer}
    >
      {loading && <Icon type="loader" />}
    </div>
  );
};
