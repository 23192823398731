import { t } from "@/i18n-js/instance";
import featurePreview1x from "../../../../../../../assets/images/features/marketing-hub/landing-page/feature-1_1x.png";
import featurePreview2x from "../../../../../../../assets/images/features/marketing-hub/landing-page/feature-1_2x.png";
import featurePreview4x from "../../../../../../../assets/images/features/marketing-hub/landing-page/feature-1_4x.png";
import { Blocks } from "../Blocks";
import { localeRoot as marketingHubLocale } from "../constants";

const localeRoot = `${marketingHubLocale}.blocks.email_marketing`;

const backgroundColors = {
  primary: "#CFD8FE",
  secondary: "#F4F6FF",
};

export const EmailMarketingFeature = () => (
  <Blocks>
    <Blocks.FeatureSummary backgroundColor={backgroundColors.secondary}>
      <Blocks.Header
        localeRoot={localeRoot}
        iconName="24-workflow-email"
        backgroundColor={backgroundColors.primary}
      />
      <Blocks.FeatureList>
        <Blocks.FeatureItem backgroundColor={backgroundColors.primary}>
          {t([localeRoot, "features", "0"])}
        </Blocks.FeatureItem>
        <Blocks.FeatureItem backgroundColor={backgroundColors.primary}>
          {t([localeRoot, "features", "1"])}
        </Blocks.FeatureItem>
        <Blocks.FeatureItem backgroundColor={backgroundColors.primary}>
          {t([localeRoot, "features", "2"])}
        </Blocks.FeatureItem>
      </Blocks.FeatureList>
    </Blocks.FeatureSummary>
    <Blocks.FeaturePreview
      backgroundColor={backgroundColors.primary}
      className="flex flex-col items-center justify-center"
    >
      <img
        alt=""
        loading="eager"
        src={featurePreview1x}
        srcSet={`${featurePreview1x} 663w, ${featurePreview2x} 812w, ${featurePreview4x} 2000w`}
        className="w-[300px] md:w-auto"
      />
    </Blocks.FeaturePreview>
  </Blocks>
);
