import type { ComponentPropsWithoutRef, ReactNode } from "react";
import classNames from "classnames";

interface FilterItemsProps extends ComponentPropsWithoutRef<"ul"> {
  className?: string;
  children: ReactNode;
}

export const FiltersItems = ({
  className,
  children,
  ...rest
}: FilterItemsProps) => (
  <ul
    className={classNames(
      "flex flex-1 list-none flex-wrap items-center gap-2",
      className,
    )}
    {...rest}
  >
    {children}
  </ul>
);
