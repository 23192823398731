export const TermColumn = (cellContext: any) => {
  const {
    row: { original: charge },
  } = cellContext;

  return (
    <div className="flex flex-col whitespace-normal">
      {charge.paywall_price.interval_qualifier}
    </div>
  );
};
