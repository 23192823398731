import { t } from "@/i18n-js/instance";
import { settingsNavPathV3 } from "@circle-react/helpers/urlHelpers";
import { NavGroup } from "../NavGroup";
import { SubNavLinkItem } from "./SubNavLinkItem";
import type { Link } from "./types";

export const SettingsLinks = () => {
  const links: Link[] = [
    {
      label: t("settings_nav_v3.settings.general"),
      path: settingsNavPathV3.settings.general,
      exact: true,
    },
    {
      label: t("settings_nav_v3.settings.custom_domain"),
      path: settingsNavPathV3.settings.custom_domain,
      exact: true,
    },
    {
      label: t("settings_nav_v3.settings.community_ai"),
      path: settingsNavPathV3.settings.community_ai,
      exact: true,
    },
    {
      label: t("settings_nav_v3.site.mobile_app"),
      path: settingsNavPathV3.settings.mobile_app,
      exact: true,
    },
    {
      label: t("settings_nav_v3.settings.weekly_digest"),
      path: settingsNavPathV3.settings.weekly_digest,
      exact: true,
    },
    {
      label: t("settings_nav_v3.settings.embed"),
      path: settingsNavPathV3.settings.embed,
      exact: true,
    },
    {
      label: t("settings_nav_v3.settings.single_sign_on"),
      path: settingsNavPathV3.settings.single_sign_on,
      exact: true,
    },
    {
      label: t("settings_nav_v3.settings.messaging"),
      path: settingsNavPathV3.settings.messaging,
      exact: true,
    },
    {
      label: t("settings_nav_v3.settings.legal"),
      path: settingsNavPathV3.settings.legal,
      exact: true,
    },
  ];

  return (
    <NavGroup groupLinks={links}>
      <NavGroup.Content title={t("settings_nav_v3.settings.label")}>
        {links.map(link => (
          <SubNavLinkItem key={link.path} label={link.label} path={link.path} />
        ))}
      </NavGroup.Content>
    </NavGroup>
  );
};
