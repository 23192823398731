import { useContext } from "react";
import { createContext } from "react";

export type AudienceSearchUsage = "contacts" | "members";

export interface AudienceSearchContextType {
  usage: AudienceSearchUsage;
}

/**
 * Some sections of the app use backend Contacts::Search
 * These might have different features from the ones using CommunityMembers::Search
 */
export const AudienceSearchContext = createContext<AudienceSearchContextType>({
  usage: "members",
});

AudienceSearchContext.displayName = "AudienceSearchContext";

export const useAudienceSearchContext = () => {
  const { usage } = useContext(AudienceSearchContext);

  const isContactsSearch = usage === "contacts";

  return {
    usage,
    isContactsSearch,
  } as const;
};
