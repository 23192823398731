import { Fragment, createContext, useContext, useState } from "react";
import type { ReactNode } from "react";
import { Popover } from "@headlessui/react";
import { createPortal } from "react-dom";
import invariant from "tiny-invariant";
import { FilterChip } from "./FilterChip";
import { FilterPopoverPanel } from "./FilterPopoverPanel";
import type { SelectedText } from "./types";

interface FilterContextValue {
  selectedText: SelectedText;
  onApply: (value: any) => void;
  close: () => void;
}

const FilterContext = createContext<FilterContextValue | null>(null);

export const useFilterContext = () => {
  const filterContext = useContext(FilterContext);
  invariant(filterContext, "useFilterContext must be used within Filter");
  return filterContext;
};

FilterContext.displayName = "FilterContext";

interface FilterProps {
  /** Title for popover */
  title?: ReactNode;
  /** Content used on the filter chip */
  chip: string;
  /** Display text added in the chip */
  selectedText?: any;
  /** Customise the text displayed inside chip completely  */
  renderSelectedText?: ({ selectedText }: { selectedText: any }) => ReactNode;
  /**
   * The actual inputs. Bring your own or use one of the existing ones in FilterTypes.
   * You can provide a single component which will be provided with `onApply` callback to pass in the selectedText
   * If you end up having multiple elements or a fragment which cannot receive pass in props, you can choose to pass in a function instead
   */
  children: ReactNode | ((props: FilterContextValue) => ReactNode);
  /**
   * Callback fired on applying a filter
   * Contains value transmitted from selected filter
   * May be different from text displayed
   */
  onApply: (value: any) => void;
  /** Open the filter automatically */
  isInitialOpen?: boolean;
  shouldShowRemoveButton?: boolean;
  shouldShowClearButton?: boolean;
  shouldUsePortal?: boolean;
  contentPortalId?: string;
}

/**
 * The V2 Filters
 * Supports any type of filters in popover as provided
 * @deprecated Use FilterV3 instead.
 */
export const Filter = ({
  children,
  title = null,
  chip,
  selectedText = null,
  renderSelectedText,
  onApply,
  isInitialOpen,
  shouldShowRemoveButton = false,
  shouldShowClearButton = true,
  shouldUsePortal = false,
  contentPortalId = "",
}: FilterProps) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLLIElement | null>();

  const portalRoot = document.getElementById(contentPortalId) ?? document.body;
  const filterPopoverPanelProps = {
    title,
    referenceElement,
    isInitialOpen,
    shouldShowRemoveButton,
  };

  return (
    <Popover as={Fragment}>
      {({ open, close }: any) => {
        const handleApply = (value: any) => {
          close();
          onApply(value);
        };
        const childrenProps = { selectedText, onApply: handleApply, close };
        return (
          <FilterContext.Provider value={childrenProps}>
            <Popover.Button
              ref={setReferenceElement}
              className="focus-visible:ring-secondary rounded-3xl focus-visible:ring-1"
              as="li"
            >
              <FilterChip
                isFocused={open}
                selectedText={selectedText}
                renderSelectedText={renderSelectedText}
                onClear={() => handleApply(null)}
                shouldShowClearButton={shouldShowClearButton}
              >
                {chip}
              </FilterChip>
            </Popover.Button>
            {shouldUsePortal ? (
              createPortal(
                <FilterPopoverPanel
                  {...{ ...filterPopoverPanelProps, handleApply }}
                  {...childrenProps}
                >
                  {children}
                </FilterPopoverPanel>,
                portalRoot,
              )
            ) : (
              <FilterPopoverPanel
                {...{ ...filterPopoverPanelProps, handleApply }}
                {...childrenProps}
              >
                {children}
              </FilterPopoverPanel>
            )}
          </FilterContext.Provider>
        );
      }}
    </Popover>
  );
};
