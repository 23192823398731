import { t } from "@/i18n-js/instance";
import { planSettingsPath } from "@/react/helpers/urlHelpers";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Icon } from "@circle-react-shared/Icon";
import { ActionModal } from "@circle-react-shared/ModalsV2/ActionModal";
import { Tippy } from "@circle-react-shared/Tippy";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";

export const FeatureDetailsModalComponent = ({ PlanFeatureName }: any) => {
  const modal = useModal();
  const featuresTranslation = [
    "conversion_code",
    "courses",
    "chat",
    "up_to_3_admins",
    "up_to_10_moderators",
    "up_to_20_spaces",
    "unlimited_members",
  ];

  const featuresTooltipTranslation: any = {
    unlimited_members: t("settings.billing.unlimited_members_tooltip"),
  };

  const redirectToPlans = () => {
    // TODO: when moving to modals v2
    // use history.push and close main modal using a context
    // to avoid passing the reference between many components
    window.location.href = planSettingsPath();
  };

  return (
    <ActionModal
      title={t("plan_details.unlock_feature.upgrade_plan")}
      isOpen={modal.visible}
      onClose={modal.hide}
    >
      <Typography.LabelLg weight="semibold">
        {t("plan_details.unlock_feature.feature_and_plan", {
          feature: PlanFeatureName,
        })}
      </Typography.LabelLg>

      <div className="mt-2">
        <Typography.BodyMd color="text-gray-600">
          {t("plan_details.unlock_feature.feature_not_included", {
            feature: PlanFeatureName,
          })}
        </Typography.BodyMd>
      </div>

      {featuresTranslation.map(feature => (
        <div key={feature} className="mt-5 flex">
          <Icon type="star-orange" className="mr-3" size={20} />
          <Typography.LabelMd weight="medium">
            {t(`plan_details.${feature}`)}
          </Typography.LabelMd>

          {featuresTooltipTranslation[feature] && (
            <Tippy content={featuresTooltipTranslation[feature]}>
              <Icon type="question-mark" className="ml-2 !w-3 align-baseline" />
            </Tippy>
          )}
        </div>
      ))}

      <Button
        onClick={redirectToPlans}
        variant="circle"
        full
        extraLarge
        className="mb-4 mt-8"
      >
        {t("plan_details.unlock_feature.actions.upgrade_now")}
      </Button>
    </ActionModal>
  );
};

const FeatureDetailsModal = ModalManager.create(({ PlanFeatureName }: any) => (
  <FeatureDetailsModalComponent PlanFeatureName={PlanFeatureName} />
));

export const useFeatureDetailsModal = () => useModal(FeatureDetailsModal);
