import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";

export const StartDateColumn = (cellContext: any) => {
  const {
    row: { original: subscription },
  } = cellContext;

  const createdAtString = formattedDateTime({
    dateTime: new Date(subscription.created_at),
    format: "long_date",
  });

  return <div className="whitespace-normal">{createdAtString}</div>;
};
