import { t } from "@/i18n-js/instance";
import { Tab } from "@circle-react-uikit/TabV2";
import { LinkClicksStatsTableV2 } from "./LinkClickStatsTableV2";
import { RecipientsTabList } from "./RecipientsTabList";

const localeNamespace = "settings.emails.view_broadcast.reports_tab";

export const BroadcastRecipientAndLinksDetails = () => (
  <Tab.Group className="grid h-full grid-rows-[min-content_1fr] overflow-hidden">
    <Tab.ListWrapper className="!px-0">
      <Tab.List>
        <Tab key="recipients">{t([localeNamespace, "recipients"])}</Tab>
        <Tab key="links_and_file">{t([localeNamespace, "links_and_file"])}</Tab>
      </Tab.List>
    </Tab.ListWrapper>
    <Tab.Panels>
      <Tab.Panel className="overflow-hidden">
        <RecipientsTabList />
      </Tab.Panel>
      <Tab.Panel className="overflow-hidden">
        <LinkClicksStatsTableV2 />
      </Tab.Panel>
    </Tab.Panels>
  </Tab.Group>
);
