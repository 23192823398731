import PropTypes from "prop-types";
import isFunction from "lodash/isFunction";
import { ExpandableBlock } from "@circle-react-uikit/ExpandableBlock";
import { useItemData } from "./ItemDataProvider";

export const Button = ({ children, className }) => {
  const { renderButton, onToggleItem } = useItemData();

  const onClickHandler = () => {
    isFunction(onToggleItem) && onToggleItem();
  };

  return (
    <ExpandableBlock.Button onClick={onClickHandler} className={className}>
      {children ? children : renderButton && renderButton()}
    </ExpandableBlock.Button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
