import { createContext, useContext } from "react";
import { noop } from "lodash";

interface PaywallSubscriptionListContextProviderProps {
  children: JSX.Element;
  shouldOpenAdminMemberEdit: boolean;
  refetchAllSubscriptions: () => void;
  variant?: string;
}

const defaultValues = {
  shouldOpenAdminMemberEdit: true,
  refetchAllSubscriptions: noop,
  variant: "default",
};

const PaywallSubscriptionListContext = createContext(defaultValues);
PaywallSubscriptionListContext.displayName = "PaywallSubscriptionListContext";

export const PaywallSubscriptionListContextProvider = ({
  children,
  shouldOpenAdminMemberEdit,
  refetchAllSubscriptions,
  variant = "default",
}: PaywallSubscriptionListContextProviderProps) => {
  const valuesToStore = {
    shouldOpenAdminMemberEdit,
    refetchAllSubscriptions,
    variant,
  };

  return (
    <PaywallSubscriptionListContext.Provider value={{ ...valuesToStore }}>
      {children}
    </PaywallSubscriptionListContext.Provider>
  );
};

export const usePaywallSubscriptionListContext = () =>
  useContext(PaywallSubscriptionListContext);
