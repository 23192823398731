import { t } from "@/i18n-js/instance";
import { useIsPaywallPaymentSettingsAvailable } from "@circle-react/components/Paywalls/Admin/PaymentSettings/hooks/useIsPaywallPaymentSettingsAvailable";
import { useIsPaywallTaxAvailable } from "@circle-react/components/Paywalls/Admin/TaxSettings/hooks/useIsPaywallTaxAvailable";
import { usePunditUserContext } from "@circle-react/contexts";
import { settingsNavPathV3 } from "@circle-react/helpers/urlHelpers";
import { NavGroup } from "../NavGroup";
import { SubNavLinkItem } from "./SubNavLinkItem";
import type { Link } from "./types";

export const PaywallLinks = () => {
  const isPaywallTaxAvailable = useIsPaywallTaxAvailable();
  const isPaywallPaymentSettingsAvailable =
    useIsPaywallPaymentSettingsAvailable();

  const { currentCommunity } = usePunditUserContext();
  const paymentProcessor = currentCommunity?.payment_processor;

  const links: Link[] = [
    {
      label: t("settings_nav_v3.payments.paywalls"),
      path: settingsNavPathV3.paywalls.index,
      exact: true,
    },
    {
      label: t("settings_nav_v3.payments.coupons"),
      path: settingsNavPathV3.paywalls.coupons,
      exact: true,
    },
    {
      label: t("settings_nav_v3.payments.subscription_groups"),
      path: settingsNavPathV3.paywalls.subscription_groups,
      exact: true,
    },
    {
      label: t("settings_nav_v3.payments.transactions"),
      path: settingsNavPathV3.paywalls.transactions,
      exact: true,
    },
    {
      label: t("settings_nav_v3.payments.subscriptions"),
      path: settingsNavPathV3.paywalls.subscriptions,
      exact: true,
    },
  ];

  if (!paymentProcessor || isPaywallTaxAvailable) {
    links.push({
      label: t("settings_nav_v3.payments.taxes"),
      path: settingsNavPathV3.paywalls.taxes,
      exact: true,
    });
  }

  links.push({
    label: t("settings_nav_v3.payments.bulk_logs"),
    path: settingsNavPathV3.paywalls.bulk_logs,
    exact: true,
  });

  if (isPaywallPaymentSettingsAvailable) {
    links.push({
      label: t("settings_nav_v3.payments.payment_settings"),
      path: settingsNavPathV3.paywalls.payment_settings,
      exact: true,
    });
  }

  return (
    <NavGroup groupLinks={links}>
      <NavGroup.Content title={t("settings_nav_v3.payments.label")}>
        {links.map(link => (
          <SubNavLinkItem key={link.path} label={link.label} path={link.path} />
        ))}
      </NavGroup.Content>
    </NavGroup>
  );
};
