import { useMemo } from "react";
import invariant from "tiny-invariant";
import { t } from "@/i18n-js/instance";
import type {
  BaseFilter,
  ProfileFieldBaseFilter,
} from "../../../MemberFilters/types";
import type { SingleSelectFilterOption } from "../SingleSelectFilter/SelectFilterOption";
import { isArrayComparatorParam } from "./arrayComparators";

const unknown = t(["settings.workflows.view.unknown"]);

export const renderArraySelectedText = (
  param: BaseFilter | ProfileFieldBaseFilter,
  value: string | null,
) => {
  const valueToRender = value ?? unknown;
  if (isArrayComparatorParam(param)) {
    return t(["filter_comparators", "values", param.filter_type], {
      value: valueToRender,
    });
  }
  // backwards compatibility for old filters
  if (param.value) {
    return t(["filter_comparators", "values", "is"], {
      value: valueToRender,
    });
  }
  return null;
};

export function useArrayComparatorSelectedItem(
  items: SingleSelectFilterOption[] = [],
) {
  const itemMap = useMemo(
    () =>
      items.reduce((acc, item) => {
        acc.set(String(item.value), item.label);
        return acc;
      }, new Map<string, string>()),
    [items],
  );

  const getLabel = (value?: string) => {
    if (!value) return null;
    const label = itemMap.get(value);
    return label ?? value;
  };

  return (param: BaseFilter | ProfileFieldBaseFilter) => {
    if (isArrayComparatorParam(param)) {
      return renderArraySelectedText(param, getLabel(param.value?.toString()));
    }
    // backwards compatibility for old filters
    if (param.value) {
      invariant(
        typeof param.value === "string" || typeof param.value === "number",
        "param.value must be a string or number",
      );
      return renderArraySelectedText(param, getLabel(param.value.toString()));
    }
    return null;
  };
}
