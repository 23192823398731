import type { FormEvent } from "react";
import { useState } from "react";
import { t } from "@/i18n-js/instance";
import type { TableColumn } from "@circle-react/components/SettingsApp/ManageMembers/TabContent/Tables/columnConfiguration";
import {
  DEFAULT_PAGE_SIZE,
  useRecipientListInfiniteQuery,
} from "@circle-react-shared/Emails/BroadcastReport/LinkClicksStats/ViewRecipients/useRecipientListInfiniteQuery";
import { InfiniteQueryDataTable } from "@circle-react-shared/InfiniteQueryDataTable";
import { SkeletonLoader } from "@circle-react-shared/uikit/SkeletonLoader";
import { numberFormatter } from "@circle-react-shared/uikit/TabV2/Counter";
import { DataTable } from "@circle-react-uikit/DataTableV2";
import { useBroadcastReport } from "../BroadcastReportContext";
import { RecipientSearchInput } from "./RecipientSearchInput";

const i18nRoot = "settings.emails.view_broadcast.reports_tab.recipients_table";

export interface EventedRecipientTableProps {
  columns: TableColumn[];
  event: string;
}

export const EventedRecipientTable = ({
  columns,
  event,
}: EventedRecipientTableProps) => {
  const { data } = useBroadcastReport();
  const [initialPage, setPage] = useState(1);
  const [filters, setFilters] = useState({});

  const infiniteQuery = useRecipientListInfiniteQuery({
    id: Number(data?.id),
    event_type: event,
    initialPage,
    filters,
  });

  const handleSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const searchValue = formData.get("search");
    setFilters({ name: searchValue, email: searchValue });
    setPage(1);
  };

  return (
    <div className="bg-primary pb-4">
      <InfiniteQueryDataTable
        columns={columns}
        infiniteQuery={infiniteQuery}
        initialPage={initialPage}
        pageSize={DEFAULT_PAGE_SIZE}
        totalCountPath="count"
        onChangePage={newPage => {
          setPage(newPage);
        }}
      >
        <div className="grid grid-flow-row justify-between sm:grid-flow-col">
          <DataTable.Headline className="!px-0 pb-4">
            <DataTable.Headline.Title>
              {infiniteQuery.isLoading ? (
                <SkeletonLoader variant="row" />
              ) : (
                t([i18nRoot, "people_count"], {
                  formattedCount: numberFormatter(
                    infiniteQuery.totalQueryMembers,
                  ),
                  count: infiniteQuery.totalQueryMembers,
                })
              )}
            </DataTable.Headline.Title>
          </DataTable.Headline>
          <RecipientSearchInput handleSearch={handleSearch} />
        </div>
        <div className="isolate">
          <DataTable.Table>
            <DataTable.Header className="z-10" />
            <DataTable.Loader />
            <DataTable.NoRecords text={t([i18nRoot, "no_recipients_found"])} />
            <DataTable.Body />
          </DataTable.Table>
          <DataTable.Pagination align="left" direction="reverse" />
        </div>
      </InfiniteQueryDataTable>
    </div>
  );
};
