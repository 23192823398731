import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts/punditUserContext";
import { useContentSpecAccessDetails } from "@circle-react/hooks/accessRegistry/useContentSpecAccessDetails";
import type { ContentSpecType } from "@circle-react/types/AccessRegistry";
import { contentSpecTypes } from "@circle-react/types/AccessRegistry";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { ContentSpecAccessGroups } from "./ContentSpecAccessGroups";

export interface ContentSpecAccessGroupsControlProps {
  contentSpecType: ContentSpecType;
  contentSpecId: number;
}

const i18nRoot =
  "settings.access_registry.components.content_spec_access_groups_control";

export const ContentSpecAccessGroupsControl = ({
  contentSpecType,
  contentSpecId,
}: ContentSpecAccessGroupsControlProps) => {
  const { currentCommunitySettings } = usePunditUserContext();
  const isAccessGroupsEnabled =
    !!currentCommunitySettings?.access_groups_enabled;

  const { data, isLoading } = useContentSpecAccessDetails(
    contentSpecType,
    contentSpecId,
    isAccessGroupsEnabled,
  );

  if (isLoading || !data || data.type === contentSpecTypes.AccessGroup) {
    return null;
  }

  return (
    <>
      <Typography.BodyMd>{t([i18nRoot, "warning"])}</Typography.BodyMd>
      <ContentSpecAccessGroups contentSpecAccessDetails={data} />
    </>
  );
};
