import PropTypes from "prop-types";

export const SearchBar = ({ name, value, onChange, searchProps }) => {
  const inputId = `search-${name}`;
  return (
    <div>
      <label className="sr-only" htmlFor={inputId}>
        {searchProps?.label}
      </label>
      <input
        name={inputId}
        id={inputId}
        className="border-primary bg-primary text-default shadow-xs ring-secondary placeholder:text-light w-full rounded-md border px-4 py-3 leading-5 placeholder:opacity-100 focus:outline-none focus:ring-1"
        autoFocus
        value={value}
        onChange={onChange}
        {...searchProps}
      />
    </div>
  );
};

SearchBar.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  searchProps: PropTypes.object,
};
