import type { BroadcastContentAttributes } from "@circle-react/components/SettingsApp/Emails/hooks/useGetBroadcast";
import { TipTapBlockEditor } from "@circle-react-shared/uikit/TipTapBlockEditor";

interface BroadcastTipTapReadonlyViewProps {
  content: BroadcastContentAttributes["email_content"];
}

export function BroadcastTipTapReadonlyView({
  content,
}: BroadcastTipTapReadonlyViewProps) {
  return (
    <TipTapBlockEditor
      value={content.body}
      shouldRefetch
      readOnly
      sgidToObjectMap={content.sgids_to_object_map}
      inlineAttachments={content.inline_attachments}
      shouldPreviewFilesAsLinks
      isWithinEmailEditor
    />
  );
}
