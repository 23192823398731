import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const useCustomerSearch = ({
  recordType,
  searchQueryDebounced,
  otherSearchParams,
}: any) => {
  const PER_PAGE = 10;

  const fetchPage = ({ pageParam = 1 }) =>
    reactQueryGet(
      recordType === "CommunityMember"
        ? communityMembersQuery(pageParam)
        : paywallsAffiliatesQuery(pageParam),
    );

  const communityMembersQuery = (pageParam: any) =>
    internalApi.search.communityMembers.index({
      params: {
        page: pageParam,
        per_page: PER_PAGE,
        ...otherSearchParams,
        query: searchQueryDebounced,
        query_type: "name_and_email",
      },
    });

  const paywallsAffiliatesQuery = (pageParam: any) =>
    internalApi.search.paywallsAffiliates.index({
      params: {
        page: pageParam,
        per_page: PER_PAGE,
        filters: {
          ...otherSearchParams,
          name_or_email: searchQueryDebounced,
        },
      },
    });

  const communityMemberRecord = (record: any) =>
    recordType === "CommunityMember" ? record : record.community_member;

  return {
    fetchPage,
    communityMemberRecord,
  };
};
