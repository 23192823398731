import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { StatusBadge } from "@circle-react-shared/uikit/StatusBadge";
import { i18nRoot } from "./constants";

export interface BadgeProps {
  variant: "connected" | "removed" | "syncing";
}

export const Badge = ({ variant }: BadgeProps) => (
  <StatusBadge variant={variant === "connected" ? "success" : "default"}>
    <div className="flex items-center">
      {variant === "syncing" && (
        <span className="-mt-0.5 mr-1">
          <Icon type="loader" size={16} />
        </span>
      )}
      {t([i18nRoot, "badge", variant])}
    </div>
  </StatusBadge>
);
