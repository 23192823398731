import { t } from "@/i18n-js/instance";
import type { SelectedAccessGroupType } from "@circle-react/types/AccessGroup";
import { ThreeDotsDropdownV3 } from "@circle-react-shared/ThreeDotsDropdownV3";
import { EditAction } from "./Actions/EditAction";
import { ReEnableAction } from "./Actions/ReEnableAction";
import { RemoveAction } from "./Actions/RemoveAction";
import { useAccessGroupsActions } from "./hooks/useAccessGroupsActions";

export const AccessGroupDropdown = ({
  accessGroup,
}: {
  accessGroup: SelectedAccessGroupType;
}) => {
  const { isAcessGroupSourceRemoved } = useAccessGroupsActions();

  return (
    <ThreeDotsDropdownV3
      label={t(
        "settings.access_groups.admin.entry_points.association.actions.label",
      )}
    >
      <EditAction accessGroup={accessGroup} />
      {isAcessGroupSourceRemoved(accessGroup.status) && (
        <ReEnableAction accessGroup={accessGroup} />
      )}
      {!isAcessGroupSourceRemoved(accessGroup.status) && (
        <RemoveAction accessGroup={accessGroup} />
      )}
    </ThreeDotsDropdownV3>
  );
};
