import type { MouseEventHandler, ReactNode } from "react";
import { useBroadcastReport } from "../../BroadcastReportContext";
import { useMatchedRecipientModal } from "./MatchedRecipientModal";

interface ViewRecipientListButtonProps {
  className?: string;
  link?: string;
  children: ReactNode;
  recipient_type: "link_clicks_recipients" | "all_recipients";
}

export function ViewRecipientListButton({
  className,
  children,
  link,
  recipient_type,
}: ViewRecipientListButtonProps) {
  const recipientModal = useMatchedRecipientModal();
  const { data } = useBroadcastReport();

  if (!data) return null;
  const { id: broadcastId } = data;

  const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation();
    void recipientModal.show({
      broadcastId: Number(broadcastId),
      link,
      recipient_type,
    });
  };

  return (
    <button type="button" onClick={handleClick} className={className}>
      {children}
    </button>
  );
}
