import { t } from "@/i18n-js/instance";
import {
  attributeSaleModalType,
  isSaleAttributed,
} from "@circle-react/helpers/paywalls/affiliates/helpers";
import { affiliateStatuses } from "@circle-react/types/PaywallsAffiliates";
import type {
  Attributable,
  AttributableType,
} from "@circle-react/types/PaywallsAffiliates";
import { Icon } from "@circle-react-shared/Icon";
import { ActionModal } from "@circle-react-shared/ModalsV2/ActionModal";
import { CustomerColumn } from "@circle-react-shared/Paywalls/CustomerColumn";
import { CustomerSearch } from "@circle-react-shared/Paywalls/CustomerSearch";
import { InfoBox } from "@circle-react-shared/uikit/InfoBox";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { RemoveAffiliateAttributionConfirmationModal } from "./RemoveAffiliateAttributionConfirmationModal";
import { useAttributeSale } from "./hooks/useAttributeSale";

interface AttributeSaleModalProps {
  attributable: Attributable;
  attributableType: AttributableType;
  onSuccess: () => void;
}

export const AttributeSaleModal = ({
  attributable,
  attributableType,
  onSuccess,
}: AttributeSaleModalProps) => {
  const {
    modal,
    onClose,
    affiliateId,
    setAffiliateId,
    actionText,
    removalNotAllowedAlert,
    onAction,
    onRemove,
    createMutation,
    destroyMutation,
    isRemoveAffiliateAttributionConfirmationModalOpen,
    openRemoveAffiliateAttributionConfirmationModal,
    closeRemoveAffiliateAttributionConfirmationModal,
  } = useAttributeSale({
    attributable,
    attributableType,
    onSuccess,
  });

  return (
    <ActionModal
      title={t(
        `settings.paywalls_admin_dashboard.attribute_sale_modal.${attributeSaleModalType(
          attributable,
        )}.title`,
      )}
      isOpen={modal.visible}
      onClose={onClose}
      onAction={onAction}
      disabled={createMutation.isLoading || !affiliateId}
      actionText={actionText}
      actionVariant="circle"
      modalContentClassName="!overflow-visible"
    >
      {isSaleAttributed(attributable) ? (
        <>
          <div className="pb-4">
            {!removalNotAllowedAlert && (
              <RemoveAffiliateAttributionConfirmationModal
                attributable={attributable}
                onConfirm={onRemove}
                isOpen={isRemoveAffiliateAttributionConfirmationModalOpen}
                onClose={closeRemoveAffiliateAttributionConfirmationModal}
                destroyMutation={destroyMutation}
              />
            )}

            <CustomerColumn
              customer={
                attributable.paywalls_affiliates_attribution_info
                  .community_member
              }
              onRemove={
                removalNotAllowedAlert
                  ? undefined
                  : openRemoveAffiliateAttributionConfirmationModal
              }
              infoClassName="w-[270px]"
            />
          </div>
          {removalNotAllowedAlert && (
            <div className="pb-4">
              <InfoBox className="flex flex-row gap-3">
                <div>
                  <Icon type="information-outline" size={24} />
                </div>
                <Typography.LabelSm weight="regular" as="div">
                  {removalNotAllowedAlert}
                </Typography.LabelSm>
              </InfoBox>
            </div>
          )}
        </>
      ) : (
        <CustomerSearch
          recordId={affiliateId}
          recordType="PaywallsAffiliate"
          searchInputPlaceholder={t(
            "settings.paywalls_admin_dashboard.attribute_sale_modal.search.name_or_email.placeholder",
          )}
          otherSearchParams={{
            status: affiliateStatuses.enrolled,
            campaign_id: attributable.paywalls_affiliates_campaign_id,
          }}
          onApply={setAffiliateId}
          onRemove={() => setAffiliateId(null)}
          className="!p-0"
          customerInfoClassName="w-[270px]"
          optionsClassName="z-10 !w-[26rem] !max-w-[26rem]"
        />
      )}
    </ActionModal>
  );
};
