import type { ReactNode } from "react";
import { useTrialBanner } from "@circle-react/components/Layout/TrialBanner/useTrialBanner";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import type { DropdownOptions } from "@circle-react-shared/uikit/Dropdown";
import { Dropdown } from "@circle-react-shared/uikit/Dropdown";

export interface ThreeDotsDropdownV3Props {
  label: string;
  options?: DropdownOptions;
  dataTestId?: string;
  children?: ReactNode;
}

export const ThreeDotsDropdownV3 = ({
  label,
  options,
  dataTestId,
  children,
}: ThreeDotsDropdownV3Props) => {
  const { shouldShowTrialBanner } = useTrialBanner();

  const maxHeightStyles = shouldShowTrialBanner
    ? "max-h-[calc(100vh-12rem)]"
    : "max-h-[calc(100vh-8rem)]";

  return (
    <Dropdown
      direction="bottom-end"
      menuWrapperClassName="!mt-2"
      buttonClassName="flex items-center h-9 w-9 flex justify-center !rounded-lg transition-colors duration-200 hover:bg-tertiary focus-visible:outline-secondary focus-visible:rounded-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-tertiary"
      menuItemsClassName={classNames("overflow-y-auto", maxHeightStyles)}
      button={
        <TippyV2 content={label} className="flex items-center p-1">
          <Icon
            className="text-default !h-6 !w-6"
            type="20-menu-dots-horizontal"
            size={20}
            aria-label={label}
          />
        </TippyV2>
      }
      options={options}
      dataTestId={dataTestId}
    >
      {children}
    </Dropdown>
  );
};
