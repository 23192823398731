import { isObject, isString } from "lodash";
import invariant from "tiny-invariant";
import { t } from "@/i18n-js/instance";
import type {
  BaseFilter,
  ProfileFieldBaseFilter,
} from "@circle-react-shared/MemberFilters/types";

const i18nRoot = "filter_comparators";

const comparatorsForArray = ["is", "is_not", "any", "empty"] as const;

export type ComparatorsForArray = (typeof comparatorsForArray)[number];

export interface ArrayComparatorParam extends BaseFilter {
  filter_type: ComparatorsForArray;
  value?: string | number;
}

export type ArrayComparatorProfileFieldParam = ArrayComparatorParam &
  ProfileFieldBaseFilter;

export const isComparatorsForArray = (
  filterType: unknown,
): filterType is ComparatorsForArray =>
  isString(filterType) &&
  comparatorsForArray.includes(filterType as ComparatorsForArray);

export const isArrayComparatorParam = (
  selected: BaseFilter,
): selected is ArrayComparatorParam =>
  isObject(selected) &&
  "filter_type" in selected &&
  isComparatorsForArray(selected.filter_type);

export const requireValueByFilterType = (filterType: string | undefined) => {
  if (!filterType) return false;

  return !["empty", "any"].includes(filterType);
};

export interface ArrayComparator {
  label: string;
  value: ComparatorsForArray;
  valueOmitted?: boolean;
}

export const arrayComparators: ReadonlyArray<ArrayComparator> = [
  {
    label: t([i18nRoot, "is"]),
    value: "is",
  },
];

export const arrayComparatorsIncludingExistence: ReadonlyArray<ArrayComparator> =
  [
    ...arrayComparators,
    {
      label: t([i18nRoot, "is_not"]),
      value: "is_not",
    },
    {
      label: t([i18nRoot, "not_exist"]),
      value: "empty",
      valueOmitted: true,
    },
    {
      label: t([i18nRoot, "exist"]),
      value: "any",
      valueOmitted: true,
    },
  ];

export const handleArrayComparatorApply = (
  param: BaseFilter | null,
  onApply?: (param: ArrayComparatorParam | null) => void,
) => {
  if (param == null) {
    return onApply?.(null);
  }

  invariant(isArrayComparatorParam(param), "Invalid param");

  onApply?.(param);
};
