import type { ReactNode } from "react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { RadioButtonGroup } from "@circle-react-shared/RadioButtonGroup";
import { AccessGroupSourceFormContent } from "./AccessGroupSourceFormContent";
import { NewChip } from "./NewChip";

interface AccessGroupContentProps {
  spaceAccessNode?: ReactNode;
  isUpdatingAccessGroupSources: boolean;
}
const i18nRoot = "settings.access_groups.admin.entry_points.association";
export const tabs = Object.freeze({
  access_groups: "access_groups",
  space_access: "space_access",
});

export const AccessGroupContent = ({
  spaceAccessNode,
  isUpdatingAccessGroupSources,
}: AccessGroupContentProps) => {
  const { currentCommunitySettings } = usePunditUserContext();
  const { setValue, watch } = useFormContext();
  const isAccessGroupsTabEnabled = watch("access_groups_enabled");
  const shouldShowAccessOptions =
    spaceAccessNode &&
    !currentCommunitySettings?.block_access_groups_for_trials_enabled;
  const [defaultTab, setDefaultTab] = useState<string>(
    isAccessGroupsTabEnabled || !shouldShowAccessOptions
      ? tabs.access_groups
      : tabs.space_access,
  );

  const changeTabHandler = (value: string) => {
    const isEnabled = value === tabs.access_groups;
    if (isAccessGroupsTabEnabled != isEnabled) {
      setValue("access_groups_enabled", isEnabled);
    }
    setDefaultTab(value);
  };

  return (
    <>
      {/* this is temporary until we fully release Access groups */}
      {shouldShowAccessOptions && (
        <div className="py-4">
          <RadioButtonGroup
            shouldShowInlineOptions
            name="access_groups_enabled"
            onChange={changeTabHandler}
            defaultChecked={defaultTab}
            size="sm"
            options={[
              {
                label: (
                  <div className="flex items-center justify-center gap-1">
                    {t([i18nRoot, "access.access_groups"])}
                    <NewChip />
                  </div>
                ),
                value: tabs.access_groups,
              },
              {
                label: t([i18nRoot, "access.space_access"]),
                value: tabs.space_access,
              },
            ]}
            checkedOptionClassName="pl-9"
          />
        </div>
      )}

      {defaultTab === tabs.space_access &&
        shouldShowAccessOptions &&
        spaceAccessNode}
      {defaultTab === tabs.access_groups && (
        <AccessGroupSourceFormContent
          isUpdatingAccessGroupSources={isUpdatingAccessGroupSources}
        />
      )}
    </>
  );
};
