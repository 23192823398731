import { isFunction } from "lodash";
import { t } from "@/i18n-js/instance";
import { Filter, InputFilterComparator } from "@circle-react-shared/Filter";
import type { StringComparatorParam } from "@circle-react-shared/Filter/FilterTypes/InputFilterComparator/stringComparators";
import { renderTextSelectedItem } from "@circle-react-shared/Filter/FilterTypes/InputFilterComparator/stringComparators";

const localeNamespace = "members_directory.header.search.filters";

interface EmailFilterProps {
  param: StringComparatorParam;
  onApply?: (value: StringComparatorParam) => void;
  isInitialOpen?: boolean;
}

export const EmailFilter = ({
  isInitialOpen,
  onApply,
  param,
}: EmailFilterProps) => (
  <Filter
    chip={t([localeNamespace, "email"])}
    title={t([localeNamespace, "email"])}
    selectedText={param.value}
    isInitialOpen={isInitialOpen}
    renderSelectedText={() => renderTextSelectedItem(param)}
    onApply={value => {
      if (isFunction(onApply)) {
        onApply(value);
      }
    }}
    shouldShowRemoveButton
  >
    <InputFilterComparator
      name="email"
      param={param}
      placeholder={t([localeNamespace, "bio_placeholder"])}
    />
  </Filter>
);
