import { t } from "@/i18n-js/instance";
import { NameCell } from "@circle-react/components/SettingsApp/ManageMembers/TabContent/Cells";
import {
  dateFormats,
  formattedDateTime,
} from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { EventBadges } from "./EventBadges";
import { EventedRecipientTable } from "./EventedRecipientTable";

const localeNamespace =
  "settings.emails.view_broadcast.reports_tab.recipient_table_columns";

const columns = [
  {
    accessorKey: "name",
    header: t([localeNamespace, "name"]),
    thClassName: "sm:w-1/3 md:w-auto md:table-cell",
    cellClassName: "sm:w-1/3 md:w-auto md:table-cell",
    cell: (info: any) => <NameCell member={info.row.original} isAvatarHidden />,
  },
  {
    accessorKey: "email",
    thClassName: "sm:w-1/3 !px-3 md:w-auto md:table-cell",
    cellClassName: "sm:w-1/3 md:w-auto md:table-cell",
    header: t([localeNamespace, "email"]),
    cell: (info: any) => (
      <Typography.BodySm weight="medium">{info.getValue()}</Typography.BodySm>
    ),
  },
  {
    accessorKey: "event_list",
    thClassName: "hidden !px-3 md:table-cell min-w-[20rem]",
    cellClassName: "hidden md:table-cell min-w-[20rem]",
    header: t([localeNamespace, "events"]),
    cell: (info: any) => <EventBadges events={info.getValue()} />,
  },
  {
    accessorKey: "type",
    thClassName: "hidden !px-3 md:table-cell min-w-[2rem]",
    cellClassName: "hidden md:table-cell min-w-[2rem]",
    header: t([localeNamespace, "member"]),
    cell: (info: any) => (
      <Typography.BodySm weight="medium">
        {info.getValue() === "CommunityMember"
          ? t("boolean_filter_options.yes")
          : t("boolean_filter_options.no")}
      </Typography.BodySm>
    ),
  },
  {
    accessorKey: "last_event_at",
    thClassName: "hidden !px-3 md:table-cell min-w-[8rem]",
    cellClassName: "hidden md:table-cell min-w-[8rem]",
    header: t([localeNamespace, "last_event_at"]),
    cell: (info: any) => (
      <Typography.BodySm weight="medium">
        {formattedDateTime({
          dateTime: new Date(info.getValue()),
          format: dateFormats.long_date_at_short_time,
        })}
      </Typography.BodySm>
    ),
  },
];

export const RecipientTab = ({ event }: { event: string }) => (
  <EventedRecipientTable columns={columns} event={event} />
);
