import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-uikit/Typography";

export const OrDivider = () => (
  <div className="m-0 flex w-full items-center justify-center pb-2 pt-6 text-center text-sm">
    <div className="mr-5 flex-1 border-t-[1px] border-solid border-gray-300" />
    <Typography.LabelSm weight="semibold">{t("or")}</Typography.LabelSm>
    <div className="ml-5 flex-1 border-t-[1px] border-solid border-gray-300" />
  </div>
);
