import { EventBadge } from "./EventBadge";
import type { EventBadgeType } from "./EventBadge";

const EVENT_TO_BADGE_MAPPING: { [key in EventTypes]: EventBadgeType } = {
  clicked: "info",
  opened: "info",
  unsubscribe: "warn",
  bounce: "error",
  complained: "error",
};

type EventTypes =
  | "clicked"
  | "opened"
  | "unsubscribe"
  | "bounce"
  | "complained";
interface EventBadgesProps {
  events: EventTypes[];
}

export const EventBadges = ({ events }: EventBadgesProps) => (
  <div className="flex flex-wrap gap-2">
    {events?.map((event: EventTypes) => (
      <EventBadge
        key={event}
        event={event}
        eventType={EVENT_TO_BADGE_MAPPING[event]}
      />
    ))}
  </div>
);
