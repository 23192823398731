import { memo } from "react";
import { MobileBottomToolbarV2View } from "@circle-react/components/Layout/MobileBottomToolbar/V2View";
import { Body } from "@circle-react/components/Layout/StandardLayout/Body";
import { Right } from "@circle-react/components/Layout/StandardLayout/Right";
import { CommunityContent } from "@circle-react/components/Layout/StandardLayoutV2/CommunityContent";
import { CommunityWrapper } from "@circle-react/components/Layout/StandardLayoutV2/CommunityWrapper";
import { Content } from "@circle-react/components/Layout/StandardLayoutV2/Content";
import { ContentWrapper } from "@circle-react/components/Layout/StandardLayoutV2/ContentWrapper";
import { ShowOnSmOrMdScreen } from "@circle-react/components/Layout/StandardLayoutV2/ShowOnSmOrMdScreen";
import { SidebarWrapper } from "@circle-react/components/Layout/StandardLayoutV2/SidebarWrapper";
import { StandardLayoutHeaderContainer } from "@circle-react/components/Layout/StandardLayoutV2/StandardLayoutHeaderContainer";
import { StandardLayoutV2Wrapper } from "@circle-react/components/Layout/StandardLayoutV2/StandardLayoutV2Wrapper";
import { PostListWrapper } from "@circle-react/components/PostsV3/PostViewList/PostListWrapper";
import { SpaceFeedWrapper } from "@circle-react/components/Spaces/Show/SpaceFeedWrapper";
import { ControlledThemeProvider } from "@circle-react/providers";
import type { ThemeObject } from "@circle-react/types/Theme";
import { Container } from "@circle-react-shared/Container";
import {
  BodyWrapper,
  BookmarksPreview,
  CommunitySwitcherPreview,
  HeaderPreview,
  MobileHeaderPreview,
  PostFeedPreview,
  RightSidebarPreview,
  SidebarPreview,
} from "@circle-react-shared/Previews";
import { HeaderV3Preview } from "@circle-react-shared/Previews/HeaderV3Preview";
import { StyledFrame } from "@circle-react-shared/StyledFrame";

export interface SpaceFrameProps {
  theme: ThemeObject;
  brandColor?: string;
  communityName?: string;
  headerSpaceEmoji?: string;
  headerSpaceTitle?: string;
  name?: string;
  sidebarLogoType?: "logo" | "icon";
  communityLogoUrl?: string | null;
  switcherIconUrl?: string | null;
  userImageUrl?: string | null;
  screenSize?: "desktop" | "tablet" | "mobile";
}

export const SpaceFrame = memo<SpaceFrameProps>(
  ({
    theme,
    brandColor = "#506CF0",
    communityName = "",
    headerSpaceEmoji,
    headerSpaceTitle = "",
    name = "",
    sidebarLogoType = "icon",
    communityLogoUrl = "",
    switcherIconUrl = "",
    userImageUrl = "",
    screenSize = "desktop",
  }) => {
    const topStyles = {
      height: "calc(100vh - 4rem)",
    };

    return (
      <StyledFrame>
        <ControlledThemeProvider theme={theme}>
          <BodyWrapper className="flex select-none">
            {screenSize == "desktop" && (
              <CommunitySwitcherPreview
                iconUrl={switcherIconUrl}
                brandColor={brandColor}
                name={communityName}
              />
            )}
            <div className="w-full">
              {screenSize == "desktop" && (
                <HeaderV3Preview
                  communityName={communityName}
                  logoType={sidebarLogoType}
                  logoUrl={communityLogoUrl}
                />
              )}
              <CommunityWrapper>
                <CommunityContent disableSidebar={false} style={topStyles}>
                  <MobileHeaderPreview
                    userImageUrl={userImageUrl}
                    communityLogoUrl={communityLogoUrl}
                    userName={name}
                    communityName={communityName}
                    className="pointer-events-none sticky top-0 z-10"
                  />
                  <StandardLayoutV2Wrapper
                    disableSidebar={false}
                    disableLayoutRightSidebar={false}
                  >
                    <SidebarWrapper>
                      <SidebarPreview />
                    </SidebarWrapper>
                    <ContentWrapper isBgSecondary isBgPrimary={false}>
                      <StandardLayoutHeaderContainer>
                        <HeaderPreview
                          spaceName={headerSpaceTitle}
                          emoji={headerSpaceEmoji}
                        />
                        <BookmarksPreview />
                      </StandardLayoutHeaderContainer>
                      <Content fullWidth fullHeight={false}>
                        <Body
                          isMinimalLayout={false}
                          hasPaddingTopMobile={false}
                        >
                          <Container>
                            <SpaceFeedWrapper>
                              <PostListWrapper displayView="posts">
                                <PostFeedPreview count={4} />
                              </PostListWrapper>
                            </SpaceFeedWrapper>
                            <Right>
                              <RightSidebarPreview />
                            </Right>
                          </Container>
                        </Body>
                      </Content>
                    </ContentWrapper>
                    <ShowOnSmOrMdScreen>
                      <MobileBottomToolbarV2View className="pointer-events-none" />
                    </ShowOnSmOrMdScreen>
                  </StandardLayoutV2Wrapper>
                </CommunityContent>
              </CommunityWrapper>
            </div>
          </BodyWrapper>
        </ControlledThemeProvider>
      </StyledFrame>
    );
  },
);

SpaceFrame.displayName = "SpaceFrame";
