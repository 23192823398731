import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import type { CancelAndRefundPaywallSubscriptionModalProps } from "./CancelAndRefundPaywallSubscriptionModal";
import { CancelAndRefundPaywallSubscriptionModal } from "./CancelAndRefundPaywallSubscriptionModal";

const CancelAndRefundPaywallSubscriptionModalComponent = ModalManager.create(
  (params: CancelAndRefundPaywallSubscriptionModalProps) => (
    <CancelAndRefundPaywallSubscriptionModal
      key={`cancelAndRefund-${params?.subscription?.processor_id}`}
      {...params}
    />
  ),
);

export const useCancelAndRefundPaywallSubscriptionModal = () =>
  useModal(CancelAndRefundPaywallSubscriptionModalComponent);
