import { noop } from "lodash";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { ENDS_AT_OPTIONS, REFUND_TYPE_OPTIONS } from "../constants";
import { usePaywallRefundSubscriptionApi } from "./usePaywallRefundSubscriptionApi";

export const useCancelAndRefundSubscriptionModal = ({
  subscription,
  onSuccess,
}: any) => {
  const modal = useModal();
  const defaultValues = {
    refund_type: REFUND_TYPE_OPTIONS.NO_REFUND,
    ends_at: ENDS_AT_OPTIONS.NOW,
  };

  const callback = {
    cancelAndRefundSubscription: {
      onSuccess: () => {
        void modal.hide();
        onSuccess && onSuccess();
      },
      onError: noop,
    },
  };
  const { refundMutation, fetchRefundOptionsSubscription } =
    usePaywallRefundSubscriptionApi({
      subscription,
      callback,
    });

  const handleSubmit = (data: any) => {
    if (data.refund_type === REFUND_TYPE_OPTIONS.NO_REFUND) {
      data.refund_type = null;
    }

    refundMutation.mutate(data);
    fetchRefundOptionsSubscription.remove();
  };

  const onModalClose = () => {
    if (!refundMutation.isLoading) {
      void modal.hide();
      fetchRefundOptionsSubscription.remove();
    }
  };

  return {
    defaultValues,
    handleSubmit,
    modal,
    isSubmitting: refundMutation.isLoading,
    onClose: onModalClose,
    isLoadingRefundOptionsSubscription:
      fetchRefundOptionsSubscription.isLoading,
    refundOptionsSubscriptionData: fetchRefundOptionsSubscription.data,
  };
};
