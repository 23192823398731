import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

interface WindowPortalProp {
  children: any;
  desconstructor: () => void;
  newTab: boolean;
  width?: number;
  height?: number;
  left?: number;
  top?: number;
}

const getInitialContent = () => `
<!DOCTYPE html>
<html>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
  </head>
  <body>
  </body>
</html>
`;

export const WindowPortal = ({
  children,
  desconstructor,
  newTab,
  width,
  height,
  left,
  top,
}: WindowPortalProp) => {
  const containerRef = useRef(document.createElement("div"));
  const windowRef = useRef<any>(null);

  useEffect(() => {
    const newWindow = window.open(
      "",
      newTab ? "new_tab" : "",
      newTab
        ? undefined
        : `width=${width},height=${height},left=${left},top=${top}`,
    );

    if (newWindow) {
      newWindow.document.write(getInitialContent());

      newWindow.onbeforeunload = () => {
        desconstructor && desconstructor();
      };
    }

    windowRef.current = newWindow;
    windowRef.current?.document.body.appendChild(containerRef.current);

    return () => {
      windowRef.current?.close();
    };
  }, []);

  return createPortal(children, containerRef.current);
};
