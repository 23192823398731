import { useMemo } from "react";
import PropTypes from "prop-types";
import truncate from "lodash/truncate";

export const SelectedText = ({ selectedText, items = [] }) => {
  const itemMap = useMemo(
    () =>
      items.reduce((acc, item) => {
        acc.set(String(item.value), item.label);
        return acc;
      }, new Map()),
    [items],
  );

  const getLabel = value => {
    const label = itemMap.get(value);
    return label ?? value;
  };

  const getSelectedItemIds = urlText => {
    if (Array.isArray(urlText)) {
      return urlText;
    }
    return urlText?.split(",") ?? [];
  };

  const getSelectedText = urlText => {
    const selectedItemsIds = getSelectedItemIds(urlText);
    const selectedItems = selectedItemsIds.map(getLabel);
    const truncatedSelectedItems = selectedItems.map(text =>
      truncate(text, { length: 20 }),
    );
    const count = selectedItems.length;
    if (count === 0) {
      return {
        truncatedText: "",
      };
    }

    // Count of items fully shown
    // More items are shown with + count
    const fullyShownCount = 2;
    if (count <= fullyShownCount) {
      return {
        text: selectedItems.join(", "),
        truncatedText: truncatedSelectedItems.join(", "),
      };
    }
    return {
      text: selectedItems,
      truncatedText: `${truncatedSelectedItems
        .slice(0, fullyShownCount)
        .join(", ")} + ${count - 2}`,
    };
  };

  const { text, truncatedText } = getSelectedText(selectedText);
  return <p title={text}>{truncatedText}</p>;
};

SelectedText.propTypes = {
  selectedText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
};
