import { useMemo } from "react";
import { compact } from "lodash";
import { usePunditUserContext } from "@circle-react/contexts";
import { hasRevenueCatPaymentProcessor } from "@circle-react/helpers/paymentProcessorHelpers";
import { possibleColumnsToRender } from "../columns";

export const useDefaultColumnsToRender = () => {
  const { currentCommunity } = usePunditUserContext();

  const paymentProcessors = currentCommunity?.payment_processors;

  const defaultColumnsToRender = useMemo(
    () =>
      compact([
        possibleColumnsToRender.customer,
        possibleColumnsToRender.term,
        possibleColumnsToRender.amount,
        possibleColumnsToRender.paymentMethod,
        possibleColumnsToRender.status,
        possibleColumnsToRender.paywall,
        hasRevenueCatPaymentProcessor(paymentProcessors)
          ? possibleColumnsToRender.platform
          : null,
        possibleColumnsToRender.createdAt,
        possibleColumnsToRender.actionMenu,
      ]),
    [paymentProcessors],
  );

  return {
    defaultColumnsToRender,
  };
};
