import type { ComponentProps } from "react";
import { FIELD_TYPES } from "@circle-react-shared/ProfileFields/constants";
import { SelectFieldFilter, TextFieldFilter } from "./FieldTypes";

interface ProfileFieldFilterProps
  extends ComponentProps<typeof TextFieldFilter> {
  field_type: string;
}

const components: any = {
  [FIELD_TYPES.TEXT]: TextFieldFilter,
  [FIELD_TYPES.LINK]: TextFieldFilter,
  [FIELD_TYPES.NUMBER]: TextFieldFilter,
  [FIELD_TYPES.TEXTAREA]: TextFieldFilter,
  [FIELD_TYPES.SELECT]: SelectFieldFilter,
  [FIELD_TYPES.CHECKBOX]: SelectFieldFilter,
};

export const ProfileFieldFilter = ({
  field_type: fieldType,
  ...rest
}: ProfileFieldFilterProps) => {
  const Component: any = components[fieldType] || (() => null);
  return <Component {...rest} />;
};
