import classNames from "classnames";
import type { BroadcastContentAttributes } from "@circle-react/components/SettingsApp/Emails/hooks/useGetBroadcast";
import { SkeletonLoader } from "@circle-react-shared/uikit/SkeletonLoader";
import { BroadcastTipTapReadonlyView } from "./BroadcastTipTapReadonlyView";

interface PreviewContentProps {
  content?: BroadcastContentAttributes["email_content"];
}

export const PreviewContent = ({ content }: PreviewContentProps) => (
  <div className="bg-tertiary overflow-y-auto py-12 shadow-sm">
    <div
      className={classNames(
        "bg-primary mx-auto min-h-[420px] rounded-lg p-8 lg:w-[600px]",
        {
          "h-full": !content,
        },
      )}
    >
      {content ? (
        <BroadcastTipTapReadonlyView content={content} />
      ) : (
        <SkeletonLoader variant="email-preview" />
      )}
    </div>
  </div>
);
