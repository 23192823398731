import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { hasRevenueCatPaymentProcessor } from "@circle-react/helpers/paymentProcessorHelpers";
import { Typography } from "@circle-react-uikit/Typography";
import { PropertyLine } from "./PropertyLine";

const localeStringRoot =
  "settings.paywalls_admin_dashboard.charges.charge_details_modal";

export const PlatformProperty = ({ charge }: any) => {
  const { currentCommunity } = usePunditUserContext();

  const currentCommunityPaymentProcessors =
    currentCommunity?.payment_processors;

  if (!hasRevenueCatPaymentProcessor(currentCommunityPaymentProcessors))
    return null;

  return (
    <PropertyLine
      title={t(`${localeStringRoot}.details_labels.platform.title`)}
    >
      <Typography.LabelSm>
        {t(`${localeStringRoot}.details_labels.platform.${charge.platform}`)}
      </Typography.LabelSm>
    </PropertyLine>
  );
};
