import classNames from "classnames";
import { colord, extend } from "colord";
import a11yPlugin from "colord/plugins/a11y";

extend([a11yPlugin]);

export interface WithNameProps {
  name: string;
  textColor?: string;
  bgColor?: string;
  includeName?: boolean;
}

export const WithName = ({
  name,
  bgColor,
  includeName = false,
}: WithNameProps) => {
  const firstLetter = name.charAt(0);

  /* TODO: Hotfix until having the field "Button text color" available in the endpoint */
  const textColor =
    bgColor && colord(bgColor).luminance() > 0.65 ? "#000" : "#fff";

  return (
    <div>
      <div
        className={classNames(
          "brand-icon brand-icon__initial !h-8 !w-8 !rounded-lg !outline-none",
        )}
        style={{
          color: textColor,
          backgroundColor: bgColor,
        }}
      >
        {firstLetter}
      </div>
      {includeName && <div className="name">{name}</div>}
    </div>
  );
};
