import { CanvasCard } from "@circle-react-shared/CanvasCard";
import { CanvasSize } from "../../CanvasSize";
import type { SpaceFrameProps } from "./SpaceFrame";
import { SpaceFrame } from "./SpaceFrame";

export interface SpaceCanvasProps extends SpaceFrameProps {
  screenSize?: "desktop" | "tablet" | "mobile";
}

export const SpaceCanvas = ({
  screenSize = "desktop",
  ...rest
}: SpaceCanvasProps) => (
  <CanvasSize screenSize={screenSize} className="p-8">
    <CanvasCard>
      <SpaceFrame screenSize={screenSize} {...rest} />
    </CanvasCard>
  </CanvasSize>
);
