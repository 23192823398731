import { SpaceView } from "@circle-react/components/Spaces/SidebarV2/CommunityMenu/SpaceList/SpaceView";
import type { Space } from "@circle-react/types/Space";

export interface SpaceRendererProps {
  space: Partial<Space>;
  shouldShowNotifications: boolean;
}

export const SpaceRenderer = ({
  space,
  shouldShowNotifications,
}: SpaceRendererProps) => (
  <SpaceView
    key={space.id}
    space={space}
    buttonLink=""
    spaceNotificationDetail={{
      unread_content_count: shouldShowNotifications ? 1 : 0,
      unread_notifications_count: 0,
    }}
  />
);
