import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-uikit/Typography";

export const NewBadge = () => (
  <div className="bg-c-sidebar-badge flex rounded px-1">
    <div className="self-center">
      <Typography.LabelXxs textTransform="uppercase" color="text-brand-bg">
        {t("new")}
      </Typography.LabelXxs>
    </div>
  </div>
);
