import type { SmallRectangleProps } from "@circle-react-shared/Placeholders";
import { EmptyAvatar, SmallRectangle } from "@circle-react-shared/Placeholders";

export interface ItemProps extends SmallRectangleProps {}

export const Item = ({ ...rest }: ItemProps) => (
  <div className="flex w-full items-center space-x-3">
    <EmptyAvatar />
    <SmallRectangle {...rest} />
  </div>
);
