import { CoverImageInputButton } from "@circle-react-uikit/CoverImageInput/CoverImageInputButton";
import type { RendererProps } from "@circle-react-uikit/ImageInput/ImageSelector";
import { CoverPreview } from "./CoverPreview";

export interface ImageInputRenderProps extends RendererProps {
  variant?: "default" | "cover";
}

export const ImageInputRender = ({
  value,
  processing,
  variant,
  ...rest
}: ImageInputRenderProps) => {
  if (!value && !processing) {
    return <CoverImageInputButton {...rest} />;
  }

  return (
    <CoverPreview
      variant={variant}
      value={value}
      processing={processing}
      {...rest}
    />
  );
};
