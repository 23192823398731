import { t } from "@/i18n-js/instance";
import { useIsSitesEnabled } from "@/react/components/SettingsApp/Site/Pages/useIsSitesEnabled";
import { settingsNavPathV3 } from "@circle-react/helpers/urlHelpers/settingsNavPathV3";
import { NavGroup } from "../NavGroup";
import { SubNavLinkItem } from "./SubNavLinkItem";
import type { Link } from "./types";

export const SiteLinks = () => {
  const isSitesEnabled = useIsSitesEnabled();
  const links: Link[] = [
    {
      label: t("settings_nav_v3.site.navigation"),
      path: settingsNavPathV3.site.navigation,
      exact: true,
    },
    ...(isSitesEnabled
      ? [
          {
            label: t("settings_nav_v3.site.pages"),
            path: settingsNavPathV3.site.pages,
            exact: true,
            isNewFeature: true,
          },
          {
            label: t("settings_nav_v3.site.seo"),
            path: settingsNavPathV3.site.seo,
            exact: true,
            isNewFeature: true,
          },
        ]
      : []),
    {
      label: t("settings_nav_v3.site.defaults"),
      path: settingsNavPathV3.site.index,
      exact: true,
    },
    {
      label: t("settings_nav_v3.site.spaces"),
      path: settingsNavPathV3.site.spaces,
      exact: true,
    },
    {
      label: t("settings_nav_v3.site.code_snippets"),
      path: settingsNavPathV3.site.code_snippets,
      exact: true,
    },
  ];

  return (
    <NavGroup groupLinks={links}>
      <NavGroup.Content title={t("settings_nav_v3.site.label")}>
        {links.map(link => (
          <SubNavLinkItem
            key={link.path}
            label={link.label}
            path={link.path}
            isNewFeature={!!link.isNewFeature}
          />
        ))}
      </NavGroup.Content>
    </NavGroup>
  );
};
