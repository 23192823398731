import { getOnChangingSortFunc } from "../../utils";
import { orderingColumn } from "../constants";

export const usePaywallSubscriptionList = ({ subscriptionData }: any) => {
  const onChangeSorting = getOnChangingSortFunc({
    dataQuery: subscriptionData,
    orderingColumn,
  });

  return {
    onChangeSorting,
  };
};
