import PropTypes from "prop-types";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";

export const FooterAction = ({
  title,
  children,
  onActionButtonClick,
  buttonText,
}) => (
  <footer className="p-4 lg:pb-40 lg:pt-16">
    <div className="border-primary flex flex-col items-center justify-center rounded-xl border px-4 py-12">
      <div className="flex max-w-3xl flex-col gap-y-8 text-center">
        <div className="flex flex-col gap-y-4">
          <Typography.TitleXl>{title}</Typography.TitleXl>
          <Typography.TitleSm>{children}</Typography.TitleSm>
        </div>
        <Button
          variant="circle"
          extraLarge
          onClick={onActionButtonClick}
          className="mx-auto max-w-xs"
        >
          {buttonText}
        </Button>
      </div>
    </div>
  </footer>
);

FooterAction.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  buttonText: PropTypes.node,
  onActionButtonClick: PropTypes.func,
};
