import invariant from "tiny-invariant";
import { renderTextSelectedItem } from "@circle-react-shared/Filter/FilterTypes/InputFilterComparator";
import { InputFilterComparator } from "@circle-react-shared/Filter/FilterTypes/InputFilterComparator/InputFilterComparatorV2";
import {
  type StringComparatorProfileFieldParam,
  isStringComparatorParam,
  stringComparatorsIncludingExistence,
} from "@circle-react-shared/Filter/FilterTypes/InputFilterComparator/stringComparators";
import { Filter } from "@circle-react-shared/FilterV3";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";
import { isProfileFieldBaseFilter } from "@circle-react-shared/MemberFilters/types";
import { useAudienceSearchContext } from "../../AudienceSearchContext";
import { ProfileFieldInputs } from "./ProfileFieldInputs";

interface TextFieldFilterProps {
  label: string;
  param: BaseFilter;
  onApply?: (value: StringComparatorProfileFieldParam | null) => void;
  isInitialOpen?: boolean;
}

export const TextFieldFilter = ({
  label,
  param,
  onApply,
  isInitialOpen,
}: TextFieldFilterProps) => {
  const { isContactsSearch } = useAudienceSearchContext();
  invariant(
    isProfileFieldBaseFilter(param),
    "param must be a profile field filter",
  );

  return (
    <Filter
      onApply={value => {
        if (value == null) {
          return onApply?.(null);
        }

        invariant(
          isStringComparatorParam(value) && isProfileFieldBaseFilter(value),
          "value must be a string comparator and profile field filter",
        );
        onApply?.(value);
      }}
    >
      <Filter.Chip renderSelectedValue={() => renderTextSelectedItem(param)}>
        {label}
      </Filter.Chip>

      <Filter.Popover title={label} isInitialOpen={isInitialOpen}>
        <InputFilterComparator
          name="profile_field"
          param={param}
          comparators={
            isContactsSearch ? stringComparatorsIncludingExistence : undefined
          }
        >
          <ProfileFieldInputs {...param} />
        </InputFilterComparator>
      </Filter.Popover>
    </Filter>
  );
};
