import { isObject, isString } from "lodash";
import invariant from "tiny-invariant";
import { t } from "@/i18n-js/instance";
import type {
  BaseFilter,
  ProfileFieldBaseFilter,
} from "@circle-react-shared/MemberFilters/types";

const i18nRoot = "filter_comparators";

const comparatorsForString = [
  "is",
  "is_not",
  "contains",
  "does_not_contain",
  "not_exist",
  "exist",
] as const;

export type ComparatorsForString = (typeof comparatorsForString)[number];

export interface StringComparatorParam extends BaseFilter {
  filter_type: ComparatorsForString;
  value?: string;
}

export type StringComparatorProfileFieldParam = StringComparatorParam &
  ProfileFieldBaseFilter;

export const isComparatorsForString = (
  filterType: unknown,
): filterType is ComparatorsForString =>
  isString(filterType) &&
  comparatorsForString.includes(filterType as ComparatorsForString);

export const isStringComparatorParam = (
  selected: BaseFilter,
): selected is StringComparatorParam =>
  isObject(selected) &&
  "filter_type" in selected &&
  isComparatorsForString(selected.filter_type);

export const renderTextSelectedItem = (selected: BaseFilter) => {
  if (isStringComparatorParam(selected)) {
    return t(["filter_comparators", "values", selected.filter_type], {
      value: selected.value,
    });
  }
  return null;
};

export const requireValueByFilterType = (filterType: string | undefined) => {
  if (!filterType) return false;

  return !["exist", "not_exist"].includes(filterType);
};

export interface StringComparator {
  label: string;
  value: ComparatorsForString;
  valueOmitted?: boolean;
}

export const stringComparators: ReadonlyArray<StringComparator> = [
  {
    label: t([i18nRoot, "contains"]),
    value: "contains",
  },
  {
    label: t([i18nRoot, "does_not_contain"]),
    value: "does_not_contain",
  },
  {
    label: t([i18nRoot, "is"]),
    value: "is",
  },
  {
    label: t([i18nRoot, "is_not"]),
    value: "is_not",
  },
];

export const stringComparatorsIncludingExistence: ReadonlyArray<StringComparator> =
  [
    ...stringComparators,
    {
      label: t([i18nRoot, "not_exist"]),
      value: "not_exist",
      valueOmitted: true,
    },
    {
      label: t([i18nRoot, "exist"]),
      value: "exist",
      valueOmitted: true,
    },
  ];

export const handleStringComparatorApply = (
  param: BaseFilter | null,
  onApply?: (param: StringComparatorParam | null) => void,
) => {
  if (param == null) {
    return onApply?.(null);
  }

  invariant(isStringComparatorParam(param), "Invalid param");

  onApply?.(param);
};
