import PropTypes from "prop-types";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";

export const HeroSection = ({
  title,
  subtitle,
  children,
  buttonText,
  onActionButtonClick,
}) => (
  <header className="flex items-center justify-center px-4 py-16 text-center">
    <div className="flex max-w-3xl flex-col gap-y-10">
      <div className="flex flex-col gap-y-2">
        <div className="flex flex-col gap-y-6">
          <Typography.TitleXxl as="h2">{title}</Typography.TitleXxl>
          <Typography.TitleMd>{subtitle}</Typography.TitleMd>
        </div>
        <Typography.TitleSm>{children}</Typography.TitleSm>
      </div>
      <Button
        variant="circle"
        extraLarge
        onClick={onActionButtonClick}
        className="mx-auto max-w-xs"
      >
        {buttonText}
      </Button>
    </div>
  </header>
);

HeroSection.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.node.isRequired,
  onActionButtonClick: PropTypes.func.isRequired,
};
