import PropTypes from "prop-types";
import { isFunction } from "lodash";
import { t } from "@/i18n-js/instance";
import {
  CheckboxFilter,
  CheckboxSelectedText,
  Filter,
} from "@circle-react-shared/Filter";
import { useMemberFilterUrl } from "@circle-react-shared/MemberFilters/useMemberFilterUrl";
import { Loader } from "@circle-react-uikit/Loader";
import { useSpaceGroupsList } from "./useSpaceGroupsList";

/**
 * This filter component is also shared by Space Directory page.
 */
export const SpaceGroupsFilter = ({
  localeNamespace = "members_directory.header.search.filters.space_groups",
  isInitialOpen,
  onApply,
  changeUrl = true,
  value,
  shouldShowRemoveButton,
}) => {
  const { filterParams, updateUrl } = useMemberFilterUrl();
  const { visibleSpaceGroups, isLoading } = useSpaceGroupsList();

  return (
    <Filter
      chip={t([localeNamespace, "title"])}
      title={t([localeNamespace, "title"])}
      selectedText={value || filterParams.space_groups}
      renderSelectedText={props => (
        <CheckboxSelectedText items={visibleSpaceGroups} {...props} />
      )}
      isInitialOpen={isInitialOpen}
      onApply={value => {
        changeUrl && updateUrl("space_groups", value);
        if (isFunction(onApply)) {
          onApply(value);
        }
      }}
      shouldShowRemoveButton={shouldShowRemoveButton}
    >
      {isLoading ? (
        <Loader center />
      ) : (
        <CheckboxFilter
          options={visibleSpaceGroups}
          legend={t([localeNamespace, "legend"])}
          name="space-groups-filter"
          hasSearch
          searchProps={{
            placeholder: t([localeNamespace, "search_placeholder"]),
          }}
          activeText={value ?? filterParams.space_groups}
        />
      )}
    </Filter>
  );
};

SpaceGroupsFilter.propTypes = {
  localeNamespace: PropTypes.string,
  isInitialOpen: PropTypes.bool,
  onApply: PropTypes.func,
  changeUrl: PropTypes.bool,
  value: PropTypes.string,
  shouldShowRemoveButton: PropTypes.bool,
};
