import { HighlightsItem } from "./HighlightsItem";

export interface HighlightsProps {
  highlights: Record<string, string>;
}

export const Highlights = ({ highlights }: HighlightsProps) => (
  <div className="flex flex-row items-center gap-x-2">
    {Object.entries(highlights).map(([key, value], index) => (
      <HighlightsItem
        key={key}
        label={key}
        value={value}
        shouldShowSeparator={index > 0}
      />
    ))}
  </div>
);
