import { t } from "@/i18n-js/instance";
import { DropdownFilter } from "../DropdownFilter";

export const OnlineFilter = () => {
  const statusOptions = [{ label: t("online") }, { label: t("offline") }];

  return (
    <DropdownFilter
      queryParam="online_status"
      type="select"
      options={statusOptions}
    />
  );
};
