import classNames from "classnames";
import { useQueryClient } from "react-query";
import { t } from "@/i18n-js/instance";
import { useCurrentPlanApi } from "@circle-react/components/SettingsApp/Plan/hooks/useCurrentPlanApi";
import { MARKETING_HUB_STATUS_QUERY_KEY } from "@circle-react/hooks/useGetMarketingHubStatus";
import { useUpgradePlanModal } from "@circle-react-shared/UpgradePlan";
import { UPGRADE_FLOW_SOURCES } from "@circle-react-shared/UpgradePlan/constants";
import { Button } from "@circle-react-shared/uikit/Button";
import { localeRoot } from "./constants";

interface CallToActionProp {
  modal?: boolean;
}

export const CallToAction = ({ modal }: CallToActionProp) => {
  const upgradePlanModal = useUpgradePlanModal();
  const { refetchCurrentPlan } = useCurrentPlanApi();
  const queryClient = useQueryClient();

  const onChangePlanSuccess = async () => {
    await queryClient.refetchQueries([MARKETING_HUB_STATUS_QUERY_KEY]);
    await refetchCurrentPlan();
  };

  const onClick = async () => {
    await upgradePlanModal.show({
      subheading: t(
        "pricing_plans.upgrade_modal.subheadings.marketing_hub_landing",
      ),
      isMarketingHubRequired: true,
      onSuccess: onChangePlanSuccess,
      successButtonLabel: t(
        "pricing_plans.upgrade_modal.actions.go_to_broadcasts",
      ),
      showPlanDetails: true,
      source: UPGRADE_FLOW_SOURCES.MARKETING_HUB_LANDING_PAGE,
    });
  };

  return (
    <div
      className={classNames({
        "pb-8": !!modal,
      })}
    >
      <Button variant="circle" onClick={onClick} extraLarge>
        {t([localeRoot, "cta"])}
      </Button>
    </div>
  );
};
