import { MemoryRouter, Route, Switch } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { useCurrentPlanApi } from "@/react/components/SettingsApp/Plan/hooks/useCurrentPlanApi";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Modal } from "@circle-react-uikit/ModalV2";
import { CancelTerms } from "./CancelTerms";
import { ReasonForm } from "./ReasonForm";
import { paths } from "./paths";

const localeRoot = "marketing_hub.cancellation_modal";

export const CancellationModal = ModalManager.create(() => {
  const modal = useModal();
  const { isCurrentPlanLoading } = useCurrentPlanApi();

  const isLoading = isCurrentPlanLoading;

  const onClose = () => {
    if (isLoading) return;
    void modal.hide();
    modal.remove();
  };

  return (
    <Modal
      isOpen={modal.visible}
      onClose={onClose}
      title={t([localeRoot, "title"])}
    >
      <Modal.Overlay />
      <Modal.Content size="md">
        <Modal.Header>
          <Modal.Title size="md">{t([localeRoot, "title"])}</Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton onClick={onClose} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body>
          <MemoryRouter initialEntries={[paths.TERMS]}>
            <Switch>
              <Route path={paths.TERMS}>
                <CancelTerms onClose={onClose} />
              </Route>
              <Route path={paths.REASON}>
                <ReasonForm onClose={onClose} />
              </Route>
            </Switch>
          </MemoryRouter>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
});

export const useCancellationModal = () => useModal(CancellationModal);
