import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { settingsNavPathV3 } from "@circle-react/helpers/urlHelpers";
import { EmptyState } from "@circle-react-uikit/EmptyState";

interface PaywallsEmptyStateProps {
  i18nBase: string;
  i18nCustomPrimaryLabel?: string;
  onPrimaryClick?: () => void;
  isPaymentProcessorEnabled: boolean;
  redirectToPaywalls?: boolean;
}
export const PaywallsEmptyState = ({
  isPaymentProcessorEnabled,
  i18nBase,
  onPrimaryClick,
  redirectToPaywalls = false,
  i18nCustomPrimaryLabel,
}: PaywallsEmptyStateProps) => {
  const history = useHistory();
  const handleEmptySateCTA = () => {
    if (redirectToPaywalls) {
      return history.push(settingsNavPathV3.paywalls.index);
    }
    if (isPaymentProcessorEnabled && onPrimaryClick) {
      return onPrimaryClick();
    }
    history.push(settingsNavPathV3.paywalls.index);
  };

  let i18nPrimaryLabel = "empty_state.primary_cta";
  if (!isPaymentProcessorEnabled) {
    i18nPrimaryLabel = "empty_state.primary_cta_no_processor";
  } else if (i18nCustomPrimaryLabel) {
    i18nPrimaryLabel = i18nCustomPrimaryLabel;
  }

  return (
    <div className="bg-primary h-full">
      <EmptyState
        header={t([i18nBase, "empty_state.header"])}
        description={t([i18nBase, "empty_state.description"])}
        primaryLabel={t([i18nBase, i18nPrimaryLabel])}
        onPrimaryClick={handleEmptySateCTA}
      />
    </div>
  );
};
