import PropTypes from "prop-types";

export const CounterBadge = ({ count }) => {
  if (!count || count <= 0) return null;
  return (
    <div className="border-primary bg-primary group-hover:bg-primary text-dark my-[-1px] ml-1.5 rounded border px-1.5 py-1 text-[11px] font-semibold leading-[11px]">
      {count}
    </div>
  );
};

CounterBadge.propTypes = {
  count: PropTypes.node.isRequired,
};
