import ReactPlayer from "react-player/lazy";

export interface VideoProps {
  url: string;
  shouldDisableDownload?: boolean;
}

export const Video = ({ url, shouldDisableDownload = false }: VideoProps) => (
  <ReactPlayer
    url={url}
    width="100%"
    height="100%"
    controls
    config={{
      file: {
        attributes: {
          controlsList: shouldDisableDownload ? "nodownload" : undefined, // Blocks download button
        },
      },
    }}
  />
);
