import { useMemo } from "react";
import type { ReactNode } from "react";
import classNames from "classnames";
import type { RenderFilter, RenderProfileFieldFilter } from "../types";
import { ClearButtonWrapper } from "./ClearButtonWrapper";
import { DynamicFilterContext } from "./DynamicFilterContext";
import { DynamicFilterItems } from "./DynamicFilterItemsV2";
import { FiltersRow } from "./FiltersRow";
import { useDynamicFilters } from "./useDynamicFilters";

interface DynamicFiltersProps {
  defaultFilterNames?: string[];
  className?: string;
  children: ReactNode;
  platformFilters: RenderFilter[];
  profileFieldFilters?: RenderProfileFieldFilter[];
}

const DynamicFilterWrapper = ({
  defaultFilterNames = [],
  className,
  children,
  platformFilters,
  profileFieldFilters = [],
}: DynamicFiltersProps) => {
  const dynamicFilterProps = useDynamicFilters({
    defaultFilterNames,
  });

  const dynamicFilterContextValue = useMemo(
    () => ({
      ...dynamicFilterProps,
      platformFilters,
      profileFieldFilters,
    }),
    [dynamicFilterProps, platformFilters, profileFieldFilters],
  );

  return (
    <DynamicFilterContext.Provider value={dynamicFilterContextValue}>
      <FiltersRow className={classNames("w-full", className)}>
        {children}
      </FiltersRow>
    </DynamicFilterContext.Provider>
  );
};

export const DynamicFilters = Object.assign(DynamicFilterWrapper, {
  Panel: DynamicFilterItems,
  ClearButton: ClearButtonWrapper,
});
