import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import invariant from "tiny-invariant";
import type { AccessGroupEntryPoint } from "@circle-react/helpers/accessGroups/accessGroupHelpers";
import type {
  AccessGroupSourceType,
  AccessGroupType,
} from "@circle-react/types/AccessGroup";

export interface AccessGroupSourcesDataContextInterface {
  originalAccessGroupSources: AccessGroupSourceType[];
  accessGroups: AccessGroupType[];
  recordType: AccessGroupEntryPoint;
  recordId?: number;
  areFormItemsEmbedded: boolean;
  canRemoveAllAccessGroups: boolean;
  isSyncing: boolean;
  syncingTooltip: string;
}

const AccessGroupSourcesDataContext =
  createContext<AccessGroupSourcesDataContextInterface | null>(null);

AccessGroupSourcesDataContext.displayName = "AccessGroupSourcesDataContext";

export const useAccessGroupSourcesData = () => {
  const context = useContext(AccessGroupSourcesDataContext);
  invariant(
    context,
    "useAccessGroupSourcesData must be used within a AccessGroupSourcesDataContextProvider",
  );
  return context;
};

interface AccessGroupSourcesDataContextProviderInterface {
  originalAccessGroupSources: AccessGroupSourceType[];
  accessGroups: AccessGroupType[];
  children: ReactNode;
  recordType: AccessGroupEntryPoint;
  recordId?: number;
  areFormItemsEmbedded: boolean;
  canRemoveAllAccessGroups: boolean;
  isSyncing: boolean;
  syncingTooltip: string;
}

export function AccessGroupSourcesDataProvider({
  accessGroups,
  originalAccessGroupSources,
  children,
  recordType,
  recordId,
  areFormItemsEmbedded,
  canRemoveAllAccessGroups,
  isSyncing,
  syncingTooltip,
}: Readonly<AccessGroupSourcesDataContextProviderInterface>) {
  return (
    <AccessGroupSourcesDataContext.Provider
      value={{
        accessGroups,
        originalAccessGroupSources,
        recordType,
        recordId,
        areFormItemsEmbedded,
        canRemoveAllAccessGroups,
        isSyncing: isSyncing,
        syncingTooltip,
      }}
    >
      {children}
    </AccessGroupSourcesDataContext.Provider>
  );
}
