export const getOnChangingSortFunc =
  ({ dataQuery, orderingColumn }: any) =>
  (orders: any) => {
    if (orders.length !== 0) {
      const orderParam = orders[0];

      const isDesc = orderParam.desc === true && "desc";
      const isAsc = orderParam.desc === false && "asc";
      const isNull = !isDesc && !isAsc;

      if (isNull) {
        dataQuery.setOrder({});
        return;
      }

      const keyTranslated = orderingColumn[orderParam.id];

      dataQuery.setOrder({
        [keyTranslated]: isDesc || isAsc,
      });
    }
  };
