import InnerHTML from "dangerously-set-html-content";
import { t } from "@/i18n-js/instance";
import { useBroadcastReport } from "./BroadcastReportContext";
import { StatsPercentageCard } from "./StatsPercentageCard";

const localeNamespace =
  "settings.emails.view_broadcast.reports_tab.percentage_stats_fields";

/* Anchor has a default style that sets it to `brand` color. Default it to brand button text for now. */
const tooltipStyle = {
  "--brand": "var(--brand-button-text)",
};

export const StatsPercentageGrid = () => {
  const {
    data: {
      delivered = null,
      opened = null,
      clicked = null,
      hard_bounced: hardBounced = null,
      unsubscribed = null,
      complained = null,
      sent = null,
      recipient_count: recipientCount = null,
    } = {},
  } = useBroadcastReport();
  const total = sent || recipientCount;

  return (
    <div className="mb-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
      <StatsPercentageCard
        label={t([localeNamespace, "delivered"])}
        value={delivered}
        total={total}
        tooltipText={t([localeNamespace, "tooltips", "delivered"])}
        borderClass="rounded-lg md:rounded-none md:rounded-tl-lg border md:border-0 md:border-b md:border-l md:border-t md:border-r-0"
      />
      <StatsPercentageCard
        label={t([localeNamespace, "opened"])}
        value={opened}
        total={total}
        tooltipText={
          <InnerHTML
            html={t([localeNamespace, "tooltips", "opened"])}
            style={tooltipStyle}
          />
        }
        borderClass="rounded-lg md:rounded-none border"
      />
      <StatsPercentageCard
        label={t([localeNamespace, "clicked"])}
        value={clicked}
        total={total}
        tooltipText={t([localeNamespace, "tooltips", "clicked"])}
        borderClass="rounded-lg md:rounded-none md:rounded-tr-lg border md:border-0 md:border-b md:border-r md:border-t"
      />
      <StatsPercentageCard
        label={t([localeNamespace, "hard_bounced"])}
        value={hardBounced}
        total={total}
        tooltipText={t([localeNamespace, "tooltips", "hard_bounced"])}
        borderClass="rounded-lg md:rounded-none md:rounded-bl-lg border md:border-0 md:border-b md:border-l"
      />
      <StatsPercentageCard
        label={t([localeNamespace, "unsubscribed"])}
        value={unsubscribed}
        total={total}
        tooltipText={
          <InnerHTML
            html={t([localeNamespace, "tooltips", "unsubscribed"])}
            style={tooltipStyle}
          />
        }
        borderClass="rounded-lg md:rounded-none md:rounded-none border md:border-0 md:border-b md:border-l md:border-r"
      />
      <StatsPercentageCard
        label={t([localeNamespace, "marked_as_spam"])}
        value={complained}
        total={total}
        tooltipText={
          <InnerHTML
            html={t([localeNamespace, "tooltips", "marked_as_spam"])}
            style={tooltipStyle}
          />
        }
        borderClass="rounded-lg md:rounded-none md:rounded-br-lg border md:border-0 md:border-b md:border-r"
      />
    </div>
  );
};
