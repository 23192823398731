import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { useRef, useState } from "react";
import classNames from "classnames";
import { compact, uniqueId } from "lodash";
import { t } from "@/i18n-js/instance";
import { useButtonVariant } from "@circle-react/helpers/useButtonVariant";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";
import { Button } from "@circle-react-uikit/Button";
import { Fieldset } from "@circle-react-uikit/Fieldset";
import { Form } from "@circle-react-uikit/Form";
import { useFilterContext } from "../../Filter";
import { BaseFilterFields } from "../BaseFilterFields";
import { SearchBar } from "../CheckboxFilter/SearchBar";

export type CheckboxFilterOptions = ReadonlyArray<{
  richLabel?: ReactNode;
  label: string;
  value: string | number;
}>;

export interface CheckboxFilterParam extends BaseFilter {
  value?: string[] | number[];
}

interface CheckboxFilterForm extends BaseFilter {
  value: Record<string, boolean>;
}

export interface SearchBarProps extends ComponentPropsWithoutRef<"input"> {
  label?: string;
}

interface CheckboxFilterProps {
  options: CheckboxFilterOptions;
  legend: string;
  hasSearch?: boolean;
  searchProps?: SearchBarProps;
  param: CheckboxFilterParam;
  className?: string;
}

/**
 * Code extracted from existing CheckboxFilter component to support multiple instances.
 */
export const CheckboxFilter = ({
  options,
  legend,
  hasSearch = false,
  searchProps,
  param,
  className,
}: CheckboxFilterProps) => {
  const idPrefixRef = useRef(uniqueId());
  const { onApply } = useFilterContext();
  const [searchTerm, setSearchTerm] = useState("");
  const primaryButtonVariant = useButtonVariant();

  const filteredOptions = options.filter(option =>
    !searchTerm
      ? option
      : option.label.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const getDefaultSelectedValues = () =>
    options.reduce<any>((acc, option) => {
      const paramValue = param.value;
      if (typeof option.value === "number") {
        const paramNumberValues = compact(paramValue?.map(Number));
        acc[option.value] = paramNumberValues?.includes(option.value);
      } else {
        const paramStringValues = paramValue?.map(String);
        acc[option.value] = paramStringValues?.includes(option.value);
      }
      return acc;
    }, {});

  const getDefaultValues = () => ({
    ...param,
    value: getDefaultSelectedValues(),
  });

  const handleApply = (submitData: CheckboxFilterForm, { reset }: any) => {
    // Find items with value true
    const checkedValues = Object.entries(submitData.value).filter(
      ([, value]) => value,
    );
    const checkedKeys = checkedValues.map(([key]) => key);
    onApply({ ...submitData, value: checkedKeys });
    reset();
  };

  return (
    <div
      className={classNames("flex w-[18rem] flex-col gap-y-5 p-4", className)}
    >
      {hasSearch && (
        <SearchBar
          name="search-bar"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          searchProps={searchProps}
        />
      )}
      <Form defaultValues={getDefaultValues()} onSubmit={handleApply}>
        <div className="flex flex-col gap-y-3">
          <div className="max-h-[20rem] gap-y-4 overflow-y-auto p-1">
            <Fieldset legend={legend} verticalSpacing="tight">
              {filteredOptions.map(option => (
                <Form.Item
                  key={option.value}
                  name={`value.${option.value}`}
                  id={`${idPrefixRef.current}-${option.value}`}
                  hideLabel
                  hideBorder
                  hideDescription
                  isFirstChild
                  className="!pb-0"
                >
                  <Form.Checkbox
                    name={`${option.value}-checkbox`}
                    option={option}
                    labelClassName="text-base font-normal"
                    className="!mt-0"
                  />
                </Form.Item>
              ))}
            </Fieldset>
            <BaseFilterFields />
          </div>
          <Button variant={primaryButtonVariant} type="submit" full>
            {t("filters.apply")}
          </Button>
        </div>
      </Form>
    </div>
  );
};
