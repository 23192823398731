import classNames from "classnames";
import { useSidebarBackgroundStyles } from "@circle-react/components/Spaces/SidebarV2/hooks";

export const SwitcherCount = () => {
  const { backgroundColor: borderColor } = useSidebarBackgroundStyles();

  return (
    <span
      style={{ borderColor }}
      className={classNames("switcher__count !-right-0.5 !-top-0.5")}
    />
  );
};
