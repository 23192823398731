import type {
  AccessGroupSourceType,
  SelectedAccessGroupType,
} from "@circle-react/types/AccessGroup";
import { ACTION_STATUSES } from "./constants";

export const normalizeAccessGroupSources = (
  accessGroupSources: AccessGroupSourceType[],
) =>
  accessGroupSources?.map(accessGroupSource => ({
    ...accessGroupSource.access_group,
    hasMembers: accessGroupSource.has_members,
    status: accessGroupSource.deleted_at
      ? ACTION_STATUSES.REMOVED
      : ACTION_STATUSES.ACTIVE,
  })) || [];

export const activeCount = (selectedAccessGroups: SelectedAccessGroupType[]) =>
  selectedAccessGroups.filter(
    accessGroup =>
      accessGroup.status === ACTION_STATUSES.NEW ||
      accessGroup.status === ACTION_STATUSES.ACTIVE,
  );

export const originalAccessGroupSourcesNormalized = (
  originalAccessGroupSources: AccessGroupSourceType[],
) =>
  originalAccessGroupSources?.map(accessGroupSource => ({
    id: accessGroupSource.access_group.id,
    status: accessGroupSource.deleted_at
      ? ACTION_STATUSES.REMOVED
      : ACTION_STATUSES.ACTIVE,
  })) || [];
