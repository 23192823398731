import { useState } from "react";
import PropTypes from "prop-types";
import { RadioGroup } from "@headlessui/react";
import { Button } from "@circle-react-uikit/Button";

export const SelectFilter = ({ options = [], onChange }) => {
  const [value, setValue] = useState(options[0]);

  const handleSubmit = () => {
    onChange(value);
  };

  return (
    <>
      <RadioGroup value={value} onChange={setValue}>
        {options.map((option, index) => (
          <RadioGroup.Option
            value={option.label}
            key={index}
            className="relative mt-3 flex cursor-pointer focus:outline-none"
          >
            {({ checked }) => (
              <>
                <span
                  className={`mt-0.5 flex h-4 w-4 cursor-pointer items-center justify-center rounded-full border ${
                    checked
                      ? "border-transparent bg-indigo-600"
                      : "border-gray-300 bg-white"
                  }`}
                  aria-hidden="true"
                >
                  <span className="h-1.5 w-1.5 rounded-full bg-white" />
                </span>
                <div className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className="text-dark block text-sm font-medium"
                  >
                    {option.label}
                  </RadioGroup.Label>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
      <div className="mt-3">
        <Button variant="primary" type="large" onClick={handleSubmit}>
          {I18n.t("members_directory.header.search.apply_filter")}
        </Button>
      </div>
    </>
  );
};

SelectFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};
