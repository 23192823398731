import type { ComponentPropsWithoutRef } from "react";
import { useMemo } from "react";
import { head } from "lodash";
import * as yup from "yup";
import { t } from "@/i18n-js/instance";
import { useButtonVariant } from "@circle-react/helpers/useButtonVariant";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { useFilterContext } from "../../Filter";
import { BaseFilterFields } from "../BaseFilterFields";
import { DaysFormControl } from "./DaysFormControl";
import type { DaysFilterParam } from "./utils";

interface DaysFilterProps
  extends ComponentPropsWithoutRef<typeof DaysFormControl> {
  param: BaseFilter;
}

export const DaysFilter = ({ options = [], param }: DaysFilterProps) => {
  const { onApply } = useFilterContext();
  const primaryButtonVariant = useButtonVariant();

  const getValidationSchema = () => {
    const errorMessage = t(
      "members_directory.header.search.filters.days_ago.less_than_0",
    );
    return yup.object().shape({
      filter_type: yup
        .string()
        .oneOf(options.map(({ value }) => value))
        .required(),
      value: yup
        .number()
        .typeError(errorMessage)
        .min(0, errorMessage)
        .integer(errorMessage)
        .required(errorMessage),
    });
  };

  const handleApply = (submitData: DaysFilterParam, { reset }: any) => {
    onApply(submitData);
    reset();
  };

  const defaultValue: any = useMemo(
    () => ({
      filter_type: head(options)?.value,
      ...param,
    }),
    [options, param],
  );

  return (
    <Form
      defaultValues={defaultValue}
      validationSchema={getValidationSchema()}
      noValidate
      onSubmit={handleApply}
    >
      <div className="flex min-w-[18rem] flex-col gap-y-4 p-4">
        <DaysFormControl options={options} />
        <BaseFilterFields />
        <Button variant={primaryButtonVariant} type="submit" full>
          {t("filters.apply")}
        </Button>
      </div>
    </Form>
  );
};
