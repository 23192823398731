import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { useCreatePaywallSubscriptionFormContext } from "@circle-react-shared/Paywalls/Modals/CreatePaywallSubscriptionModal/Form/CreatePaywallSubscriptionFormContextProvider";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Item } from "./Item";
import { ItemSummary } from "./ItemSummary";
import { List } from "./List";

const localeRoot = "paywalls.apply_coupon_modal";

export const ApplyCouponInvoice = ({
  subscription,
  previewCouponQuery,
}: any) => {
  const { couponsIndex } = useCreatePaywallSubscriptionFormContext();
  const { watch } = useFormContext();

  const couponCode = watch("coupon_code");
  const paywallName =
    subscription.paywall.name || subscription.paywall.display_name;
  const paywallPrice = subscription.paywall_price;
  const couponSelected = (couponsIndex ?? {})[couponCode];
  const futurePayments = previewCouponQuery.data.future_payments;

  return (
    <List className="gap-y-4">
      <Item
        title={`${paywallName} (${paywallPrice.interval_qualifier})`}
        titleWeight="normal"
      >
        <Typography.LabelMd>
          {paywallPrice.display_amount_with_currency}
        </Typography.LabelMd>
      </Item>
      <hr />
      <Item title={t([localeRoot, "recurring_plan_charges"])}>
        <Typography.LabelMd>
          {paywallPrice.display_amount_with_currency}
        </Typography.LabelMd>
      </Item>
      <Item
        title={t([localeRoot, "coupon"])}
        subtitle={`${couponSelected.name} (${couponSelected.terms})`}
      >
        <Typography.LabelMd>
          {futurePayments.formatted_discount}
        </Typography.LabelMd>
      </Item>
      <hr />
      <List className="gap-y-2">
        <ItemSummary title={t([localeRoot, "subtotal"])}>
          <Typography.LabelSm>
            {futurePayments.formatted_subtotal}
          </Typography.LabelSm>
        </ItemSummary>
        <ItemSummary title={t([localeRoot, "tax"])}>
          <Typography.LabelSm>
            {futurePayments.formatted_tax}
          </Typography.LabelSm>
        </ItemSummary>
        <ItemSummary title={t([localeRoot, "coupon"])}>
          <Typography.LabelSm color="text-v2-success">
            {futurePayments.formatted_discount}
          </Typography.LabelSm>
        </ItemSummary>
      </List>
      <hr />
      <Item title={t([localeRoot, "total_due_at_next_charge"])}>
        <Typography.LabelMd weight="semibold">
          {futurePayments.formatted_amount_due}
        </Typography.LabelMd>
      </Item>
    </List>
  );
};
