import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import type { IconType } from "@circle-react-shared/Icon/IconNames";
import { VIEWS } from "@circle-react-shared/MembersList/constants";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { MEMBER_LAYOUT_OPTIONS } from "./memberConstant";

export type ViewSwitcherLayoutTypes =
  | "list"
  | "cards"
  | "thumbnail"
  | "posts"
  | "calendar";

export interface ViewSwitcherLayoutOptions {
  icon: IconType;
  label: string;
  value: ViewSwitcherLayoutTypes;
}

interface ViewSwitcherProps {
  value?: ViewSwitcherLayoutTypes;
  onChange: (value: ViewSwitcherLayoutTypes) => void;
  options?: ViewSwitcherLayoutOptions[];
}

export const ViewSwitcher = ({
  value = VIEWS.CARDS,
  onChange,
  options = MEMBER_LAYOUT_OPTIONS,
}: ViewSwitcherProps) => (
  <RadioGroup value={value} onChange={onChange}>
    <RadioGroup.Label className="sr-only">{t("views.label")}</RadioGroup.Label>
    <div className="divide-primary border-primary inline-flex h-8 items-center divide-x overflow-hidden rounded-md border">
      {options.map(({ icon, label, value: optionValue }) => (
        <RadioGroup.Option value={optionValue} key={label}>
          {({ checked }) => (
            <TippyV2 content={label} placement="bottom">
              <div
                className={classNames(
                  "bg-primary text-dark cursor-pointer p-2 pt-1",
                  {
                    "bg-tertiary": checked,
                  },
                )}
              >
                <Icon aria-label={label} type={icon} size={16} />
              </div>
            </TippyV2>
          )}
        </RadioGroup.Option>
      ))}
    </div>
  </RadioGroup>
);
