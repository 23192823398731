import type { ReactNode } from "react";
import { omit } from "lodash";
import { t } from "@/i18n-js/instance";
import { useButtonVariant } from "@circle-react/helpers/useButtonVariant";
import { useFilterContext } from "@circle-react-shared/FilterV3/FilterContext";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import type { OnSubmitFunction } from "@circle-react-uikit/Form/Form";
import { BaseFilterFields } from "../BaseFilterFields";
import { RadioInput } from "../RadioInput";
import type {
  StringComparator,
  StringComparatorParam,
} from "./stringComparators";
import {
  isStringComparatorParam,
  requireValueByFilterType,
  stringComparators,
} from "./stringComparators";

interface InputFilterComparatorProps {
  name: string;
  param: BaseFilter;
  placeholder?: string;
  /** To render more input values */
  children?: ReactNode;
  comparators?: ReadonlyArray<StringComparator>;
}

/**
 * This is build for the FilterV3 component.
 * The only change being the import of FilterContext from FilterV3.
 */
export function InputFilterComparator({
  name,
  placeholder,
  param,
  children,
  comparators = stringComparators,
}: InputFilterComparatorProps) {
  const primaryButtonVariant = useButtonVariant();
  const { onApply } = useFilterContext();

  const handleSubmit: OnSubmitFunction<StringComparatorParam> = (
    data,
    { reset },
  ) => {
    if (!requireValueByFilterType(data.filter_type)) {
      return onApply(omit(data, "value"));
    }
    if (!data.value) {
      return onApply(null);
    }
    onApply(data);
    reset();
  };

  const getDefaultValues = (): StringComparatorParam => {
    if (isStringComparatorParam(param)) {
      return {
        id: param.id,
        key: name,
        filter_type: param.filter_type,
        value: param.value,
      };
    }
    return {
      id: param.id,
      key: name,
      filter_type: comparators[0]?.value,
      value: "",
    };
  };

  return (
    <Form defaultValues={getDefaultValues()} onSubmit={handleSubmit}>
      <fieldset className="flex flex-col py-1">
        <BaseFilterFields />
        {comparators.map(stringComparator => (
          <RadioInput key={stringComparator.value} {...stringComparator}>
            <Form.Input name="value" autoFocus placeholder={placeholder} />
          </RadioInput>
        ))}
        {children}
      </fieldset>
      <div className="px-4 py-3">
        <Button type="submit" variant={primaryButtonVariant} full>
          {t("done")}
        </Button>
      </div>
    </Form>
  );
}
