import { t } from "@/i18n-js/instance";
import { Button } from "@circle-react-shared/uikit/Button";
import type { DropdownOptions } from "@circle-react-uikit/Dropdown";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import type { Member } from "@circle-react-uikit/MembersAvatarRow";
import { MembersAvatarRow } from "@circle-react-uikit/MembersAvatarRow";

const dropdownOptions: DropdownOptions = [
  {
    label: t("latest"),
    value: "latest",
  },
];

const members: Member[] = [
  {
    id: "1",
    name: "A",
    avatar_url: "",
  },
  {
    id: "2",
    name: "B",
    avatar_url: "",
  },
  {
    id: "3",
    name: "C",
    avatar_url: "",
  },
];

export const RightContent = () => (
  <div className="hidden flex-row items-center space-x-4 md:flex">
    <Dropdown options={dropdownOptions} />
    <MembersAvatarRow members={members} amountToShow={3} totalCount={33} />
    <Button type="button" large>
      {t("new_post")}
    </Button>
  </div>
);
