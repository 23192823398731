import { BlockWithConnectorItem } from "@circle-react-shared/BlockWithConnectorItem";
import type { ItemDataProviderProps } from "@circle-react-shared/BlockWithConnectorItem/ItemDataProvider";
import { useBlockWithConnectorData } from "./BlockWithConnectorDataProvider";

export interface ItemProps
  extends Omit<
    ItemDataProviderProps,
    "onCloseItemAndOpenNext" | "onToggleItem"
  > {
  id: string | number;
  className?: string;
}

export const Item = ({
  id,
  isOpen: isOpenProp,
  className,
  children,
  ...rest
}: ItemProps) => {
  const { activeItemId, onToggleItem, onCloseItemAndOpenNext } =
    useBlockWithConnectorData();

  const isOpen = isOpenProp || activeItemId === id;

  const onToggleHandler = () => {
    onToggleItem(id);
  };

  return (
    <BlockWithConnectorItem
      id={id}
      isOpen={isOpen}
      onCloseItemAndOpenNext={onCloseItemAndOpenNext}
      onToggleItem={onToggleHandler}
      {...rest}
    >
      <BlockWithConnectorItem.Container className={className}>
        {children}
      </BlockWithConnectorItem.Container>
    </BlockWithConnectorItem>
  );
};
