import PropTypes from "prop-types";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { IconButton } from "./IconButton";

export const UserActions = ({ item, actions }) => {
  if (!actions) {
    return null;
  }

  return actions.map((action, index) => (
    <UserAction key={index} item={item} action={action} />
  ));
};

UserActions.propTypes = {
  item: PropTypes.object.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      tooltip: PropTypes.string,
      icon: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
};

const UserAction = ({ item, action }) => {
  const { tooltip, ...rest } = action;

  if (tooltip) {
    return (
      <TippyV2 content={tooltip}>
        <Content item={item} {...rest} />
      </TippyV2>
    );
  }

  return <Content item={item} {...rest} />;
};

UserAction.propTypes = {
  item: PropTypes.object.isRequired,
  action: PropTypes.shape({
    tooltip: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
  }),
};

const Content = ({ item, icon, onClick }) => {
  const onClickHandler = () => {
    onClick({ item });
  };

  const onPointerDownHandler = event => {
    event.stopPropagation();
  };

  return (
    <IconButton
      className="invisible flex group-hover:visible"
      onClick={onClickHandler}
      onPointerDown={onPointerDownHandler}
    >
      <Icon type={icon} size={16} useWithFillCurrentColor />
    </IconButton>
  );
};

Content.propTypes = {
  icon: PropTypes.string,
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};
