import { DataTable } from "@circle-react-uikit/DataTableV2";
import { usePaywallChargesList } from "./hooks/usePaywallChargesList";

export interface PaywallChargesListProps {
  columnsToRender: any;
  chargesData: any;
  onClickPagination: (page: number) => void;
}

export const PaywallChargesList = ({
  columnsToRender,
  chargesData,
  onClickPagination,
}: PaywallChargesListProps) => {
  const { onChangeSorting } = usePaywallChargesList({ chargesData });

  return (
    <DataTable.TableWrapper>
      <DataTable
        onChangeSorting={onChangeSorting}
        columns={columnsToRender}
        data={chargesData?.charges ?? []}
        isLoading={chargesData?.isLoadingCharges}
        initialPage={chargesData?.page ?? 1}
        onChangePage={onClickPagination}
        totalRecordsCount={chargesData?.totalCharges}
        pageSize={chargesData.perPage}
      >
        <DataTable.Table>
          <DataTable.SortableHead />
          <DataTable.Loader />
          <DataTable.Body />
        </DataTable.Table>
        <DataTable.Pagination align="left" direction="reverse" />
      </DataTable>
    </DataTable.TableWrapper>
  );
};
