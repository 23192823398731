import { useFormContext } from "react-hook-form";

export function BaseFilterFields() {
  const { register } = useFormContext();

  return (
    <>
      <input type="hidden" {...register("key")} />
      <input type="hidden" {...register("id")} />
    </>
  );
}
