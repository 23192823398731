import { DataTable } from "@circle-react-shared/uikit/DataTableV2";
import { useModalData } from "@circle-react-shared/uikit/ModalV2";
import { useMenuItems } from "./hooks/useMenuItems";

export const ActionMenuColumn = (cellContext: any) => {
  const { contentPortalElement } = useModalData();
  const { menuItems } = useMenuItems(cellContext);

  return (
    <DataTable.Actions
      contentPortalElement={contentPortalElement ?? document.body}
    >
      {menuItems.map(({ key, label, onClick }) => (
        <DataTable.Actions.Item key={key} onClick={onClick}>
          {label}
        </DataTable.Actions.Item>
      ))}
    </DataTable.Actions>
  );
};
