import classNames from "classnames";

interface WrapperProps {
  children: React.ReactNode;
  props?: any;
  className?: string;
}

export const Wrapper = ({ children, props, className }: WrapperProps) => (
  <div
    {...props}
    className={classNames("border-primary rounded-lg border", className)}
  >
    {children}
  </div>
);
