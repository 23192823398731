import classNames from "classnames";
import type {
  CardType,
  WalletType,
} from "@circle-react/types/CommunityMemberPaymentMethod";
import { Icon } from "@circle-react-shared/Icon";

export const STRIPE_SUPPORTED_CARDS: Record<
  CardType,
  { icon: CardType; iconWidth: number }
> = {
  discover: { icon: "discover", iconWidth: 32 },
  amex: { icon: "amex", iconWidth: 32 },
  diners: { icon: "diners", iconWidth: 32 },
  jcb: { icon: "jcb", iconWidth: 32 },
  visa: { icon: "visa", iconWidth: 36 },
  unionpay: { icon: "unionpay", iconWidth: 36 },
  mastercard: { icon: "mastercard", iconWidth: 37 },
  generic: { icon: "generic", iconWidth: 24 },
};

interface WalletLogoProperty {
  type: "apple-pay" | "google-pay" | "link-pay";
  viewBox: string;
  className?: string;
}

const walletLogo: Record<
  WalletType,
  { sm: WalletLogoProperty; md: WalletLogoProperty } | null
> = {
  google_pay: {
    sm: {
      type: "google-pay",
      viewBox: "0 0 16 16",
      className: "!w-3",
    },
    md: {
      type: "google-pay",
      viewBox: "0 0 16 17",
      className: "!w-6",
    },
  },
  apple_pay: {
    sm: {
      type: "apple-pay",
      viewBox: "0 0 14 20",
      className: "!w-3",
    },
    md: {
      type: "apple-pay",
      viewBox: "0 0 14 17",
      className: "!w-6",
    },
  },
  link: {
    sm: {
      type: "link-pay",
      viewBox: "0 0 14 20",
      className: "!w-3",
    },
    md: {
      type: "link-pay",
      viewBox: "0 0 14 17",
      className: "!w-6",
    },
  },
};

export interface CreditCardIcon {
  card: CardType;
  walletType?: WalletType;
  className?: string;
  variant?: "sm" | "md";
}

export const CreditCardIcon = ({
  card,
  walletType,
  variant = "sm",
  ...props
}: CreditCardIcon) => {
  const cardIcon =
    STRIPE_SUPPORTED_CARDS[card] ?? STRIPE_SUPPORTED_CARDS.generic;
  const walletProperties = walletType ? walletLogo[walletType] : null;
  const walletProperty =
    walletProperties && variant ? walletProperties[variant] : null;

  return (
    <div className="flex items-end gap-x-1">
      <div>{walletProperty && <Icon {...walletProperty} />}</div>
      <div>
        <Icon
          type={`card-${cardIcon.icon}`}
          viewBox={`0 0 ${cardIcon.iconWidth} 24`}
          className={classNames("", {
            "!w-10 !self-start": variant === "md",
          })}
          {...props}
        />
      </div>
    </div>
  );
};
