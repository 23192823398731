import { memo } from "react";
import PropTypes from "prop-types";
import { BlocksPreviewViewer } from "@circle-react/components/Spaces/CourseSpace/Lockscreen/BlocksViewer/BlocksPreviewViewer";
import {
  APPEARANCE,
  ControlledThemeProvider,
  THEME_MAP,
} from "@circle-react/providers";
import { HeaderPreview } from "@circle-react-shared/Previews";
import { StyledFrame } from "@circle-react-shared/StyledFrame";

export const LockscreenFrame = memo(
  ({
    theme = {},
    headerSpaceEmoji = "",
    headerSpaceTitle = "",
    blocks = [],
  }) => (
    <StyledFrame>
      <ControlledThemeProvider theme={theme}>
        <HeaderPreview
          spaceName={headerSpaceTitle}
          emoji={headerSpaceEmoji}
          hideRightContent
        />
        <BlocksPreviewViewer blocks={blocks} />
      </ControlledThemeProvider>
    </StyledFrame>
  ),
);
LockscreenFrame.displayName = "LockscreenFrame";

LockscreenFrame.propTypes = {
  theme: PropTypes.shape({
    current_theme: PropTypes.oneOf(Object.keys(THEME_MAP)),
    custom_colors: PropTypes.object,
    default_appearance: PropTypes.oneOf(Object.values(APPEARANCE)),
    is_custom: PropTypes.bool,
    brand_color: PropTypes.string,
  }).isRequired,
  headerSpaceEmoji: PropTypes.string,
  headerSpaceTitle: PropTypes.string,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      hidden: PropTypes.bool,
      id: PropTypes.string,
      data: PropTypes.object,
    }),
  ),
};
