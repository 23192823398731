import PropTypes from "prop-types";
import classNames from "classnames";

export const Container = ({ className, children }) => (
  <div
    className={classNames(
      "flex w-full max-w-[45rem] flex-col items-center",
      className,
    )}
  >
    {children}
  </div>
);

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
