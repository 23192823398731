import { createContext, useContext } from "react";
import classNames from "classnames";

const DEFAULT_HEIGHT_IN_REM = 49.1625;
const DEFAULT_WIDTH_IN_REM = 22.6125;

export const size = {
  width: 22.6125,
  height: 49.1625,
};

export const getSizeStyles = (
  border: boolean,
  width: number,
  height: number,
) => {
  if (border) {
    return {
      width: `${width + 1.125 * 2}rem`,
      height: `${height + 1.125 * 2}rem`,
    };
  }

  return {
    width: `${width}rem`,
    height: `${height}rem`,
  };
};

const MobileAppPreview = createContext<any>({
  width: DEFAULT_WIDTH_IN_REM,
  height: DEFAULT_HEIGHT_IN_REM,
});
MobileAppPreview.displayName = "MobileAppPreviewProvider";

export const useMobileAppPreviewContext = () => useContext(MobileAppPreview);

export const Root = ({
  children,
  border,
  height = DEFAULT_HEIGHT_IN_REM,
  width = DEFAULT_WIDTH_IN_REM,
}: any) => (
  <MobileAppPreview.Provider
    value={{
      height,
      width,
    }}
  >
    <div
      className={classNames("flex h-full w-full", {
        "items-center justify-center": border,
      })}
    >
      <div
        id="mobile-app-preview-body"
        className={classNames("relative overflow-hidden", {
          "!rounded-[64px] border-[1.125rem] !border-[black]": border,
        })}
        style={{
          scrollbarWidth: "none",
          ...getSizeStyles(border, width, height),
        }}
      >
        {children}
      </div>
    </div>
  </MobileAppPreview.Provider>
);
