import type { ReactNode } from "react";
import classNames from "classnames";

export interface BodyWrapperProps {
  children: ReactNode;
  className?: string;
}

export const BodyWrapper = ({ children, className = "" }: BodyWrapperProps) => (
  <div
    className={classNames(
      "has-bottom-banner is-signed-in view-homepage is-standard-layout-v2 react-standard-layout bg-secondary h-full w-full",
      className,
    )}
  >
    {children}
  </div>
);
