import { useSpacesForFilters } from "@/react/components/Spaces/hooks/useSpacesForFilters";
import { useSpacesContext } from "@circle-react/contexts";
import { DropdownFilter } from "../DropdownFilter";

export const SpacesFilter = () => {
  const { helpers } = useSpacesContext();
  const spaces = useSpacesForFilters({
    spaces: helpers.spaceWithViewContentAccess(),
  });

  if (helpers.isDataLoading() && !spaces.length) {
    return null;
  }

  return (
    <DropdownFilter queryParam="spaces" type="multiselect" options={spaces} />
  );
};
