import type { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";
import { times } from "lodash";
import { PostPreview } from "../PostPreview";

export interface PostFeedPreviewProps extends ComponentPropsWithoutRef<"div"> {
  count?: number;
}

export const PostFeedPreview = ({
  count = 3,
  className,
  ...rest
}: PostFeedPreviewProps) => (
  <div className={classNames("space-y-[20px]", className)} {...rest}>
    {times(count, index => (
      <PostPreview key={index} />
    ))}
  </div>
);
