import { useState } from "react";
import PropTypes from "prop-types";
import { uniqueId } from "lodash";

export const Option = ({ option, onChange, selected = false }) => {
  const { label, richLabel, value } = option;
  const [checked, setChecked] = useState(selected);
  const id = uniqueId();

  const handleOnChange = () => {
    onChange(value, !checked);
    setChecked(!checked);
  };

  return (
    <>
      <div className="flex h-5 items-center">
        <input
          id={id}
          type="checkbox"
          checked={checked}
          onChange={handleOnChange}
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={id} className="text-darkest font-medium">
          {richLabel || label}
        </label>
      </div>
    </>
  );
};

Option.propTypes = {
  option: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};
