import NiceModal from "@ebay/nice-modal-react";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { PaywallSubscriptionDetailsModal } from "./PaywallSubscriptionDetailsModal";

const PaywallSubscriptionDetailsModalComponent = ModalManager.create(
  (props: any) => <PaywallSubscriptionDetailsModal {...props} />,
);

export const usePaywallSubscriptionDetailsModal = (key: any) => {
  if (key) {
    NiceModal.register(key, PaywallSubscriptionDetailsModalComponent);
  }

  return useModal(key ?? PaywallSubscriptionDetailsModalComponent);
};
