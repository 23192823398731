import type { ReactNode } from "react";
import { useMemo } from "react";
import { nanoid } from "nanoid";
import type { SegmentPage } from "@/react/components/SettingsApp/Segments/hooks/useSegmentsInfiniteQuery";
import { useFilters } from "@/react/components/SettingsApp/Workflows/components/ViewWorkflowModal/EntryRules/BulkEntryRule/useFilters";
import { TippyV2 } from "../../../TippyV2";
import { Typography } from "../../../uikit/Typography";

interface SegmentTooltipProps {
  children: ReactNode;
  selectedSegment: SegmentPage | undefined;
}

export const SegmentTooltip = ({
  children,
  selectedSegment,
}: SegmentTooltipProps) => {
  const { filters, hasFilters } = useFilters(selectedSegment?.rules.rules);

  const rulesOfSelectedSegment = useMemo(
    () => (
      <div className="flex flex-col gap-2 p-2">
        {filters.map(filterRule => (
          <div
            className="border-secondary flex w-fit gap-1 rounded-3xl border px-2 py-[0.125rem]"
            key={nanoid()}
          >
            <div className="max-w-[260px] truncate">
              <Typography.LabelXs color="text-feature-dark">
                {filterRule.name}
              </Typography.LabelXs>
            </div>
            <div className="max-w-[260px] truncate">
              <Typography.LabelXs color="text-timestamp">
                {filterRule.value}
              </Typography.LabelXs>
            </div>
          </div>
        ))}
      </div>
    ),
    [filters],
  );

  return (
    <TippyV2
      content={rulesOfSelectedSegment}
      interactive={false}
      disabled={!hasFilters}
      placement="bottom"
      tooltipTextClassName="!max-w-[580px] !rounded-xl"
      arrow={false}
      offset={[0, 4]}
    >
      {children}
    </TippyV2>
  );
};
