import { SpaceGroupListView } from "@circle-react/components/Spaces/SidebarV2/CommunityMenu/SpaceGroupList/SpaceGroupListView";
import { SpaceGroupView } from "@circle-react/components/Spaces/SidebarV2/CommunityMenu/SpaceGroupList/SpaceGroupView";
import { SpaceListView } from "@circle-react/components/Spaces/SidebarV2/CommunityMenu/SpaceList/SpaceListView";
import { GoLivePoweredBy } from "@circle-react/components/Spaces/SidebarV2/GoLivePoweredBy";
import { LinksWrapper } from "@circle-react/components/Spaces/SidebarV2/LinksWrapper";
import { PlatformMenuV3 } from "@circle-react/components/Spaces/SidebarV2/PlatformMenuV3";
import { ProfileWrapper } from "@circle-react/components/Spaces/SidebarV2/ProfileWrapper";
import { SidebarV2Wrapper } from "@circle-react/components/Spaces/SidebarV2/SidebarV2Wrapper";
import { SpaceRenderer } from "./SpaceRenderer";
import {
  coursesSpaceGroup,
  coursesSpaces,
  getStartedSpaceGroup,
  getStartedSpaces,
} from "./constants";

export const SidebarPreview = () => (
  <SidebarV2Wrapper>
    <LinksWrapper>
      <PlatformMenuV3 isDisabled isFeedLinkActive />
      <SpaceGroupListView>
        <SpaceGroupView
          id="get-started"
          listId="get-started"
          spaceGroup={getStartedSpaceGroup}
          canManage={false}
          shouldDisplayOptions={false}
        >
          <SpaceListView>
            {getStartedSpaces.map(space => (
              <SpaceRenderer
                key={space.id}
                space={space}
                shouldShowNotifications
              />
            ))}
          </SpaceListView>
        </SpaceGroupView>
        <SpaceGroupView
          id="courses"
          listId="courses"
          spaceGroup={coursesSpaceGroup}
          canManage={false}
          shouldDisplayOptions={false}
        >
          <SpaceListView>
            {coursesSpaces.map(space => (
              <SpaceRenderer
                key={space.id}
                space={space}
                shouldShowNotifications={false}
              />
            ))}
          </SpaceListView>
        </SpaceGroupView>
      </SpaceGroupListView>
    </LinksWrapper>
    <ProfileWrapper>
      <GoLivePoweredBy isPreviewMode />
    </ProfileWrapper>
  </SidebarV2Wrapper>
);
