import { CustomerColumn as CustomerComponent } from "../../CustomerColumn";

export const CustomerColumn = (cellContext: any) => {
  const {
    row: {
      original: { community_member: communityMember },
    },
  } = cellContext;

  return (
    <div className="max-w-xs">
      <CustomerComponent customer={communityMember} shouldOpenAdminMemberEdit />
    </div>
  );
};
