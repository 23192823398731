import type { ReactNode } from "react";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

interface CardDescriptionProps {
  title: ReactNode;
  subtitle: ReactNode;
  checkedList: string[];
  bgColor?: string;
  accentColor?: string;
  mainIconEl: ReactNode;
}

export const CardDescription = ({
  title,
  subtitle,
  checkedList,
  bgColor,
  accentColor,
  mainIconEl,
}: CardDescriptionProps) => (
  <div
    className="rounded-[10px] px-6 py-10 xl:px-16 xl:py-20"
    style={{ backgroundColor: bgColor, flexBasis: "42%" }}
  >
    <div className="flex flex-col gap-y-5">
      <div
        className="flex h-[60px] w-[60px] items-center justify-center rounded-full"
        style={{ backgroundColor: accentColor }}
        aria-hidden
      >
        {mainIconEl}
      </div>
      <Typography.TitleLg as="h3" color="text-gray-800">
        {title}
      </Typography.TitleLg>
      <Typography.BodyMd color="text-gray-800">{subtitle}</Typography.BodyMd>
      <ul className="flex flex-col gap-y-4">
        {checkedList.map(checkedItem => (
          <li className="flex items-start gap-x-3" key={checkedItem}>
            <div
              className="mt-0.5 flex h-5 w-5 items-center justify-center rounded-full"
              style={{ backgroundColor: accentColor, color: accentColor }}
            >
              <Icon
                type="20-checkmark-ring-transparent"
                aria-hidden
                size={20}
              />
            </div>
            <Typography.BodyMd color="text-gray-800">
              {checkedItem}
            </Typography.BodyMd>
          </li>
        ))}
      </ul>
    </div>
  </div>
);
