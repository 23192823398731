import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoaderRow } from "@circle-react/components/shared/LoaderRow";
import { CommunityMember as CommunityMemberV2 } from "./CommunityMemberV2";

export const ListView = ({
  memberDataParams,
  shouldShowHiddenMemberTooltip,
  viewType,
  spaceGroup,
  space,
}) => {
  const {
    isActiveQuery,
    dataLength,
    fetchNextPage,
    hasNextPage,
    members,
    refetch,
  } = memberDataParams;

  return (
    <div className="member-directory-members">
      {isActiveQuery && (
        <div className="member-directory-members__count">
          {I18n.t("members_directory.header.search.members_found", {
            count: dataLength,
          })}
        </div>
      )}
      <InfiniteScroll
        style={{ overflow: "initial" }}
        scrollThreshold={0.5}
        next={fetchNextPage}
        hasMore={!!hasNextPage}
        loader={<LoaderRow />}
        dataLength={members.length}
        scrollableTarget="panel"
      >
        <ul
          className="flex list-none flex-col gap-y-4"
          data-testid="directory_members_list"
        >
          {members.map(communityMember => (
            <CommunityMemberV2
              communityMember={communityMember}
              key={communityMember.id}
              shouldShowHiddenMemberTooltip={shouldShowHiddenMemberTooltip}
              viewType={viewType}
              spaceGroup={spaceGroup}
              space={space}
              refetch={refetch}
            />
          ))}
        </ul>
      </InfiniteScroll>
    </div>
  );
};

ListView.propTypes = {
  memberDataParams: PropTypes.shape({
    isActiveQuery: PropTypes.bool,
    dataLength: PropTypes.number,
    fetchNextPage: PropTypes.func,
    hasNextPage: PropTypes.bool,
    members: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      }),
    ),
    refetch: PropTypes.func,
  }),
  shouldShowHiddenMemberTooltip: PropTypes.bool,
  viewType: PropTypes.string,
  spaceGroup: PropTypes.shape({}),
  space: PropTypes.shape({}),
};
