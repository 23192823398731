import PropTypes from "prop-types";
import classNames from "classnames";

export const TabViewDisplay = ({
  children,
  className,
  currentTab,
  tab,
  ...rest
}) => (
  <div
    className={classNames(
      "h-full w-full overflow-y-auto",
      {
        hidden: currentTab !== tab,
      },
      className,
    )}
    aria-hidden={currentTab !== tab}
    {...rest}
  >
    {children}
  </div>
);

TabViewDisplay.propTypes = {
  tab: PropTypes.string,
  className: PropTypes.string,
  currentTab: PropTypes.string,
  children: PropTypes.node,
};
