import { isEmpty } from "lodash";
import flatten from "lodash/flatten";
import { useInfiniteQuery } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useMemberFilterUrl } from "@circle-react-shared/MemberFilters/useMemberFilterUrlV2";
import { SORT_OPTIONS } from "../../constants";

export const MEMBER_INFINITE_QUERY_REQUEST_KEY = "community-members";

export interface UseMembersInfiniteQueryV2Props {
  defaultSort?: string;
  scopeResults?: object;
  per_page?: number;
}

export const useMembersInfiniteQueryV2 = ({
  defaultSort = SORT_OPTIONS[0],
  scopeResults = {},
  per_page = 30,
}: UseMembersInfiniteQueryV2Props) => {
  const {
    queryParams: { filters, sort = defaultSort, ...rest },
  } = useMemberFilterUrl();

  const fetchPage = ({ pageParam = { page: 1, searchAfter: [] } }) =>
    reactQueryGet(
      internalApi.search.communityMembers.indexV2({
        params: {
          page: pageParam.page,
          search_after: pageParam.searchAfter,
          sort,
          per_page,
          filters,
          ...scopeResults,
        },
      }),
    );

  const result = useInfiniteQuery(
    [MEMBER_INFINITE_QUERY_REQUEST_KEY, sort, filters, rest, scopeResults],
    fetchPage,
    {
      getNextPageParam: lastPageAttributes => {
        const { has_next_page, page, next_search_after } = lastPageAttributes;
        const nextPage = has_next_page ? page + 1 : undefined;
        return nextPage === undefined
          ? nextPage
          : { page: nextPage, searchAfter: next_search_after };
      },
    },
  );

  const pages = result.data?.pages || [];
  const dataLength = pages[0]?.count || 0;
  const totalMembers = pages[0]?.meta?.total_count || 0;
  const members = flatten(pages.map(page => page.records));

  return {
    ...result,
    dataLength,
    totalMembers,
    members,
    isActiveQuery: !isEmpty(filters),
  };
};
