import { createContext, useContext } from "react";
import invariant from "tiny-invariant";
import type { RenderFilter, RenderProfileFieldFilter } from "../types";
import type { useDynamicFilters } from "./useDynamicFilters";

interface DynamicFilterContextValue
  extends ReturnType<typeof useDynamicFilters> {
  platformFilters: RenderFilter[];
  profileFieldFilters?: RenderProfileFieldFilter[];
}

/**
 * This context holds all utility functions for storing and managing dynamic filters.
 * Value of filter is not stored here.
 */
export const DynamicFilterContext =
  createContext<DynamicFilterContextValue | null>(null);

DynamicFilterContext.displayName = "DynamicFilterContext";

export const useDynamicFilterContext = () => {
  const context = useContext(DynamicFilterContext);
  invariant(
    context,
    "useDynamicFilterContext must be used within a DynamicFilterContextProvider",
  );
  return context;
};
