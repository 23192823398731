import { createContext, useContext } from "react";
import invariant from "tiny-invariant";
import type { BaseFilter } from "../types";

interface FilterValueContextValue {
  filterParams: BaseFilter[];
  onApply?: (id: string, filter: BaseFilter) => void;
  onClearAll?: () => void;
  onClear?: (index: number) => void;
  onAdd?: (filter: BaseFilter) => void;
}

/**
 * This context holds value of a filter and utilities to update it.
 */
export const FilterValueContext = createContext<FilterValueContextValue | null>(
  null,
);

FilterValueContext.displayName = "FilterValueContext";

export const useFilterValueContext = () => {
  const context = useContext(FilterValueContext);

  invariant(
    context,
    "useFilterValueContext must be used within a FilterValueContext",
  );
  return context;
};
