import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import invariant from "tiny-invariant";
import { useGetBroadcast } from "@circle-react/components/SettingsApp/Emails/hooks/useGetBroadcast";

const BroadcastDataContext = createContext<ReturnType<
  typeof useGetBroadcast
> | null>(null);

BroadcastDataContext.displayName = "BroadcastDataContext";

interface BroadcastDataContextProviderProps {
  id: number;
  children: ReactNode;
}

export const useBroadcastData = () => {
  const context = useContext(BroadcastDataContext);
  invariant(
    context,
    "useBroadcastData must be used within a BroadcastDataContextProvider",
  );
  return context;
};

export function BroadcastDataProvider({
  id,
  children,
}: BroadcastDataContextProviderProps) {
  const broadcastProps = useGetBroadcast({ id });

  return (
    <BroadcastDataContext.Provider value={broadcastProps}>
      {children}
    </BroadcastDataContext.Provider>
  );
}
