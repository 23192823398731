import { t } from "@/i18n-js/instance";

export const PlatformColumn = (cellContext: any) => {
  const {
    row: { original: charge },
  } = cellContext;

  return (
    <div>
      {t(
        `settings.paywalls_admin_dashboard.charges.platform.${charge.platform}`,
      )}
    </div>
  );
};
