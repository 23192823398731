import { useCallback } from "react";
import { omit } from "lodash";
import { stringify } from "qs";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useFilterUrlV2 } from "@circle-react-shared/Filter";
import { getIsValueEmpty } from "./getIsValueEmpty";
import type { BaseFilter } from "./types";

const paramsSchema = yup.object().shape({
  page: yup.number().integer().min(1).nullable(),
  filters: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().defined(),
        key: yup.string().defined(),
        filter_type: yup.string(),
        value: yup.mixed(),
        profile_field_id: yup.number(),
        profile_field_type: yup.string(),
      }),
    )
    .nullable()
    .ensure(),
  /* Manage Members */
  order: yup.object(),
  /* Member Directory */
  sort: yup.string().oneOf(["oldest", "latest", "alphabetical"]).nullable(),
});

export const useMemberFilterUrl = (): any => {
  const params = useFilterUrlV2(paramsSchema);
  const history = useHistory();
  const { queryParams } = params;

  const updateUrl = useCallback(
    (id: string, newFilterParam: BaseFilter) => {
      const getFilter = () => {
        const { filters } = queryParams;
        if (getIsValueEmpty(newFilterParam)) {
          return filters.filter(filterParam => id !== filterParam.id);
        }
        const hasIdAlready = filters.some(filterParam => id === filterParam.id);
        const newFilterParamForUrl = omit(newFilterParam, ["isInitialOpen"]);
        if (hasIdAlready) {
          return filters.map(filterParam => {
            if (filterParam.id === id) {
              return {
                ...filterParam,
                ...newFilterParamForUrl,
              };
            }
            return filterParam;
          });
        }
        return [...filters, newFilterParamForUrl];
      };
      history.push({
        search: stringify({
          ...queryParams,
          page: 1,
          filters: getFilter(),
        }),
      });
    },
    [history, queryParams],
  );

  return {
    ...params,
    updateUrl,
    filterParams: params.queryParams?.filters ?? [],
  };
};
