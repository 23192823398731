import type { ComponentPropsWithRef } from "react";
import { forwardRef } from "react";
import classNames from "classnames";

export interface CanvasProps extends ComponentPropsWithRef<"div"> {}

export const CanvasCard = forwardRef<HTMLDivElement, CanvasProps>(
  ({ children, className, ...rest }, ref) => (
    <div
      ref={ref}
      className={classNames(
        "border-primary bg-primary relative isolate h-full w-full overflow-hidden rounded-lg border shadow-lg",
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  ),
);
CanvasCard.displayName = "CanvasCard";
