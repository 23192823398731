import type { PropsWithChildren } from "react";
import { Transition } from "@headlessui/react";

interface MobileFiltersTransitionWrapperProps {
  shouldRenderMobileFiltersTransition?: boolean;
}

export const MobileFiltersTransitionWrapper = ({
  children,
  shouldRenderMobileFiltersTransition,
}: PropsWithChildren<MobileFiltersTransitionWrapperProps>) => {
  // In other cases where the component is used but the parent does not pass a shouldRenderMobileFiltersTransition prop, we are not going to render the transition
  if (shouldRenderMobileFiltersTransition === undefined) return children;

  return (
    <Transition
      show={shouldRenderMobileFiltersTransition}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-out duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {children}
    </Transition>
  );
};
