import { createContext, useContext } from "react";

interface ExportCsvModalContextProp {
  relation: "charges" | "subscriptions";
  isLoading: boolean;
}

export const ExportCsvModalContext =
  createContext<ExportCsvModalContextProp | null>(null);

ExportCsvModalContext.displayName = "ExportCsvModalContext";

export const useExportCsvModalContext = () => {
  const context = useContext(ExportCsvModalContext);

  if (!context) {
    throw new Error(
      "useExportCsvModalContext must be used within a ExportCsvModalContext",
    );
  }

  return context;
};
