import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";

export const CreatedAtColumn = (cellContext: any) => {
  const {
    row: { original: charge },
  } = cellContext;

  const dateTime = new Date(charge.created_at);
  const dateLongFormat = formattedDateTime({
    dateTime,
    format: "long_date",
  });
  const shortTimeFormat = formattedDateTime({
    dateTime,
    format: "short_time",
  });

  return (
    <div className="flex flex-col whitespace-normal">
      <div className="block lg:hidden">{shortTimeFormat}</div>
      <div className="hidden lg:block">{dateLongFormat}</div>
    </div>
  );
};
