import { useState } from "react";
import { t } from "@/i18n-js/instance";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { Typography } from "@circle-react-uikit/Typography";
import { useExportCsvModalContext } from "./ExportCsvModalContext";
import { SelectedFields } from "./SelectedFields";
import { FIELD_OPTIONS_TYPE } from "./constants";

const commonFields = [
  "paywall_id",
  "paywall_name",
  "paywall_price_id",
  "paywall_price_amount",
  "paywall_price_type",
  "paywall_price_interval",
  "currency",
  "coupon_code",
  "coupon_terms",
  "community_member_public_uid",
  "community_member_processor_id",
  "community_member_name",
  "community_member_email",
];

const allFieldsByRelation = {
  subscriptions: [
    "processor_id",
    "status",
    "charges_quantity",
    "total_amount_paid",
    "start_date",
    "renews_on",
    "trial_ends_at",
    "ends_at",
  ].concat(commonFields),
  charges: [
    "processor_id",
    "invoice_processor_id",
    "amount",
    "amount_refunded",
    "display_status_raw",
    "subscription_processor_id",
    "created_at",
    "refund_reason",
  ].concat(commonFields),
};

const defaultFieldsByRelation = {
  subscriptions: [
    "processor_id",
    "status",
    "start_date",
    "renews_on",
    "trial_ends_at",
    "ends_at",
    "currency",
    "community_member_email",
    "community_member_name",
    "community_member_processor_id",
    "community_member_public_uid",
    "coupon_code",
    "paywall_name",
    "paywall_price_amount",
    "paywall_price_type",
    "paywall_price_interval",
  ],
  charges: [
    "processor_id",
    "invoice_processor_id",
    "amount",
    "amount_refunded",
    "currency",
    "display_status_raw",
    "subscription_processor_id",
    "created_at",
    "community_member_email",
    "community_member_name",
    "community_member_processor_id",
    "community_member_public_uid",
    "coupon_code",
    "paywall_name",
    "paywall_price_type",
    "paywall_price_interval",
    "refund_reason",
  ],
};

export const FieldsForm = () => {
  const { relation } = useExportCsvModalContext();
  const options = [
    {
      label: t("export_paywall_csv.modal.options.default", {
        fields_available: defaultFieldsByRelation[relation].length,
      }),
      value: FIELD_OPTIONS_TYPE.DEFAULT,
    },
    {
      label: t("export_paywall_csv.modal.options.all", {
        fields_available: allFieldsByRelation[relation].length,
      }),
      value: FIELD_OPTIONS_TYPE.ALL,
    },
    {
      label: t("export_paywall_csv.modal.options.custom"),
      value: FIELD_OPTIONS_TYPE.CUSTOM,
    },
  ];

  const [fieldOptionsType, setFieldOptionsType] = useState(
    FIELD_OPTIONS_TYPE.DEFAULT,
  );

  const onChange = (value: any) => {
    setFieldOptionsType(value);
  };

  return (
    <div className="flex flex-col gap-y-4">
      <Typography.LabelMd weight="bold">
        {t("export_paywall_csv.modal.columns")}
      </Typography.LabelMd>
      <Dropdown
        onChange={onChange}
        options={options}
        size="full"
        className="w-full rounded-lg border px-4 py-2"
        buttonWrapperClassName="flex flex-col"
        linkType="button"
        transitionWrapperClassName="px-4"
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [34, 14],
              },
            },
          ],
        }}
        button={
          <Dropdown.ActiveLabelButton
            className="flex w-full justify-between"
            options={options}
            activeOptionValue={fieldOptionsType}
          />
        }
      />
      <SelectedFields
        fieldOptionsType={fieldOptionsType}
        defaultFields={defaultFieldsByRelation[relation]}
        allFields={allFieldsByRelation[relation]}
      />
    </div>
  );
};
