import { t } from "@/i18n-js/instance";
import { Tab } from "@circle-react-uikit/TabV2";
import { RecipientTab } from "./RecipientsTab";

const localeNamespace =
  "settings.emails.view_broadcast.reports_tab.recipients_table";

const RECIPIENT_TABS = [
  "accepted",
  "opened",
  "clicked",
  "bounce",
  "unsubscribe",
  "complained",
];

export const RecipientsTabList = () => (
  <Tab.Group className="mt-5 grid h-full grid-rows-[min-content_1fr]">
    <Tab.ListWrapper className="!px-0">
      <Tab.List listClassName="md:flex-row flex-col">
        {RECIPIENT_TABS.map((tab: string) => (
          <Tab className="text-sm" key={tab}>
            {t([localeNamespace, tab])}
          </Tab>
        ))}
      </Tab.List>
    </Tab.ListWrapper>
    <Tab.Panels>
      {RECIPIENT_TABS.map((tab: string) => (
        <Tab.Panel className="overflow-hidden" key={tab}>
          <RecipientTab event={tab} />
        </Tab.Panel>
      ))}
    </Tab.Panels>
  </Tab.Group>
);
