import classNames from "classnames";
import { useBroadcastData } from "../BroadcastDataContext";
import { PreviewActions } from "./PreviewActions";
import { PreviewContent } from "./PreviewContent";
import { PreviewHeader } from "./PreviewHeader";

interface EmailPreviewProps {
  shouldShowActions?: boolean;
}

export const EmailPreview = ({
  shouldShowActions = false,
}: EmailPreviewProps) => {
  const { data } = useBroadcastData();

  return (
    <section
      className={classNames("grid h-full min-h-0", {
        "grid-rows-[auto_1fr_auto]": shouldShowActions,
        "grid-rows-[auto_1fr]": !shouldShowActions,
      })}
    >
      <PreviewHeader
        previewText={data?.broadcast_content?.preview_text}
        subject={data?.broadcast_content?.subject}
      />
      <PreviewContent content={data?.broadcast_content?.email_content} />
      {shouldShowActions && <PreviewActions />}
    </section>
  );
};
