import { memo, useMemo } from "react";
import type { CSSProperties, ReactNode } from "react";
import type { ResponsiveFrameProps } from "@circle-react-shared/ResponsiveFrame";
import { ResponsiveFrame } from "@circle-react-shared/ResponsiveFrame";

const getParentStylesHTML = () => {
  let styleElementsHTML = "";

  const stylesheetLinks = Array.from(
    document.querySelectorAll("link[rel=stylesheet]"),
  );
  const headStyleTags = Array.from(document.querySelectorAll("head style"));

  stylesheetLinks.forEach(link => {
    styleElementsHTML += link.outerHTML;
  });

  headStyleTags.forEach(styleElement => {
    styleElementsHTML += styleElement.outerHTML;
  });

  return styleElementsHTML;
};

const getSvgSpriteHTML = () => {
  const spriteElement = document.getElementById("icon-svg-sprite");
  return spriteElement?.outerHTML ?? "";
};

const mountPointId = "frame-mount-point";
const mountPointSelector = `#${mountPointId}`;

export interface InitialContent {
  headStart?: string;
  headEnd?: string;
  bodyStart?: string;
  bodyEnd?: string;
}

const getInitialContent = ({
  headStart = "",
  headEnd = "",
  bodyStart = "",
  bodyEnd = "",
}: InitialContent = {}) => `
  <!DOCTYPE html>
  <html>
    <head>
      ${headStart}
      ${getParentStylesHTML()}
      <style>
        :root {
          --font-system-ui: InterVariable;
        }
        ${mountPointSelector} .frame-content {
          width: 100%;
          height: 100%;
          position: relative;
          isolation: isolate;
        }
      </style>
      ${headEnd}
    </head>
    <body class="w-full h-full">
      ${bodyStart}
      ${getSvgSpriteHTML()}
      <div id="${mountPointId}" class="h-screen"></div>
      ${bodyEnd}
    </body>
  </html>
`;

export interface StyledFrameProps extends ResponsiveFrameProps, InitialContent {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

export const StyledFrame = memo(
  ({
    children,
    headStart,
    headEnd,
    bodyStart,
    bodyEnd,
    ...rest
  }: StyledFrameProps) => {
    const initialContent = useMemo(
      () => getInitialContent({ headStart, headEnd, bodyStart, bodyEnd }),
      [headStart, headEnd, bodyStart, bodyEnd],
    );

    return (
      <ResponsiveFrame
        initialContent={initialContent}
        mountTarget={mountPointSelector}
        {...rest}
      >
        {children}
      </ResponsiveFrame>
    );
  },
);

StyledFrame.displayName = "StyledFrame";
