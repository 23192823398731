import type { PropsWithChildren } from "react";
import { FeatureItem } from "./FeatureItem";
import { FeatureList } from "./FeatureList";
import { FeaturePreview } from "./FeaturePreview";
import { FeatureSummary } from "./FeatureSummary";
import { Header } from "./Header";

const BlocksComponent = ({ children }: PropsWithChildren<object>) => (
  <div className="flex w-full flex-col gap-x-6 gap-y-8 md:flex-row md:gap-y-0">
    {children}
  </div>
);

export const Blocks = Object.assign(BlocksComponent, {
  FeatureItem,
  FeatureList,
  FeatureSummary,
  FeaturePreview,
  Header,
});
