import { DataTable } from "@circle-react-uikit/DataTableV2";
import { usePaywallSubscriptionList } from "./hooks/usePaywallSubscriptionList";

export interface PaywallSubscriptionList {
  columnsToRender: any;
  subscriptionData: any;
  onClickPagination: (page: number) => void;
}

export const PaywallSubscriptionList = ({
  columnsToRender,
  subscriptionData,
  onClickPagination,
}: PaywallSubscriptionList) => {
  const { onChangeSorting } = usePaywallSubscriptionList({ subscriptionData });

  return (
    <DataTable.TableWrapper>
      <DataTable
        onChangeSorting={onChangeSorting}
        columns={columnsToRender}
        data={subscriptionData?.subscriptions ?? []}
        isLoading={subscriptionData?.isLoadingSubscriptions}
        initialPage={subscriptionData?.page ?? 1}
        onChangePage={onClickPagination}
        totalRecordsCount={subscriptionData?.totalSubscriptions}
        pageSize={subscriptionData.perPage}
      >
        <DataTable.Table>
          <DataTable.SortableHead />
          <DataTable.Loader />
          <DataTable.Body />
        </DataTable.Table>
        <DataTable.Pagination align="left" direction="reverse" />
      </DataTable>
    </DataTable.TableWrapper>
  );
};
