import type { ReactNode } from "react";
import type { AccessGroupEntryPoint } from "@circle-react/helpers/accessGroups/accessGroupHelpers";
import { useListAccessGroupSourcesQuery } from "@circle-react/hooks/accessGroups/useListAccessGroupSourcesQuery";
import { useAccessGroups } from "@circle-react/hooks/accessRegistry/useAccessGroups";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { Form } from "@circle-react-uikit/Form";
import { useAccessGroupConfirmationModal } from "./AccessGroupConfirmationModal";
import { AccessGroupContent } from "./AccessGroupContent";
import { AccessGroupHeader } from "./AccessGroupHeader";
import { AccessGroupSourcesDataProvider } from "./DataStoreContext/AccessGroupSourcesDataContext";
import { useUpdateAccessGroupsSources } from "./hooks/useUpdateAccessGroupsSources";

interface AccessGroupsProps {
  description?: string;
  recordType: AccessGroupEntryPoint;
  recordId?: number | undefined;
  spaceAccessNode?: ReactNode;
  isAccessGroupsTabEnabled?: boolean;
  areFormItemsEmbedded?: boolean;
  canRemoveAllAccessGroups?: boolean;
  isSyncing?: boolean;
  syncingTooltip?: string;
  title?: string;
}
export const tabs = Object.freeze({
  access_groups: "access_groups",
  space_access: "space_access",
});

export const AccessGroups = ({
  description,
  recordType,
  recordId,
  spaceAccessNode,
  title,
  isAccessGroupsTabEnabled = true,
  canRemoveAllAccessGroups = true,
  areFormItemsEmbedded = false,
  isSyncing = false,
  syncingTooltip = "",
}: AccessGroupsProps) => {
  const { accessGroupSources, isLoadingAccessGroupSources } =
    useListAccessGroupSourcesQuery({
      recordType: recordType,
      recordId: recordId,
    });

  const { data: accessGroups, isLoading: isLoadingAccessGroups } =
    useAccessGroups();

  const { udpateAccessGroupSourcesHandler, isUpdatingAccessGroupSources } =
    useUpdateAccessGroupsSources();

  const accessConfirmationModal = useAccessGroupConfirmationModal();

  if (isLoadingAccessGroupSources || isLoadingAccessGroups) {
    return <Loader />;
  }

  const getDefaults = () => ({
    access_groups: [],
    recordType: recordType,
    recordId: recordId,
    access_groups_enabled: isAccessGroupsTabEnabled,
  });

  const updateRecords = (data: any) => {
    udpateAccessGroupSourcesHandler({
      access_groups: data.access_groups.map((accessGroup: any) => ({
        id: accessGroup.id,
        status: accessGroup.status,
      })),
      record_type: data.recordType,
      record_id: data.recordId,
    });
  };

  const formContent = (
    <AccessGroupContent
      isUpdatingAccessGroupSources={isUpdatingAccessGroupSources}
      spaceAccessNode={spaceAccessNode}
    />
  );

  const handleSubmit = (data: any) => {
    if (spaceAccessNode && !isAccessGroupsTabEnabled) {
      void accessConfirmationModal.show({
        i18nRoot:
          "settings.access_groups.admin.entry_points.association.actions.change_to_access_groups_confirmation",
        onConfirm: () => {
          updateRecords(data);
        },
      });
    } else {
      updateRecords(data);
    }
  };

  return (
    <AccessGroupSourcesDataProvider
      accessGroups={accessGroups?.records ?? []}
      originalAccessGroupSources={accessGroupSources ?? []}
      recordType={recordType}
      recordId={recordId}
      areFormItemsEmbedded={areFormItemsEmbedded}
      canRemoveAllAccessGroups={canRemoveAllAccessGroups}
      isSyncing={isSyncing}
      syncingTooltip={syncingTooltip}
    >
      <AccessGroupHeader
        description={description}
        title={title}
        areFormItemsEmbedded={areFormItemsEmbedded}
      />

      {areFormItemsEmbedded ? (
        formContent
      ) : (
        <Form
          defaultValues={getDefaults()}
          onSubmit={data => handleSubmit(data)}
        >
          {formContent}
        </Form>
      )}
    </AccessGroupSourcesDataProvider>
  );
};
