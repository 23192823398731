import { t } from "@/i18n-js/instance";
import { useIsProfileFieldsEnabled } from "@circle-react-shared/ProfileFields";
import { AddFilterDropdown } from "../AddFilterDropdown";
import { useDynamicFilterContext } from "../DynamicFilterContext";
import type { AddFilterArgs } from "../useDynamicFilters";
import { FiltersList } from "./FiltersList";
import { ProfileFieldUpgradeMessage } from "./ProfileFieldUpgradeMessage";

interface AllFiltersListProps {
  label?: string;
}

/**
 * This contains two sections:
 * 1. Platform filters
 * 2. Profile field filters
 * If the use case requires more sections, use a different renderer component.
 */
export const AllFiltersList = ({ label }: AllFiltersListProps) => {
  const isProfileFieldsEnabled = useIsProfileFieldsEnabled();
  const {
    platformFilters,
    profileFieldFilters = [],
    onAddFilter,
  } = useDynamicFilterContext();

  return (
    <AddFilterDropdown label={label}>
      <div className="flex max-h-96 flex-col gap-y-4 overflow-y-auto">
        <FiltersList
          title={t("filters.standard")}
          filters={platformFilters}
          onAddFilter={onAddFilter}
        />
        <ProfileFieldUpgradeMessage />
        {isProfileFieldsEnabled && (
          <FiltersList
            title={t("filters.custom")}
            filters={profileFieldFilters}
            onAddFilter={(params: AddFilterArgs) =>
              onAddFilter({ ...params, key: "profile_field" })
            }
          />
        )}
      </div>
    </AddFilterDropdown>
  );
};
