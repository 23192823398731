import { t } from "@/i18n-js/instance";

export const ToggleSwitch = ({
  field,
  value,
  onToggleChange,
  label = t("checkbox"),
}) => (
  <div className="simple-toggle" id={`field-${field}`}>
    <input
      type="checkbox"
      name={field}
      id={`checkbox-${field}`}
      checked={value}
      onChange={e => onToggleChange(e.target.checked)}
    />
    <label htmlFor={`checkbox-${field}`}>
      <span className="sr-only">{label}</span>
    </label>
  </div>
);
