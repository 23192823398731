import { useMemo } from "react";
import { isProfileFieldBaseFilter } from "@circle-react-shared/MemberFilters/types";
import { useDynamicFilterContext } from "../DynamicFilterContext";

export function useAvailableFilters() {
  const {
    platformFilters,
    profileFieldFilters = [],
    displayedFilters,
  } = useDynamicFilterContext();

  return useMemo(() => {
    /* If a filter is on displayed list, then remove it from platformFilters/profileFieldFilters */
    const availablePlatformFilters = platformFilters.filter(
      platformFilter =>
        !displayedFilters.some(
          displayedFilter => displayedFilter.key === platformFilter.name,
        ),
    );
    const availableProfileFieldFilters = profileFieldFilters.filter(
      profileFieldFilter =>
        !displayedFilters.some(displayedFilter => {
          if (!isProfileFieldBaseFilter(displayedFilter)) return false;
          return displayedFilter.profile_field_id === profileFieldFilter.id;
        }),
    );
    return {
      availablePlatformFilters,
      availableProfileFieldFilters,
      hasAvailableFilters:
        availablePlatformFilters.length > 0 ||
        availableProfileFieldFilters.length > 0,
    };
  }, [displayedFilters, platformFilters, profileFieldFilters]);
}
