import { Typography } from "@circle-react-shared/uikit/Typography";

export const Item = ({
  title,
  titleWeight = "semibold",
  subtitle,
  children,
}: any) => (
  <li className="flex w-full flex-row justify-between gap-y-4 self-center">
    <div className="flex flex-col gap-y-1">
      <Typography.LabelMd weight={titleWeight}>{title}</Typography.LabelMd>
      {subtitle && (
        <Typography.LabelXs color="text-light">{subtitle}</Typography.LabelXs>
      )}
    </div>
    {children}
  </li>
);
