import { t } from "@/i18n-js/instance";
import { useSendTestEmail } from "@circle-react/components/SettingsApp/Emails/hooks/useSendTestEmail";
import { FullScreenModal } from "@circle-react-shared/ModalsV2/FullScreenModal";
import { Button } from "@circle-react-shared/uikit/Button";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Typography } from "@circle-react-shared/uikit/Typography";

const localeNamespace = "settings.emails.builder";

interface EmailEditorHeaderProps {
  isSubmitting: boolean;
  onSubmit: () => Promise<void>;
  onClose: () => void;
}

export const EmailEditorHeader = ({
  isSubmitting,
  onSubmit,
  onClose,
}: EmailEditorHeaderProps) => {
  const { handleTestEmail } = useSendTestEmail();

  const sendTestEmail = async () => {
    await onSubmit();
    await handleTestEmail();
  };

  const saveAndClose = async () => {
    await onSubmit();
    onClose();
  };

  return (
    <FullScreenModal.Header className="md:!py-4">
      <div className="divide-primary flex items-center divide-x">
        <IconButton
          name="20-arrow-left"
          iconSize={20}
          buttonSize={28}
          className="text-default me-4"
          iconClassName="w-5 h-5"
          ariaLabel={t("back")}
          onClick={onClose}
        />
        <div className="ps-4">
          <Typography.TitleSm weight="semibold" color="text-darkest">
            {t([localeNamespace, "email_editor"])}
          </Typography.TitleSm>
        </div>
      </div>
      <div className="flex shrink-0 gap-x-4">
        <Button variant="secondary" type="button" large onClick={sendTestEmail}>
          {t([localeNamespace, "send_a_test"])}
        </Button>
        <Button
          variant="circle"
          type="button"
          large
          disabled={isSubmitting}
          onClick={saveAndClose}
        >
          {isSubmitting ? t("saving") : t([localeNamespace, "save_and_exit"])}
        </Button>
      </div>
    </FullScreenModal.Header>
  );
};
