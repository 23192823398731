import { useEffect, useState } from "react";
import {
  usePunditUserContext,
  useSpaceGroupsContext,
  useSpacesContext,
} from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { isSpaceVisibleOnSidebar } from "@circle-react/helpers/spaceHelpers";

export const useSpaceGroupsList = ({ valueKey = "slug" } = {}) => {
  const [visibleSpaceGroups, setVisibleSpaceGroups] = useState([]);
  const { currentCommunityMember, isCommunityLoading } = usePunditUserContext();

  const {
    helpers: { isDataLoading: isSpaceGroupsDataLoading },
    records: spaceGroups,
  } = useSpaceGroupsContext();
  const {
    helpers: { isDataLoading: isSpacesDataLoading, spaceVisibleOnSidebar },
  } = useSpacesContext();
  const spaces = spaceVisibleOnSidebar();
  const isLoading =
    isSpacesDataLoading() || isSpaceGroupsDataLoading() || isCommunityLoading;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setVisibleSpaceGroups(
      spaceGroups.filter(spaceGroup => {
        if (isCommunityAdmin(currentCommunityMember)) {
          return true;
        }

        spaces.some(
          space =>
            space.space_group_id === spaceGroup.id &&
            isSpaceVisibleOnSidebar({ space, spaceGroup }),
        );
      }),
    );
  }, [isLoading, spaces, spaceGroups, currentCommunityMember]);

  if (isLoading && !visibleSpaceGroups.length) {
    return [];
  }

  return {
    visibleSpaceGroups: visibleSpaceGroups.map(record => ({
      label: record.name,
      value: record[valueKey],
    })),
    isLoading,
  };
};
