import { t } from "@/i18n-js/instance";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Typography } from "@circle-react-shared/uikit/Typography";

const localeNamespace =
  "settings.emails.view_broadcast.reports_tab.recipients_table";

const BACKGROUND_COLORS = {
  warn: "bg-v2-warning-100",
  error: "bg-v2-danger-100",
  info: "bg-tertiary",
};

const FONT_COLORS = {
  warn: "text-v2-warning",
  error: "text-v2-danger",
  info: "text-default",
};

export type EventBadgeType = "info" | "warn" | "error";
interface EventBadgeProps {
  event: string;
  eventType: EventBadgeType;
}

export const EventBadge = ({ event, eventType }: EventBadgeProps) => (
  <div
    className={classNames(
      "flex h-[20px] w-fit max-w-[150px] items-center space-x-2 rounded-[28px] px-[6px] py-[2px]",
      BACKGROUND_COLORS[eventType],
    )}
  >
    <Typography.LabelXs weight="medium" color={FONT_COLORS[eventType]}>
      {t([localeNamespace, event])}
    </Typography.LabelXs>
  </div>
);
