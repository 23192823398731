import type { ContextType, ReactNode } from "react";
import { Fragment, useState } from "react";
import { Popover } from "@headlessui/react";
import { FilterContext } from "./FilterContext";

type OnApply = NonNullable<ContextType<typeof FilterContext>>["onApply"];

export function Filter({
  children,
  onApply,
}: {
  children: ReactNode;
  onApply: OnApply;
}) {
  const [referenceElement, setReferenceElement] =
    useState<HTMLLIElement | null>(null);

  return (
    <Popover as={Fragment}>
      {({ open, close }) => {
        const handleApply: OnApply = value => {
          onApply(value);
          close();
        };

        return (
          <FilterContext.Provider
            value={{
              open,
              referenceElement,
              setReferenceElement,
              onApply: handleApply,
            }}
          >
            {children}
          </FilterContext.Provider>
        );
      }}
    </Popover>
  );
}
