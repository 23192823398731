import type { ReactNode } from "react";
import { Popover } from "@headlessui/react";
import { classNames } from "@/react/helpers/twMergeWithCN";
import { Icon } from "../Icon";
import { AddonFilterChip } from "./AddonFilterChip";
import { useFilterContext } from "./FilterContext";

interface FilterChipProps {
  children: ReactNode;
  renderSelectedValue: () => ReactNode;
  shouldShowClearButton?: boolean;
}

export function FilterChip({
  children,
  shouldShowClearButton,
  renderSelectedValue,
}: FilterChipProps) {
  const { setReferenceElement, open: isFocused } = useFilterContext();
  const valueNode = renderSelectedValue();
  const hasValue = Boolean(valueNode);

  return (
    <Popover.Button
      ref={setReferenceElement}
      className="focus-visible:ring-secondary rounded-3xl focus-visible:ring-1"
      as="li"
    >
      <button
        type="button"
        className={classNames(
          "hover:border-secondary focus-visible:border-secondary disabled:hover:border-primary flex h-6 items-center rounded-3xl border px-2 text-xs transition-colors disabled:cursor-not-allowed disabled:opacity-50",
          isFocused
            ? "text-dark border-secondary"
            : "text-default border-primary",
          { "text-dark": Boolean(hasValue) },
        )}
      >
        <div className="divide-selector-inactive flex divide-x-[1.25px]">
          <div className="flex items-center gap-x-1 pr-2">
            <AddonFilterChip
              hasValue={hasValue}
              shouldShowClearButton={shouldShowClearButton}
            />
            {children}
          </div>
          {hasValue && (
            <div className="text-v2-info flex items-center gap-x-1 pl-2">
              <div className="max-w-[32ch] truncate">{valueNode}</div>
              <Icon
                type="16-chevron-down"
                size={12}
                className="text-default mb-1"
              />
            </div>
          )}
        </div>
      </button>
    </Popover.Button>
  );
}
