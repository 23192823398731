import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { useResumePaywallSubscriptionApi } from "./useResumePaywallSubscriptionApi";

export const useResumePaywallSubscriptionModal = ({
  subscription,
  onSuccess,
}: any) => {
  const modal = useModal();
  const { resumeMutation } = useResumePaywallSubscriptionApi({
    callback: {
      onSuccess: () => {
        onSuccess && onSuccess();
        void modal.hide();
      },
    },
  });

  const handleSubmit = () => {
    resumeMutation.mutate(subscription.processor_id);
  };

  return {
    handleSubmit,
    isSubmitting: resumeMutation.isLoading,
    onClose: () => !resumeMutation.isLoading && modal.hide(),
    modal,
  };
};
