import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import isFunction from "lodash/isFunction";
import { t } from "@/i18n-js/instance";
import { SanitizeHTMLString } from "@circle-react/components/shared/uikit/SanitizeContent";
import { Button } from "@circle-react-uikit/Button";
import { Input } from "@circle-react-uikit/Input";
import { Modal } from "@circle-react-uikit/ModalV2";

export interface SecureConfirmationModalProps {
  title: string;
  cancelText?: string;
  confirmText?: string;
  confirmUserPrompt?: string;
  confirmVariant?: "primary" | "danger";
  isOpen: boolean;
  isDisabled?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
  children?: ReactNode;
}

const i18nRoot = "modals.secure_confirmation";

export const SecureConfirmationModal = ({
  title,
  cancelText = t([i18nRoot, "cancel"]),
  confirmText = t([i18nRoot, "confirm"]),
  confirmUserPrompt = t([i18nRoot, "confirm_prompt"]),
  confirmVariant = "primary",
  isDisabled,
  isOpen,
  onClose,
  onConfirm,
  onCancel,
  children,
}: SecureConfirmationModalProps) => {
  const [shouldDisableButton, setShouldDisableButton] = useState(false);
  const [userInput, setUserInput] = useState("");

  useEffect(() => {
    setShouldDisableButton(
      userInput !== confirmUserPrompt || Boolean(isDisabled),
    );
  }, [userInput, confirmUserPrompt, isDisabled]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setUserInput("");
        isFunction(onClose) && onClose();
      }}
    >
      <Modal.Overlay />

      <Modal.Content size="md" wrapperClassName="!h-full">
        <Modal.Header>
          <Modal.Title size="md">{title}</Modal.Title>
          <Modal.HeaderActions>
            <Modal.CloseButton
              onClick={() => {
                isFunction(onClose) && onClose();
                setUserInput("");
              }}
            />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body>
          {children}
          <div className="mt-6">
            <SanitizeHTMLString
              content={t([i18nRoot, "prompt_description"], {
                prompt: confirmUserPrompt,
              })}
            />

            <Input
              wrapperClassName="mt-4"
              type="text"
              value={userInput}
              onChange={(e: any) => setUserInput(e.target.value)}
              data-testid="secure-confirmation-input"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex items-stretch justify-end space-x-4">
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                setUserInput("");
                isFunction(onCancel) && onCancel();
              }}
            >
              {cancelText}
            </Button>
            <Button
              type="button"
              variant={confirmVariant}
              disabled={shouldDisableButton}
              onClick={() => {
                setUserInput("");
                isFunction(onConfirm) && onConfirm();
              }}
            >
              {confirmText}
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
