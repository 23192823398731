import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { BROADCAST_PERSONALIZATION_VARIABLES } from "@circle-react/components/SettingsApp/Emails/components/Broadcasts/constants";
import { PREVIEW_TEXT_MAX_LENGTH } from "@circle-react/components/SettingsApp/Emails/components/Builder/constants";
import { InputWithCounter } from "@circle-react-shared/InputWithCounter";
import { Form } from "@circle-react-shared/uikit/Form";
import { InsertVariableButton } from "@circle-react-shared/uikit/InsertVariableButton";
import { useInsertVariableForInput } from "@circle-react-shared/uikit/InsertVariableButton/useInsertVariableForInput";

const localeNamespace = "settings.emails.builder";

interface PreviewTextFieldProps {
  fieldName: string;
  onChange?: () => void;
}

export function PreviewTextField({
  fieldName,
  onChange,
}: PreviewTextFieldProps) {
  const { watch } = useFormContext<{ [key in typeof fieldName]: string }>();
  const previewTextValue = watch(fieldName);
  const { inputRef, setChangeHandler } = useInsertVariableForInput({
    value: previewTextValue,
  });

  return (
    <Form.Item
      isFirstChild
      hideBorder
      name={fieldName}
      hideDescription
      hideLabel
    >
      <InputWithCounter
        label={t([localeNamespace, "preview_text_label"])}
        maxCount={PREVIEW_TEXT_MAX_LENGTH}
        tooltipText={t([localeNamespace, "add_preview_text_description"])}
        onChange={onChange}
        ref={inputRef}
        className="form-control !pr-12"
      />
      <InsertVariableButton
        className="text-lightest dark:text-default"
        wrapperClassName="absolute right-4 top-9"
        optionsClassName="z-10 !w-60 right-2"
        variables={BROADCAST_PERSONALIZATION_VARIABLES}
        inputRef={inputRef}
        setInputChangeHandler={setChangeHandler}
      />
    </Form.Item>
  );
}
