import { noop } from "lodash";
import { t } from "@/i18n-js/instance";
import { ActionModal } from "@circle-react-shared/ModalsV2/ActionModal";
import { CustomerColumn } from "@circle-react-shared/Paywalls/CustomerColumn";
import { Form } from "@circle-react-uikit/Form";
import { Loader } from "@circle-react-uikit/Loader";
import { CancelOptionsForm } from "./Form/CancelOptionsForm";
import { RefundTypeForm } from "./Form/RefundTypeForm";
import { formId } from "./constants";
import { useCancelAndRefundSubscriptionModal } from "./hooks/useCancelAndRefundSubscriptionModal";

export interface CancelAndRefundPaywallSubscriptionModalProps {
  subscription: any;
  onSuccess: any;
}

export const CancelAndRefundPaywallSubscriptionModal = ({
  subscription,
  onSuccess,
}: CancelAndRefundPaywallSubscriptionModalProps) => {
  const {
    defaultValues,
    handleSubmit,
    modal,
    isSubmitting,
    onClose,
    isLoadingRefundOptionsSubscription,
    refundOptionsSubscriptionData,
  } = useCancelAndRefundSubscriptionModal({
    subscription,
    onSuccess,
  });

  return (
    <Form onSubmit={handleSubmit} defaultValues={defaultValues} id={formId}>
      <ActionModal
        title={t(
          "settings.paywalls_admin_dashboard.subscriptions.cancel_and_refund_modal.title",
        )}
        isOpen={modal.visible}
        onClose={onClose}
        onAction={noop}
        disabled={isSubmitting || isLoadingRefundOptionsSubscription}
        actionText={t(
          "settings.paywalls_admin_dashboard.subscriptions.cancel_and_refund_modal.cancel_button",
        )}
        actionVariant="danger"
        formId={formId}
      >
        <div className="pt-4">
          <CustomerColumn
            customer={subscription.community_member}
            shouldOpenAdminMemberEdit={false}
          />
          <CancelOptionsForm subscription={subscription} />
          {!isSubmitting && isLoadingRefundOptionsSubscription ? (
            <Loader />
          ) : (
            <RefundTypeForm
              refundOptionsSubscriptionData={refundOptionsSubscriptionData}
            />
          )}
        </div>
      </ActionModal>
    </Form>
  );
};
