import { useCallback } from "react";
import { isNil, omit } from "lodash";
import { stringify } from "qs";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useFilterUrlV2 } from "@circle-react-shared/Filter";
import type { SelectedText } from "@circle-react-shared/Filter/types";

const paramsSchema = yup.object().shape({
  page: yup.number().integer().min(1).nullable(),
  filter: yup.object().shape({
    profile_fields: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number().integer().min(1).nullable(),
          value: yup.mixed(),
        }),
      )
      .nullable()
      .ensure(),
  }),
  /* Manage Members */
  order: yup.object(),
  /* Member Directory */
  sort: yup.string().oneOf(["oldest", "latest", "alphabetical"]).nullable(),
});

export const useMemberFilterUrl = () => {
  const params = useFilterUrlV2(paramsSchema);
  const history = useHistory();
  const { queryParams } = params;

  const updateUrl = useCallback(
    (queryParam: string = "", filterValue: SelectedText = "") => {
      const getFilter = () => {
        const { filter = {} } = queryParams;
        if (isNil(filterValue) || filterValue === "") {
          return omit(filter, [queryParam]);
        }
        return {
          ...filter,
          [queryParam]: filterValue,
        };
      };
      history.push({
        search: stringify({
          ...queryParams,
          page: 1,
          filter: getFilter(),
        }),
      });
    },
    [history, queryParams],
  );

  const updateUrlForProfileField = useCallback(
    (queryParam: number, filterValue: SelectedText) => {
      const getProfileFields = () => {
        const { profile_fields } = queryParams.filter ?? {};
        const newProfileFields = profile_fields ?? [];
        if (isNil(filterValue) || filterValue === "") {
          return newProfileFields.filter(
            profile_field => profile_field.id !== queryParam,
          );
        }
        const existingProfileField = newProfileFields.find(
          profile_field => profile_field.id === queryParam,
        );
        if (existingProfileField) {
          return newProfileFields.map(profile_field => {
            if (profile_field.id === queryParam) {
              return {
                ...profile_field,
                value: filterValue,
              };
            }
            return profile_field;
          });
        }
        return [
          ...newProfileFields,
          {
            id: queryParam,
            value: filterValue,
          },
        ];
      };

      const getFilter = () => {
        const { filter = {} } = queryParams;
        return {
          ...filter,
          profile_fields: getProfileFields(),
        };
      };

      history.push({
        search: stringify({
          ...queryParams,
          page: 1,
          filter: getFilter(),
        }),
      });
    },
    [history, queryParams],
  );

  return {
    ...params,
    updateUrl,
    filterParams: params.queryParams?.filter ?? {},
    updateUrlForProfileField,
  };
};
