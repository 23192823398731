import { createContext, useContext, useState } from "react";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { ApplyingCouponStep } from "./Steps/ApplyingCouponStep";
import { PreviewCouponStep } from "./Steps/PreviewCouponStep";
import { SelectCouponStep } from "./Steps/SelectCouponStep";

const ApplyCouponContext = createContext<any>({});
ApplyCouponContext.displayName = "ApplyCouponContext";

export interface StepPropertyInterface {
  step: string;
  title: string;
  shouldHiddenCloseButton?: boolean;
  shouldHiddenBackButton?: boolean;
}

const defaultStep = {
  step: null,
  nextStep: null,
  title: "",
  shouldHiddenBackButton: false,
  shouldHiddenCloseButton: false,
};

export const ApplyCouponContextProvider = ({
  children,
  applyCouponMutation,
  removeCouponMutation,
  onSubmit,
  shouldRemoveCoupon,
}: any) => {
  const [pageHistoryStack, setPageHistoryStack] = useState<
    StepPropertyInterface[]
  >([
    shouldRemoveCoupon
      ? PreviewCouponStep.stepProperties
      : SelectCouponStep.stepProperties,
  ]);
  const modal = useModal();
  const onClose = () => {
    void modal.hide();
    modal.remove();
  };

  const clearAndSetNewPageHistory = (stepProperties: StepPropertyInterface) => {
    setPageHistoryStack([stepProperties]);
  };

  const backButtonOnClick = () => {
    setPageHistoryStack(pageHistoryStack.slice(0, -1));
  };
  const getCurrentPage = () => pageHistoryStack.at(-1) ?? defaultStep;
  const pushNewPageHistory = (stepProperties: StepPropertyInterface) => {
    setPageHistoryStack([...pageHistoryStack, stepProperties]);
  };
  const shouldHiddenBackButton =
    getCurrentPage().shouldHiddenBackButton ?? pageHistoryStack.length <= 1;
  const shouldHiddenCloseButton =
    getCurrentPage().shouldHiddenCloseButton ?? false;
  const title = getCurrentPage().title;
  const onCloseModal = () => {
    if (getCurrentPage().step === ApplyingCouponStep.stepProperties.step) {
      return;
    }
    onClose();
  };

  const valueToStore = {
    // Modal properties
    modal,
    onCloseModal,
    backButtonOnClick,
    shouldHiddenBackButton,
    shouldHiddenCloseButton,
    title,

    // Operations
    shouldRemoveCoupon,

    // Actions
    pushNewPageHistory,
    getCurrentPage,
    clearAndSetNewPageHistory,
    onClose,
    onSubmit,

    // Mutations
    applyCouponMutation,
    removeCouponMutation,
  };

  return (
    <ApplyCouponContext.Provider value={valueToStore}>
      {children}
    </ApplyCouponContext.Provider>
  );
};

export const useApplyCouponContext = () => useContext<any>(ApplyCouponContext);
