import PropTypes from "prop-types";
import { Form } from "@circle-react-uikit/Form";
import { formId } from "../constants";
import { useCancelOptionsForm } from "./hooks/useCancelOptionsForm";

export const CancelOptionsForm = ({ subscription }) => {
  const { cancelOptions } = useCancelOptionsForm({ subscription });

  return (
    <div className="pt-5">
      <Form.Item
        className="border-0 pt-2"
        labelClassName="text-sm font-semibold"
        name="ends_at"
        translationRoot="settings.paywalls_admin_dashboard.subscriptions.cancel_and_refund_modal.cancel_options"
        hideBorderfullWidth={false}
        hideDescription
        hiddenField
        id={formId}
      >
        <Form.RadioGroup
          variant="radio"
          name="ends_at"
          fullWidth={false}
          options={cancelOptions}
        />
      </Form.Item>
    </div>
  );
};

CancelOptionsForm.propTypes = {
  subscription: PropTypes.object.isRequired,
};
