import { t } from "@/i18n-js/instance";
import { Video } from "../Video";

export interface MediaUploaderProps {
  id: string;
  name: string;
  media: any;
  onMediaUpload: (e: any) => void;
  onRemoveMedia: () => void;
}

export const MediaUploader = ({
  id,
  name,
  media,
  onMediaUpload,
  onRemoveMedia,
}: MediaUploaderProps) => {
  const mediaPreview = () => {
    const type = media.file ? fileType(media.file) : media.type;
    const url = media.file ? URL.createObjectURL(media.file) : media.url;

    return (
      <div className="media-upload-wrapper">
        {type === "video" ? (
          <Video url={url} />
        ) : (
          <img loading="lazy" alt="" src={url} />
        )}
      </div>
    );
  };

  const fileType = (file: any) => file["type"].split("/")[0];

  const actionButtons = () =>
    hasAttachedMedia() ? (
      <span className="btn btn-secondary btn-half pull-left mt-0">
        {t("replace")}
      </span>
    ) : (
      <span className="w-full text-center">
        {(media ?? media["file"]) ? t("replace") : t("upload")}
      </span>
    );

  const hasAttachedMedia = () =>
    (media["url"] && media["url"].length > 0) || media["file"];

  return (
    <div className="form-input">
      <div
        data-controller="form"
        data-id="1"
        data-btn-update-text={t("upload")}
      >
        {hasAttachedMedia() && mediaPreview()}
        <label
          className={`mt-0 ${
            hasAttachedMedia() ? "" : "btn btn-secondary btn-full"
          } `}
        >
          {actionButtons()}
          <span className="hidden">
            <input
              accept="image/*, video/*"
              className="form-control"
              type="file"
              name={name}
              id={id}
              onChange={onMediaUpload}
            />
          </span>
        </label>
        {hasAttachedMedia() && (
          <button
            type="button"
            className="btn btn-secondary btn-half pull-right mt-0"
            onClick={onRemoveMedia}
          >
            {t("remove")}
          </button>
        )}
      </div>
    </div>
  );
};
