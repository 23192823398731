import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { LoaderRow } from "@circle-react-shared/LoaderRow";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import type { RendererProps } from "@circle-react-uikit/ImageInput/ImageSelector";

export interface CoverPreviewProps extends RendererProps {
  variant?: "default" | "cover";
}

export const CoverPreview = ({
  changeImageText = t("edit_cover_image"),
  handleChangeCoverClick,
  handleDelete,
  imageUrl,
  processing,
  value,
  variant = "default",
}: CoverPreviewProps) => {
  const isProcessing = Boolean(processing);
  const hasValue = Boolean(value);

  return (
    <div className="group relative">
      <button
        type="button"
        className={classNames(
          "bg-primary text-dark border-primary flex h-[88px] w-full cursor-pointer items-center justify-center overflow-hidden rounded-md border group-focus-within:border-blue-500 group-focus-within:text-blue-500 group-hover:border-blue-500 group-hover:text-blue-500",
          {
            "py-7": variant === "default",
          },
        )}
        onClick={handleChangeCoverClick}
      >
        {isProcessing && <LoaderRow />}
        {hasValue && !isProcessing && (
          <img
            loading="lazy"
            src={imageUrl}
            alt={changeImageText}
            className="h-full"
          />
        )}
      </button>
      <div className="absolute right-[-6px] top-[-10px] hidden group-focus-within:block group-hover:block">
        <TippyV2 content={t("remove_cover_image")}>
          <button
            type="button"
            onClick={handleDelete}
            className="flex items-center justify-center rounded-full bg-gray-700 p-[2px] text-gray-50"
          >
            <Icon type="16-close" size={16} className="!h-4 !w-4" />
          </button>
        </TippyV2>
      </div>
    </div>
  );
};
