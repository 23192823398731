import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";

export const PaymentsSecureAndEncrypted = () => (
  <div className="mt-2 flex flex-row items-center justify-center">
    <Icon type="lock-solid" className="mt-2 !h-6 !w-6" />
    <Typography.BodyXs weight="normal" color="text-light">
      {t(
        "member_profile_modal.billing.subscriptions.payment_secure_and_encrypted",
      )}
    </Typography.BodyXs>
  </div>
);
