import { CallToAction } from "./CallToAction";
import { AudienceCRMFeature } from "./Features/AudienceCRMFeature";
import { EmailMarketingFeature } from "./Features/EmailMarketingFeature";
import { MarketingAutomationFeature } from "./Features/MarketingAutomationFeature";
import { Header } from "./Header";

interface LandingPageProp {
  modal?: boolean;
}

export const LandingPage = ({ modal }: LandingPageProp) => (
  <div className="bg-primary flex h-full w-full flex-col items-center pb-4">
    <div className="flex h-full w-full max-w-[1078px] flex-col items-center gap-y-14 px-8 py-14">
      <Header />
      <div className="bg-primary flex flex-col gap-y-6">
        <EmailMarketingFeature />
        <MarketingAutomationFeature />
        <AudienceCRMFeature />
      </div>
      <CallToAction modal={modal} />
    </div>
  </div>
);
