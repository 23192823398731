import type { ReactNode } from "react";
import { useMemo } from "react";
import type { useFilterValueContext } from "./FilterValueContext";
import { FilterValueContext } from "./FilterValueContext";

interface FilterValueContextProviderProps
  extends ReturnType<typeof useFilterValueContext> {
  children: ReactNode;
}

export const FilterValueContextProvider = ({
  filterParams,
  onApply,
  onClearAll,
  onClear,
  onAdd,
  children,
}: FilterValueContextProviderProps) => {
  const contextValue = useMemo(
    () => ({
      filterParams,
      onApply,
      onClearAll,
      onClear,
      onAdd,
    }),
    [filterParams, onApply, onClearAll, onClear, onAdd],
  );

  return (
    <FilterValueContext.Provider value={contextValue}>
      {children}
    </FilterValueContext.Provider>
  );
};
