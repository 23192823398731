import { useMutation } from "react-query";
import { useQueryClient } from "react-query";
import { couponsApi } from "@circle-react/api/admin/paywalls/subscriptions/coupons/couponsApi";
import { paywallSubscriptionQueryString } from "@circle-react/hooks/paywalls/useAdminPaywallSubscription";

export const useApplyCoupon = ({ subscription, shouldRemoveCoupon }: any) => {
  const queryClient = useQueryClient();
  const onSuccess = () => {
    void queryClient.invalidateQueries({
      queryKey: [paywallSubscriptionQueryString],
    });
  };
  const applyCouponMutation = useMutation({
    mutationFn: (params: any) => couponsApi.create(params),
    onSuccess,
  });
  const removeCouponMutation = useMutation({
    mutationFn: () =>
      couponsApi.delete({ subscriptionProcessorId: subscription.processor_id }),
    onSuccess,
  });

  const onSubmit = (data: any) => {
    if (shouldRemoveCoupon) {
      void removeCouponMutation.mutateAsync();
    } else {
      void applyCouponMutation.mutateAsync({
        subscriptionProcessorId: subscription.processor_id,
        params: data,
      });
    }
  };

  return {
    onSubmit,
    applyCouponMutation,
    removeCouponMutation,
  };
};
