import { useRef } from "react";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { TextSelectDropdown } from "@circle-react-shared/TextSelectDropdown";
import { TippyV2 } from "@circle-react-shared/TippyV2";

interface TextAreaProps {
  name: string;
  showMenuBar?: boolean;
  className?: string;
  textAreaClassName?: string;
  menuBarOptions?: Array<{
    group: string;
    label: string;
    value: string;
  }>;
}

export const TextArea = ({
  name,
  showMenuBar = false,
  className,
  textAreaClassName,
  menuBarOptions = [],
  ...rest
}: TextAreaProps) => {
  const insertVariableButtonRef = useRef<HTMLButtonElement | null>(null);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const { register, setValue } = useFormContext();

  const { ref: textAreaReactHookFormRef, ...textAreaReactHookFormProps } =
    register(name);

  const onSelect = (variableName: string) => {
    if (!textAreaRef?.current) return;

    const cursorPosition = textAreaRef.current.selectionStart || 0;
    const textBeforeCursorPosition =
      textAreaRef.current.value.substring(0, cursorPosition) || "";
    const textAfterCursorPosition =
      textAreaRef.current.value.substring(
        cursorPosition,
        textAreaRef.current.value.length,
      ) || "";

    setValue(
      name,
      textBeforeCursorPosition + variableName + textAfterCursorPosition,
    );
    textAreaRef.current.selectionStart = (
      textBeforeCursorPosition + variableName
    ).length;
  };

  return (
    <div
      className={classNames(
        "border-primary bg-primary text-dark focus-within:border-light active:border-light relative mb-6 flex flex-col rounded-lg border border-solid px-4 py-3 text-sm transition-colors",
        className,
      )}
    >
      <textarea
        {...textAreaReactHookFormProps}
        {...rest}
        ref={e => {
          textAreaReactHookFormRef(e);
          textAreaRef.current = e;
        }}
        className={classNames(
          "placeholder:text-light bg-primary text-dark m-0 flex-auto resize-none overflow-auto border-0 p-0 placeholder:opacity-100 focus:ring-0 focus:ring-offset-0",
          textAreaClassName,
        )}
      />
      {showMenuBar && (
        <TextSelectDropdown
          options={menuBarOptions}
          groupBy={option => option.group}
          onSelect={onSelect}
          buttonRef={insertVariableButtonRef}
        >
          <TippyV2
            placement="bottom"
            delay={300}
            content={t("tiptap.add_variable")}
            interactive={false}
          >
            <Icon type="20-variables" />
          </TippyV2>
        </TextSelectDropdown>
      )}
    </div>
  );
};
