import PropTypes from "prop-types";
import classNames from "classnames";
import { HandleIcon } from "./HandleIcon";
import { UserActions } from "./UserActions";

export const styles = `
  group flex justify-between items-center
  px-3 w-full h-10 rounded-md border
  select-none border-primary bg-primary
  text-dark min-w-0`;

export const SortableItem = ({
  item,
  actions,
  useDragHandle,
  dragEventListeners,
  className,
  dataTestId,
}) => {
  const { icon, label } = item || {};

  return (
    <div
      className={classNames(
        styles,
        "hover:border-secondary transition-colors",
        className,
      )}
      data-testid={dataTestId}
    >
      <div className="flex items-center gap-2 truncate">
        {icon && (
          <HandleIcon
            icon={icon}
            {...(useDragHandle && {
              dragEventListeners,
            })}
          />
        )}
        <span className="truncate text-sm font-medium normal-case leading-5 tracking-tighter">
          {label}
        </span>
      </div>
      {actions && (
        <div className="flex gap-2">
          <UserActions item={item} actions={actions} />
        </div>
      )}
    </div>
  );
};

SortableItem.propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string,
  }),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      tooltip: PropTypes.string,
      icon: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  useDragHandle: PropTypes.bool,
  dragEventListeners: PropTypes.object,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};
