import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { Typography } from "@circle-react-uikit/Typography";

type Role = "Admin" | "Moderator" | "Member";

export const ROLES = {
  ADMIN: "Admin",
  MODERATOR: "Moderator",
  MEMBER: "Member",
} as const;

export interface RoleSelectProps {
  id: string;
  role: Role;
  onRoleChange: (id: string, role: Role) => void;
}

export const RoleSelect = ({ id, role, onRoleChange }: RoleSelectProps) => {
  let roleText: string = t("spaces.form.edit.members.member");

  const { currentCommunityMember } = usePunditUserContext();

  if (role === ROLES.MODERATOR) {
    roleText = t("spaces.form.edit.members.moderator");
  }

  if (role === ROLES.ADMIN) {
    roleText = t("spaces.form.edit.members.admin");
  }

  if (role === ROLES.ADMIN || !isCommunityAdmin(currentCommunityMember)) {
    return <Typography.LabelSm weight="medium">{roleText}</Typography.LabelSm>;
  }

  return (
    <Dropdown
      direction="bottom-end"
      size="small"
      button={
        <Dropdown.MenuButton>
          <Typography.LabelSm weight="medium">{roleText}</Typography.LabelSm>
          <Icon type="16-chevron-down" size={16} />
        </Dropdown.MenuButton>
      }
    >
      <Dropdown.Item
        onClick={() => {
          if (role === ROLES.MODERATOR) return;
          return onRoleChange(id, ROLES.MODERATOR);
        }}
      >
        <Dropdown.ItemLink type="button">
          {t("spaces.form.edit.members.moderator")}
        </Dropdown.ItemLink>
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => {
          if (role === ROLES.MEMBER) return;
          return onRoleChange(id, ROLES.MEMBER);
        }}
      >
        <Dropdown.ItemLink type="button">
          {t("spaces.form.edit.members.member")}
        </Dropdown.ItemLink>
      </Dropdown.Item>
    </Dropdown>
  );
};
