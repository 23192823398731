import { useEffect } from "react";
import { flatten } from "lodash";
import { useInfiniteQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { MemberTagsVisibleInMemberList } from "@circle-react/helpers/memberTagsHelper";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { DropdownFilter } from "../DropdownFilter";

export const MemberTagsFilter = () => {
  const fetchPage = ({ pageParam = 1 }) =>
    reactQueryGet(
      internalApi.memberTags.index({
        params: {
          page: pageParam,
        },
      }),
    );

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery(
    "member-tags",
    fetchPage,
    {
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
    },
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [data]);

  const memberTags = MemberTagsVisibleInMemberList({
    memberTags: flatten(data?.pages.map(page => page.records)),
  }).map(record => ({
    label: `${record.emoji} ${record.name}`,
    value: record.id.toString(),
  }));

  if (isLoading || !memberTags.length) {
    return null;
  }

  return (
    <DropdownFilter queryParam="tags" type="multiselect" options={memberTags} />
  );
};
