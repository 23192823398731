export const PaywallColumn = (cellContext: any) => {
  const {
    row: { original: charge },
  } = cellContext;

  return (
    <div className="max-w-[15em] overflow-hidden text-ellipsis">
      {charge.paywall.name || charge.paywall.display_name}
    </div>
  );
};
