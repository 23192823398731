import NiceModal from "@ebay/nice-modal-react";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { ChargeDetailsModal } from "./ChargeDetailsModal";

const ChargeDetailsModalComponent = ModalManager.create((props: any) => (
  <ChargeDetailsModal {...props} />
));

export const useChargeDetailsModal = (key: any) => {
  if (key) {
    NiceModal.register(key, ChargeDetailsModalComponent);
  }

  return useModal(key ?? ChargeDetailsModalComponent);
};
