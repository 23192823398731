import { Link } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { settingsPath } from "@circle-react/helpers/urlHelpers";
import { Typography } from "@circle-react-uikit/Typography";
import { PropertyLine } from "./PropertyLine";

const localeStringRoot =
  "settings.paywalls_admin_dashboard.charges.charge_details_modal";

export const SubscriptionProcessorIdProperty = ({ charge }: any) => {
  const generateSubscriptionLink = () =>
    `${settingsPath.paywallsAdminDashboard.subscriptions()}?subscription_processor_id=${
      charge.subscription_processor_id
    }`;

  return (
    charge.subscription_processor_id && (
      <PropertyLine
        title={t(
          `${localeStringRoot}.details_labels.subscription_processor_id`,
        )}
      >
        <Link
          className="text-dark hover:text-dark visited:text-dark underline"
          to={generateSubscriptionLink()}
          target="_blank"
        >
          <Typography.LabelSm>
            {charge.subscription_processor_id}
          </Typography.LabelSm>
        </Link>
      </PropertyLine>
    )
  );
};
