import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";

export const useShouldShowAvatarSetFilter = filters => {
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);

  if (isAdmin) {
    return filters;
  }

  return filters.filter(filter => filter.name !== "is_avatar_set");
};
