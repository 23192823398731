import { useEffect, useMemo } from "react";
import { flatten, sortBy } from "lodash";
import { useInfiniteQuery } from "react-query";
import { t } from "@/i18n-js/instance";
import { eventApi } from "@circle-react/api/eventApi";
import { Filter } from "@circle-react-shared/FilterV3";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { ArrayFilterComparator } from "../../Filter/FilterTypes/ArrayFilterComparator/ArrayFilterComparator";
import { handleArrayComparatorApply } from "../../Filter/FilterTypes/ArrayFilterComparator/arrayComparators";
import { useArrayComparatorSelectedItem } from "../../Filter/FilterTypes/ArrayFilterComparator/useArrayComparatorSelectedItem";

const localeNamespace = "members_directory.header.search.filters.events";

interface EventsFilterProps {
  name: string;
  isInitialOpen?: boolean;
  onApply: (value: BaseFilter | null) => void;
  param: BaseFilter;
}

export const EventsFilter = ({
  name,
  isInitialOpen,
  onApply,
  param,
}: EventsFilterProps) => {
  const fetchPage = ({ pageParam = 1 }) =>
    eventApi.listEvents({
      params: {
        page: pageParam,
      },
    });

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery(
    ["events"],
    fetchPage,
    {
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
    },
  );

  useEffect(() => {
    if (hasNextPage) {
      void fetchNextPage();
    }
  }, [data, hasNextPage, fetchNextPage]);

  const upcomingEventsLabel = t("upcoming_events");
  const pastEventsLabel = t("past_events");

  const groupedEvents = useMemo(() => {
    const generateCheckboxData = (record: any) => ({
      richLabel: (
        <>
          <Typography.LabelMd
            as="div"
            weight="medium"
            color="text-selector-active"
          >
            {record.name}
          </Typography.LabelMd>
          <Typography.LabelSm color="text-default">
            {record.start_date}
          </Typography.LabelSm>
        </>
      ),
      label: `${record.name} - ${record.start_date}`,
      value: record.id.toString(),
      group: record.upcoming_event ? upcomingEventsLabel : pastEventsLabel,
    });

    const events = flatten(data?.pages.map(page => page.records));
    // Put upcoming events first
    return sortBy(events, [event => !event.upcoming_event]).map(record =>
      generateCheckboxData(record),
    );
  }, [data]);

  const renderSelectedValue = useArrayComparatorSelectedItem(groupedEvents);

  return (
    <Filter onApply={param => handleArrayComparatorApply(param, onApply)}>
      <Filter.Chip renderSelectedValue={() => renderSelectedValue(param)}>
        {t([localeNamespace, "singular_title"])}
      </Filter.Chip>

      <Filter.Popover
        title={t([localeNamespace, "title"])}
        isInitialOpen={isInitialOpen}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {!groupedEvents?.length ? (
              <div className="flex h-28 items-center justify-center p-4">
                <Typography.LabelSm>
                  {t([localeNamespace, "no_events"])}
                </Typography.LabelSm>
              </div>
            ) : (
              <ArrayFilterComparator
                name={name}
                options={groupedEvents}
                param={param}
                groupBy={select => select.group}
              />
            )}
          </>
        )}
      </Filter.Popover>
    </Filter>
  );
};
