import { isRedemptionRedeemed } from "@circle-react/helpers/paywallCouponHelpers";

export const CouponCodeColumn = (cellContext: any) => {
  const {
    row: { original: subscription },
  } = cellContext;

  const paywallCouponRedemption = subscription?.paywall_coupon_redemption;
  const paywallCouponCode = paywallCouponRedemption?.paywall_coupon?.code;
  const couponCodeString =
    paywallCouponRedemption &&
    isRedemptionRedeemed(paywallCouponRedemption.status)
      ? paywallCouponCode
      : "";

  return <div className="whitespace-normal">{couponCodeString}</div>;
};
