import { t } from "@/i18n-js/instance";
import type { Space } from "@circle-react/types/Space";
import type { SpaceGroup } from "@circle-react/types/SpaceGroup";

const i18nBase = "customize_theme.canvas_preview";

export const getStartedSpaceGroup: SpaceGroup = {
  id: 1,
  name: t([i18nBase, "get_started_space_group.title"]),
  slug: "get-started",
  hide_non_member_spaces_from_sidebar: false,
  automatically_add_members_to_new_spaces: false,
  active_members_count: 0,
  spaces: [],
};

export const getStartedSpaces: Partial<Space>[] = [
  {
    id: 1,
    name: t([i18nBase, "get_started_space_group.spaces.start_here"]),
    emoji: "🏠",
    is_private: false,
  },
  {
    id: 2,
    name: t([i18nBase, "get_started_space_group.spaces.say_hello"]),
    emoji: "👋",
    is_private: false,
  },
  {
    id: 3,
    name: t([i18nBase, "get_started_space_group.spaces.resources"]),
    emoji: "📖",
    is_private: false,
  },
];

export const coursesSpaceGroup: SpaceGroup = {
  id: 2,
  name: t([i18nBase, "courses_space_group.title"]),
  slug: "courses-space",
  hide_non_member_spaces_from_sidebar: false,
  automatically_add_members_to_new_spaces: false,
  active_members_count: 0,
  spaces: [],
};

export const coursesSpaces: Partial<Space>[] = [
  {
    id: 1,
    name: t([i18nBase, "courses_space_group.spaces.discussions"]),
    emoji: "💬",
    is_private: false,
  },
  {
    id: 2,
    name: t([i18nBase, "courses_space_group.spaces.welcome"]),
    emoji: "🤝",
    is_private: false,
  },
  {
    id: 3,
    name: t([i18nBase, "courses_space_group.spaces.module_1"]),
    emoji: "1️⃣",
    is_private: false,
  },
  {
    id: 4,
    name: t([i18nBase, "courses_space_group.spaces.module_2"]),
    emoji: "2️⃣",
    is_private: false,
  },
  {
    id: 5,
    name: t([i18nBase, "courses_space_group.spaces.module_3"]),
    emoji: "3️⃣",
    is_private: false,
  },
];
