import { useWatch } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";
import { reasonOptions } from "./reasonOptions";
import { otherOption } from "./reasonOptions";

const localeRoot = "marketing_hub.cancellation_modal.reason_form";

export const FormFields = () => {
  const reason = useWatch({ name: "reason" });

  const shouldShowReasonDescription = reason === otherOption;

  return (
    <>
      <Form.Item
        name="reason"
        label={t([localeRoot, "reason"])}
        rules={{
          required: t([localeRoot, "reason_required"]),
        }}
        hideBorder
        hideDescription
      >
        <Form.SelectNative
          options={reasonOptions}
          name="reason"
          prompt={t([localeRoot, "reason_prompt"])}
        />
      </Form.Item>

      {shouldShowReasonDescription && (
        <Form.Item
          name="reason_description"
          label={t([localeRoot, "reason_description"])}
          hideLabel
          hideDescription
          hideBorder
        >
          <Form.TextArea name="reason_description" />
        </Form.Item>
      )}
    </>
  );
};
