import { t } from "@/i18n-js/instance";
import { InputFilterComparator } from "@circle-react-shared/Filter/FilterTypes/InputFilterComparator/InputFilterComparatorV2";
import type { StringComparatorParam } from "@circle-react-shared/Filter/FilterTypes/InputFilterComparator/stringComparators";
import {
  handleStringComparatorApply,
  renderTextSelectedItem,
  stringComparatorsIncludingExistence,
} from "@circle-react-shared/Filter/FilterTypes/InputFilterComparator/stringComparators";
import { Filter } from "@circle-react-shared/FilterV3";
import { useAudienceSearchContext } from "../AudienceSearchContext";

const localeNamespace = "members_directory.header.search.filters";

interface BioFilterProps {
  isInitialOpen?: boolean;
  onApply?: (value: StringComparatorParam | null) => void;
  param: StringComparatorParam;
}

export const BioFilter = ({
  isInitialOpen,
  onApply,
  param,
}: BioFilterProps) => {
  const { isContactsSearch } = useAudienceSearchContext();

  return (
    <Filter onApply={value => handleStringComparatorApply(value, onApply)}>
      <Filter.Chip renderSelectedValue={() => renderTextSelectedItem(param)}>
        {t([localeNamespace, "bio"])}
      </Filter.Chip>

      <Filter.Popover
        title={t([localeNamespace, "bio_title"])}
        isInitialOpen={isInitialOpen}
      >
        <InputFilterComparator
          name="bio"
          param={param}
          placeholder={t([localeNamespace, "bio_placeholder"])}
          comparators={
            isContactsSearch ? stringComparatorsIncludingExistence : undefined
          }
        />
      </Filter.Popover>
    </Filter>
  );
};
