import type { Editor } from "@tiptap/react";
import { useAddTipTapLinkModal } from "@circle-react-uikit/TipTapBlockEditor/AddTipTapLinkModal";
import { useImageLinkModal } from "@circle-react-uikit/TipTapBlockEditor/ImageExtension/EmailImage/ImageLinkModal";

export const useLinkEmailEditorModal = (editor: Editor) => {
  const tiptapLinkModal = useAddTipTapLinkModal();
  const imageLinkModal = useImageLinkModal();

  const handleLinkClick = () => {
    if (editor.isActive("image")) {
      return imageLinkModal.show({ editor });
    }
    return tiptapLinkModal.show({ editor });
  };

  const getIsLinkActive = () => {
    if (editor.isActive("link")) {
      return true;
    }
    return !!(editor.isActive("image") && editor.getAttributes("image").href);
  };

  return {
    handleLinkClick,
    getIsLinkActive,
  };
};
