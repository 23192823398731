import { KeyboardInput } from "@circle-react-shared/uikit/HeaderV3/KeyboardShortcutsModal/KeyboardInput";

export interface TippyContentProps {
  index?: number;
  name: string;
}

export const TippyContent = ({ index = 0, name }: TippyContentProps) => {
  if (index + 1 > 9) {
    return <>{name}</>;
  }

  return (
    <div className="flex flex-row items-center justify-between space-x-2 p-1">
      <div>{name}</div>
      <div>
        <KeyboardInput
          isDarkMode
          keys={["alt", "g", String(index + 1)]}
          wrapperClassName="!space-x-1"
          size="xs"
        />
      </div>
    </div>
  );
};
