import { forwardRef, useRef } from "react";
import type { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";
import { uniqueId } from "lodash";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-shared/uikit/Form";
import { ItemLabel } from "@circle-react-shared/uikit/Form/Item/ItemLabel";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface InputWithCounterProps
  extends ComponentPropsWithoutRef<typeof Form.Input> {
  label?: string;
  maxCount?: number;
  tooltipText?: string;
}

export const InputWithCounter = forwardRef<
  HTMLInputElement,
  InputWithCounterProps
>(({ label, maxCount = 140, name, tooltipText, ...rest }, ref) => {
  const { watch } = useFormContext();
  const value = watch(name);
  const characterCount = value?.length ?? 0;
  const { current: descriptionId } = useRef(
    uniqueId("input-count-description-"),
  );
  const { current: inputId } = useRef(uniqueId("input-count-"));
  const hasError = characterCount > maxCount;

  return (
    <div
      className={classNames("flex flex-col gap-y-1", {
        "form-group--has-error": hasError,
      })}
    >
      <div className="flex items-start justify-between">
        <ItemLabel
          label={label}
          hideDescription
          labelClassName="mb-0"
          tooltipText={tooltipText}
        />
        <span className="min-w-[6ch] shrink-0 text-end" id={descriptionId}>
          <Typography.LabelXs
            color={hasError ? "text-v2-danger" : "text-default"}
          >
            {t("count_separator", {
              count: characterCount,
              total: maxCount,
            })}
          </Typography.LabelXs>
        </span>
      </div>
      <Form.Input
        name={name}
        aria-describedby={descriptionId}
        hideLabel
        {...rest}
        inputId={inputId}
        ref={ref}
      />
    </div>
  );
});

InputWithCounter.displayName = "InputWithCounter";
