import { Button } from "./Button";
import { Connector } from "./Connector";
import { Container } from "./Container";
import { Content } from "./Content";
import type { ItemDataProviderProps } from "./ItemDataProvider";
import { ItemDataProvider } from "./ItemDataProvider";
import { Panel } from "./Panel";

const BlockWithConnectorItem = (props: ItemDataProviderProps) => (
  <ItemDataProvider {...props} />
);

BlockWithConnectorItem.Container = Container;
BlockWithConnectorItem.Content = Content;
BlockWithConnectorItem.Connector = Connector;
BlockWithConnectorItem.Button = Button;
BlockWithConnectorItem.Panel = Panel;

export { BlockWithConnectorItem };
