import { usePunditUserContext } from "@circle-react/contexts";

export const useShouldShowMemberTagsFilter = (filters: any) => {
  const { currentCommunityMember } = usePunditUserContext();

  if (currentCommunityMember) {
    return filters;
  }

  return filters.filter((filter: any) => filter.name !== "tags");
};
