import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { CallToAction } from "./CallToAction";
import { localeRoot } from "./constants";

export const Header = () => (
  <div className="flex flex-col items-center gap-y-6 text-center">
    <Typography.TitleXxl>{t([localeRoot, "title"])}</Typography.TitleXxl>

    <div className="flex flex-col items-center gap-y-2">
      <Typography.LabelLg weight="bold">
        {t([localeRoot, "subtitle"])}
      </Typography.LabelLg>
      <Typography.LabelLg>{t([localeRoot, "description"])}</Typography.LabelLg>
    </div>

    <CallToAction />
  </div>
);
