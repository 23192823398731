import { t } from "@/i18n-js/instance";
import {
  segmentFilter,
  tagsFilter,
} from "@circle-react/components/SettingsApp/ManageMembers/TabContent/Filters/filterConfiguration";
import type { RenderFilter } from "@circle-react-shared/MemberFilters/types";
import { useIsProfileFieldsEnabled } from "@circle-react-shared/ProfileFields";
import { AddFilterDropdown } from "../AddFilterDropdown";
import { useDynamicFilterContext } from "../DynamicFilterContext";
import type { AddFilterArgs } from "../useDynamicFilters";
import { FiltersList } from "./FiltersList";
import { ProfileFieldUpgradeMessage } from "./ProfileFieldUpgradeMessage";

const crmCatgoryFilterNames = [segmentFilter.name, tagsFilter.name] as const;

export const AdminAreaFiltersList = ({ label }: { label?: string }) => {
  const isProfileFieldsEnabled = useIsProfileFieldsEnabled();
  const {
    platformFilters,
    profileFieldFilters = [],
    onAddFilter,
  } = useDynamicFilterContext();

  const [crmCategoryFilters, otherPlatformFilters] = platformFilters.reduce<
    [RenderFilter[], RenderFilter[]]
  >(
    (acc, filter) => {
      const isCrmFilter = crmCatgoryFilterNames.includes(filter.name);
      acc[isCrmFilter ? 0 : 1].push(filter);
      return acc;
    },
    [[], []],
  );

  return (
    <AddFilterDropdown label={label}>
      <div className="flex max-h-96 flex-col gap-y-4 overflow-y-auto">
        <FiltersList
          title={t("filters.crm")}
          filters={crmCategoryFilters}
          onAddFilter={onAddFilter}
        />
        <FiltersList
          title={t("filters.standard")}
          filters={otherPlatformFilters}
          onAddFilter={onAddFilter}
        />
        <ProfileFieldUpgradeMessage />
        {isProfileFieldsEnabled && (
          <FiltersList
            title={t("filters.custom")}
            filters={profileFieldFilters}
            onAddFilter={(params: AddFilterArgs) =>
              onAddFilter({ ...params, key: "profile_field" })
            }
          />
        )}
      </div>
    </AddFilterDropdown>
  );
};
