import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { ENDS_AT_OPTIONS, REFUND_TYPE_OPTIONS } from "../../constants";

const LOCALES_ROOT =
  "settings.paywalls_admin_dashboard.subscriptions.cancel_and_refund_modal";

export interface UseRefundTypeFormProps {
  refundOptionsSubscriptionData: any;
}

export const useRefundTypeForm = ({
  refundOptionsSubscriptionData,
}: UseRefundTypeFormProps) => {
  const { setValue, watch } = useFormContext();
  const cancelOptionSelected = watch("ends_at");
  const [refundOptionsSubscriptionState] = useState(
    refundOptionsSubscriptionData,
  );

  const isRefundable =
    refundOptionsSubscriptionState?.prorated_applicable ||
    refundOptionsSubscriptionState?.full_refund_applicable;

  const shouldDisableRefundForm = () =>
    cancelOptionSelected !== ENDS_AT_OPTIONS.NOW || !isRefundable;

  const [isDisabled, setDisabled] = useState(shouldDisableRefundForm());
  const selectionOptions = [];

  selectionOptions.push({
    label: t(`${LOCALES_ROOT}.refund_type_options.refund_options.no_refund`),
    value: REFUND_TYPE_OPTIONS.NO_REFUND,
  });

  if (refundOptionsSubscriptionState?.prorated_applicable) {
    selectionOptions.push({
      label: t(
        `${LOCALES_ROOT}.refund_type_options.refund_options.prorated_refund`,
      ),
      value: REFUND_TYPE_OPTIONS.PRORATED,
    });
  }

  if (refundOptionsSubscriptionState?.full_refund_applicable) {
    selectionOptions.push({
      label: t(
        `${LOCALES_ROOT}.refund_type_options.refund_options.full_refund`,
      ),
      value: REFUND_TYPE_OPTIONS.FULL,
    });
  }

  useEffect(() => {
    if (shouldDisableRefundForm()) {
      setValue("refund_type", REFUND_TYPE_OPTIONS.NO_REFUND);
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [cancelOptionSelected]);

  return {
    selectionOptions,
    isDisabled,
  };
};
