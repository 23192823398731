import { useMemo } from "react";
import invariant from "tiny-invariant";
import { Filter } from "@circle-react-shared/FilterV3";
import { ArrayFilterComparator } from "../../../Filter/FilterTypes/ArrayFilterComparator/ArrayFilterComparator";
import type { ArrayComparatorProfileFieldParam } from "../../../Filter/FilterTypes/ArrayFilterComparator/arrayComparators";
import { isArrayComparatorParam } from "../../../Filter/FilterTypes/ArrayFilterComparator/arrayComparators";
import { useArrayComparatorSelectedItem } from "../../../Filter/FilterTypes/ArrayFilterComparator/useArrayComparatorSelectedItem";
import { isProfileFieldBaseFilter } from "../../types";
import { ProfileFieldInputs } from "./ProfileFieldInputs";

interface SelectFieldFilterProps {
  param: ArrayComparatorProfileFieldParam;
  label: string;
  choices: Array<{ id: number; value: string }>;
  onApply: (value: ArrayComparatorProfileFieldParam | null) => void;
  isInitialOpen?: boolean;
}

export const SelectFieldFilter = ({
  label,
  choices,
  onApply,
  param,
  isInitialOpen = false,
}: SelectFieldFilterProps) => {
  const options = useMemo(
    () => choices.map(({ id, value }) => ({ label: value, value: String(id) })),
    [choices],
  );
  const renderSelectedValue = useArrayComparatorSelectedItem(options);

  return (
    <Filter
      onApply={value => {
        if (value == null) {
          return onApply?.(null);
        }

        invariant(
          isArrayComparatorParam(value) && isProfileFieldBaseFilter(value),
          "value must be a array comparator and profile field filter",
        );
        onApply?.(value);
      }}
    >
      <Filter.Chip renderSelectedValue={() => renderSelectedValue(param)}>
        {label}
      </Filter.Chip>

      <Filter.Popover title={label} isInitialOpen={isInitialOpen}>
        <ArrayFilterComparator
          name="profile_field"
          param={param}
          options={options}
        >
          <ProfileFieldInputs {...param} />
        </ArrayFilterComparator>
      </Filter.Popover>
    </Filter>
  );
};
