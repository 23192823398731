import type { ReactNode } from "react";
import classNames from "classnames";

export interface FiltersRowProps {
  className?: string;
  children: ReactNode;
}

export const FiltersRow = ({
  className,
  children,
  ...rest
}: FiltersRowProps) => (
  <div
    className={classNames(
      "bg-primary border-primary flex items-center justify-between gap-x-1 border-b px-6 py-3 lg:px-9",
      className,
    )}
    {...rest}
  >
    {children}
  </div>
);
