import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import { noop } from "lodash";

type RenderFunction = () => ReactNode;

interface ItemDataContextType {
  id?: string | number;
  isOpen?: boolean;
  isDefaultOpen?: boolean;
  hasBottomConnection?: boolean;
  shouldCloseOnClickOutside?: boolean;
  onAddNewConnection?: () => void;
  onCloseItemAndOpenNext?: () => void;
  onToggleItem?: () => void;
  renderButton?: RenderFunction;
  renderPanel?: RenderFunction;
}

const ItemData = createContext<ItemDataContextType>({
  id: undefined,
  isOpen: undefined,
  isDefaultOpen: undefined,
  hasBottomConnection: undefined,
  shouldCloseOnClickOutside: undefined,
  onAddNewConnection: noop,
  onCloseItemAndOpenNext: noop,
  onToggleItem: noop,
  renderButton: noop,
  renderPanel: noop,
});
ItemData.displayName = "ItemData";

export interface ItemDataProviderProps extends Partial<ItemDataContextType> {
  children: ReactNode;
}

export const ItemDataProvider = ({
  id,
  isOpen,
  isDefaultOpen,
  hasBottomConnection,
  shouldCloseOnClickOutside,
  onAddNewConnection,
  onCloseItemAndOpenNext,
  onToggleItem,
  renderButton,
  renderPanel,
  children,
}: ItemDataProviderProps) => (
  <ItemData.Provider
    value={{
      id,
      isOpen,
      isDefaultOpen,
      hasBottomConnection,
      shouldCloseOnClickOutside,
      onAddNewConnection,
      onCloseItemAndOpenNext,
      onToggleItem,
      renderButton,
      renderPanel,
    }}
  >
    {children}
  </ItemData.Provider>
);

export const useItemData = () => useContext(ItemData);
