import type { ReactNode } from "react";
import { DisplayedFilters } from "./AllFiltersList/DisplayedFilters";
import { useDynamicFilterContext } from "./DynamicFilterContext";
import { FiltersItems } from "./FiltersItems";

interface DynamicFilterItemsProps {
  children: ReactNode;
}

/**
 * This version will show all filters regardless of whether they are already added.
 * All filters might not used this currently.
 */
export const DynamicFilterItems = ({ children }: DynamicFilterItemsProps) => {
  const {
    displayedFilters,
    onRemoveFilter,
    platformFilters,
    profileFieldFilters = [],
  } = useDynamicFilterContext();

  return (
    <FiltersItems data-testid="members_filters">
      <DisplayedFilters
        displayedFilters={displayedFilters}
        onRemoveFilter={onRemoveFilter}
        platformFilters={platformFilters}
        profileFieldFilters={profileFieldFilters}
      />
      {children}
    </FiltersItems>
  );
};
