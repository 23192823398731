import { t } from "@/i18n-js/instance";
import { useReportsAmounts } from "@circle-react/components/SettingsApp/Moderation/useReportsAmounts";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  isCommunityAdmin,
  isCommunityModerator,
} from "@circle-react/helpers/communityMemberHelpers";
import { settingsNavPathV3 } from "@circle-react/helpers/urlHelpers";
import { NavGroup } from "../NavGroup";
import { SubNavLinkItem } from "./SubNavLinkItem";
import type { Link } from "./types";

export const ContentLinks = () => {
  const { currentCommunityMember } = usePunditUserContext();
  const { inboxCount: moderationInboxCount } = useReportsAmounts();

  const links: Link[] = [
    {
      label: t("settings_nav_v3.content.posts"),
      path: settingsNavPathV3.content.posts,
      isDisabled: !(
        isCommunityAdmin(currentCommunityMember) ||
        isCommunityModerator(currentCommunityMember)
      ),
      exact: true,
    },
    {
      label: t("settings_nav_v3.content.moderation"),
      path: settingsNavPathV3.content.moderation,
      isDisabled: !(
        isCommunityAdmin(currentCommunityMember) ||
        isCommunityModerator(currentCommunityMember)
      ),
      badgeLabel: moderationInboxCount > 0 ? String(moderationInboxCount) : "",
      exact: false,
    },
    {
      label: t("settings_nav_v3.live_streams.label"),
      path: settingsNavPathV3.live_streams.live_streams,
      isDisabled: !isCommunityAdmin(currentCommunityMember),
      exact: true,
    },
    {
      label: t("settings_nav_v3.content.topics"),
      path: settingsNavPathV3.content.topics,
      isDisabled: !isCommunityAdmin(currentCommunityMember),
      exact: true,
    },
    {
      label: t("settings_nav_v3.content.bulk_logs"),
      path: settingsNavPathV3.content.posts_bulk_actions,
      isDisabled: !isCommunityAdmin(currentCommunityMember),
      exact: true,
    },
  ];

  return (
    <NavGroup groupLinks={links}>
      <NavGroup.Content title={t("settings_nav_v3.content.label")}>
        {links.map(link => (
          <SubNavLinkItem key={link.path} {...link} />
        ))}
      </NavGroup.Content>
    </NavGroup>
  );
};
