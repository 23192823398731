import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Icon } from "@circle-react-shared/Icon";

export const Button = ({ onClick = noop, isDisabled, ...rest }) => (
  <button
    type="button"
    className="text-dark border-secondary bg-primary hover:text-darkest hover:bg-tertiary flex h-6 w-6 items-center justify-center rounded-full border transition-colors duration-200 hover:shadow-sm"
    disabled={isDisabled}
    onClick={onClick}
    {...rest}
  >
    <Icon
      type="plus-v2"
      size={16}
      className="h-4 !w-4"
      useWithFillCurrentColor
    />
  </button>
);

Button.propTypes = {
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};
