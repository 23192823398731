import { useMemo } from "react";
import { flatten } from "lodash";
import { t } from "@/i18n-js/instance";
import { useInvitationLinksInfiniteQuery } from "@circle-react/components/SettingsApp/InvitationLinks/InvitationLinksTable/useInvitationLinksInfiniteQuery";
import type { InvitationLinkProps } from "@circle-react/components/SettingsApp/InvitationLinks/interfaces";
import { Filter } from "@circle-react-shared/FilterV3";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { Loader } from "@circle-react-uikit/Loader";
import { ArrayFilterComparator } from "../../Filter/FilterTypes/ArrayFilterComparator/ArrayFilterComparator";
import {
  type ArrayComparatorParam,
  handleArrayComparatorApply,
} from "../../Filter/FilterTypes/ArrayFilterComparator/arrayComparators";
import { useArrayComparatorSelectedItem } from "../../Filter/FilterTypes/ArrayFilterComparator/useArrayComparatorSelectedItem";

const localeNamespace =
  "members_directory.header.search.filters.invitation_link_ids";

interface InvitationLinksFilterProps {
  name: string;
  isInitialOpen?: boolean;
  onApply?: (value: ArrayComparatorParam | null) => void;
  param: ArrayComparatorParam;
}

const generateCheckboxData = (record: InvitationLinkProps) => ({
  richLabel: (
    <Typography.LabelMd as="div" weight="medium" color="text-selector-active">
      {record.name}
    </Typography.LabelMd>
  ),
  label: `${record.name}`,
  value: record.id?.toString() ?? "",
});

export const InvitationLinksFilter = ({
  name,
  isInitialOpen = false,
  onApply,
  param,
}: InvitationLinksFilterProps) => {
  const { data, isLoading } = useInvitationLinksInfiniteQuery();

  const invitationLinks = useMemo(() => {
    const flatInvitationLinks = flatten(data?.pages.map(page => page.records));
    return flatInvitationLinks.map(generateCheckboxData);
  }, [data]);

  const renderSelectedValue = useArrayComparatorSelectedItem(invitationLinks);

  return (
    <Filter onApply={param => handleArrayComparatorApply(param, onApply)}>
      <Filter.Chip renderSelectedValue={() => renderSelectedValue(param)}>
        {t([localeNamespace, "singular_title"])}
      </Filter.Chip>

      <Filter.Popover
        title={t([localeNamespace, "title"])}
        isInitialOpen={isInitialOpen}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <ArrayFilterComparator
            name={name}
            options={invitationLinks}
            param={param}
          />
        )}
      </Filter.Popover>
    </Filter>
  );
};
