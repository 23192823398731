import type { ReactNode } from "react";
import classNames from "classnames";

export const FiltersList = ({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) => (
  <ul
    className={classNames(
      "flex flex-1 list-none flex-wrap items-center gap-2",
      className,
    )}
    role="list"
  >
    {children}
  </ul>
);
