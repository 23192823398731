export const TermColumn = (cellContext: any) => {
  const {
    row: { original: subscription },
  } = cellContext;

  return (
    <div className="whitespace-normal">
      {" "}
      {subscription.paywall_price.short_description}{" "}
    </div>
  );
};
