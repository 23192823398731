import { t } from "@/i18n-js/instance";
import { useModal } from "@circle-react/components/Layout/ModalManagerProvider";
import { ActionModal } from "@circle-react-shared/ModalsV2/ActionModal";
import { ChargeDetails } from "./ChargeDetails";

export const ChargeDetailsModal = (props: any) => {
  const modal = useModal();

  return (
    <ActionModal
      title={t(
        "settings.paywalls_admin_dashboard.charges.charge_details_modal.title",
      )}
      isOpen={modal.visible}
      onClose={modal.hide}
      actionVariant="danger"
    >
      <ChargeDetails {...props} />
    </ActionModal>
  );
};
