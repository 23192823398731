export interface ProgressBarProps {
  progress: number;
  filename?: string;
}

export const ProgressBar = ({ progress, filename }: ProgressBarProps) => (
  <div className="progress-card">
    <div className="editor-overlay">
      <div className="overlay-editor-actions">
        <a
          href="#remove-attachment"
          data-action="remove-attachment"
          className="close-btn"
        >
          <div className="close-btn__caret" />
        </a>
      </div>
    </div>
    {filename && <span className="progress-card__filename">{filename}</span>}
    <span className="progress-card__amount">{progress} %</span>
    <div className="attachment__upload progress-bar">
      <div className="progress-bar__line" style={{ width: progress + "%" }} />
    </div>
  </div>
);
