import PropTypes from "prop-types";
import classNames from "classnames";
import isUndefined from "lodash/isUndefined";
import { ExpandableBlock } from "@circle-react-uikit/ExpandableBlock";
import { useItemData } from "./ItemDataProvider";

export const Content = ({ children, className }) => {
  const { isOpen, isDefaultOpen, onToggleItem, shouldCloseOnClickOutside } =
    useItemData();
  const isControlled = !isUndefined(isOpen);
  return (
    <ExpandableBlock
      {...(isControlled && {
        isOpen,
      })}
      isDefaultOpen={isDefaultOpen}
      onToggle={onToggleItem}
      shouldCloseOnClickOutside={shouldCloseOnClickOutside}
      className={classNames(
        "aria-expanded:border-secondary aria-expanded:shadow-xs border-disabled hover:border-light hover:aria-expanded:border-secondary overflow-hidden border aria-expanded:overflow-visible",
        className,
      )}
    >
      {children}
    </ExpandableBlock>
  );
};

Content.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
