import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-shared/uikit/Typography";

const i18nRoot = "settings.emails.view_broadcast.reports_tab.recipients_table";

interface LinkClickedRecipientListProps {
  link?: string;
  count: number;
  formattedCount: string;
}

export const LinkClickedRecipientList = ({
  link,
  count,
  formattedCount,
}: LinkClickedRecipientListProps) => (
  <div className="gap-y-0.5 px-8">
    <div className="my-2 w-3/4 truncate">
      <Typography.LabelMd weight="medium" color="text-default">
        {link}
      </Typography.LabelMd>
    </div>
    <div className="pb-1 pt-5">
      <Typography.LabelMd weight="semibold" color="text-very-dark">
        {t([i18nRoot, "people_count"], {
          formattedCount,
          count,
        })}
      </Typography.LabelMd>
    </div>
  </div>
);
