import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-uikit/Typography";

export const FiltersSeparator = () => (
  <span className="flex items-center">
    <Typography.LabelXs color="text-default">
      {t("filters.seperator.and")}
    </Typography.LabelXs>
  </span>
);
