import { t } from "@/i18n-js/instance";
import type { SelectedAccessGroupType } from "@circle-react/types/AccessGroup";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { useAccessGroupsActions } from "../hooks/useAccessGroupsActions";

export const EditAction = ({
  accessGroup,
}: {
  accessGroup: SelectedAccessGroupType;
}) => {
  const { redirectToAccessGroups } = useAccessGroupsActions();

  if (!accessGroup) {
    return null;
  }

  return (
    <Dropdown.ItemWithLink
      onClick={event => {
        redirectToAccessGroups(accessGroup.id);
        event.preventDefault();
      }}
    >
      {t("settings.access_groups.admin.entry_points.association.actions.edit")}
    </Dropdown.ItemWithLink>
  );
};
