import classNames from "classnames";
import { useRouteMatch } from "react-router-dom";
import { TippyV2 } from "@circle-react/components/shared/TippyV2";
import type { IconType } from "@circle-react-shared/Icon";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";
import type { PathKey } from "./NavLinkWrapper";
import { NavLinkWrapper } from "./NavLinkWrapper";

export interface LinkItemProps {
  label: string;
  path: string;
  icon: IconType;
  pathKey?: PathKey;
  shouldShowUnreadCount?: boolean;
  unreadCount?: number;
}

export const LinkItem = ({
  label,
  path,
  icon,
  pathKey,
  shouldShowUnreadCount,
  unreadCount = 0,
}: LinkItemProps) => {
  const match = useRouteMatch({
    path: path,
    exact: true,
  });
  const isRouteMatch = Boolean(match);

  return (
    <TippyV2
      interactive={false}
      key={label}
      content={label}
      placement="right"
      arrow={false}
    >
      <div className="relative">
        <NavLinkWrapper path={path} pathKey={pathKey}>
          {shouldShowUnreadCount && unreadCount > 0 && (
            <div className="border-tertiary absolute -right-2 -top-2 flex h-6 w-6 items-center justify-center rounded-full border-2 bg-[#15803D]">
              <Typography.LabelXxs color="text-white">
                {unreadCount}
              </Typography.LabelXxs>
            </div>
          )}
          <Icon
            type={icon}
            className={classNames({
              "text-default": !isRouteMatch,
              "text-selector-active": isRouteMatch,
            })}
            size={20}
          />
        </NavLinkWrapper>
      </div>
    </TippyV2>
  );
};
