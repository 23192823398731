import { useRef } from "react";
import { Listbox } from "@headlessui/react";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoaderRow } from "@circle-react/components/shared/LoaderRow";
import { CustomerColumn } from "@circle-react-shared/Paywalls/CustomerColumn";

export const OptionsDropdown = ({
  records,
  fetchNextPage,
  hasNextPage,
  activeOptionIndex,
  onMouseOver,
  setRecord,
  communityMemberRecord,
  className = "",
}: any) => {
  const containerRef = useRef(null);
  const setPublicUid = (record: any) => {
    setRecord(record.id);
  };

  return (
    <div
      ref={containerRef}
      className={classNames(
        "bg-primary absolute mt-1 max-h-60 w-96 max-w-sm overflow-auto rounded-lg border",
        className,
        { hidden: records.length === 0 },
      )}
    >
      <Listbox onChange={setPublicUid} value="">
        {containerRef.current && (
          <InfiniteScroll
            scrollThreshold={0.5}
            next={fetchNextPage}
            hasMore={!!hasNextPage}
            loader={<LoaderRow />}
            dataLength={records.length}
            scrollableTarget={containerRef.current}
          >
            <Listbox.Options static className="list-none">
              {records.map((record: any, index: any) => (
                <Listbox.Option
                  key={record.id}
                  value={record}
                  className={classNames("cursor-pointer p-4", {
                    "bg-secondary": index === activeOptionIndex,
                  })}
                  onMouseOver={() => onMouseOver(index)}
                >
                  <CustomerColumn customer={communityMemberRecord(record)} />
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </InfiniteScroll>
        )}
      </Listbox>
    </div>
  );
};
