import { useIsActivityScoreEnabledOrUpgradable } from "@circle-react/hooks/useIsActivityScoreEnabled";

export const useShouldShowActivityScoreFilter = ({
  filters,
  canShowActivityScoreDisabled = false,
}: any) => {
  const isActivityScoreUIEnabledOrUpgradable =
    useIsActivityScoreEnabledOrUpgradable();

  if (isActivityScoreUIEnabledOrUpgradable && canShowActivityScoreDisabled) {
    return filters;
  }

  return filters.filter((filter: any) => filter.name !== "activity_score");
};
