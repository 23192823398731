import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-uikit/Typography";
import { PropertyLine } from "./PropertyLine";

const localeStringRoot =
  "settings.paywalls_admin_dashboard.charges.charge_details_modal";

export const ChargeProcessorIdProperty = ({ charge }: any) =>
  charge.processor_id && (
    <PropertyLine
      title={t(`${localeStringRoot}.details_labels.charge_processor_id`)}
    >
      <Typography.LabelSm>{charge.processor_id}</Typography.LabelSm>
    </PropertyLine>
  );
