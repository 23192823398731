import type { ChangeEvent } from "react";
import { useState } from "react";
import fuzzysort from "fuzzysort";
import trim from "lodash/trim";
import { t } from "@/i18n-js/instance";
import { useFocusOnLoad } from "@circle-react/hooks/useFocusOnLoad";
import type { SpaceGroup } from "@circle-react/types";
import { useDropdownContext } from "@circle-react-uikit/Dropdown";

export interface SpaceGroupListProps {
  spaceGroups: SpaceGroup[];
  handleChange: (id: number) => void;
}

export const SpaceGroupList = ({
  spaceGroups,
  handleChange,
}: SpaceGroupListProps) => {
  const { isOpen, toggle } = useDropdownContext();
  const inputRef = useFocusOnLoad<HTMLInputElement>([isOpen]);
  const [searchQuery, setSearchQuery] = useState("");

  let filteredSpaceGroups = spaceGroups;
  if (searchQuery) {
    filteredSpaceGroups = fuzzysort
      .go(searchQuery, spaceGroups, { keys: ["name"] })
      .map(res => res.obj);
  }

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(trim(event.target.value));
  };

  const onItemClick = (id: number) => {
    handleChange(id);
    toggle();
  };

  return (
    <div data-testid="space-group-list">
      <div className="px-4 py-2">
        <input
          type="text"
          ref={inputRef}
          className="px bg-secondary text-dark placeholder:text-light w-full rounded border-none text-sm placeholder:opacity-100 focus:shadow-none focus:ring-0"
          data-testid="space-group-search"
          placeholder={t("search_space_groups")}
          onChange={onSearch}
        />
      </div>
      <h6 className="text-light block select-none px-5 py-2 text-xs font-semibold uppercase">
        {t("space_groups.title")}
      </h6>
      <ul className="space-group-selector__dropdown__list max-h-64 overflow-auto">
        {filteredSpaceGroups?.map(spaceGroup => (
          <li key={spaceGroup.id}>
            <button
              type="button"
              onClick={() => onItemClick(spaceGroup.id)}
              className="space-group-selector__dropdown__name"
              data-testid="space-group-result"
            >
              {spaceGroup.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
