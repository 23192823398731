import { useEffect } from "react";
import { noop } from "lodash";
import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { isCommunityAdmin } from "@/react/helpers/communityMemberHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import { EmptyState } from "@circle-react-uikit/EmptyState";
import { Loader } from "@circle-react-uikit/Loader";
import type { SortOptions, Views } from "../constants";
import { SORT_OPTIONS, VIEWS } from "../constants";
import { CardView } from "./CardView";
import { ListView } from "./ListView";
import { useSelectMembersInfiniteQuery } from "./hooks/useSelectMembersInfiniteQuery";
import "./styles.scss";

export interface CommunityMembersProps {
  setMemberCount: (memberCount: number) => void;
  scopeResults: object;
  defaultSort?: SortOptions;
  activeView?: Views;
  viewType: string;
  [key: string]: any;
}

export const CommunityMembers = ({
  setMemberCount = noop,
  scopeResults = {},
  defaultSort = SORT_OPTIONS[0],
  activeView,
  viewType,
  ...rest
}: CommunityMembersProps) => {
  const history = useHistory();
  const { currentCommunityMember } = usePunditUserContext();
  const memberDataParams = useSelectMembersInfiniteQuery({
    defaultSort,
    scopeResults,
    viewType,
  });
  const {
    isLoading: isLoadingMembers,
    dataLength,
    totalMembers,
  } = memberDataParams;

  const isAdmin = isCommunityAdmin(currentCommunityMember);

  useEffect(() => {
    if (totalMembers) {
      setMemberCount(totalMembers);
    }
  }, [totalMembers]);

  if (isLoadingMembers) {
    return <Loader />;
  }

  if (totalMembers <= 1 && isAdmin) {
    return (
      <EmptyState
        header={t("member_directory.no_members.title")}
        description={t("member_directory.no_members.description")}
        primaryLabel={t("member_directory.no_members.cta")}
        onPrimaryClick={() => {
          history.push("/audience/manage");
        }}
      />
    );
  }

  if (dataLength === 0) {
    return (
      <EmptyState
        header={t("members_directory.no_members.title")}
        description={t("members_directory.no_members.description")}
      />
    );
  }

  const ActiveViewComponent = activeView === VIEWS.CARDS ? CardView : ListView;

  return (
    <ActiveViewComponent
      memberDataParams={memberDataParams}
      viewType={viewType}
      {...rest}
    />
  );
};
