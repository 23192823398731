import { useCallback } from "react";
import { omit } from "lodash";
import { stringify } from "qs";
import { useHistory } from "react-router-dom";
import type { AnyObjectSchema } from "yup";
import { useRouterQueryParams } from "@circle-react/hooks/useRouterQueryParamsV2";

export const useFilterUrl = <T extends AnyObjectSchema>(
  queryParamsSchema: T,
) => {
  const history = useHistory();
  const queryParams = useRouterQueryParams(queryParamsSchema);
  const removeAllQueryParams = useCallback(() => {
    history.push({
      search: "",
    });
  }, [history]);

  const removeFilterFromUrl = useCallback(() => {
    const newParams = omit(queryParams, ["filters", "page"]);
    history.push({ search: stringify(newParams) });
  }, [queryParams, history]);

  return {
    history,
    queryParams,
    removeAllQueryParams,
    removeFilterFromUrl,
  };
};
