import type { PropsWithChildren } from "react";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface FeatureItemProp {
  backgroundColor: string;
}

export const FeatureItem = ({
  children,
  backgroundColor,
}: PropsWithChildren<FeatureItemProp>) => (
  <li>
    <div className="flex flex-row items-start gap-x-3">
      <div>
        <Icon
          type="20-checkmark-circle-fill-dark"
          className="text-default h-5 w-5"
          viewBox="0 0 20 20"
          style={{ color: backgroundColor }}
        />
      </div>
      <Typography.LabelSm color="text-[#2B2E33]" weight="medium">
        <span>{children}</span>
      </Typography.LabelSm>
    </div>
  </li>
);
