import type { ComponentPropsWithoutRef, ReactNode } from "react";
import classNames from "classnames";

interface CardProps extends Omit<ComponentPropsWithoutRef<"img">, "loading"> {
  children: ReactNode;
  bgColor: string;
  imgClassName?: string;
  loading?: "eager" | "lazy";
}

export const Card = ({
  children,
  bgColor,
  imgClassName,
  loading = "lazy",
  alt = "",
  ...rest
}: CardProps) => (
  <article className="flex flex-col gap-y-6 md:flex-row md:gap-x-6">
    {children}
    <div
      className="flex items-center justify-center overflow-y-hidden rounded-[10px] p-6 xl:p-0"
      style={{ flexBasis: "58%", backgroundColor: bgColor }}
    >
      <img
        alt={alt}
        loading={loading}
        className={classNames("rounded-[10px]", imgClassName)}
        style={{ backgroundColor: bgColor }}
        {...rest}
      />
    </div>
  </article>
);
