import type { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";
import type { ScreenSize } from "@circle-react-shared/ScreenSizeSelect";

export interface CanvasSizeProps extends ComponentPropsWithoutRef<"div"> {
  screenSize?: ScreenSize;
}

export const CanvasSize = ({
  screenSize = "desktop",
  className,
  children,
  ...rest
}: CanvasSizeProps) => (
  <div
    className={classNames(
      "h-full w-full",
      {
        "max-w-full": screenSize === "desktop",
        "max-w-3xl": screenSize === "tablet",
        "max-w-sm": screenSize === "mobile",
      },
      className,
    )}
    {...rest}
  >
    {children}
  </div>
);
