import { getStatPercentage } from "@circle-react/components/SettingsApp/Emails/components/helper";
import { toLocaleString } from "@circle-react/helpers/number";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface StatsPercentageCardProps {
  label: string;
  value: number | null;
  total: number | null;
  tooltipText: string | JSX.Element;
  borderClass: string;
}

export const StatsPercentageCard = ({
  label,
  value,
  total,
  tooltipText,
  borderClass,
}: StatsPercentageCardProps) => (
  <div className={classNames("flex flex-col gap-y-1 px-6 py-3.5", borderClass)}>
    <div className="flex justify-between">
      <div>
        <Typography.LabelSm weight="medium" color="text-default">
          {label}
        </Typography.LabelSm>
        <Typography.LabelSm weight="medium" color="text-default">
          {value ? toLocaleString(value) : 0}
        </Typography.LabelSm>
      </div>
      {tooltipText && (
        <TippyV2
          className="text-default flex items-center"
          content={tooltipText}
          interactive
          allowHTML
        >
          <Icon type="16-info" size={16} useWithStrokeCurrentColor />
        </TippyV2>
      )}
    </div>
    <Typography.TitleSm weight="bold" color="text-very-dark">
      {getStatPercentage({ total, value })}
    </Typography.TitleSm>
  </div>
);
