import classNames from "classnames";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Typography } from "@circle-react-uikit/Typography";

export const SnapshotNumbers = ({
  number,
  label,
  className = "",
  tooltipText = "",
}: any) => (
  <div
    className={classNames(
      "border-primary mb-3 rounded-lg border py-5 text-center",
      className,
    )}
  >
    <Typography.TitleMd weight="bold" color="text-dark">
      {number}
    </Typography.TitleMd>

    <Typography.LabelXs color="text-dark">{label}</Typography.LabelXs>
    {tooltipText && (
      <TippyV2 placement="bottom" content={tooltipText} className="pl-1">
        <Icon type="question-mark" className="text-dark !w-3" />
      </TippyV2>
    )}
  </div>
);
