import { SpaceTitleWithEmoji } from "@circle-react/components/Spaces/Header/SpaceHeaderTitle/SpaceTitleWithEmoji";
import type { Space } from "@circle-react/types";
import { Icon } from "@circle-react-shared/Icon";
import { Dropdown } from "@circle-react-uikit/Dropdown";

export interface LeftContentProps {
  space: Space;
}

export const LeftContent = ({ space }: LeftContentProps) => (
  <Dropdown
    button={
      <Dropdown.MenuButton>
        <SpaceTitleWithEmoji space={space} />
        <Icon
          size={16}
          type="caret-down-bold"
          className="text-dark transition-all duration-100 group-hover:mt-1"
        />
      </Dropdown.MenuButton>
    }
  />
);
