import type { ComponentPropsWithoutRef } from "react";
import { BlockWithConnectorItem } from "@circle-react-shared/BlockWithConnectorItem";
import { BlockWithConnectorDataProvider } from "./BlockWithConnectorDataProvider";
import { Item } from "./Item";

const BlockWithConnectorComponent = (
  props: ComponentPropsWithoutRef<typeof BlockWithConnectorDataProvider>,
) => <BlockWithConnectorDataProvider {...props} />;

export const BlockWithConnector = Object.assign(BlockWithConnectorComponent, {
  Item: Item,
  Content: BlockWithConnectorItem.Content,
  Button: BlockWithConnectorItem.Button,
  Panel: BlockWithConnectorItem.Panel,
  Connector: BlockWithConnectorItem.Connector,
});
