import type { ReactNode } from "react";
import { values } from "lodash";
import { NavLink } from "react-router-dom";
import { usePunditUserContext } from "@circle-react/contexts";
import type { SettingsNavPathV3 } from "@circle-react/helpers/urlHelpers/settingsNavPathV3";
import {
  isSettingsNavPathV3,
  settingsNavPathV3,
} from "@circle-react/helpers/urlHelpers/settingsNavPathV3";
import { BACK_TO_COMMUNITY_PATH_KEY } from "./useCollapsedSidebarLinksV3";

export type PathKey = SettingsNavPathV3 | "back-to-community";

export interface NavLinkWrapperProps {
  children: ReactNode;
  path: string;
  pathKey?: PathKey;
}

export const NavLinkWrapper = ({
  children,
  path,
  pathKey,
}: NavLinkWrapperProps) => {
  const { currentCommunity } = usePunditUserContext();

  const shouldRenderTraditionalAnchorTag =
    pathKey === BACK_TO_COMMUNITY_PATH_KEY &&
    !currentCommunity?.home_page_enabled;

  const links = settingsNavPathV3;

  if (!shouldRenderTraditionalAnchorTag) {
    return (
      <NavLink
        exact
        to={path}
        className="hover:bg-secondary hover:border-dark flex items-center justify-center rounded-lg border border-transparent p-2.5 transition-all duration-150 ease-linear"
        activeClassName="bg-tertiary"
        isActive={(match, location) => {
          const hasMatch = Boolean(match);
          const routes = isSettingsNavPathV3(pathKey) ? links[pathKey] : null;
          const hasRoutes = !!routes;

          const isPathInLocation =
            hasRoutes &&
            values(routes).some(route => {
              const isCommunityInboxPage = location?.pathname.startsWith(
                settingsNavPathV3.community_inbox.index,
              );
              if (isCommunityInboxPage) {
                return route === settingsNavPathV3.community_inbox.index;
              }
              return location?.pathname === route;
            });

          return hasMatch || isPathInLocation;
        }}
      >
        {children}
      </NavLink>
    );
  }

  return (
    <a
      href={path}
      className="hover:bg-secondary hover:border-dark active:bg-tertiary flex items-center justify-center rounded-lg border border-transparent p-2.5 transition-all duration-150 ease-linear"
    >
      {children}
    </a>
  );
};
