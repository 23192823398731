import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { isNumber, isString, omit } from "lodash";
import { t } from "@/i18n-js/instance";
import { useButtonVariant } from "@circle-react/helpers/useButtonVariant";
import { useFilterContext } from "@circle-react-shared/FilterV3/FilterContext";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { useAudienceSearchContext } from "../../../MemberFilters/AudienceSearchContext";
import { BaseFilterFields } from "../BaseFilterFields";
import { RadioInput } from "../RadioInput";
import type { ArrayComparatorParam } from "./arrayComparators";
import {
  arrayComparators,
  arrayComparatorsIncludingExistence,
  isArrayComparatorParam,
  requireValueByFilterType,
} from "./arrayComparators";

interface TextSelectOption {
  label: string;
  value: string;
  description?: string;
  group?: string;
}

interface ArrayFilterComparatorProps {
  name: string;
  param: BaseFilter;
  placeholder?: string;
  /** To render more input values */
  children?: ReactNode;
  options: TextSelectOption[];
  groupBy?: (option: TextSelectOption & { group: string }) => string;
}

export function ArrayFilterComparator({
  name,
  placeholder,
  param,
  children,
  options,
  groupBy,
}: ArrayFilterComparatorProps) {
  const primaryButtonVariant = useButtonVariant();
  const { onApply } = useFilterContext();
  const { isContactsSearch } = useAudienceSearchContext();
  const comparators = isContactsSearch
    ? arrayComparatorsIncludingExistence
    : arrayComparators;

  const handleSubmit: ComponentPropsWithoutRef<
    typeof Form<ArrayComparatorParam>
  >["onSubmit"] = (data, { reset }) => {
    if (!requireValueByFilterType(data.filter_type)) {
      return onApply(omit(data, "value"));
    }
    if (!data.value) {
      return onApply(null);
    }
    onApply(data);
    reset();
  };

  const getDefaultValues = (): ArrayComparatorParam => {
    if (isArrayComparatorParam(param)) {
      return {
        id: param.id,
        key: name,
        filter_type: param.filter_type,
        value: param.value,
      };
    }

    // Before comparators were introduced, we used to store the value in the param.value field
    // Backwards compatibility: normalize this to is:value
    const defaultFilterType = comparators[0].value;
    if (isString(param.value) || isNumber(param.value)) {
      return {
        id: param.id,
        key: name,
        filter_type: defaultFilterType,
        value: param.value,
      };
    }

    return {
      id: param.id,
      key: name,
      filter_type: defaultFilterType,
      value: "",
    };
  };

  return (
    <Form<ArrayComparatorParam>
      defaultValues={getDefaultValues()}
      onSubmit={handleSubmit}
    >
      <fieldset className="flex flex-col py-1">
        <BaseFilterFields />
        {comparators.map(arrayComparator => (
          <RadioInput key={arrayComparator.value} {...arrayComparator}>
            <Form.TextSelect
              name="value"
              label=""
              options={options}
              placeholder={placeholder}
              groupBy={groupBy}
            />
          </RadioInput>
        ))}
      </fieldset>
      {children}
      <div className="px-4 py-3">
        <Button type="submit" variant={primaryButtonVariant} full>
          {t("done")}
        </Button>
      </div>
    </Form>
  );
}
