import { isRenderProfileFieldFilter } from "@circle-react-shared/MemberFilters/types";
import type {
  RenderFilter,
  RenderProfileFieldFilter,
} from "@circle-react-shared/MemberFilters/types";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { FilterContainer } from "../AvailableFiltersList/FilterContainer";
import { useUpgradeLogic } from "./useUpgradeLogic";

interface FiltersListProps {
  filters: Array<RenderFilter | RenderProfileFieldFilter>;
  title: string;
  onAddFilter: (filter: any) => void;
}

export const FiltersList = ({
  filters,
  title,
  onAddFilter,
}: FiltersListProps) => {
  const { showUpgradeModal } = useUpgradeLogic();

  if (!filters.length) {
    return null;
  }

  return (
    <FilterContainer title={title}>
      {filters.map(renderFilterParam => {
        const { name, label, listLabel, upgradeDetails } = renderFilterParam;
        const getKey = () => {
          if (isRenderProfileFieldFilter(renderFilterParam)) {
            return renderFilterParam.id;
          }
          return name;
        };
        return (
          <Dropdown.ItemWithLink
            key={getKey()}
            linkType="button"
            onClick={async () => {
              if (upgradeDetails) {
                await showUpgradeModal({
                  minimumRequiredTier: upgradeDetails.minimumRequiredTier,
                });
              } else {
                if (isRenderProfileFieldFilter(renderFilterParam)) {
                  const { id, field_type } = renderFilterParam;
                  return onAddFilter({
                    key: name,
                    profile_field_id: id,
                    profile_field_type: field_type,
                  });
                }
                onAddFilter({ key: name });
              }
            }}
          >
            {listLabel ?? label}
          </Dropdown.ItemWithLink>
        );
      })}
    </FilterContainer>
  );
};
