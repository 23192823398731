import { footerHeightInRem } from "./Footer";
import { headerHeightInRem } from "./Header";
import { useMobileAppPreviewContext } from "./Root";

export const Body = ({ children }: any) => {
  const { height } = useMobileAppPreviewContext();
  return (
    <div
      className="bg-primary h-full overflow-auto"
      style={{
        height: `${height - footerHeightInRem - headerHeightInRem}rem`,
        scrollbarWidth: "none",
      }}
    >
      {children}
    </div>
  );
};
