import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { EditAccessGroupModalContainer } from "@circle-react/components/SettingsApp/AccessGroups/EditAccessGroupModal/EditAccessGroupModalContainer";
import { MEMBER_EDIT_PATHS } from "@circle-react/components/SettingsApp/AccessGroups/EditAccessGroupModal/constants";
import type { AccessGroupType } from "@circle-react/types/AccessGroup";

interface EditAccessGroupContentType {
  accessGroup: AccessGroupType;
}

export const EditAccessGroupModalContent = ({
  accessGroup,
}: EditAccessGroupContentType) => {
  const modal = useModal();
  return (
    <EditAccessGroupModalContainer
      accessGroup={accessGroup}
      handleClose={modal.hide}
      isOpen={modal.visible}
      initialTab={MEMBER_EDIT_PATHS.CONTENT}
    />
  );
};

const EditAccessGroupModal = ModalManager.create(
  ({ accessGroup }: EditAccessGroupContentType) => (
    <EditAccessGroupModalContent accessGroup={accessGroup} />
  ),
);

export const useEditAccessGroupModal = () => useModal(EditAccessGroupModal);
