import type { RouteProps } from "react-router-dom";
import { Redirect, Route } from "react-router-dom";
import { planSettingsPath } from "@/react/helpers/urlHelpers";
import { useProtectedRoute } from "@circle-react-shared/ProtectedRoute/useProtectedRoute";

export interface ProtectedRouteProps extends RouteProps {
  children?: never;
}

export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { canSeeProtectedRoute } = useProtectedRoute();

  if (!canSeeProtectedRoute) {
    return <Redirect to={planSettingsPath()} />;
  }

  return <Route {...props} />;
};
