import { Body } from "./Body";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Page } from "./Page";
import { Root } from "./Root";

export const MobileAppPreview = Object.assign(Root, {
  Header: Header,
  Body: Body,
  Footer: Footer,
  Page: Page,
});
