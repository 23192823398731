import invariant from "tiny-invariant";
import { t } from "@/i18n-js/instance";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";

export interface DaysFilterParam extends BaseFilter {
  filter_type: string;
  value: number;
}

export interface DaysAgoFormOption {
  label: string;
  value: string;
  direction?: "days_ago" | "days_from_now";
}

const isDaysFilterParam = (
  options: DaysAgoFormOption[],
  param: BaseFilter,
): param is DaysFilterParam =>
  "filter_type" in param &&
  options.some(option => option.value === param.filter_type);

export const renderSelectedOption =
  (options: DaysAgoFormOption[]) =>
  (
    selectedParam: BaseFilter,
    localeNamespace = "members_directory.header.search.filters",
  ) => {
    if (!isDaysFilterParam(options, selectedParam)) {
      return null;
    }
    const operationOption = options.find(
      option => option.value === selectedParam.filter_type,
    );
    invariant(
      operationOption,
      "Could not find option from the related options",
    );

    const direction = operationOption.direction || "days_ago";

    const daysAgoLabel = t([localeNamespace, direction, operationOption.value]);
    const daysCountLabel = t([localeNamespace, direction, "day"], {
      count: +selectedParam.value,
    });

    return `${daysAgoLabel} ${daysCountLabel}`;
  };
