import { removeOpengraphImagePath } from "../../../../helpers/urlHelpers";
import { FieldLabel } from "../../Form/FieldLabel";
import { MediaUploader } from "../../MediaUploader";

export const MetaTagDetailForm = ({
  localizedData,
  metaTagDetails,
  onCommunitySettingChange,
}) => {
  const metaLocalizedData = localizedData["meta_tag"];
  const textFields = [
    "meta_title",
    "meta_description",
    "opengraph_title",
    "opengraph_description",
  ];

  const onChange = event => {
    onCommunitySettingChange(
      "meta_tags",
      event.target.name,
      event.target.value,
    );
  };

  const onOpengraphImageUpload = event => {
    onCommunitySettingChange("meta_tags", event.target.name, {
      file: event.target.files[0],
    });
  };

  const removeOpengraphImage = () => {
    onCommunitySettingChange("meta_tags", "opengraph_image", {
      type: "image",
      url: null,
    });
  };

  const onOpengraphImageRemove = async () => {
    if (metaTagDetails["id"]) {
      const response = await fetch(
        removeOpengraphImagePath(metaTagDetails["id"]),
        {
          method: "DELETE",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
      );
      if (response.ok) {
        removeOpengraphImage();
      }
    } else {
      removeOpengraphImage();
    }
  };

  const metaTextFields = textFields.map(fieldName => (
    <div
      className="form-group"
      id={`field-${fieldName}`}
      key={`field-${fieldName}`}
    >
      <FieldLabel
        field={fieldName}
        label={metaLocalizedData[fieldName]}
        description={metaLocalizedData[`${fieldName}_description`]}
      />
      <div className="form-input">
        <input
          type="text"
          className="form-control placeholder:text-light placeholder:opacity-100"
          name={fieldName}
          value={metaTagDetails[fieldName] || ""}
          onChange={onChange}
        />
      </div>
    </div>
  ));

  const openGraphImageField = () => {
    const fieldName = "opengraph_image";

    return (
      <div
        className="form-group"
        id={`field-${fieldName}`}
        key={`field-${fieldName}`}
      >
        <FieldLabel
          field={fieldName}
          label={metaLocalizedData[fieldName]}
          description={metaLocalizedData[`${fieldName}_description`]}
        />
        <MediaUploader
          id={fieldName}
          name={fieldName}
          media={metaTagDetails[fieldName] || {}}
          onMediaUpload={onOpengraphImageUpload}
          onRemoveMedia={onOpengraphImageRemove}
        />
      </div>
    );
  };

  return (
    <div className="form-section__content">
      {metaTextFields}
      {openGraphImageField()}
    </div>
  );
};
