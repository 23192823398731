import { useCallback } from "react";
import { isNil, omit } from "lodash";
import qs from "query-string";
import { useHistory } from "react-router-dom";
import { toQueryString } from "@circle-react/helpers/urlHelpers";
import { useRouterQueryParams } from "@circle-react/hooks/useRouterQueryParams";

export const useFilterUrl = () => {
  const history = useHistory();
  const queryParams = useRouterQueryParams();

  const updateUrl = useCallback(
    (queryParam = "", filterValue = "") => {
      const urlSearchParams = new URLSearchParams(history.location.search);
      /* Not using updateOrRemoveQueryParam since `page` also has to be set here.
        updateOrRemoveQueryParam always fetches the latest queryParams from the url.
      */
      if (isNil(filterValue) || filterValue === "") {
        urlSearchParams.delete(queryParam);
      } else {
        urlSearchParams.set(queryParam, filterValue);
      }
      urlSearchParams.set("page", 1);
      history.push({
        search: urlSearchParams.toString(),
      });
    },
    [history],
  );

  const appendToUrl = (queryParam = "", filterValue = "") => {
    queryParams[queryParam] = filterValue;
    queryParams.page = 1;
    history.push({ search: qs.stringify(queryParams) });
  };

  const removeAllQueryParams = useCallback(() => {
    history.push({
      search: "",
    });
  }, [history]);

  const removeQueryParams = useCallback(
    (queryKeys = []) => {
      const newParams = omit(queryParams, [...queryKeys, "page"]);
      const search = toQueryString(newParams);
      history.push({ search });
    },
    [queryParams, history],
  );

  return {
    history,
    queryParams,
    updateUrl,
    removeAllQueryParams,
    removeQueryParams,
    appendToUrl,
  };
};
