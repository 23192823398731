import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { AttributeSaleModal } from "./AttributeSaleModal";

const AttributeSaleModalComponent = ModalManager.create(AttributeSaleModal);

export const useAttributeSaleModal = () =>
  useModal(AttributeSaleModalComponent);
