import { ClearButton } from "@circle-react-shared/Filter";
import { useFilterValueContext } from "../FilterValueContext";
import { useDynamicFilterContext } from "./DynamicFilterContext";

export const ClearButtonWrapper = () => {
  const { isClearFiltersShown, onClearFilters: clearState } =
    useDynamicFilterContext();
  const { onClearAll: clearValue } = useFilterValueContext();
  return (
    <ClearButton
      isVisible={isClearFiltersShown}
      onClick={() => {
        clearState();
        clearValue?.();
      }}
    />
  );
};
