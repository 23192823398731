import { useState } from "react";
import { t } from "@/i18n-js/instance";
import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { Modal } from "@circle-react-uikit/ModalV2";
import { LinkClickedRecipientList } from "./LinkClickedRecipientList";
import { MatchedRecipientList } from "./MatchedRecipientList";
import { SearchableRecipientList } from "./SearchableRecipientList";
import { useViewRecipientList } from "./useViewRecipientList";

const localeNamespace = "settings.emails.builder.recipient_modal";

interface MatchedRecipientModalProps {
  broadcastId: number;
  link?: string;
  recipient_type: "link_clicks_recipients" | "all_recipients";
}

const MatchedRecipientModalContent = ({
  broadcastId,
  link,
  recipient_type,
}: MatchedRecipientModalProps) => {
  const [searchTerm, setSearchTerm] = useState("");

  const {
    members: searchedMembers,
    fetchNextPage,
    hasNextPage = false,
    isLoading,
    count,
    formattedCount,
    isRecipientRecordsProcessing,
  } = useViewRecipientList({
    id: Number(broadcastId),
    event_type:
      recipient_type === "link_clicks_recipients" ? "clicked" : "accepted",
    email: searchTerm,
    name: searchTerm,
    link: link || "",
  });

  const modal = useModal();

  const modalTitleNameSpace = () => {
    if (recipient_type === "link_clicks_recipients") {
      return "click_report";
    }

    return "recipients";
  };

  const handleClose = () => {
    setSearchTerm("");
    void modal.hide();
  };

  return (
    <Modal
      title={t([localeNamespace, modalTitleNameSpace()])}
      isOpen={modal.visible}
      onClose={handleClose}
    >
      <Modal.Overlay />
      <Modal.Content size="xl">
        <Modal.Header>
          <Modal.Title>
            {t([localeNamespace, modalTitleNameSpace()])}
          </Modal.Title>
          <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
            <Modal.CloseButton onClick={handleClose} />
          </Modal.HeaderActions>
        </Modal.Header>
        <Modal.Body className="!px-0">
          {isLoading ? (
            <Loader />
          ) : (
            <MatchedRecipientList
              count={count}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              searchedMembers={searchedMembers}
              isRecipientRecordsProcessing={isRecipientRecordsProcessing}
              recipientType={recipient_type}
            >
              {recipient_type === "link_clicks_recipients" ? (
                <LinkClickedRecipientList
                  link={link}
                  count={count}
                  formattedCount={formattedCount}
                />
              ) : (
                <SearchableRecipientList
                  setSearchTerm={setSearchTerm}
                  count={count}
                  formattedCount={formattedCount}
                />
              )}
            </MatchedRecipientList>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export const MatchedRecipientModal = ModalManager.create(
  ({ link, broadcastId, recipient_type }: MatchedRecipientModalProps) => (
    <MatchedRecipientModalContent
      link={link}
      broadcastId={broadcastId}
      recipient_type={recipient_type}
    />
  ),
);

export const useMatchedRecipientModal = () => useModal(MatchedRecipientModal);
