import { Fragment } from "react";
import { Listbox as HeadlessListbox, Transition } from "@headlessui/react";
import type { Editor } from "@tiptap/react";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import type { IconType } from "@circle-react-shared/Icon";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";

const localeNamespace = "settings.emails.builder";

interface TextOption {
  label: string;
  value: string;
  icon: IconType;
  onAssign: (editor: Editor) => void;
}

const paragraphOption: TextOption = {
  label: t([localeNamespace, "body_text"]),
  value: "paragraph",
  icon: "16-capital-text",
  onAssign: editor => editor.chain().focus().setParagraph().run(),
};

const heading1Option: TextOption = {
  label: t([localeNamespace, "heading_1"]),
  value: "heading-1",
  icon: "16-heading1",
  onAssign: editor => editor.chain().focus().setHeading({ level: 1 }).run(),
};

const heading2Option: TextOption = {
  label: t([localeNamespace, "heading_2"]),
  value: "heading-2",
  icon: "16-editor-h2",
  onAssign: editor => editor.chain().focus().setHeading({ level: 2 }).run(),
};

const heading3Option: TextOption = {
  label: t([localeNamespace, "heading_3"]),
  value: "heading-3",
  icon: "16-editor-h3",
  onAssign: editor => editor.chain().focus().setHeading({ level: 3 }).run(),
};

const options: ReadonlyArray<TextOption> = [
  paragraphOption,
  heading1Option,
  heading2Option,
  heading3Option,
];

interface TextOptionsDropdownProps {
  editor: Editor;
}

export function TextOptionsDropdown({ editor }: TextOptionsDropdownProps) {
  const getSelectedOption = () => {
    if (editor.isActive("heading", { level: 1 })) {
      return heading1Option;
    }
    if (editor.isActive("heading", { level: 2 })) {
      return heading2Option;
    }
    if (editor.isActive("heading", { level: 3 })) {
      return heading3Option;
    }
    return paragraphOption;
  };

  const handleChange = (selectedOption: TextOption) => {
    selectedOption.onAssign(editor);
  };

  const selectedOption = getSelectedOption();
  return (
    <HeadlessListbox value={selectedOption} onChange={handleChange}>
      {({ open }) => (
        <Fragment>
          <div className="relative">
            <HeadlessListbox.Button
              className={classNames(
                "text-default hover:bg-secondary flex items-center justify-center gap-x-0 rounded-md px-1.5 py-2",
                {
                  "bg-secondary": open,
                },
              )}
            >
              <Icon type={selectedOption.icon} size={16} />
              <Icon type="16-chevron-down" size={16} />
            </HeadlessListbox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <HeadlessListbox.Options className="bg-primary border-primary absolute !z-50 mt-1 w-full max-w-[15rem] overflow-auto rounded-lg border py-2 shadow-2xl">
              {options.map(option => (
                <HeadlessListbox.Option
                  key={option.value}
                  value={option}
                  className="hover:text-dark hover:bg-secondary cursor-pointer list-none px-5 py-2"
                >
                  {({ selected }) => (
                    <div className="flex items-center justify-between">
                      <div className="text-default flex gap-x-2">
                        <Icon type={option.icon} size={16} />
                        <Typography.LabelSm>{option.label}</Typography.LabelSm>
                      </div>
                      {selected && (
                        <Icon
                          type="20-checkmark"
                          className="text-selector-active"
                        />
                      )}
                    </div>
                  )}
                </HeadlessListbox.Option>
              ))}
            </HeadlessListbox.Options>
          </Transition>
        </Fragment>
      )}
    </HeadlessListbox>
  );
}
