import PropTypes from "prop-types";
import { canManageSpaceGroups } from "@circle-react/helpers/spaceGroupHelpers";
import { userIsAdminOrModeratorOfSpace } from "@circle-react/helpers/spaceHelpers";
import { ChatButton } from "./ChatButton";
import { DirectoryOptionsDropdown } from "./DirectoryOptionsDropdown";
import { MobileActions } from "./MobileActions";
import { SpaceGroupOptionsDropdown } from "./SpaceGroupOptionsDropdown";
import { SpaceOptionsDropdown } from "./SpaceOptionsDropdown";

export const ViewTypes = {
  MEMBER_DIRECTORY: "members.directory",
  MEMBER_SPACES: "members.space",
  MEMBER_SPACE_GROUPS: "members.space_group",
};

export const MemberActions = ({
  viewType,
  communityMember,
  isCurrentUser,
  space,
  spaceGroup,
  refetch,
  isAdmin,
  showChatButton,
}) => {
  const showSpaceOptions =
    viewType === ViewTypes.MEMBER_SPACES &&
    userIsAdminOrModeratorOfSpace(space);

  const showSpaceGroupOptions =
    viewType === ViewTypes.MEMBER_SPACE_GROUPS &&
    canManageSpaceGroups({ spaceGroup });

  const showDirectoryOptions =
    viewType === ViewTypes.MEMBER_DIRECTORY && isAdmin;

  return (
    <>
      {/* Chat button desktop */}
      {showChatButton && (
        <ChatButton
          communityMemberId={communityMember.id}
          viewType={viewType}
          showChatButton={showChatButton}
          isAdmin={isAdmin}
        />
      )}

      {/* Spaces three-dots desktop */}
      {showSpaceOptions && (
        <SpaceOptionsDropdown
          communityMember={communityMember}
          space={space}
          refetch={refetch}
          showEditButton={isAdmin}
          showRemoveButton={!isCurrentUser}
        />
      )}

      {/* Space Groups three-dots desktop */}
      {showSpaceGroupOptions && (
        <SpaceGroupOptionsDropdown
          communityMember={communityMember}
          spaceGroup={spaceGroup}
          refetch={refetch}
          showEditButton={isAdmin}
          showRemoveButton={!isCurrentUser}
        />
      )}

      {/* Member directory three-dots desktop */}
      {showDirectoryOptions && (
        <DirectoryOptionsDropdown communityMember={communityMember} />
      )}

      {/* Mobile */}
      <MobileActions
        communityMemberId={communityMember.id}
        showChatButton={showChatButton}
        showEditButton={isAdmin}
        removeFromSpace={
          showSpaceOptions && (
            <SpaceOptionsDropdown
              communityMember={communityMember}
              space={space}
              refetch={refetch}
              showRemoveButton={!isCurrentUser}
              showEditButton={isAdmin}
              renderAs="link"
            />
          )
        }
        removeFromSpaceGroup={
          showSpaceGroupOptions && (
            <SpaceGroupOptionsDropdown
              communityMember={communityMember}
              spaceGroup={spaceGroup}
              showRemoveButton={!isCurrentUser}
              showEditButton={isAdmin}
              refetch={refetch}
              renderAs="link"
            />
          )
        }
      />
    </>
  );
};

MemberActions.propTypes = {
  communityMember: PropTypes.object.isRequired,
  viewType: PropTypes.oneOf(Object.values(ViewTypes)),
  space: PropTypes.object,
  isCurrentUser: PropTypes.bool,
  spaceGroup: PropTypes.object,
  refetch: PropTypes.func,
  isAdmin: PropTypes.bool,
  showChatButton: PropTypes.bool,
};
