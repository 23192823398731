import { memo } from "react";
import type { CSSProperties } from "react";
import { BlocksPreviewViewer } from "@circle-react/components/Spaces/CourseSpace/Lockscreen/BlocksViewer/BlocksPreviewViewer";
import { PREVIEW_MODE } from "@circle-react/components/Spaces/CourseSpace/Lockscreen/Providers";
import { ControlledThemeProvider } from "@circle-react/providers";
import { MobileAppPreview } from "@circle-react-shared/Previews/MobileAppPreview";
import { StyledFrame } from "@circle-react-shared/StyledFrame";

interface MobileLockscreenFrameProp {
  theme: any;
  headerSpaceTitle: string;
  blocks: any;
  border: boolean;

  width?: number;
  height?: number;
  scale?: number;
}

export const responsiveFrame: CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
};

export const MobileLockscreenFrame = memo(
  ({
    theme = {},
    headerSpaceTitle = "",
    blocks = [],
    border,
    ...rest
  }: MobileLockscreenFrameProp) => (
    <StyledFrame
      className="static"
      style={{ ...responsiveFrame, border: "0px" }}
    >
      <ControlledThemeProvider theme={theme}>
        <MobileAppPreview border={border} {...rest}>
          <MobileAppPreview.Header spaceName={headerSpaceTitle} />
          <MobileAppPreview.Body>
            <BlocksPreviewViewer
              blocks={blocks}
              previewMode={PREVIEW_MODE.MOBILE}
            />
          </MobileAppPreview.Body>
          <MobileAppPreview.Footer />
        </MobileAppPreview>
      </ControlledThemeProvider>
    </StyledFrame>
  ),
);

MobileLockscreenFrame.displayName = "MobileLockscreenFrame";
