import { useMemo } from "react";
import { classNames } from "@/react/helpers/twMergeWithCN";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";
import type { SingleSelectFilterOption } from "./SelectFilterOption";
import { getIsSingleSelectFilterParam } from "./SingleSelectFilter";

interface SelectedTextProps {
  param?: BaseFilter;
  items?: SingleSelectFilterOption[];
  className?: string;
}

const SingleSelectFilterSelectedText = ({
  param,
  items = [],
  className,
}: SelectedTextProps) => {
  const itemMap = useMemo(
    () =>
      items.reduce((acc, item) => {
        acc.set(String(item.value), item.label);
        return acc;
      }, new Map<string, string>()),
    [items],
  );

  const getLabel = (value?: unknown) => {
    if (!value) return null;
    const label = itemMap.get(String(value));
    return label ?? value;
  };

  return (
    <span className={classNames(["max-w-xs truncate", className])}>
      {getLabel(param?.value)}
    </span>
  );
};

export const renderSingleSelectFilterSelectedText = ({
  param,
  items = [],
  className,
}: SelectedTextProps) => {
  if (!param) return null;
  if (!getIsSingleSelectFilterParam(param)) return null;

  return (
    <SingleSelectFilterSelectedText
      param={param}
      items={items}
      className={className}
    />
  );
};
