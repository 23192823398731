import { Typography } from "@circle-react-shared/uikit/Typography";

interface PageProp {
  title?: string;
  subtitle?: string;
}

export const Page = ({ title, subtitle }: PageProp) => (
  <div className="bg-primary flex h-full w-full flex-col items-center justify-center text-center">
    <Typography.TitleSm weight="bold">{title}</Typography.TitleSm>
    <Typography.LabelSm>{subtitle}</Typography.LabelSm>
  </div>
);
