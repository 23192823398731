import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { get, isFunction, noop } from "lodash";
import { DataTable } from "@circle-react-uikit/DataTableV2";

export const InfiniteQueryDataTable = ({
  children,
  infiniteQuery,
  initialPage = 1,
  onChangePage = noop,
  pageDataPath = "records",
  pageNumberPath = "page",
  totalCountPath = "count",
  ...rest
}) => {
  const [pageNumber, setPageNumber] = useState(initialPage ? initialPage : 1);

  useEffect(() => {
    setPageNumber(initialPage);
  }, [initialPage]);

  if (!infiniteQuery) return null;

  const getPageNumber = (page = {}) => get(page, pageNumberPath, -1);
  const getPageData = (page = {}) => get(page, pageDataPath, []);
  const getTotalCount = (page = {}) => get(page, totalCountPath, 0);

  const {
    data = {},
    fetchNextPage,
    fetchPreviousPage,
    isLoading,
    isFetchingNextPage,
    isFetchingPreviousPage,
  } = infiniteQuery;

  const { pages = [] } = data;
  const firstPage = pages[0] || {};
  const totalRecordsCount = getTotalCount(firstPage);
  const currentPage = pages.find(page => getPageNumber(page) === pageNumber);
  const currentPageData = getPageData(currentPage);

  const handleChangedPage = newPage => {
    const alreadyFetched = pages.some(x => getPageNumber(x) === newPage);

    if (newPage > pageNumber && !alreadyFetched) {
      fetchNextPage();
    } else if (newPage < pageNumber && !alreadyFetched) {
      fetchPreviousPage();
    }

    setPageNumber(newPage);

    if (isFunction(onChangePage)) {
      onChangePage(newPage);
    }
  };

  return (
    <DataTable
      data={currentPageData}
      totalRecordsCount={totalRecordsCount}
      isLoading={isLoading || isFetchingNextPage || isFetchingPreviousPage}
      initialPage={initialPage}
      onChangePage={handleChangedPage}
      {...rest}
    >
      {children}
    </DataTable>
  );
};

InfiniteQueryDataTable.propTypes = {
  children: PropTypes.node,
  infiniteQuery: PropTypes.shape({
    data: PropTypes.shape({
      pages: PropTypes.arrayOf(PropTypes.object),
    }),
    fetchNextPage: PropTypes.func,
    fetchPreviousPage: PropTypes.func,
    isLoading: PropTypes.bool,
    isFetchingNextPage: PropTypes.bool,
    isFetchingPreviousPage: PropTypes.bool,
  }),
  initialPage: PropTypes.number,
  onChangePage: PropTypes.func,
  pageDataPath: PropTypes.string,
  pageNumberPath: PropTypes.string,
  pageSize: PropTypes.number,
  perPagePath: PropTypes.string,
  totalCountPath: PropTypes.string,
};
