export const formId = "cancel-and-refund-subscription";

export const ENDS_AT_OPTIONS = Object.freeze({
  NOW: "now",
  AT_PERIOD_END: "at_period_end",
});

export const REFUND_TYPE_OPTIONS = Object.freeze({
  PRORATED: "prorated",
  FULL: "full",
  NO_REFUND: "no_refund",
});
