import { useEffect } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import { LinkItem } from "./LinkItem";
import { useCollapsedSidebarLinksV3 } from "./useCollapsedSidebarLinksV3";

export const CollapsedSidebar = () => {
  const settingsNavLinksV3 = useCollapsedSidebarLinksV3();
  const { totalUnreadPausedAiChatsCount, fetchPausedAiUnreadChatsCount } =
    useCommunityInboxStore();

  // API to fetch unread chats count
  useEffect(() => {
    void fetchPausedAiUnreadChatsCount();
  }, [fetchPausedAiUnreadChatsCount]);

  return (
    <OverlayScrollbarsComponent
      className="border-r-primary h-[calc(100vh-64px)] w-16 border-r"
      options={{
        scrollbars: {
          autoHide: "leave",
          autoHideDelay: 0,
        },
      }}
    >
      <div className="bg-primary flex h-full flex-col items-center justify-start space-y-2 p-3">
        {settingsNavLinksV3.map(({ pathKey, label, path, icon, enabled }) => {
          if (!enabled) return null;
          const isCommunityInboxPage = pathKey === "community_inbox";
          return (
            <LinkItem
              key={label}
              pathKey={pathKey}
              label={label}
              path={path}
              icon={icon}
              shouldShowUnreadCount={
                isCommunityInboxPage && totalUnreadPausedAiChatsCount > 0
              }
              unreadCount={totalUnreadPausedAiChatsCount}
            />
          );
        })}
      </div>
    </OverlayScrollbarsComponent>
  );
};
