import type { Dispatch, SetStateAction } from "react";
import { t } from "@/i18n-js/instance";
import { DebouncedSearchBar } from "@circle-react/components/shared/DebouncedSearchBar";
import { Typography } from "@circle-react-shared/uikit/Typography";

const localeNamespace = "settings.emails.builder.recipient_modal";

interface SearchableRecipientListProps {
  setSearchTerm: Dispatch<SetStateAction<string>>;
  count: number;
  formattedCount: string;
}

export const SearchableRecipientList = ({
  setSearchTerm,
  count,
  formattedCount,
}: SearchableRecipientListProps) => (
  <>
    <div className="border-primary border-b px-8 pb-4 pt-1">
      <div className="border-primary overflow-hidden rounded-lg border px-3 py-2">
        <DebouncedSearchBar
          onSearch={setSearchTerm}
          placeholder={t("settings.emails.view_broadcast.search_name_or_email")}
          withIcon={false}
        />
      </div>
    </div>
    <div className="px-8 py-4">
      <Typography.LabelMd weight="semibold" color="text-very-dark">
        {t([localeNamespace, "x_recipients"], {
          count,
          formattedCount,
        })}
      </Typography.LabelMd>
    </div>
  </>
);
