import { useMemo } from "react";
import { classNames } from "@/react/helpers/twMergeWithCN";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";
import type { SingleSelectFilterOption } from "./SelectFilterOption";
import { getIsSingleSelectFilterParam } from "./SingleSelectFilter";

interface SelectedTextProps {
  param?: BaseFilter;
  items?: SingleSelectFilterOption[];
  className?: string;
}

export const SelectedText = ({
  param,
  items = [],
  className,
}: SelectedTextProps) => {
  const itemMap = useMemo(
    () =>
      items.reduce((acc, item) => {
        acc.set(String(item.value), item.label);
        return acc;
      }, new Map<string, string>()),
    [items],
  );

  const getLabel = (value?: string) => {
    if (!value) return null;
    const label = itemMap.get(value);
    return label ?? value;
  };

  if (!param) return null;
  if (!getIsSingleSelectFilterParam(param)) return null;

  return (
    <span className={classNames(["max-w-xs truncate", className])}>
      {getLabel(param.value)}
    </span>
  );
};
