import {
  ModalManager,
  useModal,
} from "@circle-react/components/Layout/ModalManagerProvider";
import { CreatePaywallSubscriptionFormContextProvider } from "@circle-react-shared/Paywalls/Modals/CreatePaywallSubscriptionModal/Form/CreatePaywallSubscriptionFormContextProvider";
import { Form } from "@circle-react-uikit/Form";
import { ApplyCouponContextProvider } from "./ApplyCouponContextProvider";
import { ModalTemplate } from "./ModalTemplate";
import { ApplyingCouponStep } from "./Steps/ApplyingCouponStep";
import { PreviewCouponStep } from "./Steps/PreviewCouponStep";
import { SelectCouponStep } from "./Steps/SelectCouponStep";
import { useApplyCoupon } from "./hooks/useApplyCoupon";

const formId = "apply-paywall-coupon";

export const ApplyCouponModal = ({ subscription, shouldRemoveCoupon }: any) => {
  const { onSubmit, applyCouponMutation, removeCouponMutation } =
    useApplyCoupon({ subscription, shouldRemoveCoupon });

  return (
    <ApplyCouponContextProvider
      removeCouponMutation={removeCouponMutation}
      applyCouponMutation={applyCouponMutation}
      onSubmit={onSubmit}
      shouldRemoveCoupon={shouldRemoveCoupon}
    >
      <ModalTemplate>
        <Form
          id={formId}
          onSubmit={onSubmit}
          defaultValues={{}}
          formClassName="flex flex-col pt-4"
        >
          <CreatePaywallSubscriptionFormContextProvider>
            <SelectCouponStep subscription={subscription} />
            <PreviewCouponStep subscription={subscription} />
            <ApplyingCouponStep />
          </CreatePaywallSubscriptionFormContextProvider>
        </Form>
      </ModalTemplate>
    </ApplyCouponContextProvider>
  );
};

export const useApplyCouponModal = () =>
  useModal(ModalManager.create(ApplyCouponModal));
