import PropTypes from "prop-types";
import classNames from "classnames";
import { usePunditUserContext } from "@circle-react/contexts";
import { useMdScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { DropdownFilter } from "./DropdownFilter";
import { HasPastDueSubscriptionFilter } from "./HasPastDueSubscriptionFilter";
import { MemberTagsFilter } from "./MemberTagsFilter";
import { MobileFiltersTransitionWrapper } from "./MobileFiltersTransitionWrapper";
import { OnlineFilter } from "./OnlineFilter";
import { PaywallsFilter } from "./PaywallsFilter";
import { RoleFilter } from "./RoleFilter";
import { SpaceGroupsFilter } from "./SpaceGroupsFilter";
import { SpacesFilter } from "./SpacesFilter";
import "./styles.scss";

export const Filters = ({
  shouldShowSpacesFilter = true,
  shouldShowAdminFilters = false,
  shouldBeHiddenOnMd = true,
  shouldRenderMobileFiltersTransition,
  className,
}) => {
  const isMd = useMdScreenMediaQuery();
  const { currentCommunityMember } = usePunditUserContext();
  const shouldShowMemberTagsFilter = !!currentCommunityMember;

  return (
    <MobileFiltersTransitionWrapper
      shouldRenderMobileFiltersTransition={shouldRenderMobileFiltersTransition}
    >
      <div
        className={classNames(
          "member-directory-filters !flex flex-wrap gap-2",
          {
            /*
          We hide this whole filters on MembersDirectory (/members) on display
          widths from `md` and up, because MembersDirectory uses the new DynamicFilters.

          Other legacy places like V1 SpaceMembers (c/space-slug/members)
          and SpaceGroupMembers (s/group-slug/members) don't use the DynamicFilters
          so this filters are used across all display widths.
        */
            "!hidden": isMd && shouldBeHiddenOnMd,
          },
          className,
        )}
      >
        <DropdownFilter queryParam="bio" type="text" />
        <DropdownFilter queryParam="headline" type="text" />
        <DropdownFilter queryParam="location" type="text" />
        <RoleFilter />
        {shouldShowSpacesFilter && <SpacesFilter />}
        {shouldShowSpacesFilter && <SpaceGroupsFilter />}
        {shouldShowMemberTagsFilter && <MemberTagsFilter />}
        <OnlineFilter />
        {shouldShowAdminFilters && <PaywallsFilter />}
        {shouldShowAdminFilters && <HasPastDueSubscriptionFilter />}
      </div>
    </MobileFiltersTransitionWrapper>
  );
};

Filters.propTypes = {
  shouldShowSpacesFilter: PropTypes.bool,
  shouldShowAdminFilters: PropTypes.bool,
  shouldBeHiddenOnMd: PropTypes.bool,
  className: PropTypes.string,
};
