import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { LargeRectangle } from "@circle-react-shared/Placeholders";
import { Typography } from "@circle-react-uikit/Typography";

export const TagsPreview = ({ className, ...rest }) => (
  <div
    className={classNames(
      "border-primary bg-primary h-[148px] space-y-4 rounded-lg border p-5",
      className,
    )}
    {...rest}
  >
    <div className="flex h-5 items-center">
      <Typography.LabelMd weight="semibold">
        {t("customize_ui.tags")}
      </Typography.LabelMd>
    </div>
    <div className="flex flex-wrap gap-3">
      <LargeRectangle className="w-[80px]" />
      <LargeRectangle className="w-[64px]" />
      <LargeRectangle className="w-[44px]" />
      <LargeRectangle className="w-[120px]" />
      <LargeRectangle className="w-[112px]" />
    </div>
  </div>
);

TagsPreview.propTypes = {
  className: PropTypes.string,
};
