import { Modal } from "@circle-react-uikit/ModalV2";
import { useApplyCouponContext } from "./ApplyCouponContextProvider";

export const ModalTemplate = ({ children }: any) => {
  const {
    modal,
    onClose,
    onCloseModal,
    backButtonOnClick,
    shouldHiddenBackButton,
    shouldHiddenCloseButton,
    title,
  } = useApplyCouponContext();

  return (
    <Modal
      isOpen={modal.visible}
      onClose={onCloseModal}
      contentPortalId="apply-coupon-modal"
    >
      <Modal.Overlay />
      <Modal.Content size="md">
        <Modal.Header>
          {title && (
            <Modal.Title size="md" className="text-center">
              {title}
            </Modal.Title>
          )}
          {!shouldHiddenCloseButton && (
            <Modal.HeaderActions className="absolute right-0 top-0 mr-6 mt-6">
              <Modal.CloseButton onClick={onClose} />
            </Modal.HeaderActions>
          )}
          {!shouldHiddenBackButton && (
            <Modal.HeaderActions className="absolute left-0 top-0 ml-6 mt-6">
              <Modal.BackButton onClick={backButtonOnClick} />
            </Modal.HeaderActions>
          )}
        </Modal.Header>
        {children}
      </Modal.Content>
    </Modal>
  );
};
