export interface SelectImageButtonProps {
  text?: string;
}

export const SelectImageButton = ({ text = "" }: SelectImageButtonProps) => (
  <button
    type="button"
    className="text-dark bg-primary border-primary flex h-[88px] w-full cursor-pointer items-center justify-center rounded-md border py-6 hover:border-blue-500 hover:text-blue-500 focus:border-blue-500 focus:text-blue-500"
  >
    {text}
  </button>
);
