import PropTypes from "prop-types";
import classnames from "classnames";
import { find, noop } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { SpaceGroupsDropdown } from "./SpaceGroupsDropdown";
import "./styles.scss";

export const SpaceGroupSelect = ({
  name,
  rules = {},
  onChange = noop,
  ...passThroughProps
}) => {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <Component
          {...passThroughProps}
          name={field.name}
          value={field.value}
          onChange={value => {
            field.onChange(value);
            onChange?.();
            setValue(name, value, {
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true,
            });
          }}
        />
      )}
    />
  );
};

SpaceGroupSelect.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  variant: PropTypes.oneOf(["form-input", "large"]),
};

const Component = ({ spaceGroups, onChange, value, variant, appendTo }) => {
  const selectedSpaceGroup = find(spaceGroups, { id: value });
  const containerClassnames = classnames("space-group-selector", {
    "form-control": variant === "form-input",
    large: variant === "large",
  });

  return (
    <div className={containerClassnames}>
      <SpaceGroupsDropdown
        spaceGroups={spaceGroups}
        selectedSpaceGroup={selectedSpaceGroup}
        onChange={onChange}
        appendTo={appendTo}
      />
    </div>
  );
};

Component.propTypes = {
  appendTo: PropTypes.element,
  spaceGroups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(["form-input", "large"]),
};
