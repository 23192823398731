import { t } from "@/i18n-js/instance";
import { DropdownFilter } from "../DropdownFilter";

export const HasPastDueSubscriptionFilter = () => {
  const options = [
    {
      label: t("boolean_filter_options.yes"),
    },
    {
      label: t("boolean_filter_options.no"),
    },
  ];

  return (
    <DropdownFilter
      queryParam="has_past_due_subscription"
      type="select"
      options={options}
    />
  );
};
