import { useMemo, useState } from "react";
import { isNil } from "lodash";
import { nanoid } from "nanoid";
import type { FieldTypes } from "@circle-react-shared/ProfileFields/constants";
import { useFilterValueContext } from "../FilterValueContext";
import type { BaseFilter, ProfileFieldBaseFilter } from "../types";
import { createBaseFilter, isProfileFieldBaseFilter } from "../types";

export interface AddFilterArgs {
  key: string;
  profile_field_id?: number;
  profile_field_type?: FieldTypes;
}

interface UseDynamicFiltersArgs {
  defaultFilterNames: string[];
}

export const useDynamicFilters = ({
  defaultFilterNames,
}: UseDynamicFiltersArgs) => {
  const { filterParams, onAdd } = useFilterValueContext();

  const getInitialDisplayedFilters = () => {
    const initialDisplayedFilters: Array<BaseFilter | ProfileFieldBaseFilter> =
      [];
    const alreadyIncludedFilterNames = new Set<string>();

    const getDisplayFilterParam = (filterParam: BaseFilter) => {
      if (isProfileFieldBaseFilter(filterParam)) {
        return initialDisplayedFilters.push({
          id: filterParam.id,
          key: filterParam.key,
          profile_field_id: filterParam.profile_field_id,
          profile_field_type: filterParam.profile_field_type,
        });
      }
      initialDisplayedFilters.push({
        id: filterParam.id,
        key: filterParam.key,
      });
      return undefined;
    };

    defaultFilterNames.forEach((filterItemName: string) => {
      const includedFilterParam = filterParams.find(
        filterParam => filterParam.key === filterItemName,
      );
      if (includedFilterParam) {
        alreadyIncludedFilterNames.add(filterItemName);
        getDisplayFilterParam(includedFilterParam);
      } else {
        initialDisplayedFilters.push(createBaseFilter({ key: filterItemName }));
      }
    });

    filterParams.forEach(filterParam => {
      if (alreadyIncludedFilterNames.has(filterParam.key)) {
        alreadyIncludedFilterNames.delete(filterParam.key);
      } else {
        getDisplayFilterParam(filterParam);
      }
    });
    return initialDisplayedFilters;
  };
  const [displayedFilters, setDisplayedFilters] = useState<
    BaseFilter[] | ProfileFieldBaseFilter[]
  >(getInitialDisplayedFilters);

  const isClearFiltersShown = useMemo(
    () => filterParams.some(({ value }) => !isNil(value)),
    [filterParams],
  );

  const handleClearFilters = () => {
    /* This cannot add profile field filters by default. But that shouldn't be an issue now. */
    setDisplayedFilters(
      defaultFilterNames.map(name => createBaseFilter({ key: name })),
    );
  };

  const handleAddFilter = ({
    key,
    profile_field_id,
    profile_field_type,
  }: AddFilterArgs) => {
    const newFilterParam = {
      id: nanoid(),
      key,
      profile_field_id,
      profile_field_type,
      isInitialOpen: true,
    };
    onAdd?.(newFilterParam);
    setDisplayedFilters(prevState => [...prevState, newFilterParam]);
  };

  const handleRemoveFilter = (id: string) => {
    setDisplayedFilters(prevState =>
      prevState.filter(filter => filter.id !== id),
    );
  };

  return {
    displayedFilters,
    isClearFiltersShown,
    onClearFilters: handleClearFilters,
    onAddFilter: handleAddFilter,
    onRemoveFilter: handleRemoveFilter,
  };
};
