import { t } from "@/i18n-js/instance";
import { useSpacesForFilters } from "@/react/components/Spaces/hooks/useSpacesForFilters";
import { useSpacesContext } from "@circle-react/contexts";
import { Filter } from "@circle-react-shared/FilterV3";
import { Loader } from "@circle-react-uikit/Loader";
import { ArrayFilterComparator } from "../../Filter/FilterTypes/ArrayFilterComparator/ArrayFilterComparator";
import {
  type ArrayComparatorParam,
  handleArrayComparatorApply,
} from "../../Filter/FilterTypes/ArrayFilterComparator/arrayComparators";
import { useArrayComparatorSelectedItem } from "../../Filter/FilterTypes/ArrayFilterComparator/useArrayComparatorSelectedItem";
import type { BaseFilter } from "../types";

const localeNamespace = "members_directory.header.search.filters.spaces";

interface SpacesFilterProps {
  name: string;
  isInitialOpen?: boolean;
  onApply?: (value: ArrayComparatorParam | null) => void;
  param: BaseFilter;
}

export const SpacesFilter = ({
  name,
  isInitialOpen,
  onApply,
  param,
}: SpacesFilterProps) => {
  const { helpers } = useSpacesContext();
  const spaces = useSpacesForFilters({
    spaces: helpers.spaceWithViewContentAccess(),
  });
  const renderSelectedValue = useArrayComparatorSelectedItem(spaces);

  return (
    <Filter onApply={param => handleArrayComparatorApply(param, onApply)}>
      <Filter.Chip renderSelectedValue={() => renderSelectedValue(param)}>
        {t([localeNamespace, "singular_title"])}
      </Filter.Chip>

      <Filter.Popover
        title={t([localeNamespace, "title"])}
        isInitialOpen={isInitialOpen}
      >
        {helpers.isDataLoading() ? (
          <Loader />
        ) : (
          <ArrayFilterComparator name={name} options={spaces} param={param} />
        )}
      </Filter.Popover>
    </Filter>
  );
};
