import type { ComponentPropsWithoutRef } from "react";
import classNames from "classnames";
import { MembersPreview } from "../MembersPreview";

export interface RightSidebarPreviewProps
  extends ComponentPropsWithoutRef<"div"> {}

export const RightSidebarPreview = ({
  className,
  ...rest
}: RightSidebarPreviewProps) => (
  <div className={classNames("space-y-[20px]", className)} {...rest}>
    <MembersPreview />
  </div>
);
