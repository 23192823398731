import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-uikit/Typography";
import { PropertyLine } from "./PropertyLine";

const localeStringRoot =
  "settings.paywalls_admin_dashboard.charges.charge_details_modal";

export const AmountPaidProperty = ({ charge }: any) => (
  <PropertyLine title={t(`${localeStringRoot}.details_labels.amount_paid`)}>
    <Typography.LabelSm>
      {charge.display_amount_with_currency}
    </Typography.LabelSm>
  </PropertyLine>
);
