import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Tab } from "@circle-react-uikit/Tabs/Tab";
import { TabNavigationView } from "./TabNavigationView";

const TabNaVigationData = createContext({
  tabs: [],
  currentTab: "",
  setCurrentTab: noop,
});
TabNaVigationData.displayName = "TabNaVigationData";

export const useTabNavigation = () => useContext(TabNaVigationData);

export const TabNavigation = ({
  children,
  initialTab = "",
  tabs = [],
  ...rest
}) => {
  const [currentTab, setCurrentTab] = useState(
    initialTab || tabs[0]?.label || "",
  );

  return (
    <TabNaVigationData.Provider
      value={{
        tabs,
        currentTab,
        setCurrentTab,
      }}
    >
      <div className="flex h-full w-full flex-col">
        <TabNavigationView
          tabs={tabs}
          currentTab={currentTab}
          onTabClick={tab => setCurrentTab(tab)}
          {...rest}
        />
        <div className="flex-1">{children}</div>
      </div>
    </TabNaVigationData.Provider>
  );
};

TabNavigation.propTypes = {
  children: PropTypes.node.isRequired,
  initialTab: PropTypes.string,
  tabs: PropTypes.arrayOf(Tab.propTypes),
};
