import { useMemo } from "react";
import PropTypes from "prop-types";
import { uniqueId } from "lodash";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-uikit/Form";

export const RadioElement = ({ option }) => {
  const { label, value, direction = "days_ago" } = option;
  const radioButtonId = useMemo(uniqueId, []);
  const inputId = useMemo(uniqueId, []);
  const { register, watch } = useFormContext();
  const radioInputName = `filter_type`;
  const radioInputChecked = watch(radioInputName);

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex items-baseline gap-x-2">
        <input
          type="radio"
          id={radioButtonId}
          {...register(radioInputName)}
          value={value}
          className="self-center"
        />
        <label htmlFor={radioButtonId} className="m-0 flex-1">
          {label}
        </label>
      </div>
      {radioInputChecked === value && (
        <div className="flex items-center gap-x-2">
          <div className="w-40">
            <Form.Item
              hideLabel
              hideDescription
              isFirstChild
              id={inputId}
              hideBorder
              hideError
              name="value"
            >
              <Form.Input type="number" autoFocus min="0" />
            </Form.Item>
          </div>
          <label htmlFor={inputId}>{t(["filters", direction])}</label>
        </div>
      )}
    </div>
  );
};

RadioElement.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};
