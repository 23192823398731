import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { UserImage } from "@circle-react-shared/uikit/UserImage";

export interface MobileHeaderPreviewProps {
  userImageUrl?: string | null;
  communityLogoUrl?: string | null;
  userName?: string;
  communityName?: string;
  className?: string;
}

export const MobileHeaderPreview = ({
  userImageUrl = "",
  communityLogoUrl = "",
  userName = "",
  communityName = "",
  className,
}: MobileHeaderPreviewProps) => (
  <div className={classNames("lg:hidden", className)}>
    <header id="mainHeader" className="header header-v2">
      <div className="header__inside">
        <div className="header__menu">
          <button
            type="button"
            className="toggle-button"
            onClick={event => event.preventDefault()}
          >
            <svg className="icon icon-menu icon--hamburger" viewBox="0 0 24 24">
              <use xlinkHref="#icon-menu" />
            </svg>
          </button>
        </div>
        <div className="header__left">
          <a
            href="/"
            className="community__brand"
            onClick={event => event.preventDefault()}
          >
            {communityLogoUrl ? (
              <div className="community__logo">
                <img
                  loading="lazy"
                  alt={t("community_logo_alt", {
                    community_name: communityName,
                  })}
                  src={communityLogoUrl}
                />
              </div>
            ) : (
              <div className="name">{communityName}</div>
            )}
          </a>
        </div>
        <div className="header__actions">
          <div className="header__user">
            <div className="user-menu">
              <button
                type="button"
                onClick={event => event.preventDefault()}
                aria-label={userName}
              >
                <UserImage src={userImageUrl ?? ""} name={userName} size="10" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
);
