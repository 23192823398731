import { Icon } from "@circle-react-shared/Icon";

export const footerHeightInRem = 4.875;

export const Footer = () => (
  <div
    className="bg-primary absolute bottom-0 flex w-full flex-col gap-y-6"
    style={{ height: `${footerHeightInRem}rem` }}
  >
    <div className="border-primary sticky bottom-0 left-0 flex w-full flex-row justify-center gap-x-12 border-t pt-4">
      <Icon type="home-v2" className="!w-5" viewBox="0 0 16 16" />
      <Icon type="search-v2" className="!w-6" viewBox="0 0 20 20" />
      <Icon type="plus-v2" className="!w-6" viewBox="0 0 16 16" />
      <Icon type="20-bell-v3" className="!w-6" viewBox="0 0 20 20" />
      <Icon type="16-messages" className="!w-5" viewBox="0 0 16 16" />
    </div>
    <div
      style={{ height: "0.313rem", width: "9.6rem" }}
      className="sticky self-center rounded-full bg-[black]"
    />
  </div>
);
