import { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import type { ProfileFieldBaseFilter } from "@circle-react-shared/MemberFilters/types";

type ProfileFieldInputsProps = Pick<
  ProfileFieldBaseFilter,
  "profile_field_id" | "profile_field_type"
>;

export function ProfileFieldInputs({
  profile_field_id,
  profile_field_type,
}: ProfileFieldInputsProps) {
  const { register } = useFormContext();

  return (
    <Fragment>
      <input
        type="hidden"
        {...register("profile_field_type")}
        value={profile_field_type}
      />
      <input
        type="hidden"
        {...register("profile_field_id")}
        value={profile_field_id}
      />
    </Fragment>
  );
}
