import { t } from "@/i18n-js/instance";
import type { SelectedAccessGroupType } from "@circle-react/types/AccessGroup";
import { Dropdown } from "@circle-react-uikit/Dropdown";
import { useAccessGroupConfirmationModal } from "../AccessGroupConfirmationModal";
import { useAccessGroupsActions } from "../hooks/useAccessGroupsActions";

export const RemoveAction = ({
  accessGroup,
}: {
  accessGroup: SelectedAccessGroupType;
}) => {
  const { shouldShowRemoveConfirmationModal, removeAccessGroup } =
    useAccessGroupsActions();

  const removeConfirmationModal = useAccessGroupConfirmationModal();

  if (!accessGroup) {
    return null;
  }

  const removeAccessGroupHandler = (accessGroup: SelectedAccessGroupType) => {
    if (shouldShowRemoveConfirmationModal(accessGroup)) {
      void removeConfirmationModal.show({
        i18nRoot:
          "settings.access_groups.admin.entry_points.association.actions.remove_confirmation",
        onConfirm: () => {
          removeAccessGroup(accessGroup);
        },
      });
    } else {
      removeAccessGroup(accessGroup);
    }
  };

  return (
    <Dropdown.ItemWithLink
      onClick={event => {
        removeAccessGroupHandler(accessGroup);
        event.preventDefault();
      }}
    >
      {t(
        "settings.access_groups.admin.entry_points.association.actions.remove",
      )}
    </Dropdown.ItemWithLink>
  );
};
