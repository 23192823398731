import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import type { Link } from "../NestedNavBar/types";
import { NavGroupContent } from "./NavGroupContent";
import { NavGroupIcon } from "./NavGroupIcon";

export interface NavGroupContextValue {
  isActive: boolean;
}

export interface NavGroupProps {
  children: ReactNode;
  groupLinks?: Link[];
}

const NavGroupContext = createContext<NavGroupContextValue | null>(null);
NavGroupContext.displayName = "NavGroupContext";

export const useNavContext = (): NavGroupContextValue => {
  const context = useContext(NavGroupContext);

  if (!context) {
    throw new Error(
      "useNavContext must be used within a NavGroupContext.Provider",
    );
  }

  return context;
};

export const NavGroup = ({ children, groupLinks = [] }: NavGroupProps) => {
  const { pathname } = useLocation();
  const [isActive, setIsActive] = useState<boolean | null>(null);

  useEffect(() => {
    if (groupLinks.length > 0) {
      const isActive = groupLinks.some(link =>
        matchPath(pathname, {
          path: link.path,
          exact: link.exact,
        }),
      );
      setIsActive(isActive);
    }
  }, [groupLinks, pathname]);

  const value = {
    isActive: Boolean(isActive),
  };

  return (
    <NavGroupContext.Provider value={value}>
      <div>{children}</div>
    </NavGroupContext.Provider>
  );
};

NavGroup.Icon = NavGroupIcon;
NavGroup.Content = NavGroupContent;
