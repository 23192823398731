import type { ReactNode } from "react";
import { RadioGroup } from "@headlessui/react";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";

export interface SingleSelectFilterOption {
  label: string;
  richLabel?: ReactNode;
  value: string;
}

type SingleSelectFilterOptions = ReadonlyArray<SingleSelectFilterOption>;

interface SelectFilterOption {
  option: SingleSelectFilterOptions[number];
}

export function SelectFilterOption({ option }: SelectFilterOption) {
  return (
    <RadioGroup.Option
      value={option.value}
      className="text-selector-active hover:bg-secondary [data-headlessui-state:active]:bg-secondary [data-headlessui-state:checked]:bg-secondary flex cursor-pointer items-center justify-between px-4 py-3"
    >
      {({ checked }) => (
        <>
          <Typography.LabelMd weight={checked ? "semibold" : "normal"}>
            {option.richLabel ?? option.label}
          </Typography.LabelMd>
          {checked && (
            <span>
              <Icon type="check" aria-hidden />
            </span>
          )}
        </>
      )}
    </RadioGroup.Option>
  );
}
