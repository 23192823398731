import { useMemo } from "react";
import { nameToInitials } from "@circleco/ui-kit";
import { t } from "@/i18n-js/instance";
import { useGetMarketingEmailAddressSettings } from "@circle-react/components/SettingsApp/Emails/hooks/useGetMarketingEmailAddressSettings";
import { Typography } from "@circle-react-shared/uikit/Typography";

interface PreviewHeaderProps {
  name?: string;
  email?: string;
  subject?: string;
  previewText?: string;
}

const i18nRoot = "settings.emails.broadcasts";

export const PreviewHeader = ({ subject, previewText }: PreviewHeaderProps) => {
  const { data } = useGetMarketingEmailAddressSettings();
  const name = data?.sender_name || t([i18nRoot, "default_sender_name"]);
  const email = useMemo(() => {
    if (data?.sender_email_local_part && data?.sender_email_domain) {
      return `${data.sender_email_local_part}@${data.sender_email_domain}`;
    }
    return t([i18nRoot, "default_sender_email"]);
  }, [data]);

  const initials = useMemo(() => nameToInitials(name), [name]);

  const renderReceiverDetails = () => (
    <div className="flex flex-row items-center gap-x-2">
      <span className="bg-overlay-dark text-default flex aspect-square h-6 w-6 items-center justify-center rounded-full text-xs">
        {initials}
      </span>
      <Typography.LabelXs color="text-light">{`${name} <${email}>`}</Typography.LabelXs>
    </div>
  );

  const renderSubject = () => {
    if (subject) {
      return (
        <Typography.LabelSm weight="semibold">{subject}</Typography.LabelSm>
      );
    }
    return (
      <Typography.LabelSm weight="semibold">
        {t([i18nRoot, "default_subject"])}
      </Typography.LabelSm>
    );
  };

  const renderPreviewText = () => {
    if (previewText) {
      return (
        <Typography.LabelXs color="text-default">
          {previewText}
        </Typography.LabelXs>
      );
    }
    return (
      <Typography.LabelXs color="text-default">
        {t([i18nRoot, "default_preview"])}
      </Typography.LabelXs>
    );
  };

  return (
    <div className="border-b-primary flex flex-col gap-2 border-b px-4 py-3">
      <div className="flex flex-col gap-1">
        {renderSubject()}
        {renderPreviewText()}
      </div>
      {renderReceiverDetails()}
    </div>
  );
};
