import PropTypes from "prop-types";
import isFunction from "lodash/isFunction";
import { Tabs } from "@circle-react-uikit/Tabs";
import { Tab } from "@circle-react-uikit/Tabs/Tab";

export const TabNavigationView = ({
  tabs,
  currentTab,
  onTabClick,
  ...rest
}) => (
  <Tabs
    variant="underline"
    className="sticky grow-0"
    wrapperClassName="px-8 overflow-x-auto"
    controlled
    activeTab={currentTab}
    onClick={tab => isFunction(onTabClick) && onTabClick(tab)}
    options={tabs}
    {...rest}
  />
);

TabNavigationView.propTypes = {
  tabs: PropTypes.arrayOf(Tab.propTypes),
  currentTab: PropTypes.string,
  onTabClick: PropTypes.func,
};
