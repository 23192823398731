import { t } from "@/i18n-js/instance";
import { isRefundedOrPartialRefunded } from "@circle-react/helpers/communityMemberChargeHelpers";
import { Typography } from "@circle-react-uikit/Typography";
import { PropertyLine } from "./PropertyLine";

const localeStringRoot =
  "settings.paywalls_admin_dashboard.charges.charge_details_modal";

export const AmountRefundedProperty = ({ charge }: any) =>
  isRefundedOrPartialRefunded(charge) && (
    <PropertyLine
      title={t(`${localeStringRoot}.details_labels.amount_refunded`)}
    >
      <Typography.LabelSm>
        {charge.display_refunded_amount_with_currency}
      </Typography.LabelSm>
    </PropertyLine>
  );
