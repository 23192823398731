import { shuffle } from "lodash";
import { t } from "@/i18n-js/instance";

const reasonLocaleRoot =
  "settings.billing.marketing_hub_plan_subscription_cancellation.reasons";

const reasonFormLocaleRoot = "marketing_hub.cancellation_modal.reason_form";

export const otherOption = "other";

const REASON_KEYS = [
  "bugs",
  "cost",
  "missing_features",
  "no_need",
  "pause",
  "competitor",
] as const;

const emptyOption = {
  label: t([reasonFormLocaleRoot, "reason_prompt"]),
  value: "",
};

// Other is always the last option and other keys are shuffled
const randomisedReasonKeys = [...shuffle(REASON_KEYS), otherOption];

export const reasonOptions = [
  emptyOption,
  ...randomisedReasonKeys.map(key => ({
    label: t([reasonLocaleRoot, key]),
    value: key,
  })),
];
