/* eslint-disable eslint-comments/no-restricted-disable -- Search input wrapper component */
import { useRef } from "react";
import type { KeyboardEvent } from "react";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";

interface Props {
  handleSearch: (e: KeyboardEvent<HTMLFormElement>) => void;
}

const localeNamespace = "settings.emails.view_broadcast.reports_tab";

export const RecipientSearchInput = ({ handleSearch }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- This is a search input
    <div className="flex items-center pb-4 pt-5" onClick={handleClick}>
      <form
        className="focus-within:border-very-dark flex h-8 w-[240px] gap-2 rounded-md border px-3 py-1"
        role="search"
        onSubmit={handleSearch}
      >
        <Icon
          type="search-v2"
          className="text-default !h-3 !w-3"
          size={18}
          useWithFillCurrentColor
        />
        <input
          ref={inputRef}
          className="text-placeholder sm:bg-primary placeholder:text-light w-full rounded border-none bg-transparent p-0 text-xs outline-none placeholder:opacity-100 focus:shadow-none focus:outline-none focus:ring-0 sm:rounded-none"
          placeholder={t([localeNamespace, "search_placeholder"])}
          data-name="v2-search"
          data-testid="v2-search"
          aria-autocomplete="list"
          type="search"
          name="search"
        />
      </form>
    </div>
  );
};
