import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Typography } from "@circle-react-uikit/Typography";
import { MemberActions } from "./MemberActions";

export const CardHeader = ({
  communityMember,
  shouldShowHiddenMemberTooltip = false,
}) => {
  const shouldShowHiddenMemberIcon =
    shouldShowHiddenMemberTooltip &&
    !communityMember.visible_in_member_directory;
  const renderTagline = () => communityMember.headline;
  const isSmScreen = useSmScreenMediaQuery();

  const MemberResponsiveTitleComponent = isSmScreen
    ? Typography.LabelSm
    : Typography.LabelLg;

  return (
    <div className="flex items-start justify-between">
      <div className="flex flex-col gap-y-0 md:gap-y-1">
        <header className="flex items-center gap-x-2" data-testid="member-name">
          <MemberResponsiveTitleComponent weight="semibold">
            {communityMember.name}
          </MemberResponsiveTitleComponent>
          {shouldShowHiddenMemberIcon && (
            <div
              aria-label={t(
                "members_directory.community_members.hidden_profile",
              )}
            >
              <TippyV2
                placement="right"
                content={t(
                  "members_directory.community_members.hidden_profile",
                )}
              >
                <Icon type="eye-closed-solid" size={16} />
              </TippyV2>
            </div>
          )}
        </header>
        <div className="text-default hidden text-left md:flex">
          <Typography.LabelXs>{renderTagline()}</Typography.LabelXs>
        </div>
        <span className="text-default flex md:hidden">
          <Typography.LabelXs>{communityMember.email}</Typography.LabelXs>
        </span>
      </div>
      <MemberActions communityMember={communityMember} />
    </div>
  );
};

CardHeader.propTypes = {
  communityMember: PropTypes.shape({
    name: PropTypes.string,
    visible_in_member_directory: PropTypes.bool,
    profile_info: PropTypes.shape({
      location: PropTypes.string,
    }),
    headline: PropTypes.string,
  }),
  shouldShowHiddenMemberTooltip: PropTypes.bool,
};
