import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";

const daysAgoFilters = ["joined_community_days_ago", "last_logged_in_days_ago"];

export const useShouldShowMemberDaysAgoFilters = filters => {
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = isCommunityAdmin(currentCommunityMember);

  if (isAdmin) {
    return filters;
  }

  return filters.filter(filter => !daysAgoFilters.includes(filter.name));
};
