import { Portal } from "@material-ui/core";
import type { Editor } from "@tiptap/react";
import { t } from "@/i18n-js/instance";
import { BROADCAST_PERSONALIZATION_VARIABLES } from "@circle-react/components/SettingsApp/Emails/components/Broadcasts/constants";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { EmojiPickerV3 } from "@circle-react-shared/uikit/EmojiPickerV3";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { InsertVariableButton } from "@circle-react-shared/uikit/InsertVariableButton";
import { useCTASettingsModal } from "@circle-react-shared/uikit/TipTapBlockEditor/CTAExtension/CTASettingsModal";
import {
  getCurrentRange,
  showSlashCommandMenu,
} from "@circle-react-shared/uikit/TipTapBlockEditor/Toolbar/helpers";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { TextOptionsDropdown } from "./TextOptionsDropdown";
import { ToolbarButton } from "./ToolbarButton";
import { useLinkEmailEditorModal } from "./useLinkEmailEditorModal";

interface CommonHandlerProps {
  editor: Editor;
  range: {
    from: number;
    to: number;
  };
}
interface TiptapToolbarProps {
  editor: Editor;
  portalId: string;
  showImagePickerModal: (props: CommonHandlerProps) => void;
  showGiphyPickerModal: (props: CommonHandlerProps) => void;
  showPollModal: (props: CommonHandlerProps) => void;
  disabledExtensions: any;
  showFileUploadModal: (
    props: CommonHandlerProps & {
      type?: "file" | "video" | "audio";
    },
  ) => void;
}

export const EmailEditorToolbar = ({
  editor,
  portalId,
  showImagePickerModal,
  showGiphyPickerModal,
  disabledExtensions,
  showFileUploadModal,
}: TiptapToolbarProps) => {
  const { handleLinkClick, getIsLinkActive } = useLinkEmailEditorModal(editor);
  const tiptapCTAModal = useCTASettingsModal();

  if (!editor || !portalId) return null;
  return (
    <Portal container={() => document.getElementById(portalId)}>
      <div className="divide-primary flex divide-x">
        <ul className="flex list-none items-center gap-1.5 px-2">
          <li>
            <IconButton
              iconSize={20}
              buttonSize={32}
              name="20-add-circle"
              ariaLabel={t("tiptap.open_slash_commands_menu")}
              onClick={() => {
                showSlashCommandMenu({ editor });
              }}
            />
          </li>
        </ul>
        <ul className="flex list-none items-center gap-1.5 px-2">
          <li>
            <ToolbarButton
              name="16-bold"
              ariaLabel={t("tiptap.bubble_menu.bold")}
              isActive={editor.isActive("bold")}
              onClick={() => {
                editor.chain().focus().toggleBold().run();
              }}
            />
          </li>
          <li>
            <ToolbarButton
              name="16-italic"
              ariaLabel={t("tiptap.bubble_menu.italic")}
              isActive={editor.isActive("italic")}
              onClick={() => {
                editor.chain().focus().toggleItalic().run();
              }}
            />
          </li>
          <li>
            <ToolbarButton
              name="16-string-text"
              ariaLabel={t("tiptap.bubble_menu.strike")}
              isActive={editor.isActive("strike")}
              onClick={() => {
                editor.chain().focus().toggleStrike().run();
              }}
            />
          </li>
          <li>
            <ToolbarButton
              name="16-edit-underline"
              ariaLabel={t("tiptap.bubble_menu.underline")}
              isActive={editor.isActive("underline")}
              onClick={() => {
                editor.chain().focus().toggleUnderline().run();
              }}
            />
          </li>
        </ul>
        <ul className="flex list-none items-center gap-1.5 px-2">
          <li>
            <TextOptionsDropdown editor={editor} />
          </li>
        </ul>
        <ul className="flex list-none items-center gap-1.5 px-2">
          <li>
            <ToolbarButton
              name="16-editor-quote"
              isActive={editor.isActive("blockquote")}
              ariaLabel={t("tiptap.slash_commands.blockquote")}
              onClick={() => {
                editor.chain().focus().toggleBlockquote().run();
              }}
            />
          </li>
          <li>
            <ToolbarButton
              name="16-editor-ol"
              isActive={editor.isActive("orderedList")}
              ariaLabel={t("tiptap.ordered_list")}
              onClick={() => {
                editor.chain().focus().toggleOrderedList().run();
              }}
            />
          </li>
          <li>
            <ToolbarButton
              isActive={editor.isActive("bulletList")}
              name="16-editor-ul"
              ariaLabel={t("tiptap.bullet_list")}
              onClick={() => {
                editor.chain().focus().toggleBulletList().run();
              }}
            />
          </li>
        </ul>
        <ul className="flex list-none items-center gap-1.5 px-2">
          <li>
            <InsertVariableButton
              editor={editor}
              className="text-default"
              wrapperClassName=""
              optionsClassName="z-10 !w-60"
              variables={BROADCAST_PERSONALIZATION_VARIABLES}
            />
          </li>
          <li>
            <ToolbarButton
              name="20-character-link"
              iconSize={20}
              ariaLabel={t("tiptap.link")}
              isActive={getIsLinkActive()}
              onClick={handleLinkClick}
            />
          </li>
          <li>
            <IconButton
              iconSize={16}
              buttonSize={32}
              name="16-editor-divider"
              ariaLabel={t("tiptap.slash_commands.divider")}
              onClick={() => {
                editor.chain().focus().setHorizontalRule().run();
              }}
            />
          </li>
          <li>
            <IconButton
              iconSize={20}
              buttonSize={32}
              iconClassName="!text-dark"
              ariaLabel={t("tiptap.attach_file")}
              name="20-attachment"
              onClick={() =>
                showFileUploadModal({
                  editor,
                  range: getCurrentRange({ editor }),
                })
              }
            />
          </li>
          <li>
            {!disabledExtensions["entities"] && (
              <TippyV2
                placement="top"
                interactive={false}
                content={t("tiptap.add_entity")}
                dataTestId="add_entity_list"
              >
                <IconButton
                  iconSize={20}
                  buttonSize={32}
                  ariaLabel={t("tiptap.add_entity")}
                  name="20-hash"
                  onClick={() => {
                    editor.chain().focus().insertContent(" #").run();
                  }}
                />
              </TippyV2>
            )}
          </li>
          <li className="hidden md:flex">
            <IconButton
              iconSize={20}
              buttonSize={32}
              name="20-gif"
              ariaLabel={t("tiptap.add_gif")}
              onClick={() =>
                showGiphyPickerModal({
                  editor,
                  range: getCurrentRange({ editor }),
                })
              }
            />
          </li>
          <li>
            <IconButton
              iconSize={20}
              buttonSize={32}
              ariaLabel={t("tiptap.add_image")}
              onClick={() =>
                showImagePickerModal({
                  editor,
                  range: getCurrentRange({ editor }),
                })
              }
              name="20-file-image"
            />
          </li>
          {!disabledExtensions["emojipicker"] && (
            <EmojiPickerV3
              placement="bottom-start"
              onEmojiSelect={emojiData =>
                editor.chain().focus().insertContent(emojiData.emoji).run()
              }
              as="li"
              popoverPanelClassName="bg-primary isolate"
              shouldEmojiPickerRenderAsPortal
              targetWrapperClassName="!flex"
              target={
                <IconButton
                  iconSize={20}
                  buttonSize={32}
                  ariaLabel={t("tiptap.add_emoji")}
                  iconClassName="text-default"
                  name="20-reaction"
                />
              }
            />
          )}
        </ul>
        <div className="px-2">
          <button
            className="focus-visible:ring-secondary text-default hover:bg-secondary flex items-center gap-x-1 rounded-md px-2 py-1.5 text-sm focus-visible:ring-1"
            onClick={() =>
              tiptapCTAModal.show({
                editor,
                range: getCurrentRange({ editor }),
              })
            }
            type="button"
          >
            <Typography.LabelMd weight="medium" color="text-default">
              {t("email_editor.cta.button")}
            </Typography.LabelMd>
          </button>
        </div>
      </div>
    </Portal>
  );
};
