import type { PropsWithChildren } from "react";

interface FeatureSummaryProp {
  backgroundColor: string;
}

export const FeatureSummary = ({
  children,
  backgroundColor,
}: PropsWithChildren<FeatureSummaryProp>) => (
  <div
    className="flex w-full flex-col gap-y-5 rounded-xl p-10 md:w-[41%]"
    style={{ backgroundColor }}
  >
    {children}
  </div>
);
