import { Fragment, useMemo } from "react";
import { useFilterValueContext } from "@circle-react-shared/MemberFilters/FilterValueContext";
import { getIsValueEmpty } from "@circle-react-shared/MemberFilters/getIsValueEmpty";
import type {
  BaseFilter,
  RenderFilter,
  RenderProfileFieldFilter,
} from "@circle-react-shared/MemberFilters/types";
import { isProfileFieldBaseFilter } from "@circle-react-shared/MemberFilters/types";

interface DisplayedFiltersProps {
  displayedFilters: BaseFilter[];
  platformFilters: RenderFilter[];
  profileFieldFilters: RenderProfileFieldFilter[];
  onRemoveFilter: (id: string) => void;
}

export function DisplayedFilters({
  displayedFilters,
  onRemoveFilter,
  platformFilters,
  profileFieldFilters,
}: Readonly<DisplayedFiltersProps>) {
  const { filterParams, onApply } = useFilterValueContext();
  const platformFilterMap = useMemo(
    () =>
      platformFilters.reduce((acc, filter) => {
        acc.set(filter.name, filter);
        return acc;
      }, new Map<string, RenderFilter>()),
    [platformFilters],
  );
  const profileFieldFilterMap = useMemo(
    () =>
      profileFieldFilters.reduce((acc, filter) => {
        acc.set(filter.id, filter);
        return acc;
      }, new Map<number, RenderProfileFieldFilter>()),
    [profileFieldFilters],
  );
  const paramValueMap = useMemo(
    () =>
      filterParams.reduce((acc, param) => {
        acc.set(param.id, param);
        return acc;
      }, new Map<string, BaseFilter>()),
    [filterParams],
  );

  return (
    <Fragment>
      {displayedFilters.map((displayedFilter, index) => {
        const { key, id } = displayedFilter;
        let renderFilter = platformFilterMap.get(key);
        if (isProfileFieldBaseFilter(displayedFilter)) {
          renderFilter = profileFieldFilterMap.get(
            Number(displayedFilter.profile_field_id),
          );
        }
        if (!renderFilter) return null;
        const { name, component: Component, ...props } = renderFilter;
        const paramValue = paramValueMap.get(id);
        let isTemplateDefaultOpen = false;
        /** When a workflow is created from a template, it will have value set to "" for initial open. */
        if (paramValue?.value === "") {
          isTemplateDefaultOpen = true;
        }

        return (
          <Component
            key={id}
            name={name}
            index={index}
            param={{ ...displayedFilter, ...paramValue }}
            onApply={(param: BaseFilter) => {
              if (getIsValueEmpty(param)) {
                onRemoveFilter(id);
              }
              onApply?.(id, param);
            }}
            isInitialOpen={
              isTemplateDefaultOpen || displayedFilter.isInitialOpen
            }
            shouldShowRemoveButton
            {...props}
          />
        );
      })}
    </Fragment>
  );
}
