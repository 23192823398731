import { useMdScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { ViewTypes } from "../ListView/CommunityMember/MemberActions";
import { useMembersInfiniteQuery } from "./useMembersInfiniteQuery";
import { useMembersInfiniteQueryV2 } from "./useMembersInfiniteQueryV2";

export interface UseSelectMembersInfiniteQueryProps {
  defaultSort: string;
  scopeResults: object;
  viewType: string;
}

export const useSelectMembersInfiniteQuery = ({
  defaultSort,
  scopeResults,
  viewType,
}: UseSelectMembersInfiniteQueryProps) => {
  const isMd = useMdScreenMediaQuery();
  const desktopMemberParams = useMembersInfiniteQueryV2({
    defaultSort,
    scopeResults,
  });
  const mobileMemberParams = useMembersInfiniteQuery({
    defaultSort,
    scopeResults,
  });

  if (viewType !== ViewTypes.MEMBER_DIRECTORY) {
    return mobileMemberParams;
  }
  return isMd ? desktopMemberParams : mobileMemberParams;
};
