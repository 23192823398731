import type { CSSProperties } from "react";
import classNames from "classnames";
import { t } from "@/i18n-js/instance";
import { Typography } from "@circle-react-uikit/Typography";

export interface PoweredByCircleProps {
  styles?: CSSProperties;
  communitySlug?: string;
  className?: string;
}

export const PoweredByCircle = ({
  styles,
  communitySlug = "",
  className,
}: PoweredByCircleProps) => {
  const url = `https://circle.so/?utm_source=powered-by&community=${communitySlug}`;

  const textColorClass = communitySlug ? "text-c-sidebar" : "text-white";

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(
        "flex w-full items-center gap-1 px-4 py-2.5",
        /* TODO: Provisory until having Circle 3.0 theme styles */
        "opacity-60",
        className,
      )}
      style={styles}
    >
      <Typography.LabelXs color={textColorClass} weight="medium">
        {t("powered_by_circle")}
      </Typography.LabelXs>
    </a>
  );
};
