import RenewalDateText from "@circle-react/components/shared/Paywalls/PaywallSubscriptionList/RenewalDateText";

export const RenewalDateColumn = (cellContext: any) => {
  const {
    row: { original: subscription },
  } = cellContext;

  return (
    <div className="whitespace-normal">
      <RenewalDateText subscription={subscription} />
    </div>
  );
};
