import type { ReactNode } from "react";
import classNames from "classnames";
import { Typography } from "@circle-react-uikit/Typography";

interface FilterContainerProps {
  title: string;
  children?: ReactNode;
}

export const FilterContainer = ({ title, children }: FilterContainerProps) => (
  <div className="flex flex-col">
    <span className={classNames("inline-block px-4")}>
      <Typography.LabelXxs color="text-light">{title}</Typography.LabelXxs>
    </span>
    <div>{children}</div>
  </div>
);
