import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { InfoBox } from "@circle-react-uikit/InfoBox";
import { Item } from "./Item";
import { ItemSummary } from "./ItemSummary";
import { List } from "./List";

const localeRoot = "paywalls.apply_coupon_modal";

export const RemoveCouponInvoice = ({
  subscription,
  previewCouponQuery,
}: any) => {
  const paywallName =
    subscription.paywall.name || subscription.paywall.display_name;
  const paywallPrice = subscription.paywall_price;
  const futurePayments = previewCouponQuery.data.future_payments;

  const couponName = subscription.paywall_coupon_redemption.paywall_coupon.name;
  const couponTerms =
    subscription.paywall_coupon_redemption.paywall_coupon.terms;

  return (
    <List className="gap-y-4">
      <Item
        title={`${paywallName} (${paywallPrice.interval_qualifier})`}
        titleWeight="normal"
      >
        <Typography.LabelMd>{paywallPrice.amount_formatted}</Typography.LabelMd>
      </Item>
      <hr />
      <Item title={t([localeRoot, "recurring_plan_charges"])}>
        <Typography.LabelMd>{paywallPrice.amount_formatted}</Typography.LabelMd>
      </Item>
      <hr />
      <List className="gap-y-2">
        <ItemSummary title={t([localeRoot, "subtotal"])}>
          <Typography.LabelSm>
            {futurePayments.formatted_subtotal}
          </Typography.LabelSm>
        </ItemSummary>
        <ItemSummary title={t([localeRoot, "tax"])}>
          <Typography.LabelSm>
            {futurePayments.formatted_tax}
          </Typography.LabelSm>
        </ItemSummary>
      </List>
      <hr />
      <Item title={t([localeRoot, "total_due_at_next_charge"])}>
        <Typography.LabelMd weight="semibold">
          {futurePayments.formatted_amount_due}
        </Typography.LabelMd>
      </Item>
      <InfoBox className="flex flex-row gap-3">
        <div>
          <Icon type="information-outline" size={24} />
        </div>
        <Typography.LabelSm weight="regular" as="div">
          {t([localeRoot, "removing_coupon_warning"], {
            coupon_name: couponName,
            coupon_terms: couponTerms,
          })}
        </Typography.LabelSm>
      </InfoBox>
    </List>
  );
};
