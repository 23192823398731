import { useEffect, useMemo } from "react";
import { t } from "@/i18n-js/instance";
import { PAYWALL_COUPON_STATUSES } from "@circle-react/helpers/paywallCouponHelpers";
import { usePaywallCouponList } from "@circle-react/hooks/paywalls/usePaywallCouponList";
import { useCreatePaywallSubscriptionFormContext } from "../CreatePaywallSubscriptionFormContextProvider";

const noCouponCodeStringDefault = t("add_paywall_coupon_modal.select_coupon");
export const couponStatusesDefault = PAYWALL_COUPON_STATUSES;

export const useCouponForm = ({
  paywallId,
  selectCouponString = noCouponCodeStringDefault,
  couponStatuses = couponStatusesDefault,
  skipDateValidations = true,
}) => {
  const { paywallCouponsListQuery } = usePaywallCouponList({
    paywallId: paywallId,
    couponStatuses,
    skipDateValidations,
  });

  const [couponCodeOptions, couponMappedByCouponCode] = useMemo(() => {
    const couponsRaw = paywallCouponsListQuery?.data || [];
    const couponCodeOptions = [{ label: selectCouponString, value: "" }];
    const couponMappedByCouponCode = [];

    couponsRaw.forEach(coupon => {
      couponCodeOptions.push({
        label: coupon.code,
        value: coupon.code,
      });
      couponMappedByCouponCode[coupon.code] = coupon;
    });

    return [couponCodeOptions, couponMappedByCouponCode];
  }, [selectCouponString, paywallCouponsListQuery.data]);

  const { setCouponsIndex } = useCreatePaywallSubscriptionFormContext();

  useEffect(() => {
    setCouponsIndex(couponMappedByCouponCode ?? {});
  }, [couponMappedByCouponCode, setCouponsIndex]);

  return {
    paywallCouponsListQuery,
    couponCodeOptions,
  };
};
