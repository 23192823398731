import type { IconType } from "@circle-react-shared/Icon";
import { LinkItem } from "../CollapsedSidebar/LinkItem";

interface NavGroupIconProps {
  label: string;
  path: string;
  icon: IconType;
}

export const NavGroupIcon = ({ label, path, icon }: NavGroupIconProps) => (
  <LinkItem label={label} path={path} icon={icon} />
);
