import { useRef } from "react";
import type { Editor } from "@tiptap/react";
import { noop } from "lodash";
import invariant from "tiny-invariant";
import type { BroadcastContentAttributes } from "@circle-react/components/SettingsApp/Emails/hooks/useGetBroadcast";
import type { useUpdateBroadcast } from "@circle-react/components/SettingsApp/Emails/hooks/useUpdateBroadcast";
import { FullScreenModal } from "@circle-react-shared/ModalsV2/FullScreenModal";
import { EmailEditorForm } from "./EmailEditorForm";
import { EmailEditorHeader } from "./Header";
import { TipTapEditor } from "./TipTapEditor";

interface EmailEditorProps {
  update: ReturnType<typeof useUpdateBroadcast>;
  onClose: () => void;
}

export const EmailEditor = ({ update, onClose }: EmailEditorProps) => {
  const { mutateAsync, isLoading: isSubmitting } = update;
  const editorRef = useRef<Editor>(null);
  const triggerFormSubmitRef = useRef<() => Promise<void>>(null);

  const handleSubmit = async (data: {
    email_content: BroadcastContentAttributes["email_content"];
  }) => {
    await mutateAsync({
      body: {
        broadcast_content: {
          email_content: data.email_content,
        },
      },
    });
  };

  return (
    <FullScreenModal isOpen size="screen" onClose={noop}>
      <EmailEditorForm
        triggerFormSubmitRef={triggerFormSubmitRef}
        onSubmit={handleSubmit}
      >
        <EmailEditorHeader
          isSubmitting={isSubmitting}
          onSubmit={() => {
            invariant(
              triggerFormSubmitRef.current,
              "Form submit ref is missing",
            );
            return triggerFormSubmitRef.current();
          }}
          onClose={onClose}
        />
        <div
          id="email-editor-toolbar-portal"
          className="border-primary flex items-center justify-center border-b py-2.5"
        />
        <FullScreenModal.Body className="mx-auto w-full overflow-y-auto py-6 md:py-10">
          <TipTapEditor editorRef={editorRef} />
        </FullScreenModal.Body>
      </EmailEditorForm>
    </FullScreenModal>
  );
};
