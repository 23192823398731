import { FeatureCards } from "./FeatureCards";
import { FooterAction } from "./FooterAction";
import { HelloBar } from "./HelloBar";
import { HeroSection } from "./HeroSection";

export const FeatureLandingPage = {
  HelloBar,
  HeroSection,
  FeatureCards,
  FooterAction,
};
