import PropTypes from "prop-types";
import { ExpandableBlock } from "@circle-react-uikit/ExpandableBlock";
import { useItemData } from "./ItemDataProvider";

export const Panel = ({ className, panelClassName, children }) => {
  const { renderPanel, onCloseItemAndOpenNext } = useItemData();

  return (
    <ExpandableBlock.Panel className={panelClassName}>
      {renderPanel ? (
        renderPanel({ onCloseItemAndOpenNext })
      ) : (
        <div className={className}>{children}</div>
      )}
    </ExpandableBlock.Panel>
  );
};

Panel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
