import { Link } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import { hasRevenueCatPaymentProcessor } from "@circle-react/helpers/paymentProcessorHelpers";
import { settingsPath } from "@circle-react/helpers/urlHelpers";
import { CustomerColumn } from "@circle-react-shared/Paywalls/CustomerColumn";
import { PaywallSubscriptionBadge } from "@circle-react-shared/Paywalls/PaywallSubscriptionBadge";
import { Typography } from "@circle-react-uikit/Typography";
import { CouponField } from "./Fields/CouponField";
import { RenewsOrEndsAtDetails } from "./RenewsOrEndsAtDetails";

const localeStringRoot =
  "settings.paywalls_admin_dashboard.subscriptions.subscription_details_modal";

export const PaywallSubscriptionDetails = ({
  subscription,
  shouldOpenAdminMemberEdit,
}) => {
  const {
    currentCommunity: { payment_processors: currentCommunityPaymentProcessors },
  } = usePunditUserContext();

  const createdAtString = formattedDateTime({
    dateTime: new Date(subscription.created_at),
    format: "long_date",
  });

  const generateTransactionLink = () =>
    `${settingsPath.paywallsAdminDashboard.charges()}?subscription_processor_id=${
      subscription.processor_id
    }`;

  return (
    <div>
      <div className="border-b-2 py-4">
        <CustomerColumn
          customer={subscription.community_member}
          shouldOpenAdminMemberEdit={shouldOpenAdminMemberEdit}
        />
      </div>
      <div className="grid grid-cols-1 gap-y-3 pb-6 pt-4">
        <div>
          <div>
            <Typography.LabelSm weight="semibold">
              {t(`${localeStringRoot}.labels.paywall_name`)}
            </Typography.LabelSm>
          </div>
          <Typography.LabelSm>
            {subscription.paywall.name || subscription.paywall.display_name}
          </Typography.LabelSm>
        </div>
        {hasRevenueCatPaymentProcessor(currentCommunityPaymentProcessors) && (
          <div>
            <div>
              <Typography.LabelSm weight="semibold">
                {t(`${localeStringRoot}.labels.platform.title`)}
              </Typography.LabelSm>
            </div>
            <Typography.LabelSm>
              {t(
                `${localeStringRoot}.labels.platform.${subscription.platform}`,
              )}
            </Typography.LabelSm>
          </div>
        )}
        <div>
          <div>
            <Typography.LabelSm weight="semibold">
              {t(`${localeStringRoot}.labels.subscription_id`)}
            </Typography.LabelSm>
          </div>
          <Typography.LabelSm>{subscription.processor_id}</Typography.LabelSm>
        </div>

        <div>
          <div>
            <Typography.LabelSm weight="semibold">
              {t(`${localeStringRoot}.labels.transactions`)}
            </Typography.LabelSm>
          </div>
          <Link
            className="text-dark hover:text-dark visited:text-dark underline"
            to={generateTransactionLink()}
            target="_blank"
          >
            <Typography.LabelSm>
              {subscription.charges_count}
            </Typography.LabelSm>
          </Link>
        </div>

        <div>
          <div>
            <Typography.LabelSm weight="semibold">
              {t(`${localeStringRoot}.labels.status`)}
            </Typography.LabelSm>
          </div>
          <PaywallSubscriptionBadge subscription={subscription} />
        </div>

        <div>
          <div>
            <Typography.LabelSm weight="semibold">
              {t(`${localeStringRoot}.labels.start_date`)}
            </Typography.LabelSm>
          </div>
          <Typography.LabelSm>{createdAtString}</Typography.LabelSm>
        </div>

        <div className="flex flex-col gap-3">
          <RenewsOrEndsAtDetails
            subscription={subscription}
            textColor="text-primary"
          />
        </div>

        <div>
          <div>
            <Typography.LabelSm weight="semibold">
              {t(`${localeStringRoot}.labels.price`)}
            </Typography.LabelSm>
          </div>
          <Typography.LabelSm>
            {subscription.paywall_price.short_description}
          </Typography.LabelSm>
        </div>
        <CouponField subscription={subscription} />
      </div>
    </div>
  );
};
