import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import { Popover } from "@headlessui/react";
import delay from "lodash/delay";
import isFunction from "lodash/isFunction";
import { usePopper } from "react-popper";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";
import { TruncateText } from "@circle-react-shared/uikit/TruncateText";
import { Typography } from "@circle-react-shared/uikit/Typography";

export interface FilterPopoverPanelProps {
  title?: ReactNode;
  referenceElement?: HTMLLIElement | null;
  isInitialOpen?: boolean;
  shouldShowRemoveButton?: boolean;
  handleApply: (value: any) => void;
  children: ReactNode;
  childrenProps?: any;
}

export const FilterPopoverPanel = ({
  title,
  referenceElement,
  isInitialOpen,
  shouldShowRemoveButton,
  handleApply,
  children,
  childrenProps,
}: FilterPopoverPanelProps) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
  });

  useEffect(() => {
    if (referenceElement && isInitialOpen) {
      delay(() => {
        referenceElement.click();
      }, 100);
    }
  }, [referenceElement, isInitialOpen]);

  return (
    <Popover.Panel
      ref={setPopperElement}
      style={styles.popper}
      className="isolate z-10 w-[336px] max-w-md"
      data-testid="filter_component"
      {...attributes.popper}
    >
      <div className="bg-primary border-primary mt-1 w-full rounded-lg border shadow-xl">
        {title && (
          <div className="border-b-primary flex items-center justify-between border-b px-4 py-3">
            <TruncateText>
              <Typography.LabelMd weight="semibold" truncate>
                {title}
              </Typography.LabelMd>
            </TruncateText>
            {shouldShowRemoveButton && (
              <button
                title={t("members_directory.header.search.remove_filter")}
                type="button"
                className="text-default"
                onClick={() => handleApply(null)}
              >
                <Icon size={16} type="16-trash-bin" useWithStrokeCurrentColor />
              </button>
            )}
          </div>
        )}
        {isFunction(children) ? children(childrenProps) : children}
      </div>
    </Popover.Panel>
  );
};
