import { t } from "@/i18n-js/instance";
import mobilePicture1x from "../../../../../../assets/images/features/in-app-payments/mobile-1x.png";
import mobilePicture2x from "../../../../../../assets/images/features/in-app-payments/mobile-2x.png";
import mobilePicture4x from "../../../../../../assets/images/features/in-app-payments/mobile-4x.png";

const i18nRoot = "settings.general.branding.in_app.landing_page";

export const LandingPage = () => (
  <main className="grid-in-main flex h-full w-full flex-row justify-center gap-x-9 bg-[#191B1F] px-[72px]">
    <div className="flex max-w-[35rem] flex-col gap-y-7 self-center">
      <span className="text-5xl font-extrabold tracking-[-1px] text-[#F7F9FA]">
        {t([i18nRoot, "title", "first_part"])}
        <span className="text-[#EE8263]">
          {t([i18nRoot, "title", "second_part"])}
        </span>
      </span>
      <span className="text-lg font-medium leading-7 tracking-[-0.4px] text-[#E4E7EB]">
        {t([i18nRoot, "subtitle"])}
      </span>
    </div>
    <div
      className="flex w-[30rem] items-center pt-8"
      style={{
        background:
          "linear-gradient(360deg, #191B1F 29.75%, rgba(25, 27, 31, 0) 66.67%)",
      }}
    >
      <img
        alt=""
        loading="eager"
        src={mobilePicture1x}
        srcSet={`${mobilePicture1x} 663w, ${mobilePicture2x} 812w, ${mobilePicture4x} 2000w`}
      />
    </div>
  </main>
);
