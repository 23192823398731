import type { PropsWithChildren } from "react";
import classNames from "classnames";

interface FeaturePreviewProp {
  backgroundColor: string;
  className?: string;
}

export const FeaturePreview = ({
  children,
  backgroundColor,
  className,
}: PropsWithChildren<FeaturePreviewProp>) => (
  <div
    className={classNames("w-full rounded-xl md:w-[60%]", className)}
    style={{ backgroundColor }}
  >
    {children}
  </div>
);
