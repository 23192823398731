import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Container } from "./Container";

const BlockWithConnectorData = createContext({
  items: [],
  activeItemId: undefined,
  setActiveItemId: noop,
  onToggleItem: noop,
  onCloseItemAndOpenNext: noop,
});
BlockWithConnectorData.displayName = "BlockWithConnectorData";

export const useBlockWithConnectorData = () =>
  useContext(BlockWithConnectorData);

export const BlockWithConnectorDataProvider = ({
  items = [],
  defaultOpenId,
  children,
  closeOnBlur = false,
}) => {
  const [activeItemId, setActiveItemId] = useState(
    defaultOpenId || items[0]?.id,
  );

  const onToggleItem = id => {
    setActiveItemId(activeItemId === id ? undefined : id);
  };

  const onCloseItemAndOpenNext = () => {
    const nextActiveItem = getNextItemId();
    setActiveItemId(nextActiveItem);
  };

  const getNextItemId = () => {
    const index = items.findIndex(arrayItem => arrayItem.id === activeItemId);
    if (index !== -1) {
      return items[index + 1]?.id;
    }
  };

  return (
    <BlockWithConnectorData.Provider
      value={{
        items,
        activeItemId,
        setActiveItemId,
        onToggleItem,
        onCloseItemAndOpenNext,
      }}
    >
      <Container closeOnBlur={closeOnBlur}>{children}</Container>
    </BlockWithConnectorData.Provider>
  );
};

BlockWithConnectorDataProvider.propTypes = {
  items: PropTypes.array,
  defaultOpenId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
  closeOnBlur: PropTypes.bool,
};
