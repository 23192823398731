import { TruncateText } from "@circle-react-shared/uikit/TruncateText";

export const PaywallColumn = (cellContext: any) => {
  const {
    row: { original: subscription },
  } = cellContext;

  return (
    <span className="grid pr-4">
      <TruncateText>
        {subscription.paywall.name || subscription.paywall.display_name}
      </TruncateText>
    </span>
  );
};
