import PropTypes from "prop-types";
import { useFocusWithin } from "@circle-react/hooks/utils/useFocusWithin";
import { useBlockWithConnectorData } from "./BlockWithConnectorDataProvider";

export const Container = ({ closeOnBlur = false, children, ...rest }) => {
  const { setActiveItemId } = useBlockWithConnectorData();

  const onBlurWithinHandler = () => {
    setActiveItemId(undefined);
  };

  const { focusWithinProps } = useFocusWithin({
    onBlurWithin: onBlurWithinHandler,
  });

  if (!closeOnBlur) {
    return <div {...rest}>{children}</div>;
  }

  return (
    <div {...rest} {...focusWithinProps}>
      {children}
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  closeOnBlur: PropTypes.bool,
};
