import { AmountPaidProperty } from "./Properties/AmountPaidProperty";
import { AmountRefundedProperty } from "./Properties/AmountRefundedProperty";
import { ChargeInvoiceIdProperty } from "./Properties/ChargeInvoiceIdProperty";
import { ChargeProcessorIdProperty } from "./Properties/ChargeProcessorIdProperty";
import { ChargeStatusProperty } from "./Properties/ChargeStatusProperty";
import { CouponAppliedProperty } from "./Properties/CouponAppliedProperty";
import { CreatedAtProperty } from "./Properties/CreatedAtProperty";
import { CustomerProperty } from "./Properties/CustomerProperty";
import { PaymentMethodProperty } from "./Properties/PaymentMethodProperty";
import { PaywallNameProperty } from "./Properties/PaywallNameProperty";
import { PlatformProperty } from "./Properties/PlatformProperty";
import { SubscriptionProcessorIdProperty } from "./Properties/SubscriptionProcessorIdProperty";

export const ChargeDetails = ({ charge, shouldOpenAdminMemberEdit }) => (
  <div>
    <CustomerProperty
      communityMember={charge.community_member}
      shouldOpenAdminMemberEdit={shouldOpenAdminMemberEdit}
    />
    <div className="grid grid-cols-1 gap-y-3 pb-6 pt-4">
      <CreatedAtProperty charge={charge} />
      <AmountPaidProperty charge={charge} />
      <AmountRefundedProperty charge={charge} />
      <ChargeStatusProperty charge={charge} />
      <ChargeProcessorIdProperty charge={charge} />
      <ChargeInvoiceIdProperty charge={charge} />
      <SubscriptionProcessorIdProperty charge={charge} />
      <PaywallNameProperty charge={charge} />
      <PlatformProperty charge={charge} />
      <CouponAppliedProperty charge={charge} />
      <PaymentMethodProperty charge={charge} />
    </div>
  </div>
);
