import { CustomerColumn } from "@circle-react-shared/Paywalls/CustomerColumn";

export const CustomerProperty = ({
  communityMember,
  shouldOpenAdminMemberEdit,
}: any) => (
  <div className="border-b-2 py-4">
    <CustomerColumn
      customer={communityMember}
      shouldOpenAdminMemberEdit={shouldOpenAdminMemberEdit}
    />
  </div>
);
