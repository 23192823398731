import { t } from "@/i18n-js/instance";
import featurePreview1x from "../../../../../../../assets/images/features/marketing-hub/landing-page/feature-2_1x.png";
import featurePreview2x from "../../../../../../../assets/images/features/marketing-hub/landing-page/feature-2_2x.png";
import featurePreview4x from "../../../../../../../assets/images/features/marketing-hub/landing-page/feature-2_4x.png";
import { Blocks } from "../Blocks";
import { localeRoot as marketingHubLocale } from "../constants";

const localeRoot = `${marketingHubLocale}.blocks.marketing_automation`;

const backgroundColors = {
  primary: "#B0EBD9",
  secondary: "#EBFAF5",
};

export const MarketingAutomationFeature = () => (
  <Blocks>
    <Blocks.FeatureSummary backgroundColor={backgroundColors.secondary}>
      <Blocks.Header
        localeRoot={localeRoot}
        iconName="20-lightning"
        backgroundColor={backgroundColors.primary}
      />
      <Blocks.FeatureList>
        <Blocks.FeatureItem backgroundColor={backgroundColors.primary}>
          {t([localeRoot, "features", "0"])}
        </Blocks.FeatureItem>
        <Blocks.FeatureItem backgroundColor={backgroundColors.primary}>
          {t([localeRoot, "features", "1"])}
        </Blocks.FeatureItem>
        <Blocks.FeatureItem backgroundColor={backgroundColors.primary}>
          {t([localeRoot, "features", "2"])}
        </Blocks.FeatureItem>
      </Blocks.FeatureList>
    </Blocks.FeatureSummary>
    <Blocks.FeaturePreview
      backgroundColor={backgroundColors.primary}
      className="flex flex-col items-center pt-12"
    >
      <img
        src={featurePreview1x}
        alt=""
        loading="eager"
        srcSet={`${featurePreview1x} 1000px, ${featurePreview2x} 1500px, ${featurePreview4x} 2000w`}
        className="w-[200px] md:w-[300px]"
      />
    </Blocks.FeaturePreview>
  </Blocks>
);
